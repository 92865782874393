<template>
  <portal selector="#Reservations">
    <div class="downloadreportSearchContainer"  :style="{ top: (event.pageY + 22) + 'px', left: event.pageX + 'px' }" v-click-outside="toggleMenuM">
    <div
      id="downloadReportSearch"
    >
      <header class="titlereport">¿ Que reporte deseas descargar ?
      </header>
      <main class="box-report">
        <section class="reportOptionsContainer">
          <label v-for="reportOption in reportOptions" :key="reportOption.value" :class="{active:reportSelected===reportOption.value}">
            <input
              type="radio"
              v-model="reportSelected"
              :value="reportOption.value"
            />
            {{ reportOption.name }}
          </label>
        </section>
        <section class="reportType" :class="{notReadyForPick:!reportSelected}">
          <h2>Escoge que tipo de archivo</h2>
          <nav>
            <img src="../../assets/icons/files/excel.svg" alt="Excel Icon" @click="()=>typeFile='xml'" :class="{active:typeFile==='xml'}">
            <img src="../../assets/icons/files/pdf.svg" alt="PDF Icon" @click="()=>typeFile='pdf'" :class="{active:typeFile==='pdf'}">
          </nav>
        </section>
        <button class="downloadButton" :class="{active:reportSelected && typeFile && !generate}" @click="isDownload">{{generate ? 'Generando Reporte ...': 'Descargar reporte'}}</button>
      </main>
    </div>
    </div>
  </portal>
</template>

<script>
import reservationTypes from '@/store/types/reservation';
import axios from 'axios';
import { Portal as portal } from '@linusborg/vue-simple-portal';
import { mapGetters } from 'vuex';
export default {
  components: { portal },
  props: ['event'],
  data () {
    return {
      typeFile: null,
      download: false,
      reportOptions: [
        {
          value: 'upcoming',
          name: 'Reservas Proximas'
        },
        {
          value: 'finished',
          name: 'Reservas Finalizadas'
        },
        {
          value: 'all',
          name: 'Todas las reservas'
        }
      ],
      reportSelected: null,
      generate: false
    };
  },
  methods: {
    isOpen () {
      this.$store
        .dispatch({
          type: 'reservation:deleteReport',
          data: { url: this.apiReportDownload }
        })
        .then(() => {
          this.$store.commit(
            reservationTypes.mutations.setApiReportDownload,
            null
          );
        })
        .catch(() => {
          this.$store.commit(
            reservationTypes.mutations.setApiReportDownload,
            null
          );
        });
      this.$emit('close-emit');
    },
    isDownload () {
      this.download = true;
      axios({
        url:
          process.env.VUE_APP_URL_REPORT +
          '/downloadReport/' +
          this.apiReportDownload,
        responseType: 'blob',
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('_token')
        }
      })
        .then((response) => {
          this.download = false;
          const year = this.$moment().year();
          const hour = this.$moment().hours();
          const hour2 = hour <= 9 ? '0' + hour : hour;
          const minute = this.$moment().minutes();
          const minute2 = minute <= 9 ? '0' + minute : minute;
          const second = this.$moment().seconds();
          const second2 = second <= 9 ? '0' + second : second;
          const month = this.$moment().month() + 1;
          const month2 = month <= 9 ? '0' + month : month;
          const date = this.$moment().date();
          const date2 = date <= 9 ? '0' + date : date;
          const filename =
            year +
            '_' +
            month2 +
            '_' +
            date2 +
            '_' +
            hour2 +
            ':' +
            minute2 +
            ':' +
            second2;
          if (this.typeApiReportDownload === 'xlsx') {
            const blob = new Blob([response.data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,'
            });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'Reporte_Reservas_' + filename + '.xlsx';
            link.click();
            this.isOpen();
          } else {
            const blob = new Blob([response.data], { type: 'application/pdf' });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'Reporte_Reservas_' + filename + '.pdf';
            link.click();
            this.isOpen();
          }
        })
        .catch(() => {
          this.$buefy.toast.open({
            message: 'Lo sentimos, no hemos podido encontrar el archivo',
            type: 'is-danger'
          });
        });
    },
    toggleMenuM () {
      this.$store.commit(reservationTypes.mutations.setApiReportDownload, null);
      this.download = false;
      this.$emit('close-emit');
    },
    generateReport (date) {
      this.generate = true;
      if (this.vendor.printPrevius === 1 && this.today !== this.selectedDate) {
        this.$store
          .dispatch({
            type: 'reservation:generateReportPrevius',
            data: {
              date: this.encrypt(date),
              vendorId: this.vendorId,
              token: window.localStorage.getItem('_token'),
              type: this.typeFile,
              filter: this.reportSelected,
              mealType: this.mealTypeDay
            }
          })
          .then(({ data }) => {
            this.$store.commit(
              reservationTypes.mutations.setApiReportDownload,
              data.urlApi
            );
            this.$store.commit(
              reservationTypes.mutations.setTypeApiReportDownload,
              data.type
            );
            this.generate = false;
          })
          .catch((e) => {
            this.reportSelected = null;
            this.typeFile = null;
            if (e.response) {
              if (e.response.status === 404) {
                this.$buefy.toast.open({
                  message: 'No hay reservas disponibles con este filtro para generar un reporte de este dia',
                  type: 'default'
                });
                return;
              }
            }
            this.$buefy.toast.open({
              message: 'Lo siento, ha ocurrido un error al intentar generar el reporte',
              type: 'is-danger'
            });
            this.generate = false;
          });
      } else {
        this.$store
          .dispatch({
            type: 'reservation:generateReport',
            data: {
              date: this.encrypt(date),
              vendorId: this.vendorId,
              token: window.localStorage.getItem('_token'),
              type: this.typeFile,
              filter: this.reportSelected,
              mealType: this.mealTypeDay
            }
          })
          .then(({ data }) => {
            this.$store.commit(
              reservationTypes.mutations.setApiReportDownload,
              data.urlApi
            );
            this.$store.commit(
              reservationTypes.mutations.setTypeApiReportDownload,
              data.type
            );
            this.generate = false;
          })
          .catch((e) => {
            this.reportSelected = null;
            this.typeFile = null;
            if (e.response) {
              if (e.response.status === 404) {
                this.$buefy.toast.open({
                  message: 'No hay reservas disponibles con este filtro para generar un reporte de este dia',
                  type: 'is-dark'
                });
                this.generate = false;
                return;
              }
            }
            this.$buefy.toast.open({
              message: 'Lo siento, ha ocurrido un error al intentar generar el reporte',
              type: 'is-danger'
            });
            this.generate = false;
          });
      }
    },
    readyForDownload () {
      if (this.reportSelected && this.typeFile) {
        this.generateReport(this.selectedDate);
      }
    }
  },
  watch: {
    reportSelected () {
      this.readyForDownload();
    },
    typeFile () {
      this.readyForDownload();
    }
  },
  computed: {
    ...mapGetters({
      mealTypeDay: [reservationTypes.getters.mealTypeDay]
    })
  }
};
</script>
<style lang="scss">
.disable{
  filter: grayscale(60%);
  opacity: 0.6;
  pointer-events: none;
}
.downloadreportSearchContainer{
    transform: translateX(-92%);
    z-index: 1000;
  position: absolute;
  width: 265px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  align-content: space-between;
  background-color: transparent;
  height: fit-content;
    &::after{
    position: absolute;
    top: -5px;
    left: 91%;
    background-color: #444b57;
    display: block;
    width: 10px;
    height: 10px;
    transform: rotate(45deg);
    content: "";
  }
}
#downloadReportSearch {
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.208);
    border-radius: 12px;
  background-color: white;
  width: 100%;
  overflow: hidden;
  height: fit-content;
  .titlereport {
    background-color: #444b57;
    font-weight: 500;
    font-size: 13px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 14px;
    width: 100%;
  }
  .box-report {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding: 3px 9px 20px 9px;
    height: fit-content;
    .downloadButton{
      border: unset;
      outline: unset;
      background-color: #444b57;
      color: white;
      padding: 11px 18px;
      border-radius:16px;
      width: 70%;
      font-weight: 500;
      font-size: 13px;
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0.6;
      &.active{
        pointer-events: all;
        opacity: 1;
        cursor: pointer;
      }
    }
    .reportType{
      display: flex;
      align-items: center;
      flex-direction: column;
      padding-bottom: 20px;
      &.notReadyForPick{
        opacity: 0.5;
        pointer-events: none;
      }
      h2{
        font-weight: 500;
        font-size: 14px;
        color: #444b57;
        padding: 8px;
        padding-bottom: 12px;
      }
      nav{
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        width: 100%;
        padding:3px 0px;
        img{
          widows: 48px;
          height: 52px;
          border: #707070b3 solid 1px;
          border-radius: 4px;
          padding: 2px;
          cursor: pointer;
          &.active{
            border: #444b57 solid 2px;
            box-shadow: 0px 10px 15px -12px rgba(0,0,0,0.75);
          }
        }
      }
    }
    .reportOptionsContainer {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      flex-direction: column;
      gap: 2px;
      input[type="radio"] {
        border-radius: 3px;
        overflow: hidden;
        position: relative;
        width: 15px;
        height: 15px;
        border: solid 1px rgba(0, 0, 0, 0.498);
        /* Add if not using autoprefixer */
        -webkit-appearance: none;
        appearance: none;
        /* For iOS < 15 to remove gradient background */
        background-color: #fff;
        /* Not removed via appearance */
        margin: 0;
        &::before {
          display: block;
          content: "";
          height: 100%;
          width: 100%;
          background-color: #444b57;
          opacity: 0;
        }
        &:checked::before {
opacity: 1;
        }
                &::after {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 2px;
          display: block;
          margin: auto;
          content: "";
          height: 10px;
          width: 6px;
          background-color: transparent;
          border-bottom: solid 2px white;
          border-right: solid 2px white;
          transform: rotate(40deg);
          opacity: 0;
        }
        &:checked::after {
          opacity: 1;
        }
      }
      label {
        font-size: 14px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        min-width: 100%;
        max-width: 100%;
        padding: 0px 28px;
        gap: 40px;
        border: solid rgba(128, 128, 128, 0.276) 1px;
        height: 45px;
        &.active{
          outline: #444b57 solid 2px;
        }
      }
    }
    .btnreport {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #707070;
      color: #444b57;
      background-color: transparent;
      border-radius: 20px;
      font-size: 13px;
      font-weight: bold;
      padding: 3px 12% 3px 10%;
      width: 125px;
      margin-right: 5px;
    }
  }
}
.adjust i {
  position: relative !important;
  font-size: 12px !important;
  color: #000 !important;
  margin-right: 5px !important;
  right: 5px !important;
  margin-top: 0px !important;
}
</style>
