<template>
  <div id="sumaryg">
    <div style="margin:14px 0 0 0" v-if="dataReservation">
      <div
          class="report-icon"
          style="display: flex; justify-content: space-around; margin-bottom: 4px;"
        >
          <p class="text bold tittle">Reporte General</p>
          <i v-if="!generate" @click="generateReport()" class="icon_download icon-mks black"></i>
          <i
            class="fa fa-spinner fa-spin"
            v-if="generate"
          ></i>
      </div>
      <div :class="{'reservas': true}" class="header-color margin-bot">Total Reservas</div>
      <div
        class="porcentaje"
      >{{ Math.round((dataReservation.resevationReservations * 100) / dataReservation.reservas) }} %</div>
      <div class="flex-container margin-bot padding-bot border-bot">
        <div class="col">
          <i class="icon_person icon-mks normal gray-blue"></i>
          <p class="text bold numG">{{ dataReservation.resevationPeople }}</p>
          <div class="text small">No. de personas</div>
        </div>
        <div class="col">
          <i class="icon_reservation icon-mks reservation gray-blue"></i>
          <p class="text numG">{{ dataReservation.resevationReservations }}</p>
          <div class="text small">No. de reservas</div>
        </div>
      </div>
      <div :class="{'precompra': true}" class="header-color margin-bot">Total Precompras</div>
      <div
        class="porcentaje"
      >{{ Math.round((dataReservation.prePurchaseReservations * 100) / dataReservation.reservas) }} %</div>
      <div class="flex-container margin-bot padding-bot border-bot">
        <div class="col">
          <i class="icon_person icon-mks normal gray-blue"></i>
          <p class="text bold numG">{{ dataReservation.prePurchasePeople }}</p>
          <div class="text small">No. de personas</div>
        </div>
        <div class="col">
          <i class="icon_reservation icon-mks reservation gray-blue"></i>
          <p class="text numG">{{ dataReservation.prePurchaseReservations }}</p>
          <div class="text small">No. de reservas</div>
        </div>
      </div>
      <div :class="{'walking': true}" class="header-color margin-bot">Total Walk-In</div>
      <div
        class="porcentaje"
      >{{ Math.round((dataReservation.walkinReservations * 100) / dataReservation.reservas) }} %</div>
      <div class="flex-container margin-bot padding-bot border-bot">
        <div class="col">
          <i class="icon_person icon-mks normal gray-blue"></i>
          <p class="text bold numG">{{ dataReservation.walkinPeople }}</p>
          <div class="text small">No. de personas</div>
        </div>
        <div class="col">
          <i class="icon_reservation icon-mks reservation gray-blue"></i>
          <p class="text numG">{{ dataReservation.walkinReservations }}</p>
          <div class="text small">No. de reservas</div>
        </div>
      </div>
      <div style="padding:0 10px">
        <p class="label-date">Filtrar Rango Fechas</p>
        <div class="header-Date"></div>
        <div class="flex-container evenly" id="datePicker">
          <div class="col">
            <b-field>
              <i class="icon_calendar_voild calendar icon-mks black"></i>
              <span class="the_calendar_day">{{ dateBar_day }}</span>
              <p class="text bold small paddin-rigth">Fecha De inicio</p>
              <date-pick :inputAttributes="{placeholder: 'Seleccione Fecha'}" v-model="dateFrom"></date-pick>
            </b-field>
          </div>
          <div class="col">
            <b-field>
              <i class="icon_calendar_voild calendar icon-mks black"></i>
              <span class="the_calendar_day">{{ dateBar_day2 }}</span>
              <p class="text bold small paddin-rigth">Fecha Fin</p>
              <date-pick :inputAttributes="{placeholder: 'Seleccione Fecha'}" v-model="dateTo"></date-pick>
            </b-field>
          </div>
        </div>
        <div class="flex-container evenly margin-bot">
          <a
            :class="{'active': daySection == 3}"
            @click="getDataByFilter('DESAYUNO',3)"
            class="day-b"
          >
            <i class="icon_moon icon-mks gray-blue small"></i>
            <p class="text bold grey tittle">Desayuno</p>
          </a>
          <a
            :class="{'active': daySection == 1}"
            @click="getDataByFilter('ALMUERZO',1)"
            class="day-b"
          >
            <i class="icon_sun icon-mks gray-blue small"></i>
            <p class="text bold grey tittle">Almuerzo</p>
          </a>
          <a :class="{'active': daySection == 2}" @click="getDataByFilter('CENA',2)" class="day-b">
            <i class="icon_moon icon-mks gray-blue small"></i>
            <p class="text bold grey tittle">Cena</p>
          </a>
        </div>
        <div class="flex-container center margin-bot padding-bot border-bot">
          <a @click="clearDate()" class="btn">
            <i class="icon_trash icon-mks black trash"></i>LIMPIAR
          </a>
        </div>
        <div class="year">
          <b-field label="Año">
            <b-select expanded placeholder="Seleccione un año" v-model="year">
              <option :key="option.id" :value="option.id" v-for="option in years">{{ option.id }}</option>
            </b-select>
          </b-field>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DatePick from 'vue-date-pick';
import { mapGetters } from 'vuex';
import 'vue-date-pick/dist/vueDatePick.css';
import metricsTypes from '@/store/types/metrics';
import globalTypes from '@/store/types/global';
import serviceReport from '@/helpers/apis/serviceReport';
import axios from 'axios';
const MIN_YEARS = 5;
export default {
  props: ['dataReservation', 'sectionId'],
  components: { DatePick },
  data () {
    return {
      dateFrom: null,
      dateTo: null,
      dateBar_day: '00',
      dateBar_day2: '00',
      load: false,
      daySection: 0,
      years: [],
      year: null,
      generate: false
    };
  },
  computed: {
    ...mapGetters({
      dataReservation: [metricsTypes.getters.dataReservation],
      dataReservationRange: [metricsTypes.getters.dataReservationRange],
      byMonth: [metricsTypes.getters.byMonth],
      byDay: [metricsTypes.getters.byDay],
      byRange: [metricsTypes.getters.byRange],
      notEffective: [metricsTypes.getters.notEffective],
      timeState: [metricsTypes.getters.timeState],
      yearGlobal: [metricsTypes.getters.year],
      dateFromGlobal: [metricsTypes.getters.dateFrom],
      dateToGlobal: [metricsTypes.getters.dateTo]
    })
  },
  mounted () {
    const max = parseInt(this.$moment().format('YYYY'));
    const min = (max - MIN_YEARS);
    const years = [];
    for (let index = max; index >= min; index--) {
      years.push({ id: index });
    }
    if (this.dateFromGlobal !== null && this.dateToGlobal !== null) {
      this.dateFrom = this.dateFromGlobal;
      this.dateTo = this.dateToGlobal;
    }
    this.year = (this.yearGlobal !== max) ? this.yearGlobal : max;
    this.years = years;
  },
  methods: {
    getDataByFilter (type, section) {
      this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: true });
      if (section === this.daySection) {
        this.$store.dispatch({
          type: 'metrics:getDataReservation',
          data: {
            vendorId: this.$store.state.idVendor,
            type: this.sectionId,
            year: this.year
          }
        }).then(() => {
          this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
          this.daySection = 0;
        });
        return;
      }
      this.$store.dispatch({
        type: 'metrics:getDataReservationFilter',
        data: {
          vendorId: this.$store.state.idVendor,
          type: this.sectionId,
          dateTo: this.dateTo,
          dateFrom: this.dateFrom,
          filter: type,
          year: this.year
        }
      })
        .then(() => {
          this.daySection = section;
          this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
        })
        .catch(e => {
          this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
          this.$buefy.notification.open({
            message: `<i class="icon_alert_danger icon-mks red"></i> ${e}`,
            type: 'is-danger'
          });
        });
    },
    clearDate () {
      this.load = true;
      this.dateFrom = null;
      this.dateTo = null;
      this.$store.commit(metricsTypes.mutations.setDateFrom, null);
      this.$store.commit(metricsTypes.mutations.setDateTo, null);
      this.$store.dispatch({
        type: 'metrics:getDataReservation',
        data: {
          vendorId: this.$store.state.idVendor,
          type: this.sectionId,
          year: this.year
        }
      }).then(() => {
        this.load = false;
        this.daySection = 0;
      });
    },
    generateReport () {
      this.generate = true;
      const daySections = {
        1: 'ALMUERZO',
        2: 'CENA',
        3: 'DESAYUNO'
      };
      serviceReport.post('generateGeneralMetricsReport', {
        vendorId: this.$store.state.idVendor,
        dateTo: this.dateTo,
        dateFrom: this.dateFrom,
        year: this.year,
        filter: daySections[this.daySection]
      })
        .then(({ data }) => {
          this.isDownload(data.urlApi);
        }).finally(() => {
          this.generate = false;
        });
    },
    isDownload (resource) {
      this.download = true;
      axios({
        url: process.env.VUE_APP_URL_REPORT + '/downloadReport/' + resource,
        responseType: 'blob',
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('_token')
        }
      }).then((response) => {
        this.download = false;
        const year = new Date().getFullYear();
        const hour = new Date().getHours();
        const hour2 = (hour <= 9) ? '0' + hour : hour;
        const minute = new Date().getMinutes();
        const minute2 = (minute <= 9) ? '0' + minute : minute;
        const second = new Date().getSeconds();
        const second2 = (second <= 9) ? '0' + second : second;
        const month = new Date().getMonth() + 1;
        const month2 = (month <= 9) ? '0' + month : month;
        const date = new Date().getDate();
        const date2 = (date <= 9) ? '0' + date : date;
        const filename = year + '_' + month2 + '_' + date2 + '_' + hour2 + ':' + minute2 + ':' + second2;
        const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'Reporte_Metricas' + filename + '.xlsx';
        link.click();
      }).catch((e) => {
        console.error(e);
        this.download = false;
        this.$buefy.notification.open({
          message: 'Archivo no encontrado',
          type: 'is-danger'
        });
      });
    }
  },
  watch: {
    dateFrom () {
      this.$store.commit(metricsTypes.mutations.setDateFrom, this.dateFrom);
      if (this.dateFrom === '' || this.dateFrom == null) {
        this.dateBar_day = '00';
        this.$store.commit(metricsTypes.mutations.setDateFrom, null);
      } else {
        this.dateBar_day = this.$moment(this.dateFrom).format('DD');
      }
    },
    dateTo () {
      this.$store.commit(metricsTypes.mutations.setDateTo, this.dateTo);
      if (this.dateTo === '' || this.dateTo == null) {
        this.dateBar_day2 = '00';
        this.$store.commit(metricsTypes.mutations.setDateTo, null);
      } else {
        this.dateBar_day2 = this.$moment(this.dateTo).format('DD');
      }
    },
    year () {
      this.$store.commit(metricsTypes.mutations.setYear, this.year);
    },
    sectionId (newVal) {
      if (newVal === 4) {
        if (this.dateFromGlobal !== null && this.dateToGlobal !== null) {
          this.dateFrom = this.dateFromGlobal;
          this.dateTo = this.dateToGlobal;
        }
      }
    }
  }
};
</script>
<style lang="scss">
#sumaryg {
  display: flex;
  flex-direction: column;
  background: white;
  width: 280px;
  min-width: 280px;
  text-align: center;
  .header-color {
    height: 26px;
    color: white;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    &.reservas {
      background: rgba(0, 173, 198, 1);
    }
    &.precompra {
      background: rgba(202, 211, 0, 1);
    }
    &.walking {
      background: rgba(162, 91, 223, 1);
    }
    &.margin-bot {
      margin-bottom: 12px;
    }
  }
  .btn {
    display: flex;
    justify-content: center;
    border: 1px solid rgba(112, 112, 112, 1);
    color: black;
    background-color: transparent;
    border-radius: 20px;
    font-size: 14px;
    font-weight: bold;
    width: 120px;
    height: 35px;
    justify-content: center;
    align-items: center;
    &:hover {
      background: rgba(112, 112, 112, 1);
      color: white;
      transition: all 0.2s ease-in;
      i {
        background: #fff;
      }
    }
  }
  .flex {
    display: flex;
    justify-content: center;
    align-items: center;
    &-right {
      justify-content: flex-end;
    }
  }
  .flex-container {
    display: flex;
    width: 100%;
    &.evenly {
      justify-content: space-evenly;
    }
    &.center {
      justify-content: center;
    }
    &.border-bot {
      border-bottom: 1px solid rgba(230, 230, 230, 1);
    }
    &.padding-bot {
      padding-bottom: 30px;
    }
    .col {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    &.margin-bot {
      margin-bottom: 30px;
    }
    &.margin-top {
      margin-top: 30px;
    }
  }
  .day-b {
    width: 100%;
    cursor: pointer;
    background: rgba(242, 242, 242, 1);
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:first-child {
      border-right: 1px solid rgba(230, 230, 230, 1);
    }
    &.active {
      opacity: 0.2;
    }
    &:hover {
      opacity: 0.7;
    }
  }
  .columns {
    width: 100%;
  }
  .column {
    &-color {
      background: rgb(0, 173, 198);
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &-margin-top {
      align-self: center;
    }
  }
  .back-reservas {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(0, 173, 198);
  }
  .back-precompra {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #cad300;
  }
  .back-walking {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #9453cb;
  }
  .icon-mks {
    &-tittle {
      width: 120px !important ;
      height: 120px !important;
    }
    &.small {
      height: 20px;
      width: 20px;
    }
    &.trash {
      height: 17px;
      width: 17px;
    }
    &.normal {
      height: 35px;
      width: 35px;
    }
    &.margin-bot {
      margin-bottom: 15px;
    }
    &.reservation {
      height: 35px;
      width: 48px;
    }
    &.calendar {
      position: absolute;
      margin: 0 0 0 4px !important;
      height: 22px !important;
      width: 22px !important;
    }
  }
  .fa {
    align-self: center;
  }
  .day-a {
    width: 100%;
    background: rgba(242, 242, 242, 1);
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:first-child {
      border-right: 1px solid rgba(230, 230, 230, 1);
    }
  }
  .text {
    &.margin-bot {
      margin-bottom: 40px;
      &-small {
        margin-bottom: 15px;
      }
    }
    &.numG {
      font-size: 22px;
    }
    &.bold {
      font-weight: bold;
    }
    &.paddin-rigth {
      padding: 0 0 0 25px;
    }
  }
  .header-Date {
    width: 100%;
    height: 5px;
    background: #444b57;
    border-radius: 3px 3px 0 0;
  }
  #datePicker {
    .vdpComponent {
      &:hover{
        background: transparent!important;
      }
    }
    border: 1px solid rgba(230, 230, 230, 1);
    .vdpFloating{
      z-index: 10000;
    }
    #datePickRigth {
      .vdpFloating.vdpPositionLeft {
        left: inherit;
      }
    }
    .vdpComponent.vdpWithInput {
      padding: 0 0 0 25px;
    }
    .vdpComponent.vdpWithInput > input {
      padding-right: 0px;
      font-size: 10px;
    }
    input {
      padding: 0px;
      width: 100%;
      border: 0px;
      outline: inherit;
    }

    .column {
      &-display-flex {
        display: flex;
      }
    }
    .field {
      display: flex;
      flex-direction: column;
      position: relative;
      text-align-last: center;
      height: 50px;
      justify-content: center;
      &:first-child {
        border-right: 1px solid rgba(230, 230, 230, 1);
      }
    }
    .icon-mks {
      margin: 0 0 0 15px;
    }
    .vdpInnerWrap {
      border-radius: 20px;
      background: rgba(68, 75, 87, 1);
    }
    .vdpHeader {
      background: rgba(68, 75, 87, 1);
      border-radius: 20px;
    }
    .selectable {
      color: white;
    }
    .outOfRange {
      color: rgba(112, 112, 112, 1);
    }
    .vdpHeadCellContent {
      color: white;
      font-weight: bold;
    }
    .vdpPeriodControl > button {
      color: white;
      font-weight: bold;
    }
    .vdpArrowPrev:after {
      border-right-color: white;
    }
    .vdpArrowNext:after {
      border-left-color: white;
    }
    .vdpCell.selected .vdpCellContent {
      color: black;
      background: white;
      &:hover {
        color: black;
        background: white;
      }
    }
  }
  .label-date {
    font-weight: 500;
    font-size: 10px;
    text-align: initial;
    padding-left: 3px;
  }
  .the_calendar_day {
    position: absolute;
    left: 8.5px;
    font-size: 11px;
    top: 21px;
  }
  .porcentaje {
    font-size: 38px;
    font-weight: 500;
    color: rgba(68, 75, 87, 1);
  }
}
</style>
