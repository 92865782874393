<template>
  <div id="historicoReserva">
    <div class="close">
      <i @click="$emit('close')" aria-hidden="true" class="icon_close icon-mks black"></i>
    </div>
    <div class="container-columns">
      <div class="sub-tittle sub-tittle-center big">Historico</div>
      <p class="sub-tittle sub-tittle-center big black">Información histórica de reserva</p>
      <p class="sub-tittle sub-tittle-center big black">#{{ dataInfoReserva.id }}</p>
      <div class="buttons columns">
        <button
          :class="{ active: activeTab == 0 }"
          @click="activeTab = 0"
          class="tab-button tab1 column"
        >HISTORICOS RESERVAS</button>
        <button
          :class="{ active: activeTab == 1 }"
          @click="activeTab = 1"
          class="tab-button tab2 column"
        >STATUS NOTIFICACIONES</button>
      </div>
      <div class="msg">A continuación se listaran los cambios realizados a esta reserva:</div>
      <b-tabs v-model="activeTab">
        <b-tab-item>
          <div v-if="!infoAdmin">
            <b-table :data="dataReserva['NoAdmin']" bordered>
              <template slot-scope="props">
                <b-table-column centered field="id" label="ID">
                  {{
                  props.row.id
                  }}
                </b-table-column>
                <b-table-column centered field="accion" label="Movimiento">
                  {{
                  validateAction(props.row.accion)
                  }}
                </b-table-column>
                <b-table-column centered field="created_at" label="Fecha">
                  {{
                  dateFormatter(props.row.created_at)
                  }}
                </b-table-column>
                <b-table-column
                  centered
                  field="usuario"
                  label="Realizado por"
                >{{validateReferrer(dataInfoReserva.referrer, props.row)}}</b-table-column>
              </template>
            </b-table>
          </div>
          <div v-else-if="infoAdmin">
            <p class="text center">
              <b>Administradores</b>:
            </p>
            <b-table :data="dataReserva['Admin']" bordered>
              <template slot-scope="props">
                <b-table-column centered field="id" label="ID">
                  {{
                  props.row.id
                  }}
                </b-table-column>
                <b-table-column centered field="accion" label="Movimiento">
                  {{
                  validateAction(props.row.accion)
                  }}
                </b-table-column>
                <b-table-column centered field="created_at" label="Fecha">
                  {{
                  dateFormatter(props.row.created_at)
                  }}
                </b-table-column>
                <b-table-column centered field="usuario" label="Realizado por">{{props.row.usuario}}</b-table-column>
              </template>
            </b-table>
            <p class="text center">
              <b>Usuario</b>:
            </p>
            <b-table :data="dataReserva['NoAdmin']" bordered>
              <template slot-scope="props">
                <b-table-column centered field="id" label="ID">
                  {{
                  props.row.id
                  }}
                </b-table-column>
                <b-table-column centered field="accion" label="Movimiento">
                  {{
                  validateAction(props.row.accion)
                  }}
                </b-table-column>
                <b-table-column centered field="created_at" label="Fecha">
                  {{
                  dateFormatter(props.row.created_at)
                  }}
                </b-table-column>
                <b-table-column
                  centered
                  field="usuario"
                  label="Realizado por"
                >{{validateReferrer(dataInfoReserva.referrer, props.row)}}</b-table-column>
              </template>
              <template slot="empty">
                <section class="section">
                  <div class="sub-tittle">
                    <p>
                      <b-icon icon="emoticon-sad" size="is-large"></b-icon>
                    </p>
                    <p class="text center">No se registran cambios realizados en esta reserva.</p>
                  </div>
                </section>
              </template>
            </b-table>
          </div>
        </b-tab-item>
        <b-tab-item>
          <b-table :data="dataReserva['Notification']" bordered>
            <template slot-scope="props">
              <b-table-column centered field="id" label="ID">
                {{
                props.row.id
                }}
              </b-table-column>
              <b-table-column centered field="accion" label="Movimiento">
                {{
                validateAction(props.row.accion)
                }}
              </b-table-column>
              <b-table-column centered field="created_at" label="Fecha">
                {{
                dateFormatter(props.row.created_at)
                }}
              </b-table-column>
            </template>
            <template slot="empty">
              <section class="section">
                <div class="sub-tittle">
                  <p class="text center">No se registran cambios realizados en esta reserva.</p>
                </div>
              </section>
            </template>
          </b-table>
        </b-tab-item>
      </b-tabs>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      data: this.dataReserva,
      activeTab: 0
    };
  },
  props: ['dataReserva', 'dataInfoReserva', 'infoAdmin'],
  methods: {
    validateAction (accion) {
      let str = accion;
      str = str.replace('WhiteLabel', 'Página Web');
      return str;
    },
    validateReferrer (referrer, data) {
      return data.usuario;
      // if (referrer === 'dashboard' || data.hostId !== null) {
      // }
      // return 'Cliente';
    },
    dateFormatter (date) {
      return this.$moment(date).format('YYYY-MM-DD h:mm:ss');
    }
  }
};
</script>
<style lang="scss">
#historicoReserva {
  width: 500px;
  height: calc(100% - 52px);
  background: #fff;
  display: flex;
  flex-direction: column;
  position: absolute;

  overflow: auto;
  top: 52px;
  right: 0;
  padding: 30px;
  padding-bottom: 20px;
  -webkit-box-shadow: -7px 7px 5px 0px #00000029;
  -moz-box-shadow: -7px 7px 5px 0px #00000029;
  box-shadow: -7px 7px 5px 0px #00000029;
  .msg {
    margin-top: 10px;
    text-align: center;
    padding: 15px 5px;
    border-top: 2px solid #00000029;
    border-bottom: 2px solid #00000029;
  }
  .sub-tittle {
    &.big {
      font-size: 22px;
    }
    &.black {
      color: black;
    }
  }
  .text {
    &.center {
      text-align: center;
    }
  }
  .b-table {
    font-size: 12px;
  }
  .buttons {
    margin-top: 20px !important;
    padding: 0 45px 0 45px;
    position: relative;
    border-radius: 10px 10px 0px 0px;
    border: 1px solid #444b57;
    .tab-button {
      border: none;
      height: 35px;
      font-size: 11px;
      font-weight: bold;
      color: #444b57;
    }
    .tab1 {
      border-radius: 10px 0px 0px 0px;
    }
    .tab2 {
      border-radius: 0px 10px 0px 0px;
    }
    .active {
      background: #444b57;
      color: #fff;
    }
  }
}
</style>
