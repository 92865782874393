export default {
  custom: {
    email: {
      required: 'El correo electrónico es requerido',
      email: 'El correo electrónico no es válido'
    },
    mail: {
      required: 'El correo electrónico es requerido',
      email: 'El correo electrónico no es válido'
    },
    emails: {
      required: 'El correo electrónico es requerido',
      email: 'El correo electrónico no es válido'
    },
    username: {
      required: 'El nombre de usuario es requerido',
      alpha_spaces: 'Valor no permitido, solo se permiten letras'
    },
    identification: {
      required: 'El valor del campo es requerido',
      numeric: 'Solo se permiten números'
    },
    name: {
      required: 'El valor del campo es requerido',
      alpha_spaces: 'Solo se permiten letras'
    },
    password: {
      required: 'La contraseña es requerida',
      min: 'La contraseña es demasiado corta'
    },
    password_confirmation: {
      required: 'Se requiere que confirme la contraseña',
      min: 'La contraseña es demasiado corta',
      confirmed: 'La contraseña no coincide'
    },
    phone: {
      required: 'El valor del campo es requerido',
      numeric: 'Ingrese solo números',
      min: (field, [min]) => `El número debe tener mínimo ${min} dígitos.`,
      max: (field, [max]) => `El número debe tener máximo ${max} dígitos.`
    },
    phones: {
      required: 'El valor del campo es requerido',
      numeric: 'Ingrese solo números'
    },
    address: {
      required: 'Se requiere la dirección de habitación',
      numeric: 'Ingrese solo números'
    },
    mes: {
      required: 'El valor del campo es requerido',
      numeric: 'Ingrese solo números'
    },
    ano: {
      required: 'El valor del campo es requerido',
      numeric: 'Ingrese solo números'
    },
    city: {
      required: 'Se requiere que ingrese la ciudad',
      min: 'Mínimo de caracteres no permitido'
    },
    creditCard: {
      required: 'Se requiere que ingrese el número de la tarjeta de crédito',
      error: 'Número de tarjeta de crédito no valida'
    },
    bank: {
      required: 'Por favor, seleccione una entidad bancaria'
    },
    typeDoc: {
      required: 'Por favor, seleccione un tipo de documento'
    },
    typePeople: {
      required: 'Por favor, seleccione un tipo de persona'
    },
    people: {
      min_value: 'El valor mínimo permitido es '
    },
    validateToken: {
      required: 'Por favor ingrese el token',
      max_value: 'El valor máximo permitido es 8'
    },
    name_companion: {
      required: 'Ingrese un nombre',
      min: 'Ingrese un nombre mayor a 2 letras'
    },
    identification_companion: {
      required: 'Ingrese número de identificación',
      numeric: 'Solo se permiten números',
      min: 'El valor mínimo permitido es 5 números'
    },
    temperature_companion: {
      required: 'Ingrese la temperatura',
      numeric: 'Ingrese solo números',
      min_value: 'El valor mínimo es 0'
    },
    phone_companion: {
      required: 'Ingrese número de teléfono',
      max: 'Ingrese un número valido',
      min: 'Ingrese un número valido',
      numeric: 'Ingrese solo números'
    },
    email_companion: {
      required: 'Ingrese un correo electrónico',
      email: 'El correo electrónico no es válido'
    },
    pais_companion: {
      required: 'Debe seleccionar un país'
    },
    displayName: {
      required: 'Ingrese el nombre',
      min: 'El valor mínimo es 3 caracteres'
    },
    identityDocument: {
      regex: 'Valor no válido'
    }
  }
};
