<template>
  <section class="card-content card-custom-content">
    <b-tabs class="tabMenuCustom" v-model="tabActive">
      <b-tab-item label="Categoría">
        <b-field v-if="params.languageMenuActivate">
          <b-radio-button
            name="spanish"
            native-value="spanish"
            v-if="formCategory._id == null || formCategory.typeLanguage === 'spanish'"
            v-model="formCategory.typeLanguage"
          >Español</b-radio-button>
          <b-radio-button
            name="english"
            native-value="english"
            v-if="formCategory._id == null || formCategory.typeLanguage === 'english'"
            v-model="formCategory.typeLanguage"
          >Inglés</b-radio-button>
        </b-field>
        <div class="columns">
          <div class="column is-12">
            <b-field label="Categoría Padre">
              <treeselect
                :normalizer="normalizer"
                :options="categories"
                class="width-auto"
                placeholder="Seleccione Categoría"
                v-model="formCategory.parentId"
              />
            </b-field>
            <b-field
              label="Menú"
              v-if="formCategory.parentId === null || formCategory.parentId === undefined"
            >
              <treeselect
                :normalizer="normalizer"
                :options="listVendorMenu"
                class="width-auto"
                placeholder="Seleccione Menú"
                v-model="formCategory.vendorMenuId"
              />
            </b-field>
            <b-field
              :message="formValidateCategory.name.message"
              :type="formValidateCategory.name.type"
              label="Categoría"
            >
              <b-input
                class="inputs-size-custom"
                placeholder="Categoría"
                v-model="formCategory.name"
              ></b-input>
            </b-field>
            <b-field label="Descripción">
              <b-input
                class="inputs-size-custom"
                placeholder="Descripción"
                v-model="formCategory.description"
              ></b-input>
            </b-field>
            <b-field label="Orden">
              <b-input class="inputs-size-custom" type="number" v-model="formCategory.order"></b-input>
            </b-field>
            <b-field
              grouped
              v-if="formCategory.parentId == null || formCategory.parentId == undefined"
            >
              <b-field label="Imagen Precio Derecha">
                <b-upload
                  @input="fileUpload(fileImagePriceOne, 'priceOne')"
                  class="file-label"
                  v-model="fileImagePriceOne"
                >
                  <span class="file-cta">
                    <span class="file-label" v-if="fileImagePriceOne">{{ fileImagePriceOne.name }}</span>
                    <span class="file-label" v-if="!fileImagePriceOne">Subir Imagen</span>
                  </span>
                </b-upload>
              </b-field>
              <b-field label="URL Imagen Precio Derecha">
                <b-input
                  placeholder="URL Imagen Precio Derecha"
                  type="text"
                  v-model="formCategory.imagePriceOne"
                ></b-input>
              </b-field>
            </b-field>
            <b-field
              grouped
              v-if="formCategory.parentId == null || formCategory.parentId == undefined"
            >
              <b-field label="Imagen Precio Izquierda" v-if="formCategory.twoPrices">
                <b-upload
                  @input="fileUpload(fileImagePriceTwo, 'priceTwo')"
                  class="file-label"
                  v-model="fileImagePriceTwo"
                >
                  <span class="file-cta">
                    <span class="file-label" v-if="fileImagePriceTwo">{{ fileImagePriceTwo.name }}</span>
                    <span class="file-label" v-if="!fileImagePriceTwo">Subir Imagen</span>
                  </span>
                </b-upload>
              </b-field>
              <b-field label="URL Imagen Precio Izquierda" v-if="formCategory.twoPrices">
                <b-input
                  placeholder="URL Imagen Precio Izquierda"
                  type="text"
                  v-model="formCategory.imagePriceTwo"
                ></b-input>
              </b-field>
            </b-field>
            <b-field
              grouped
              v-if="formCategory.parentId == null || formCategory.parentId == undefined"
            >
              <b-field label="Imagen banner">
                <b-upload
                  @input="fileUpload(fileBannerCategory, 'bannerCategory')"
                  class="file-label"
                  v-model="fileBannerCategory"
                >
                  <span class="file-cta">
                    <span class="file-label" v-if="fileBannerCategory">{{ fileBannerCategory.name }}</span>
                    <span class="file-label" v-if="!fileBannerCategory">Subir Imagen</span>
                  </span>
                </b-upload>
              </b-field>
              <b-field label="URL Imagen Banner">
                <b-input
                  placeholder="URL Imagen Banner"
                  type="text"
                  v-model="formCategory.imageBanner"
                ></b-input>
              </b-field>
            </b-field>
            <b-field label="Recomendado">
              <b-checkbox
                :false-value="0"
                :true-value="1"
                type="is-success"
                v-model="formCategory.recommended"
              ></b-checkbox>
            </b-field>
            <b-field
              label="Dos Precios"
              v-if="formCategory.parentId == null || formCategory.parentId == undefined"
            >
              <b-checkbox
                :false-value="0"
                :true-value="1"
                type="is-success"
                v-model="formCategory.twoPrices"
              ></b-checkbox>
            </b-field>
            <b-field label="Estado">
              <b-checkbox
                :false-value="0"
                :true-value="1"
                type="is-success"
                v-model="formCategory.active"
              ></b-checkbox>
            </b-field>
            <b-field class="has-text-centered justify-content-center" position="is-centered">
              <b-button
                :disabled="disabledButton"
                :type="buttonCustom.type"
                @click="methodController('category')"
              >{{buttonCustom.title}}</b-button>&nbsp;
              <b-button @click="clearForm('category')" type="is-info">Limpiar formulario</b-button>
            </b-field>
            <b-field
              grouped
              label="Buscador"
              label-position="on-border"
              position="is-right"
              v-if="tableCopyCategories.length > 0"
            >
              <b-input
                class="inputs-size-custom"
                placeholder="Buscar por nombre"
                type="search"
                v-model="searchCategory"
              />
            </b-field>
            <b-field>
              <b-table
                :data="tableCategories"
                :paginated="true"
                :per-page="4"
                focusable
                v-if="tableCategories.length !== 0"
              >
                <template slot-scope="props">
                  <b-table-column label="#">{{ props.index + 1 }}</b-table-column>
                  <b-table-column label="Tipo de Categoría">
                    <b-tag
                      type="is-info is-light"
                    >{{ (props.row.parentId == null) ? 'Categoría' : 'Subcategoría' }}</b-tag>
                  </b-table-column>
                  <b-table-column field="name" label="Categoría">{{ props.row.name }}</b-table-column>
                  <b-table-column
                    field="description"
                    label="Descripción"
                  >{{ props.row.description }}</b-table-column>
                  <b-table-column
                    field="vendorMenuId"
                    label="Menú"
                  >{{ categoryName(props.row.vendorMenuId,listVendorMenu) }}</b-table-column>
                  <b-table-column field="order" label="Orden">{{ props.row.order }}</b-table-column>
                  <b-table-column field="active" label="Estado">
                    <b-tag type="is-success" v-if="parseInt(props.row.active) === 1">Activo</b-tag>
                    <b-tag type="is-danger" v-else>Inactivo</b-tag>
                  </b-table-column>
                  <b-table-column label="Acción">
                    <b-button @click="getCategory(props.row._id,'edit')" type="is-warning">
                      <i class="icon-mks white icon_edit_info_schedule icon-size-action"></i>
                    </b-button>&nbsp;
                    <b-button @click="getCategory(props.row._id,'delete')" type="is-danger">
                      <i class="icon-mks white icon_delete_info_schedule icon-size-action"></i>
                    </b-button>
                  </b-table-column>
                </template>
              </b-table>
            </b-field>
          </div>
        </div>
      </b-tab-item>
      <b-tab-item label="Producto">
        <b-field v-if="params.languageMenuActivate">
          <b-radio-button
            name="spanish"
            native-value="spanish"
            v-if="formProduct._id == null || formProduct.typeLanguage === 'spanish'"
            v-model="formProduct.typeLanguage"
          >Español</b-radio-button>
          <b-radio-button
            name="english"
            native-value="english"
            v-if="formProduct._id == null || formProduct.typeLanguage === 'english'"
            v-model="formProduct.typeLanguage"
          >Inglés</b-radio-button>
        </b-field>
        <div class="columns">
          <div class="column is-12">
            <b-field label="Es imagen">
              <b-checkbox
                :false-value="0"
                :true-value="1"
                type="is-success"
                v-model="formProduct.isImage"
              ></b-checkbox>
            </b-field>
            <b-field
              :message="formValidateProduct.image.message"
              :type="formValidateProduct.image.type"
              label="Imagen Productos"
              v-if="formProduct.isImage == 1"
            >
              <b-upload
                @input="fileUpload(fileImageProducts, 'imageProducts')"
                class="file-label"
                v-model="fileImageProducts"
              >
                <span class="file-cta">
                  <b-icon class="file-icon" icon="upload"></b-icon>
                  <span class="file-label" v-if="fileImageProducts">{{ fileImageProducts.name }}</span>
                  <span class="file-label" v-if="!fileImageProducts">Subir Imagen</span>
                </span>
              </b-upload>
            </b-field>
            <b-field label="URL Imagen Productos" v-if="formProduct.isImage == 1">
              <b-input
                placeholder="URL Imagen Productos"
                type="text"
                v-model="formProduct.imageProducts"
              ></b-input>
            </b-field>
            <b-field
              label="Categoría"
              v-if="formProduct.vendorMenuId === null || formProduct.vendorMenuId === undefined"
            >
              <treeselect
                :normalizer="normalizer"
                :options="categories"
                class="width-auto"
                placeholder="Seleccione Categoría"
                v-model="formProduct.categoryId"
              />
            </b-field>
            <b-field
              label="Menú"
              v-if="formProduct.categoryId === null || formProduct.categoryId === undefined"
            >
              <treeselect
                :normalizer="normalizer"
                :options="listVendorMenu"
                class="width-auto"
                placeholder="Seleccione Menú"
                v-model="formProduct.vendorMenuId"
              />
            </b-field>
            <b-field
              :message="formValidateProduct.name.message"
              :type="formValidateProduct.name.type"
              label="Producto"
              v-if="formProduct.isImage == 0"
            >
              <b-input
                class="inputs-size-custom"
                placeholder="Producto"
                ref="product"
                v-model="formProduct.name"
              ></b-input>
            </b-field>
            <b-field label="Tipo de Precio">
              <b-switch
                :false-value="'USD'"
                :true-value="'COP'"
                type="is-primary"
                v-model="formProduct.typePrice"
              >{{ formProduct.typePrice }}</b-switch>
            </b-field>
            <b-field
              :message="formValidateProduct.price.message"
              :type="formValidateProduct.price.type"
              label="Precio Derecha"
              v-if="formProduct.isImage == 0"
            >
              <b-input
                class="inputs-size-custom"
                placeholder="Precio del producto"
                v-model="formProduct.price"
              ></b-input>
            </b-field>
            <b-field label="Precio Izquierda" v-if="twoPrices && formProduct.isImage == 0">
              <b-input
                class="inputs-size-custom"
                placeholder="Precio del producto"
                v-model="formProduct.priceTwo"
              ></b-input>
            </b-field>
            <b-field label="Orden">
              <b-input
                class="inputs-size-custom"
                placeholder="Orden"
                type="number"
                v-model="formProduct.order"
              ></b-input>
            </b-field>
            <b-field label="Descripción" v-if="formProduct.isImage == 0">
              <vue-editor :editor-toolbar="editorToolbar" v-model="formProduct.description" />
            </b-field>
            <b-field label="Activo">
              <b-checkbox
                :false-value="0"
                :true-value="1"
                type="is-success"
                v-model="formProduct.active"
              ></b-checkbox>
            </b-field>
            <b-field label="Recomendado" v-if="formProduct.isImage == 0">
              <b-checkbox
                :false-value="0"
                :true-value="1"
                type="is-success"
                v-model="formProduct.recommended"
              ></b-checkbox>
            </b-field>
            <b-field label="Restricciones alimentarias">
              <b-dropdown
                @change="changeDietaryRestrictions"
                position="is-bottom-right"
                v-model="dietaryRestrictionsAux"
                class="alimentary-restrictions-dropdown-products"
                multiple>
                <b-button
                    slot="trigger"
                    type="is-primary"
                    icon-right="menu-down">
                    Seleccionadas ({{ formProduct.dietaryRestrictions.length }})
                </b-button>
                <b-dropdown-item v-for="restriction in restrictions" :key="restriction._id" :value="restriction.index" aria-role="listitem">
                    <i class="icon-mks icon-restrictions-product" :style="`-webkit-mask-image: url('${restriction.iconURL}'); mask-image: url('${restriction.iconURL}');`" />
                    <span>{{restriction.name}}</span>
                </b-dropdown-item>
              </b-dropdown>
            </b-field>
            <b-field label="Imagen Producto" v-if="formProduct.isImage == 0">
              <b-upload
                @input="fileUpload(fileImageProduct, 'imageProduct')"
                class="file-label"
                v-model="fileImageProduct"
              >
                <span class="file-cta">
                  <b-icon class="file-icon" icon="upload"></b-icon>
                  <span class="file-label" v-if="fileImageProduct">{{ fileImageProduct.name }}</span>
                  <span class="file-label" v-if="!fileImageProduct">Subir Imagen</span>
                </span>
              </b-upload>
            </b-field>
            <b-field label="URL Imagen Producto" v-if="formProduct.isImage == 0">
              <b-input placeholder="URL Imagen Producto" type="text" v-model="formProduct.image"></b-input>
            </b-field>
            <b-field position="is-centered">
              <b-button
                :disabled="disabledButton"
                :type="buttonCustomP.type"
                @click="methodController('product')"
              >{{buttonCustomP.title}}</b-button>&nbsp;
              <b-button @click="clearForm('product')" type="is-info">Limpiar Formulario</b-button>
            </b-field>
            <b-field
              grouped
              label="Buscador"
              label-position="on-border"
              position="is-right"
              v-if="tableCopyProducts.length > 0"
            >
              <b-input placeholder="Buscar por nombre" type="search" v-model="searchProduct" />
            </b-field>
            <b-field>
              <b-table
                :data="tableProducts"
                :paginated="true"
                :per-page="4"
                focusable
                v-if="tableProducts.length > 0"
              >
                <template slot-scope="props">
                  <b-table-column label="#">{{ props.index + 1 }}</b-table-column>
                  <b-table-column
                    field="categoryId"
                    label="Categoría"
                  >{{ categoryName(props.row.categoryId,categories) }}</b-table-column>
                  <b-table-column field="name" label="Producto">{{ props.row.name }}</b-table-column>
                  <b-table-column field="description" label="Descripción">{{ props.row.description}}</b-table-column>
                  <b-table-column field="price" label="Precio Derecha">{{ props.row.price}}</b-table-column>
                  <b-table-column field="priceTwo" label="Precio Izquierda">{{ props.row.priceTwo}}</b-table-column>
                  <b-table-column
                    field="vendorMenuId"
                    label="Menú"
                  >{{ categoryName(props.row.vendorMenuId,listVendorMenu) }}</b-table-column>
                  <b-table-column field="isImage" label="Es Imagen">
                    <b-tag type="is-success" v-if="parseInt(props.row.isImage) === 1">Si</b-tag>
                    <b-tag type="is-danger" v-else>No</b-tag>
                  </b-table-column>
                  <b-table-column
                    field="imageProducts"
                    label="Url Imagen Productos"
                  >{{ props.row.imageProducts}}</b-table-column>
                  <b-table-column field="active" label="Estado">
                    <b-tag type="is-success" v-if="parseInt(props.row.active) === 1">Activo</b-tag>
                    <b-tag type="is-danger" v-else>Inactivo</b-tag>
                  </b-table-column>
                  <b-table-column label="Acción">
                    <b-button @click="getProduct(props.row._id,'edit')" type="is-warning">
                      <i class="icon-mks white icon_edit_info_schedule icon-size-action"></i>
                    </b-button>&nbsp;
                    <b-button @click="getProduct(props.row._id,'delete')" type="is-danger">
                      <i class="icon-mks white icon_delete_info_schedule icon-size-action"></i>
                    </b-button>
                  </b-table-column>
                </template>
              </b-table>
            </b-field>
          </div>
        </div>
      </b-tab-item>
      <b-tab-item label="Información">
        <b-field label="PDF a JPG Click para ver" style="cursor:pointer">
          <span @click="copyToGo">{{ urlPdf }}</span>
        </b-field>
        <b-field label="Para Optimizar las imagenes" style="cursor:pointer">
          <span @click="copyToGoOptimizer">{{ urlOptimizar }}</span>
        </b-field>
        <b-field>
          <span>
            El peso maximo de las imagenes es de que no se quieran optimizar es de
            <b>2MB</b>
          </span>
        </b-field>
        <b-field>
          <span>
            Si se van a subír multiples imagenes ninguna puede pesar más de
            <b>1MB</b>
          </span>
        </b-field>
        <b-field>
          <span>
            Solo se acepta formato
            <b>JPG, JPEG, PNG</b>
          </span>
        </b-field>
      </b-tab-item>
    </b-tabs>
  </section>
</template>
<script>
import _ from 'lodash';
import serviceQr from '@/helpers/apis/serviceQr.js';
import { VueEditor } from 'vue2-editor';
export default {
  props: ['restaurant'],
  components: {
    VueEditor
  },
  data () {
    return {
      tabActive: 0,
      searchCategory: null,
      searchProduct: null,
      formCategory: {
        _id: null,
        name: null,
        parentId: null,
        vendorId: this.restaurant.vendorId,
        description: null,
        recommended: 0,
        order: 0,
        vendorMenuId: null,
        active: 1,
        twoPrices: 0,
        imagePriceOne: null,
        imagePriceTwo: null,
        imageBanner: null,
        typeLanguage: 'spanish'
      },
      formValidateCategory: {
        name: {
          message: '',
          type: 'is-default'
        }
      },
      formValidateProduct: {
        name: {
          message: '',
          type: 'is-default'
        },
        price: {
          message: '',
          type: 'is-default'
        },
        image: {
          message: '',
          type: 'is-default'
        }
      },
      formProduct: {
        _id: null,
        name: null,
        categoryId: null,
        vendorId: this.restaurant.vendorId,
        description: null,
        price: '1.000',
        priceTwo: 0,
        order: 0,
        vendorMenuId: null,
        active: 1,
        image: null,
        isImage: 0,
        imageProducts: null,
        typeLanguage: 'spanish',
        typePrice: 'COP',
        dietaryRestrictions: []
      },
      categories: [],
      fileImageProduct: null,
      fileImageProducts: null,
      fileImagePriceOne: null,
      fileImagePriceTwo: null,
      fileBannerCategory: null,
      disabledButton: false,
      listCategories: [],
      listVendorMenu: [],
      tableCategories: [],
      tableCopyCategories: [],
      tableSubCategories: [],
      tableProducts: [],
      tableCopyProducts: [],
      twoPrices: false,
      editorToolbar: [
        ['bold'],
        [{ list: 'ordered' }, { list: 'bullet' }]
      ],
      buttonCustom: {
        type: 'is-success',
        title: 'Guardar Categoría'
      },
      buttonCustomP: {
        type: 'is-success',
        title: 'Guardar Producto'
      },
      urlPdf: 'https://www.ilovepdf.com/es/pdf_a_jpg',
      urlOptimizar: 'https://imagecompressor.com/es/',
      restrictions: [],
      dietaryRestrictionsAux: []
    };
  },
  mounted () {
    this.getListVendorMenu();
    this.getListCategories();
    this.getListTableCategories();
    this.getListTableProduct();
    this.getListDietary();
  },
  methods: {
    bytesToSize (bytes) {
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
      if (bytes === 0) return '0 Byte';
      const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return { size: Math.round(bytes / Math.pow(1024, i), 2), format: sizes[i] };
    },
    copyToGo () {
      window.open(this.urlPdf, '_blank');
    },
    copyToGoOptimizer () {
      window.open(this.urlOptimizar, '_blank');
    },
    fileUpload (file, type) {
      this.disabledButton = true;
      const name = file.name.split('.');
      const fileInfo = this.bytesToSize(file.size);
      if (fileInfo.format === 'MB' && fileInfo.size >= 5) {
        this.$buefy.toast.open({
          message: 'El Archivo que esta intentando subir es muy pesado por favor dirijase a la pestaña de información allí encontrara el link que lo llevara al optimizador de imagenes.',
          type: 'is-danger',
          duration: 7000
        });
        if (type === 'priceOne') {
          this.fileImagePriceOne = null;
        }
        if (type === 'priceTwo') {
          this.fileImagePriceTwo = null;
        }
        if (type === 'imageProduct') {
          this.fileImageProduct = null;
        }
        if (type === 'imageProducts') {
          this.fileImageProducts = null;
        }
        if (type === 'bannerCategory') {
          this.fileBannerCategory = null;
        }
        return;
      }
      if (fileInfo.format === 'MB' && fileInfo.size >= 1) {
        this.$buefy.dialog.confirm({
          title: 'Imagen Muy Pesada.',
          message: 'Se recomienda subir imagenes con tamaño inferior a <b>1 MB</b>. ¿Desea que optimicemos la imagen que esta cargando?. El peso actual de tu imagen es de aproximadamente' + `<b>${fileInfo.size} ${fileInfo.format}</b>`,
          confirmText: 'Si, subir',
          cancelText: 'No, cancelar',
          type: 'is-danger',
          hasIcon: true,
          onConfirm: () => {
            this.uploadImageGlobal(file, name[0], null, this.$store.getters['global:vendor'].vendor, 'menu', 1)
              .then(({ data, code }) => {
                if (code === 200) {
                  if (type === 'priceOne') {
                    this.formCategory.imagePriceOne = data.data;
                  } else if (type === 'priceTwo') {
                    this.formCategory.imagePriceTwo = data.data;
                  } else if (type === 'imageProduct') {
                    this.formProduct.image = data.data;
                  } else if (type === 'imageProducts') {
                    this.formProduct.imageProducts = data.data;
                  } else if (type === 'bannerCategory') {
                    this.formCategory.imageBanner = data.data;
                  }
                  this.disabledButton = false;
                  this.$buefy.toast.open({
                    message: 'Se ha sudido la imagen',
                    type: 'is-success'
                  });
                  return;
                }
                this.$buefy.toast.open({
                  message: this.$t('errors.request'),
                  type: 'is-danger'
                });
              });
          },
          onCancel: () => {
            if (type === 'priceOne') {
              this.fileImagePriceOne = null;
            }
            if (type === 'priceTwo') {
              this.fileImagePriceTwo = null;
            }
            if (type === 'imageProduct') {
              this.fileImageProduct = null;
            }
            if (type === 'imageProducts') {
              this.fileImageProducts = null;
            }
            if (type === 'bannerCategory') {
              this.fileBannerCategory = null;
            }
          }
        });
        return;
      }
      this.uploadImageGlobal(file, name[0], null, this.$store.getters['global:vendor'].vendor, 'menu')
        .then(({ data, code }) => {
          if (code === 200) {
            if (type === 'priceOne') {
              this.formCategory.imagePriceOne = data.data;
            } else if (type === 'priceTwo') {
              this.formCategory.imagePriceTwo = data.data;
            } else if (type === 'imageProduct') {
              this.formProduct.image = data.data;
            } else if (type === 'imageProducts') {
              this.formProduct.imageProducts = data.data;
            } else if (type === 'bannerCategory') {
              this.formCategory.imageBanner = data.data;
            }
            this.disabledButton = false;
            this.$buefy.toast.open({
              message: 'Se ha sudido la imagen',
              type: 'is-success'
            });
            return;
          }
          this.$buefy.toast.open({
            message: this.$t('errors.request'),
            type: 'is-danger'
          });
        });
    },
    methodController (form) {
      if (form === 'product') {
        const status = this.validarFormProduct();
        if (status === false) {
          this.buttonCustomP.type === 'is-success' && this.addProduct();
          this.buttonCustomP.type === 'is-warning' && this.saveEditProduct();
        }
        (this.buttonCustomP.type === 'is-danger' && this.formProduct._id !== null) ? this.deleteProduct() : this.clearForm('product');
      } else if (form === 'category') {
        const status = this.validarFormCategory();
        if (status === false) {
          this.buttonCustom.type === 'is-warning' && this.saveEditCategory();
          this.buttonCustom.type === 'is-success' && this.addCategory();
        }
        (this.buttonCustom.type === 'is-danger' && this.formCategory._id !== null) ? this.deleteCategory() : this.clearForm('category');
      }
    },
    addCategory () {
      if (this.formCategory.parentId === undefined) {
        this.formCategory.parentId = null;
      }
      this.formCategory.active !== null ? parseInt(this.formCategory.active) : this.formCategory.active = null;
      this.formCategory.order = (this.formCategory.order === null || this.formCategory.order === undefined) ? 0 : parseInt(this.formCategory.order);
      serviceQr.post('/menuCustom/category', this.formCategory).then((resp) => {
        this.clearForm('category');
        this.clearForm('product');
        this.getListCategories();
        this.getListTableCategories();
      });
    },
    validarFormCategory () {
      this.formValidateCategory.name = {
        type: _.isEmpty(this.formCategory.name) ? 'is-danger' : 'is-default',
        message: _.isEmpty(this.formCategory.name) ? 'Debes ingresar un nombre' : ''
      };
      return !!_.isEmpty(this.formCategory.name);
    },
    validarFormProduct () {
      if (this.formProduct !== null && this.formProduct.isImage === 0) {
        this.formValidateProduct.name = {
          type: _.isEmpty(this.formProduct.name) ? 'is-danger' : 'is-default',
          message: _.isEmpty(this.formProduct.name) ? 'Debes ingresar un nombre' : ''
        };
        this.formValidateProduct.price = {
          type: !(this.formProduct.price > 0) ? 'is-danger' : 'is-default',
          message: !(this.formProduct.price > 0) ? 'Debes ingresar un precio' : ''
        };
        if (_.isEmpty(this.formProduct.name) || this.formProduct.price < 0) {
          return true;
        } else {
          return false;
        }
      } else {
        this.formValidateProduct.image = {
          type: _.isEmpty(this.formProduct.imageProducts) ? 'is-danger' : 'is-default',
          message: _.isEmpty(this.formProduct.imageProducts) ? 'Debes subir una imagen' : ''
        };
        return !((_.isEmpty(this.formProduct.image)));
      }
    },
    getListVendorMenu () {
      serviceQr.get(`/menuCustom/listMenu/${this.restaurant.vendorId}`).then(({ data }) => {
        this.listVendorMenu = data.data;
      });
    },
    getListCategories () {
      serviceQr.get(`/menuCustom/selectCategory/${this.restaurant.vendorId}`).then(({ data }) => {
        this.categories = data.data;
      });
    },
    getListTableCategories () {
      serviceQr.get(`/menuCustom/listCategory/${this.restaurant.vendorId}`).then(({ data }) => {
        this.tableCategories = data.data;
        this.tableCopyCategories = data.data;
      });
    },
    normalizer (node) {
      return {
        id: node._id,
        label: node.name,
        children: node.children
      };
    },
    getCategory (idCategory, method) {
      serviceQr.get(`/menuCustom/category/${idCategory}`).then(({ data }) => {
        if (method === 'edit') {
          this.buttonCustom = {
            type: 'is-warning',
            title: data.data.parentId !== null && data.data.parentId !== undefined ? 'Editar SubCategoria' : 'Editar Categoría'
          };
        } else if (method === 'delete') {
          this.buttonCustom = {
            type: 'is-danger',
            title: data.data.parentId !== null && data.data.parentId !== undefined ? 'Eliminar SubCategoria' : 'Eliminar Categoría'
          };
        }
        this.formCategory = data.data;
      });
    },
    saveEditCategory () {
      this.formCategory.parentId === undefined ? this.formCategory.parentId = null : parseInt(this.formCategory.parentId);
      this.formCategory.active !== null ? parseInt(this.formCategory.active) : this.formCategory.active = null;
      this.formCategory.order = (this.formCategory.order === null || this.formCategory.order === undefined) ? 0 : parseInt(this.formCategory.order);
      serviceQr.put(`/menuCustom/editCategory/${this.formCategory._id}`, this.formCategory).then(() => {
        this.clearForm('category');
        this.clearForm('product');
        this.getListCategories();
        this.getListTableCategories();
      });
    },
    deleteCategory () {
      serviceQr.delete(`/menuCustom/deleteCategory/${this.formCategory._id}/${this.restaurant.vendorId}`).then(() => {
        this.clearForm('category');
        this.clearForm('product');
        this.getListCategories();
        this.getListTableCategories();
        this.getListTableProduct();
      });
    },
    addProduct () {
      this.formProduct.categoryId = this.formProduct.categoryId === undefined ? null : this.formProduct.categoryId;
      this.formProduct.order = (this.formProduct.order === null || this.formProduct.order === undefined) ? 0 : parseInt(this.formProduct.order);
      serviceQr.post('/menuCustom/product', this.formProduct).then(() => {
        this.clearForm('product');
        this.clearForm('category');
        this.getListTableProduct();
      });
    },
    getListTableProduct () {
      serviceQr.get(`/menuCustom/listProduct/${this.restaurant.vendorId}`).then(({ data }) => {
        this.tableProducts = data.data;
        this.tableCopyProducts = data.data;
      });
    },
    getProduct (_id, method) {
      serviceQr.get(`/menuCustom/product/${_id}`).then(({ data }) => {
        if (method === 'edit') {
          this.buttonCustomP = {
            type: 'is-warning',
            title: 'Editar Producto'
          };
        } else if (method === 'delete') {
          this.buttonCustomP = {
            type: 'is-danger',
            title: 'Eliminar Producto'
          };
        }
        this.$refs.product.focus();
        this.formProduct = data.data;
        if (!this.formProduct.dietaryRestrictions) {
          this.dietaryRestrictionsAux = [];
          this.formProduct.dietaryRestrictions = [];
        } else {
          this.dietaryRestrictionsAux = this.restrictions.map(item => item.index).filter(id => this.formProduct.dietaryRestrictions.includes(id));
          this.formProduct.dietaryRestrictions = this.restrictions.map(item => item.index).filter(id => this.formProduct.dietaryRestrictions.includes(id));
        }
        this.formProduct.isImage = this.formProduct.isImage === undefined ? 0 : this.formProduct.isImage;
      });
    },
    saveEditProduct () {
      this.formProduct.order = (this.formProduct.order === null || this.formProduct.order === undefined) ? 0 : parseInt(this.formProduct.order);
      serviceQr.put(`/menuCustom/editProduct/${this.formProduct._id}`, this.formProduct).then(() => {
        this.clearForm('product');
        this.clearForm('category');
        this.getListTableProduct();
      });
    },
    deleteProduct () {
      serviceQr.delete(`/menuCustom/deleteProduct/${this.formProduct._id}/${this.formProduct.vendorId}`).then(() => {
        this.clearForm('product');
        this.clearForm('category');
        this.getListTableProduct();
      });
    },
    clearForm (form) {
      if (form === 'category') {
        this.formCategory = {
          _id: null,
          name: null,
          parentId: null,
          vendorMenuId: null,
          vendorId: this.restaurant.vendorId,
          description: null,
          recommended: 0,
          order: 0,
          active: 1,
          twoPrices: 0,
          imagePriceOne: null,
          imagePriceTwo: null,
          imageBanner: null,
          typeLanguage: 'spanish'
        };
        this.buttonCustom = {
          type: 'is-success',
          title: 'Guardar Categoría'
        };
      } else if (form === 'product') {
        this.formProduct = {
          _id: null,
          name: null,
          categoryId: null,
          vendorMenuId: null,
          vendorId: this.restaurant.vendorId,
          description: null,
          price: null,
          priceTwo: 0,
          order: 0,
          active: 1,
          recommended: 0,
          image: null,
          isImage: this.formProduct.isImage,
          imageProducts: null,
          typeLanguage: 'spanish',
          typePrice: 'COP',
          dietaryRestrictions: []
        };
        this.dietaryRestrictionsAux = [];
        this.buttonCustomP = {
          type: 'is-success',
          title: 'Guardar Producto'
        };
      }
      this.fileImageProduct = null;
      this.fileImageProducts = null;
      this.fileImagePriceOne = null;
      this.fileImagePriceTwo = null;
      this.fileBannerCategory = null;
    },
    categoryName (categoryId, list) {
      let name;
      if (list !== undefined) {
        list.forEach((e) => {
          if (e._id === categoryId) {
            name = e.name;
          } else {
            if (e.children) {
              name = this.categoryName(categoryId, e.children);
            }
          }
        });
      }
      return name;
    },
    searchNameList (type) {
      const copy = type === 'category' ? this.tableCopyCategories : this.tableCopyProducts;
      if (type === 'category') {
        this.tableCategories = copy.filter(x => (x.name != null) && String(x.name).toLowerCase().includes(this.searchCategory.toLowerCase()));
      } else if (type === 'product') {
        this.tableProducts = copy.filter(x => ((x.name != null) && String(x.name).toLowerCase().includes(this.searchProduct.toLowerCase())));
      }
    },
    getListDietary () {
      serviceQr.get(`/menuCustom/DietaryRestrictions/${this.restaurant.vendorId}`).then(({ data }) => {
        this.restrictions = data.data;
      }).catch(() => {
      });
    },
    changeDietaryRestrictions (value) {
      this.formProduct.dietaryRestrictions = value;
    }
  },
  watch: {
    searchCategory () {
      this.searchNameList('category');
    },
    searchProduct () {
      this.searchNameList('product');
    },
    'formProduct.categoryId' () {
      if (this.formProduct !== null && (this.formProduct.isImage === 0 || this.formProduct.isImage === undefined)) {
        let findCategory = this.tableCategories.find(e => e._id === this.formProduct.categoryId);
        if (findCategory !== undefined && findCategory.parentId !== null && findCategory.parentId !== undefined) {
          findCategory = this.tableCategories.find(e => e._id === findCategory.parentId);
          if (findCategory !== undefined && findCategory.twoPrices) {
            this.twoPrices = true;
          } else {
            this.twoPrices = false;
          }
        } else {
          if (findCategory !== undefined && findCategory.twoPrices) {
            this.twoPrices = true;
          } else {
            this.twoPrices = false;
          }
        }
      }
    }
  }
};
</script>
<style lang="scss">
.icon-size-action {
  width: 20px;
  height: 20px;
  margin: auto;
}
.card-custom-content {
  overflow-y: scroll;
  height: calc(100vh - 90px);
}
.tabMenuCustom > .tabs {
  display: block !important;
}
.inputs-size-custom > .input {
  font-size: 1rem !important;
  height: 2.25em !important;
}
.width-auto > .vue-treeselect__control {
  width: auto !important;
}
.alimentary-restrictions-dropdown-products{
  .dropdown-item{
    gap: 6px;
    display: flex;
    align-items: center;
    &:not(.is-active){
      .icon-restrictions-product{
        background: black;
      }
    }
    .icon-restrictions-product{
      background: white;
      width: 20px;
      height: 20px;
    }
  }
}
@media screen and (min-width: 769px) {
  .modal-customMenu {
    width: 960px !important;
  }
}
@media screen and (min-width: 200px) and (max-width: 621px) {
  .modal-customMenu {
    max-height: calc(100vh - 40px) !important;
  }
}
</style>
