<template>
  <div id="addUserModal">
    <div class="header_modal">
      <i class="icons icon_close_modal" @click="$parent.close()"></i>
      <span class="title_modal ffr">Agregar usuario</span>
    </div>
    <div class="content_modal">
      <div class="first_line">
        <div class="input_name">
          <input class="inputs_add name ffr" v-validate="'required'" name="displayName" type="text" v-model="newUser.displayName" placeholder="Nombre">
          <div v-show="errors.has('displayName')" class="error_add">
            El nombre no es válido.
          </div>
        </div>
        <div class="user_vip" :class="{'isActive' : newUser.vip}" @click="vipToogle">
          <span class="vip_add ffr">VIP</span>
        </div>
      </div>
      <div class="second_line">
        <div class="container_phone">
          <div class="container_select">
            <img :src="bandera" class="bandera_list">
            <select class="select_indicative ffr" v-if="option" v-model="option" @input="changedPais(option)">
              <option class="ffr" :value="p" v-for="(p, i) in paises" :key="i">{{ p.label_show }}</option>
            </select>
          </div>
          <div class="input_phone">
            <input v-validate="validateTel"
              type="text"
              name="phone"
              v-model="newUser.phoneNumber"
              placeholder="Celular"
              class="inputs_add phone ffr">
            <div v-show="errors.has('phone')" class="error_add">
              El celular no es válido.
            </div>
          </div>
        </div>
        <div class="input_mail">
          <input name="email" v-validate="'email'" class="inputs_add email ffr" placeholder="Mail" v-model="newUser.email">
          <div v-show="errors.has('email')" class="error_add">
            El mail no es válido.
          </div>
        </div>
      </div>
      <div class="third_line">
        <div class="input_document">
          <input type="text" class="inputs_add document ffr" placeholder="Documento" v-model="newUser.identityDocument" />
        </div>
      </div>
      <div class="quarter_line">
        <div class="input_tags">
          <div class="title_nt tag_add">
            <i class="icons icon_add_tag" @click="modalTags = true"></i>
            <span class="ffr" @click="modalTags = true">Tags</span>
          </div>
          <div class="client_tags">
            <div class="client_tag" v-for="(tag, i) in userTags" :key="i">
              <div>
                <i class="icons icon_tag"></i>
              </div>
              <span class="client_texts ffr">{{ tag }}</span>
              <div>
                <i class="icons icon_close_modal_tag" @click="deleteNT('tag', i)"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="notas-cont" :style="{marginTop: (userTags.length > 0) ? '14px' : 'unset'}">
        <textarea class="notes-input" v-model="notes" placeholder="Notas" maxlength="250"></textarea>
      </div>
    </div>
    <div class="footer_modal">
      <div class="container_upload">
        <span class="title_footer ffr">Cargar usuarios</span>
        <div class="input_upload">
          <b-upload v-model="file" class="file_label">
            <span class="file_name ffr" v-if="file">
              {{ file.name }}
            </span>
            <span class="file_name ffr" v-else>
              Sin archivos seleccionados
            </span>
            <span class="file_button">
              <span class="btn_text_file ffr">Seleccionar archivo</span>
            </span>
          </b-upload>
        </div>
      </div>
      <div class="container_buttons">
        <button class="btn_footer save ffr" @click="actionSave()" :disabled="sending">Guardar</button>
        <button class="btn_footer download ffr" @click="download">Descargar template</button>
      </div>
    </div>
    <b-modal :active.sync="modalTags" :can-cancel="false" id="modalTagUser">
      <div class="card_nt">
        <div class="card_header">
          <i class="icons icon_close_modal cards" @click="modalTags = false"></i>
          <span class="title_card ffr">Añadir tag</span>
        </div>
        <div class="card_content">
          <div class="input_card">
            <input type="text" class="inputs_card" placeholder="Escribir tag" v-model="tag">
          </div>
          <div class="btn_card">
            <button class="btn_add_card" @click="addNT('tags')">Agregar</button>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import callingCode from 'country-json/src/country-by-calling-code.json';
import BanderaPais from 'country-json/src/country-by-abbreviation.json';
import { minLength, required, email } from 'vuelidate/lib/validators';
import Axios from 'axios';
import globalTypes from '@/store/types/global';
import LocalStorage from 'store';
import _ from 'lodash';
export default {
  name: 'addUserModal',
  props: ['value'],
  data: () => ({
    newUser: {
      displayName: '',
      phoneNumber: '',
      email: '',
      identityDocument: null,
      vip: false,
      indicative: null
    },
    tag: '',
    notes: '',
    userTags: [],
    sending: false,
    errorXhr: false,
    option: {
      value: 57,
      label: 'Colombia ( +57 )',
      label_show: '+57',
      selected: true
    },
    bandera: null,
    paises: [],
    validateTel: '',
    file: null,
    modalTags: false
  }),
  mounted () {
    this.listPaises();
    const indicativo = this.$store.getters['global:vendor'].params.indicativeRegion;
    this.indicativeParam(indicativo);
    this.changedPais(this.option);
    document.getElementById('sc').scrollIntoView();
  },
  methods: {
    deleteNT (type, i) {
      if (type === 'tag') {
        this.userTags.splice(i, 1);
      }
    },
    addNT (type) {
      if (type === 'tags') {
        this.userTags.push(this.tag);
        this.tag = '';
        this.modalTags = false;
      }
    },
    actionSave () {
      if (this.file) {
        this.submitFile();
        return;
      }
      this.saveNewUser();
    },
    download () {
      window.open(process.env.VUE_APP_API + '/downloadTemplatePeople');
    },
    submitFile () {
      if (this.file !== '') {
        this.sending = true;
        this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: true, typeMessage: 3 });
        const vendorId = this.vendor.id;
        const thes = this;
        const formData = new FormData();
        formData.append('file', this.file);
        Axios.post(process.env.VUE_APP_API + '/dashboard/people/upload/' + parseInt(vendorId),
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: 'Bearer ' + LocalStorage.get('_token')
            }
          }
        ).then(data => {
          this.sending = false;
          this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
          thes.add_file = false;
          this.$buefy.notification.open({
            message: '<i class="icon_alert_success icon-mks green"></i>' + data.data.message,
            type: 'is-success',
            position: 'is-top'
          });
          this.$parent.close();
        })
          .catch(() => {
            this.sending = false;
            this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
            this.$buefy.notification.open({
              message: '<i class="icon_alert_danger icon-mks red"></i> Error subiendo el archivo',
              type: 'is-danger',
              duration: 8000,
              position: 'is-top'
            });
          });
      }
    },
    indicativeParam (indicativo) {
      const selectIndicative = this.paises.find(value => parseInt(value.value) === indicativo);
      this.option = selectIndicative;
    },
    saveNewUser () {
      this.sending = true;
      this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: true, typeMessage: 3 });
      this.$validator.validateAll().then(result => {
        if (result) {
          const vip = (this.newUser.vip === true) ? 1 : 0;
          const { email, displayName, phoneNumber } = this.newUser;
          const people = {
            vendorId: this.$store.state.idVendor,
            name: displayName,
            email: email,
            phone: phoneNumber,
            isVip: vip,
            tags: this.userTags,
            identityDocument: this.newUser.identityDocument,
            notes: this.notes,
            indicative: this.option.value
          };
          this.$store.dispatch({ type: 'people:add', data: people }).then(() => {
            this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
            this.$emit('loadUsers');
            this.add_user = false;
            this.$parent.close();
          }).catch(() => {
            this.sending = false;
            this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
            this.errorXhr = true;
          });
        } else {
          this.sending = false;
          this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
        }
      });
    },
    vipToogle () {
      this.newUser.vip = !this.newUser.vip;
    },
    listPaises () {
      var data = [];
      var ind = this.newUser.indicative != null ? this.newUser.indicative : null;
      _.forEach(callingCode, function (item) {
        if (item.calling_code !== null && item.country !== 'Bouvet Island') {
          if (ind != null && ind === item.calling_code) {
            data.push({
              value: item.calling_code,
              label: item.country + ' ( +' + item.calling_code + ' )',
              label_show: '+' + item.calling_code,
              selected: true
            });
          } else {
            data.push({
              value: item.calling_code,
              label: item.country + ' ( +' + item.calling_code + ' )',
              label_show: item.country + ' ( +' + item.calling_code + ' )'
            });
          }
        }
      });
      this.paises = data;
    },
    changedPais (indicativo) {
      this.option = indicativo;
      let aux2 = null;
      const aux3 = indicativo.label;
      this.pais = indicativo.label;
      this.newUser.indicative = indicativo.value;
      const ind = indicativo.value;
      _.forEach(BanderaPais, function (item) {
        if (ind !== null) {
          const data = item.country + ' ( +' + ind + ' )';
          if (data === aux3) {
            aux2 = item.abbreviation.toLowerCase();
          }
        }
      });
      this.bandera =
      aux2 != null ? '/dashboard/banderas/' + aux2 + '.png' : null;
      this.option.label_show = '+' + ind;
      switch (indicativo.value) {
        case 57:
          this.validateTel = 'numeric|min:10|max:10';
          break;
        case 507:
          this.validateTel = 'numeric|min:7|max:8';
          break;
        default:
          this.validateTel = 'numeric|min:7|max:11';
          break;
      };
    }
  },
  validations: {
    newUser: {
      displayName: { required },
      phoneNumber: { required, minLength: minLength(10) },
      email: { email }
    }
  },
  watch: {
    add_user () {
      if (!this.add_user) {
        this.newUser = {
          displayName: '',
          phoneNumber: '',
          email: '',
          vip: false,
          indicative: null
        };
        this.userTags = [];
        this.sending = false;
        this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
      }
    }
  },
  computed: {
    add_user: {
      get () {
        return this.value;
      },
      set (value) {
        this.$emit('input', value);
      }
    }
  }
};
</script>
<style  lang="scss">
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700);
@import '../../../assets/styles/users/addUserModal.scss';
</style>
