import Vue from 'vue';
import Vuex from 'vuex';
import { Validator } from 'vee-validate';
import validatorEs from '@/lang/validations/es';
import globalTypes from '@/store/types/global';
import authModule from '@/store/modules/auth';
import reservationModule from '@/store/modules/reservations';
import metricsModule from '@/store/modules/metrics';
import marketingModule from '@/store/modules/marketing';
import searchsModule from '@/store/modules/searchs';
import pollModule from '@/store/modules/poll';
import peopleModule from '@/store/modules/people';
import waitingListModule from '@/store/modules/waitingList';
import digitalOceanModule from '@/store/modules/digitalOcean';
import apiAuth from '@/helpers/serviceAuth';
import serviceVendor from '@/helpers/apis/serviceVendor';
import serviceAdmin from '@/helpers/apis/serviceAdmin';
import serviceUser from '@/helpers/apis/serviceUser';
import sectionModule from '@/store/modules/section';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    online: true,
    language: 'es',
    url: '',
    vendorUrl: '',
    vendor: [],
    permissions: [],
    socket: false,
    vendorId: (process.browser && window.localStorage.getItem('_vendorId') ? window.localStorage.getItem('_vendorId') : []),
    isMobile: false,
    orientation: null,
    width: null,
    height: null,
    sideBarState: false,
    idVendor: 0,
    reservation: (process.browser && window.localStorage.getItem('_reservation') ? JSON.parse(atob(window.localStorage.getItem('_reservation'))) : {}),
    serverTime: null,
    editMap: 0,
    showMessage: false,
    version: null,
    versionApp: 'v4.26.43-3',
    showSideBar: false,
    showTurnDetail: false,
    loaderPrecompro: {
      status: true,
      message: '',
      typeMessage: 1
    },
    referrers: [],
    chooseVendor: false
  },
  actions: {
    [globalTypes.actions.changeLanguage]: ({ commit }, lang) => {
      commit(globalTypes.mutations.setLanguage, lang);
      switch (lang) {
        case 'en':
          Validator.localize('en', validatorEs);
          break;
        case 'es':
          Validator.localize('es', validatorEs);
          break;
        default:
          Validator.localize('en', validatorEs);
      }
    },
    [globalTypes.actions.vendorId]: ({ commit }, vendor) => {
      return new Promise((resolve, reject) => {
        serviceVendor.get('vendorFromUrl?url=' + vendor.data.url)
          .then(response => {
            if (process.browser && response.data.code === 200) {
              window.localStorage.setItem('_vendorId', response.data.vendorId);
              window.localStorage.setItem('_id', response.data.Id);
            }
            commit(globalTypes.mutations.setVendorId);
            commit(globalTypes.mutations.setId);
            resolve(response);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    [globalTypes.actions.setVersion]: ({ commit }, vendor) => {
      return new Promise((resolve, reject) => {
        apiAuth.post('version', vendor.data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    [globalTypes.actions.vendor]: ({ commit }, vendor) => {
      return new Promise((resolve, reject) => {
        serviceVendor.get('vendors/' + vendor.data.vendorId)
          .then(response => {
            if (process.browser) {
              commit(globalTypes.mutations.setVendor, response.data);
            }
            resolve(response);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    [globalTypes.actions.getPermissions]: ({ commit }, data) => {
      return new Promise((resolve, reject) => {
        serviceUser.get('permissions/user/' + data.data.userId)
          .then(response => {
            commit(globalTypes.mutations.setPermissions, response.data.permissions);
            resolve(response);
          }).catch(err => {
            reject(err);
          });
      });
    },
    [globalTypes.actions.getServerTime]: ({ commit }, info) => {
      return new Promise((resolve, reject) => {
        serviceVendor.post('serverTime')
          .then(({ data }) => {
            commit(globalTypes.mutations.setServerTime, data.serverTime);
            resolve(data);
          }).catch(error => {
            reject(error);
          });
      });
    },
    [globalTypes.actions.getReferrers]: ({ commit }, info) => {
      serviceAdmin.get('referers')
        .then(({ data }) => {
          commit(globalTypes.mutations.setReferrers, data.data);
        }).catch(err => {
          console.error(err);
        });
    },
    [globalTypes.actions.getCustomPDF]: ({ commit }, vendor) => {
      return new Promise((resolve, reject) => {
        serviceVendor.post('pdfCustomTermsConditions/' + vendor.data.vendorId, vendor)
          .then(response => {
            resolve(response);
          }).catch(err => {
            reject(err);
          });
      });
    },
    [globalTypes.actions.trackInfoUserTermsConditions]: ({ commit }, vendor) => {
      return new Promise((resolve, reject) => {
        serviceVendor.post('acceptInfoTermsConditions/' + vendor.data.vendorId, vendor)
          .then(response => {
            resolve(response);
          }).catch(err => {
            reject(err);
          });
      });
    },
    [globalTypes.actions.getUserLogTermsConditionsAccepted]: ({ commit }, data) => {
      return new Promise((resolve, reject) => {
        serviceVendor.post('getUserInfoTermsConditions', data)
          .then(response => {
            resolve(response);
          }).catch(err => {
            reject(err);
          });
      });
    }
  },
  getters: {
    [globalTypes.getters.language]: state => state.language,
    [globalTypes.getters.vendorUrl]: state => state.vendorUrl,
    [globalTypes.getters.vendorId]: state => state.vendorId,
    [globalTypes.getters.idVendor]: state => state.idVendor,
    [globalTypes.getters.socket]: state => state.socket,
    [globalTypes.getters.url]: state => state.url,
    [globalTypes.getters.vendor]: state => state.vendor,
    [globalTypes.getters.reservation]: state => state.reservation,
    [globalTypes.getters.online]: state => state.online,
    [globalTypes.getters.width]: state => state.width,
    [globalTypes.getters.height]: state => state.height,
    [globalTypes.getters.permissions]: state => state.permissions,
    [globalTypes.getters.sideBarState]: state => state.sideBarState,
    [globalTypes.getters.serverTime]: state => state.serverTime,
    [globalTypes.getters.editMap]: state => state.editMap,
    [globalTypes.getters.showMessage]: state => state.showMessage,
    [globalTypes.getters.version]: state => state.version,
    [globalTypes.getters.versionApp]: state => state.versionApp,
    [globalTypes.getters.showSideBar]: state => state.showSideBar,
    [globalTypes.getters.referrers]: state => state.referrers,
    [globalTypes.getters.chooseVendor]: state => state.chooseVendor,
    [globalTypes.getters.showTurnDetail]: state => state.showTurnDetail
  },
  mutations: {
    [globalTypes.mutations.setVersionApp] (state, versionApp) {
      state.versionApp = versionApp;
    },
    [globalTypes.mutations.setShowSideBar] (state, showSideBar) {
      state.showSideBar = showSideBar;
    },
    [globalTypes.mutations.setVersion] (state, version) {
      state.version = version;
    },
    [globalTypes.mutations.setShowMessage] (state, showMessage) {
      state.showMessage = showMessage;
    },
    [globalTypes.mutations.setEditMap] (state, editMap) {
      state.editMap = editMap;
    },
    [globalTypes.mutations.setUrl] (state, url) {
      state.url = url;
    },
    [globalTypes.mutations.setSideBarState] (state, sideBarState) {
      state.sideBarState = sideBarState;
    },
    [globalTypes.mutations.setSocket] (state, socket) {
      state.socket = socket;
    },
    [globalTypes.mutations.setLanguage] (state, lang) {
      state.language = lang;
    },
    [globalTypes.mutations.setVendorUrl] (state, url) {
      state.vendorUrl = url;
    },
    [globalTypes.mutations.setVendorId] (state) {
      state.vendorId = (process.browser && window.localStorage.getItem('_vendorId')) ? window.localStorage.getItem('_vendorId') : [];
    },
    [globalTypes.mutations.setId] (state) {
      state.idVendor = (process.browser && window.localStorage.getItem('_id')) ? window.localStorage.getItem('_id') : [];
    },
    [globalTypes.mutations.setVendor] (state, vendor) {
      state.vendor = vendor;
    },
    [globalTypes.mutations.setOnline] (state, status) {
      state.online = status;
    },
    [globalTypes.mutations.setReservation] (state) {
      state.reservation = (process.browser && window.localStorage.getItem('_reservation')) ? JSON.parse(atob(window.localStorage.getItem('_reservation'))) : {};
    },
    [globalTypes.mutations.setWidth] (state, width) {
      state.width = width;
    },
    [globalTypes.mutations.setHeight] (state, height) {
      state.height = height;
    },
    [globalTypes.mutations.setPermissions] (state, permissions) {
      state.permissions = permissions;
    },
    [globalTypes.mutations.setServerTime] (state, serverTime) {
      state.serverTime = serverTime;
    },
    [globalTypes.mutations.setReferrers] (state, referrers) {
      state.referrers = referrers;
    },
    [globalTypes.mutations.setShowTurnDetail] (state, showTurnDetail) {
      state.showTurnDetail = showTurnDetail;
    },
    [globalTypes.mutations.setLoaderPrecompro] (state, { status, typeMessage = 2 }) {
      state.loaderPrecompro.status = status;
      switch (typeMessage) {
        case 1:
          state.loaderPrecompro.message = 'Validando Usuario...';
          break;
        case 2:
          state.loaderPrecompro.message = 'Cargando información...';
          break;
        case 3:
          state.loaderPrecompro.message = 'Procesando...';
          break;
        case 4:
          state.loaderPrecompro.message = 'Iniciando Dashboard...';
          break;
        case 5:
          state.loaderPrecompro.message = 'Cargando Metricas...';
          break;
        default:
          break;
      }
      state.loaderPrecompro.typeMessage = typeMessage;
    },
    [globalTypes.mutations.setChooseVendor] (state, val) {
      state.chooseVendor = val;
    }
  },
  modules: {
    authModule,
    reservationModule,
    metricsModule,
    pollModule,
    searchsModule,
    peopleModule,
    waitingListModule,
    digitalOceanModule,
    sectionModule,
    marketingModule
  }
});
