<template>
  <div class="payment-card">
    <div class="payment-card__flag">
      <span>{{index + 1}}</span>
    </div>
    <div class="payment-card__data">
      <p>
        <span style="max-width: 80px; margin-right: 5px;"># Factura</span>
        <span style="color: #444b57; max-width: 110px; width: 110px;">{{(payment.numberPaid) ? payment.numberPaid: 'No registra'}}</span>
      </p>
      <p>
        <span style="max-width: 80px; width: 80px; margin-right: 5px;">Nombre</span>
        {{ payment.name }}
      </p>
      <p>
        <span style="max-width: 80px; width: 80px; margin-right: 5px;">Fecha de pago</span>
        {{ payment.created_at | moment('DD/MM/YY') }}
      </p>
    </div>
    <div class="payment-card__footer">
      <i
        class="icon-mks paymentgray icon_contactless_payment_card payment-card__footer__icon"
        style="margin-top: 0px;"
      ></i>
      Pago {{ parseInt(payment.amount || payment.transaction_amount) | currency('$ ', 0, { thousandsSeparator: '.' }) }}
    </div>
  </div>
</template>
<script>
export default {
  props: ['payment', 'index']
};
</script>
<style lang="scss">
.payment-card {
  display: flex;
  flex-direction: column;
  border: 2px solid #a8adb4;
  border-radius: 0.5rem;
  width: 18.125rem;
  min-width: 10.563rem;
  height: 12.125rem;
  font-family: "Source Sans Pro";
  font-weight: 700;
  position: relative;
  @media screen and (min-width: 1920px) {
    margin-left: 0.2rem;
  }
  @media screen and (max-width: 1599px) and (min-width: 1500px) {
    margin-left: 0.5rem;
    width: 16.125rem;
  }
  &__flag {
    background-image: url(../../assets/icons/contactlessPayment/flag-icon.svg);
    width: 29px;
    height: 31px;
    position: absolute;
    right: 16px;
    color: white;
    text-align: center;
    font-size: 11pt;
    font-weight: 600;
    padding-top: 2.1px;
  }
  &__data {
    height: 67%;
    margin: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

  &__data p {
    display: flex;
    color: #444b57;
    font-size: 10pt;
  }
  &__data p span {
    color: #a8adb4;
    width: 110px;
    max-width: 110px;
    font-size: 9pt;
  }
  &__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #d8dadd;
    border-radius: 0 0 0.4rem 0.4rem;
    color: #444b57;
    height: 33%;
    gap: 10px;
    font-size: 15pt;
    &__icon {
      width: 30px;
      height: 40px;
    }
  }
}
@media screen and (max-width: 658px) and (min-width: 550px) {
  .payment-card {
    width: auto;
    height: 12.125rem;
    font-size: 9pt;
    &__data {
      height: 75%;
    }
    &__data p {
      flex-direction: column;
    }
    &__footer {
      height: 25%;
      gap: 10px;
      font-size: 12pt;
      &__icon {
        width: 30px;
        height: 30px;
      }
    }
  }
}
@media (max-width: 550px) {
  .payment-card {
    width: auto;
    height: 11.688rem;
    font-size: 9pt;
    &__data {
      height: 82%;
      justify-content: center;
    }
    &__data p {
      flex-direction: column;
    }
    &__footer {
      height: 18%;
      gap: 10px;
      font-size: 12pt;
      &__icon {
        width: 25px;
        height: 25px;
      }
    }
  }
}
</style>
