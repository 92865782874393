<template>
  <b-modal :active.sync="showTempPassword" :can-cancel="false" class="" id="modalTempPassword" centered>
    <div class="content-modal-reset-password">
      <h2>¡Crea tu nueva contraseña!</h2>
      <p>Por seguridad debes crear una contraseña nueva para acceder a tu cuenta.</p>
      <form @submit.prevent="validateBeforeSubmit" v-if="showFormReset">
        <div class="content-modal-reset-password__fields">
          <div class="field">
            <label>Escribe tu nueva contraseña</label>
            <div class="container-password">
              <input :type="showPassword ? 'text': 'password'" class="" @input="validateFields" id="password" :class="{ 'has-error' : errors.has('password') }" placeholder="Nueva Contraseña" autocomplete="off" ref="password" v-validate="'required|min:6'" name="password" v-model="password" @keypress="setMsgNull()">
              <svg @click="showPass('password')" class="icon-eye-password" xmlns="http://www.w3.org/2000/svg" width="14" height="10" viewBox="0 0 14 10" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M7 0C10.6107 0 12.6679 2.26752 13.5723 3.61905C13.8519 4.03398 14 4.51217 14 5C14 5.48783 13.8519 5.96602 13.5723 6.38095C12.6679 7.73248 10.6107 10 7 10C3.38934 10 1.3321 7.73248 0.427685 6.38095C0.148096 5.96602 0 5.48783 0 5C0 4.51217 0.148096 4.03398 0.427685 3.61905C1.3321 2.26752 3.38934 0 7 0ZM7 8.92991C10.0433 8.92991 11.8014 6.97967 12.5781 5.82076C12.7442 5.57413 12.8322 5.28993 12.8322 5C12.8322 4.71007 12.7442 4.42587 12.5781 4.17924C11.8014 3.01819 10.0433 1.07009 7 1.07009C3.95671 1.07009 2.19861 3.02033 1.4219 4.17924C1.25579 4.42587 1.16781 4.71007 1.16781 5C1.16781 5.28993 1.25579 5.57413 1.4219 5.82076C2.19861 6.97967 3.95671 8.92991 7 8.92991ZM5.33329 2.50559C5.82664 2.17595 6.40666 2 7 2C7.79536 2.00095 8.55787 2.31733 9.12027 2.87973C9.68267 3.44213 9.99905 4.20464 10 5C10 5.59334 9.82405 6.17336 9.49441 6.66671C9.16477 7.16006 8.69623 7.54457 8.14805 7.77164C7.59987 7.9987 6.99667 8.05811 6.41473 7.94235C5.83279 7.8266 5.29824 7.54088 4.87868 7.12132C4.45912 6.70176 4.1734 6.16721 4.05765 5.58527C3.94189 5.00333 4.0013 4.40013 4.22836 3.85195C4.45543 3.30377 4.83994 2.83524 5.33329 2.50559Z" fill="#A1A5AB"/>
              </svg>
            </div>
            <div class="error-s" v-if="errors.first('password') && msg === null" >
              <span class="">{{ errors.first('password') }}</span>
            </div>
          </div>
          <div class="field">
            <label>Confirmar contraseña</label>
            <div class="container-password">
              <input :type="showRePassword ? 'text': 'password'" class="" @input="validateFields" id="password_confirmation" :class="{ 'has-error' : errors.has('password_confirmation') }" placeholder="Confirmar Contraseña" autocomplete="off" v-validate="'required|confirmed:password'" name="password_confirmation" v-model="repassword" @keypress="setMsgNull()">
              <svg @click="showPass('repassword')" class="icon-eye-password" xmlns="http://www.w3.org/2000/svg" width="14" height="10" viewBox="0 0 14 10" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M7 0C10.6107 0 12.6679 2.26752 13.5723 3.61905C13.8519 4.03398 14 4.51217 14 5C14 5.48783 13.8519 5.96602 13.5723 6.38095C12.6679 7.73248 10.6107 10 7 10C3.38934 10 1.3321 7.73248 0.427685 6.38095C0.148096 5.96602 0 5.48783 0 5C0 4.51217 0.148096 4.03398 0.427685 3.61905C1.3321 2.26752 3.38934 0 7 0ZM7 8.92991C10.0433 8.92991 11.8014 6.97967 12.5781 5.82076C12.7442 5.57413 12.8322 5.28993 12.8322 5C12.8322 4.71007 12.7442 4.42587 12.5781 4.17924C11.8014 3.01819 10.0433 1.07009 7 1.07009C3.95671 1.07009 2.19861 3.02033 1.4219 4.17924C1.25579 4.42587 1.16781 4.71007 1.16781 5C1.16781 5.28993 1.25579 5.57413 1.4219 5.82076C2.19861 6.97967 3.95671 8.92991 7 8.92991ZM5.33329 2.50559C5.82664 2.17595 6.40666 2 7 2C7.79536 2.00095 8.55787 2.31733 9.12027 2.87973C9.68267 3.44213 9.99905 4.20464 10 5C10 5.59334 9.82405 6.17336 9.49441 6.66671C9.16477 7.16006 8.69623 7.54457 8.14805 7.77164C7.59987 7.9987 6.99667 8.05811 6.41473 7.94235C5.83279 7.8266 5.29824 7.54088 4.87868 7.12132C4.45912 6.70176 4.1734 6.16721 4.05765 5.58527C3.94189 5.00333 4.0013 4.40013 4.22836 3.85195C4.45543 3.30377 4.83994 2.83524 5.33329 2.50559Z" fill="#A1A5AB"/>
              </svg>
            </div>
            <div class="error-s" v-if="errors.first('password_confirmation') && msg === null" >
              <span class="">{{ errors.first('password_confirmation') }}</span>
            </div>
          </div>
            <div class="error-s" v-if="error != null">
            <span class="">{{ error }}</span>
          </div>
          <div class="alert" v-if="msg != null">
            <span class="error-s">{{ msg }}</span>
          </div>
        </div>
        <div class="content-modal-reset-password__terms-conditions" v-if="allowTermsConditionsIfExist">
          <b-checkbox class="" v-model="acceptTerms"></b-checkbox>
          <div>Para ver los Términos y condiciones <b @click="loadPDF">¡haz clic aquí!</b></div>
        </div>
        <b-button :disabled="!allValidated" class="content-modal-reset-password--action-save" native-type="submit">Acepto</b-button>
      </form>
    </div>
  </b-modal>
</template>

<script>
import { mapActions } from 'vuex';
import authTypes from '@/store/types/auth';
import ModalPdfViewerVue from '@/components/_shared/ModalPdfViewer';

export default {
  components: {
  },
  data () {
    return {
      loading: false,
      password: null,
      repassword: null,
      logoutAction: false,
      error: null,
      msg: null,
      showFormReset: true,
      hasError: false,
      modalInstance: null,
      acceptTerms: false,
      logTerms: {
        vendorId: null,
        dateAccept: null,
        dateView: null,
        dateDownload: null,
        email: null
      },
      newTermsForOldUsers: false, // se realiza este state para saber si hay un usuario en el log de términos y condiciones y así, saber si debo sacarle o no los términos y condiciones para los viejos usuarios
      showPassword: false,
      showRePassword: false,
      allValidated: false,
      allowTermsConditionsIfExist: false
    };
  },
  mounted () {
    this.getCustomPDF();
  },
  methods: {
    ...mapActions({
      changedTemp: authTypes.actions.changedTempPassword
    }),
    validateBeforeSubmit () {
      this.$validator.validateAll().then(result => {
        if (result) {
          if (this.allowTermsConditionsIfExist) {
            this.trackUserAcceptTermsConditions();
          } else {
            this.changedTempPasswordUser();
          }
        }
      });
    },
    changedTempPasswordUser () {
      this.loading = true;
      this.changedTemp({
        userId: this.idUser,
        password: this.password,
        password_confirmation: this.repassword,
        logout: this.logoutAction
      }).then(response => {
        if (parseInt(response.status) === 200) {
          this.loading = false;
          this.password = null;
          this.repassword = null;
          this.logoutAction = false;
          this.msg = 'Se actualizó con éxito!';
        } else {
          this.loading = false;
          this.error = response;
        }
      }).catch(fallo => {
        this.loading = false;
        this.error = fallo;
      });
    },
    onReset (event) {
      event.preventDefault();
      // Reset our form values
      this.form.password = '';
      this.form.repassword = '';
      // Trick to reset/clear native browser form validation state
      this.showFormReset = false;
      this.$nextTick(() => {
        this.showFormReset = true;
      });
    },
    getCustomPDF () {
      this.loading = true;
      this.$store.dispatch({ type: 'global:getCustomPDF', data: { vendorId: this.vendor.id, restaurantName: this.vendor.displayName, userDateCreated: this.$moment(this.user.created_at).format('DD/MM/YYYY') } }).then(resp => {
        const { data } = resp;
        if (resp.status === 200) {
          if (data.link) {
            this.allowTermsConditionsIfExist = true;
          } else {
            this.acceptTerms = true;
            this.allowTermsConditionsIfExist = false;
          }
          if (this.user.hasAccess !== undefined) {
            if (!this.user.hasAccess) {
              this.$store.commit(authTypes.mutations.setShowTempPassword, true);
            } else {
              this.validateIfUserExistInLogTermsConditionsAccepted();
            }
          }
          this.loading = false;
        } else {
          this.$buefy.toast.open({
            message: 'Ha ocurrido un error, porfavor comunicate con un administrador',
            type: 'is-danger'
          });
        }
      }).catch(() => {
        this.$buefy.toast.open({
          message: 'Lo siento, ha ocurrido un error al intentar procesar tu petición',
          type: 'is-danger'
        });
      });
    },
    loadPDF () {
      this.logTerms.dateView = this.$moment().format('YYYY-MM-DD HH:mm:ss');
      const thes = this;
      this.$buefy.modal.open({
        parent: this,
        canCancel: false,
        component: ModalPdfViewerVue,
        hasModalCard: true,
        customClass: 'custom-load-pdf',
        trapFocus: true,
        props: {
          newTermsForOldUsers: thes.newTermsForOldUsers,
          onlyView: false
        },
        events: {
          changeLoading (status) {
            thes.loading = status;
          },
          changeTermsConditions (status) {
            thes.acceptTerms = status;
          },
          registerDateDownloadFile () {
            thes.logTerms.dateDownload = thes.$moment().format('YYYY-MM-DD HH:mm:ss');
          },
          trackUserAcceptTermsConditions () {
            thes.acceptTerms = true;
            thes.logTerms.dateAccept = thes.$moment().format('YYYY-MM-DD HH:mm:ss');
            thes.trackUserAcceptTermsConditions();
          }
        }
      });
    },
    trackUserAcceptTermsConditions () {
      this.loading = true;
      this.logTerms.vendorId = this.vendor.id;
      this.logTerms.email = this.user.email;
      const data = this.logTerms;
      this.$store.dispatch({ type: 'global:trackInfoUserTermsConditions', data }).then(resp => {
        this.loading = false;
        if (resp.data.code === 200) {
          if (this.newTermsForOldUsers) {
            this.$buefy.toast.open({
              message: 'Términos y condiciones aceptados correctamente',
              type: 'is-success'
            });
          } else {
            this.changedTempPasswordUser();
          }
        } else {
          this.$buefy.toast.open({
            message: resp.data.message,
            type: 'is-danger'
          });
        }
      }).catch(() => {
        this.loading = false;
        this.$buefy.toast.open({
          message: 'Lo siento, ha ocurrido un error al intentar procesar tu petición',
          type: 'is-danger'
        });
      });
    },
    validateIfUserExistInLogTermsConditionsAccepted () {
      if (this.allowTermsConditionsIfExist) {
        this.loading = true;
        const data = {
          email: this.user.email,
          vendorId: this.vendor.id
        };
        this.$store.dispatch({ type: 'global:getUserLogTermsConditionsAccepted', data }).then(resp => {
          this.loading = false;
          const { data } = resp;
          if (data.code === 200) {
            if (!data.exist) {
              this.newTermsForOldUsers = true;
              this.loadPDF();
            }
          } else {
            this.$buefy.toast.open({
              message: data.message,
              type: 'is-danger'
            });
          }
        }).catch(() => {
          this.loading = false;
          this.$buefy.toast.open({
            message: 'Lo siento, ha ocurrido un error al intentar procesar tu petición',
            type: 'is-danger'
          });
        });
      }
    },
    showPass (type) {
      if (type === 'password') {
        this.showPassword = !this.showPassword;
      } else {
        this.showRePassword = !this.showRePassword;
      }
    },
    setMsgNull () {
      this.msg = null;
    },
    validateFields () {
      this.$validator.validateAll().then(result => {
        if (result) {
          if (this.acceptTerms) {
            this.allValidated = true;
          } else {
            this.allValidated = false;
          }
        } else {
          this.allValidated = false;
        }
      });
    }
  },
  watch: {
    acceptTerms () {
      if (this.acceptTerms) {
        this.$validator.validateAll().then(result => {
          if (result) {
            this.allValidated = true;
          }
        });
        this.logTerms.dateAccept = this.$moment().format('YYYY-MM-DD HH:mm:ss');
      } else {
        this.allValidated = false;
      }
    }
  }
};
</script>
<style lang="scss">
  @import "../../assets/styles/users/tempPassword.scss";
</style>
