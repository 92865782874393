import namespace from '@/helpers/namespace';

export default namespace('people', {
  actions: [
    'add',
    'findByReservationId',
    'search',
    'update',
    'getOneUser',
    'getMetricsUser',
    'getStatisticsOneUser'
  ],
  getters: [
  ],
  mutations: [
  ]
});
