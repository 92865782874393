<template>
  <div
    id="CronologyMap"
    ref="CronologyMap"
    :class="{
      tableModalOpen: tableModalOpen || keyForModal || keyForModal === 0,
      frontOfTables: keyForModal === 'xd',
    }"
  >
  <div class="floatButtonContainer" v-if="!validatePermission('ReadOnly') && !validatePermission('MixSFCN')">
          <button v-if="isAtleastToday" class="floatButton ReservationButton" @click.prevent.stop="setAddReservation()" v-show="!(tableModalOpen || keyForModal || keyForModal === 0 || keyForModal === 'xd')"><p>+</p></button>
           <button v-if="isToday" class="floatButton WalkButton" @click.prevent.stop="setAddWalkin()" v-show="!(tableModalOpen || keyForModal || keyForModal === 0 || keyForModal === 'xd')"><p>+</p></button>
           <button v-if="isAtleastToday && params.prePurchaseDashboard" class="floatButton precompraButton" @click.prevent.stop="setAddPrecompra()" v-show="!(tableModalOpen || keyForModal || keyForModal === 0 || keyForModal === 'xd')"><p>+</p></button>
  </div>

                <portal selector="#space">
                      <header id="headerMapCronology" :style="{width:timeWidth}">

      <h2
        v-for="(hour, index) in hoursAvailable"
        :key="'hour' + hour['dateComplete']"
        :class="{ afternon: isAfternon(hour), firstOfType: index === 0 ,halfAfternoon: isHalfwhite(hour,false),firstHalfMorning:isFirstHalfwhite(hour,false) }"
      >
        {{ hour.dateComplete | moment("hh:mm:a") }}
      </h2>
      <h2
        v-for="hourExtra in extraHours"
        :key="'extra' + hourExtra"
        :class="{
          afternon: isAfternon(hourExtra),
          halfAfternoon: isHalfwhite(hourExtra),

        }"
      >
        {{ hourExtra | moment("hh:mm:a") }}
      </h2>
      <div class="lastblock"></div>
                      <div
      class="TimeLineTime"
      :style="{
        transform: `translateX(${positionXTimeLine - 39}px)`,
      }"
    >
    <p>
      {{timeOnCursor}}
    </p>
    </div>
    </header>
                  </portal>
                <portal selector="#mainContainer">
                  <div id="virtualScrollMap" ref="virtualScrollMap" :style="{'top':this.bottom+'px' }">
                    <section :style="{'width':this.selfWidthScroll+'px'}" class="scrollV"></section>
                  </div>
                  </portal>
    <!-- <section class="headerSpace">

    </section> -->
    <section class="mainContent" id="cronologymainContent">
          <div
      class="TimeLine"
      id="TimeLine"
      :style="{
        transform: `translateX(${positionXTimeLine}px)`,
      }"
    ></div>
      <div
        v-for="(zone, zoneIndex) in tablesOrderByZoneInfo"
        :key="'zone-' + zoneIndex"
        :class="{ isHidden: hideZones.includes(zoneIndex) ,noTouch:!zone[0].zoneId}"
        class="ZoneofCronology"
        :id="zone.zoneId"
        :style="{height:'fit-content'}"
      >
      <section v-if="!zonesNotOnView.includes(zone[0].zoneId) || hideZones.includes(zoneIndex)"  class="containerofZones">
        <div v-if="!hideZones.includes(zoneIndex) " class="zoneContainer mapForZoneContainer">
          <div
            v-for="(table, indexTables) in zone"
            :key="'table-' + table.id"
            class="rowTable"
            :style="{ height: `${table.height}px` }"
            :class="{ isTableSelected: table.id === tableModalOpen }"
            :id="`tableCode${table.id}`"
          >
          <div class="squaresContainer">
            <div class="hourSquare first" ref="firstSquareHour">
              <span
                class="extraHourMask"
                @click="() => {modalOpen({ key: '', tableId: '' }); switchClickOnMask = !switchClickOnMask;}"
              ></span>
            </div>
            <div
              class="hourSquare"
              id="hourSquare"
              ref="normalSquares"
              v-for="(hour, index) in hoursAvailable"
              :key="'hourAvailable-' + hour.dateComplete"
              :class="{
                occuped:table.zoneId ? probeIfOccuped(hour.dateComplete, table) : false,
                afternon: isAfternon(hour),
                hideBlock:
                  index === keyForModal && modalOpenFromTableId === table.id,
              }"
              @click="
                createItemMap(
                  $event,
                  table,
                  hour,
                  indexTables,
                  allTables,
                  tablesOrderByZoneInfo
                )
              "
            >
              <MapItemReservation
                v-if="
                  itemHaveTobeCreated(
                    reservationsOrderByTable[table.id],
                    formatHour(hour)
                  )
                "
                :switchClickOnMask="switchClickOnMask"
                :lastScroll="lastScroll"
                :hourSquare="formatHour(hour)"
                :reservationOftheDay="reservationsOrderByTable[table.id]"
                @needToHide="modalOpen"
                :keySquare="index"
                :keySelected="keyForModal"
                :nameTofilter="nameTofilter"
              ></MapItemReservation>
              <span class="hourMask"></span>
              <span
                class="extraHourMask"
                @click="
                  () => {
                    modalOpen({ key: '', tableId: '' });
                    switchClickOnMask = !switchClickOnMask;
                  }
                "
              ></span>
            </div>
            <div
              v-for="hourExtra in extraHours"
              :key="'extra2-' + hourExtra"
              class="hourSquare"
              id="hourSquare"
              :class="{ afternon: isAfternon(hourExtra) }"
            >
              <span class="hourMask"
                ><span
                  class="extraHourMask"
                  @click="
                    () => {
                      modalOpen({ key: '', tableId: '' });
                      switchClickOnMask = !switchClickOnMask;
                    }
                  "
                ></span
              ></span>
            </div>
            </div>
          </div>
        </div>
        <div v-else class="titleForHiddenZone">
          {{ zone[0].zone }}
        </div>
        </section>
        <section v-else class="loaderPage" :style="{'min-height':zone.length*50 + 'px'}"></section>
      </div>
          <div
            v-for="(table) in extraRowsObject"
            :key="'table2-' + table.id"
            class="rowTable"
            :style="{ height: `${table.height}px` }"
          >
            <div class="hourSquare first" ref="firstSquareHour">
              <span
                class="extraHourMask"
              ></span>
            </div>
            <div
              class="hourSquare"
              id="hourSquare"
              ref="normalSquares"
              v-for="(hour, index) in hoursAvailable"
              :key="'hour3-' + hour.dateComplete"
              :class="{
                occuped:table.zoneId ? probeIfOccuped(hour.dateComplete, table) : false,
                afternon: isAfternon(hour),
                hideBlock:
                  index === keyForModal && modalOpenFromTableId === table.id,
              }"
            >
              <span class="hourMask"></span>
              <span
                class="extraHourMask"
              ></span>
            </div>
            <div
              v-for="hourExtra in extraHours"
              :key="hourExtra"
              class="hourSquare"
              id="hourSquare"
              :class="{ afternon: isAfternon(hourExtra) }"
            >
              <span class="hourMask"
                ><span
                  class="extraHourMask"
                ></span
              ></span>
            </div>
        </div>
    </section>
  </div>
</template>
<script>
import Vue from 'vue';
import MapItem from './ReservationItem.vue';
import MapItemReservation from './reservationInfo.vue';
import store from '@/store';
import { Portal as portal } from '@linusborg/vue-simple-portal';
import moment from 'moment-timezone';

export default {
  props: [
    'hoursAvailable',
    'allTables',
    'reservationList',
    'tableModalOpen',
    'hideZones',
    'tablesOrderByZoneInfo',
    'lastScroll',
    'nameTofilter',
    'rerender',
    'lastScrollY',
    'lastScrollX',
    'minutesBetweenTurns',
    'extraRowsObject'
  ],
  components: {
    MapItemReservation,
    portal
  },
  data () {
    return {
      Zones: [],
      positionXTimeLine: 0,
      timeOnCursor: '00:00:am',
      instanceForNewReservationItem: {},
      instancesForReservations: [],
      extraHours: [],
      isModalOpen: false,
      keyForModal: '',
      modalOpenFromTableId: '',
      modalFromItemOpen: true,
      renderComponent: true,
      selectedDateChange: false,
      switchClickOnMask: false,
      gettingReady: false,
      selfWidth: 0,
      selfWidthScroll: 0,
      bottom: 0,
      virtualScrollMap: null,
      leftPoistion: 0,
      observadores: [],
      inScroll: false,
      loading: true,
      squareSize: 0,
      firstSpace: 0,
      firstTimeLineHour: 0,
      timerId: null,
      zonesNotOnView: []
    };
  },
  created () {
    this.$emit('loading', true);
  },

  mounted () {
    if (window.innerWidth > 600) {
      const divObservado = document.getElementById('cronologymainContent');
      const observador = new MutationObserver(mutations => {
        mutations.forEach(mutation => {
          if (mutation.type === 'childList') {
            this.detenerObservadores();
            [...document.getElementsByClassName('ZoneofCronology')].forEach((e) => {
              this.agregarObservador(e);
            });
          }
        });
      });

      const opcionesObservador = {
        childList: true // Observar cambios en la lista de hijos
      };

      observador.observe(divObservado, opcionesObservador);
    }

    this.observeHeight();
  },
  methods: {
    probeIfOccuped (squareHour, squareTable) {
      const hourBlock = this.$moment(squareHour).valueOf() + '';
      const blockedHours = squareTable.blocked;
      if (blockedHours.length > 0) {
        const isblocked = blockedHours.filter((hourObject) => {
          return hourObject.timestamp === hourBlock;
        });
        if (isblocked.length > 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    agregarObservador (elemento) {
      const observador = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add('onview');
            const index = this.zonesNotOnView.findIndex((e) => parseInt(e) === parseInt(entry.target.id));
            if (index >= 0) {
              this.zonesNotOnView.splice(index, 1);
            }
          } else {
            entry.target.classList.remove('onview');
            const index = this.zonesNotOnView.findIndex((e) => parseInt(e) === parseInt(entry.target.id));
            if (index > 0) {
            } else {
              this.zonesNotOnView.push(parseInt(entry.target.id));
            }
          }
        });
      });
      observador.observe(elemento);
      this.observadores.push(observador);
    },
    detenerObservadores () {
      this.observadores.forEach(observador => {
        observador.disconnect();
      });

      this.observadores.length = 0; // Vaciar el array de observadores
    },
    observeHeight () {
      const instance = this;
      const resizeObserver = new ResizeObserver(() => {
        const innerWidth = window.innerWidth;
        if (this.$el.clientHeight < 45) {
          this.loading = true;
          this.$emit('loading', true);
        } else {
          this.loading = false;
          this.$emit('loading', false);
        }
        if (!this.loading) {
          const virualScroll = document.getElementById('virtualScrollMap');
          const mainContainer = this.$refs.CronologyMap;
          mainContainer.addEventListener('mousemove', this.cursorMove, { passive: true });
          if (innerWidth > 600) {
            virualScroll.style.maxWidth = mainContainer.innertWidth + 'px';
            virualScroll.style.left = document.getElementById('mainContainer').offsetLeft + 153 + 'px';
          }
          const scrollXcontainer = document.getElementById('scrollXContainer');
          scrollXcontainer.addEventListener('scroll', function (e) {
            if (!instance.inScroll) {
              window.requestAnimationFrame(function () {
                document.getElementById('virtualScrollMap').scrollLeft = e.target.scrollLeft;
                document.getElementById('headerMapCronology').scrollLeft = e.target.scrollLeft;
              });
            }
          }, { passive: true });
          if (!this.virtualScrollMap) {
            if (virualScroll) {
              instance.virtualScrollMap = virualScroll;
              virualScroll.addEventListener('mouseenter', () => {
                instance.inScroll = true;
              }, { passive: true });
              virualScroll.addEventListener('mouseleave', () => {
                instance.inScroll = false;
              }, { passive: true });
              virualScroll.addEventListener('scroll', function (e) {
                if (instance.inScroll) {
                  document.getElementById('headerMapCronology').scrollLeft = e.target.scrollLeft;
                  document.getElementById('scrollXContainer').scrollLeft = e.target.scrollLeft;
                }
              }, { passive: true });
            }
          }
          this.leftPoistion = document.getElementById('mapContainer').offsetLeft;
          if (innerWidth <= 600) {
            this.selfWidth = document.getElementById('mainContainer').offsetWidth - 123;
            this.selfWidthScroll = this.$el.scrollWidth;
          } else {
            this.selfWidth = document.getElementById('mainContainer').offsetWidth - 160;
            this.selfWidthScroll = this.$el.scrollWidth;
            const elemento = document.getElementById('mainContainer');
            if (elemento.getBoundingClientRect()) {
              const posicionTop = elemento.getBoundingClientRect().top;
              const altura = elemento.offsetHeight;
              const posicionBottom = posicionTop + altura;
              if (innerWidth <= 900) {
                this.bottom = posicionBottom - 102;
              } else {
                this.bottom = posicionBottom - 53;
              }
            }
          }
        }
      });

      resizeObserver.observe(document.getElementById('mainContainer'));
    },
    cursorMove (event) {
      clearTimeout(this.timerId);
      this.timerId = setTimeout(() => {
        this.firstSpace = this.$refs.firstSquareHour[0].offsetWidth;
        this.squareSize = this.$refs.normalSquares[0].offsetWidth;
        this.firstHour = this.$moment(this.hoursAvailable[0].dateComplete);
        const map = this.$refs.CronologyMap;
        const realPosition = event.clientX - map.getBoundingClientRect().left;
        this.positionXTimeLine = realPosition;
        const timeAdvanced = ((30 / this.squareSize)) * (realPosition - this.firstSpace);
        this.timeOnCursor = this.firstHour.add(timeAdvanced, 'minutes').format('hh:mm a');
      }, 8);
    },
    createItemMap (event, tableInfo, hour, index, array, zonesWithTables) {
      const $moment = this.$moment;
      const todayHour = parseInt($moment().format('HH'));
      const todayMinutes = parseInt($moment().minutes());
      const squareHour = parseInt($moment(hour.dateComplete).hours());
      const squareMinutes = parseInt($moment(hour.dateComplete).minutes());
      const squareDate = parseInt(
        $moment(this.selectedDate)
          .set({ hour: parseInt(squareHour), minute: parseInt(squareMinutes) })
          .format('HH')
      );
      if (this.instanceForNewReservationItem.$destroy) {
        if (this.instanceForNewReservationItem.$el.parentNode) {
          this.instanceForNewReservationItem.$el.parentNode.removeChild(
            this.instanceForNewReservationItem.$el
          );
          this.modalOpen({
            key: '',
            tableId: ''
          });
        }

        this.instanceForNewReservationItem.$destroy();
      }

      if (window.innerWidth <= 600 && event.target.id === 'hourSquare') {
        this.scrollParentToChild(document.getElementById('scrollXContainer'), event.target);
        document.getElementById('Cronology').style.setProperty('--lastScrollYmodalOfItem', document.getElementById('mainContainer').scrollTop + 'px');
      }
      let validMinutes;
      if (todayHour === squareDate) {
        validMinutes = todayMinutes <= squareMinutes + this.minutesBetweenTurns - 1;
      } else {
        validMinutes = true;
      }

      if (squareDate >= todayHour && validMinutes) {
        if (event.target.id === 'hourSquare') {
          let totalTablesArray = [];
          const zonesWithTablesArray = [...zonesWithTables];
          let lastTable = false;
          if (this.hideZones.length > 0) {
            this.hideZones.forEach((hideZone) => {
              zonesWithTablesArray.splice(hideZone, 1);
            });
          }
          zonesWithTablesArray.forEach((array) => {
            totalTablesArray = totalTablesArray.concat(array);
          });
          if (
            tableInfo.displayName ===
              totalTablesArray[totalTablesArray.length - 1].displayName ||
            tableInfo.displayName ===
              totalTablesArray[totalTablesArray.length - 2].displayName
          ) {
            lastTable = true;
          }
          const ComponentClass = Vue.extend(MapItem);
          const instance = new ComponentClass({
            store,
            propsData: {
              alltableInformation: tableInfo,
              actualHour: hour,
              lastTable,
              lastScrollY: this.lastScrollY,
              lastScrollX: this.lastScrollX,
              minutesBetweenTurns: this.minutesBetweenTurns

            }
          });
          instance.$mount(); // pass nothing
          this.modalOpen({
            key: 'xd',
            tableId: tableInfo.id
          });
          instance.$on('close', () => {
            if (this.instanceForNewReservationItem.$destroy) {
              if (this.instanceForNewReservationItem.$el.parentNode) {
                this.instanceForNewReservationItem.$el.parentNode.removeChild(
                  this.instanceForNewReservationItem.$el
                );
                this.modalOpen({
                  key: '',
                  tableId: ''
                });
              }

              this.instanceForNewReservationItem.$destroy();
            }
          }
          );
          event.target.appendChild(instance.$el);
          this.instanceForNewReservationItem = instance;
        }
      }
    },
    formatHour (hour) {
      const formattedHour = hour.dateComplete
        ? this.$moment(hour.dateComplete).format('hh:mm a')
        : this.$moment(hour).format('hh:mm a');
      return formattedHour;
    },
    isConfigRotation (reservation) {
      let response = null;
      const dayOfWeek = this.$moment(parseInt(reservation.date)).format('d');
      if (Object.values(this.rotation).length > 0) {
        this.rotation.map((item) => {
          if (item.dayOfWeek == null && item.people != null) {
            if (reservation.people === item.people) {
              response = item.rotationTime;
            }
          } else if (item.dayOfWeek != null && item.people != null) {
            if (
              reservation.people === item.people &&
              item.dayOfWeek === dayOfWeek
            ) {
              response = item.rotationTime;
            }
          } else if (item.dayOfWeek != null && item.people == null) {
            if (item.dayOfWeek === dayOfWeek) {
              response = item.rotationTime;
            }
          } else if (reservation.sectionId === item.zoneId + '') {
            response = item.rotationTime;
          }
        });
      }
      return response ? moment.duration(response).hours() : 1;
    },
    isAfternon (hour, needtoFormat = true) {
      const hourFormatted = needtoFormat ? this.formatHour(hour) : hour;
      if (hourFormatted.includes('pm')) {
        return true;
      }
      return false;
    },
    isHalfwhite (hour, fromExtra = true) {
      let formattedHour;
      if (fromExtra) {
        formattedHour = this.formatHour({ dateComplete: hour });
      } else {
        formattedHour = this.formatHour(hour);
      }
      return formattedHour === '12:00 am';
    },
    isFirstHalfwhite (hour, fromExtra = true) {
      let formattedHour;
      if (fromExtra) {
        formattedHour = this.formatHour({ dateComplete: hour });
      } else {
        formattedHour = this.formatHour(hour);
      }
      return formattedHour === '12:00 pm';
    },
    modalOpen (value) {
      this.keyForModal = value.key;
      this.modalOpenFromTableId = parseInt(value.tableId);
      if (value.key !== '' || value.key === 0) {
        this.$emit('openModalFromMap', true);
      } else {
        this.$emit('openModalFromMap', false);
      }
    },
    scrollParentToChild (parent, child) {
      var parentRect = parent.getBoundingClientRect();
      var childRect = child.getBoundingClientRect();
      const scrollLeft = childRect.left - parentRect.left;
      parent.scrollLeft += scrollLeft;
      return scrollLeft;
    },
    forceRerender () {
      // Removing my-component from the DOM
      this.renderComponent = false;

      this.$nextTick(() => {
        // Adding the component back in
        this.renderComponent = true;
      });
    },
    itemHaveTobeCreated (reservationOfTheDay, hourSquare) {
      const hoursReserved = reservationOfTheDay
        ? Object.keys(reservationOfTheDay)
        : null;
      if (hoursReserved) {
        if (hoursReserved.includes(hourSquare)) {
          return true;
        }
      }
      return false;
    }
  },
  computed: {
    timeWidth () {
      if (window.innerWidth <= 600) {
        return this.selfWidth + 16 + 'px';
      } else {
        return this.selfWidth + 6 + 'px';
      }
    },
    reservationsOrderByTable () {
      return { ...this.reservationList };
    },
    isToday () {
      const fecha1 = this.$moment(this.selectedDate);
      const fecha2 = this.$moment();
      return fecha1.isSame(fecha2, 'date');
    },
    isAtleastToday () {
      const $moment = this.$moment;
      const dia1 = parseInt($moment(this.selectedDate).format('DD'));
      const dia2 = parseInt($moment().format('DD'));
      const mes1 = parseInt($moment(this.selectedDate).format('MM'));
      const mes2 = parseInt($moment().format('MM'));
      if (dia1 < dia2 && mes1 <= mes2) {
        return false;
      } else {
        return true;
      }
    }
  },

  watch: {
    selectedDate () {
      this.selectedDateChange = true;
    },
    hoursAvailable (value) {
      const $moment = this.$moment;
      const lastHour = value[value.length - 1].dateComplete;
      const arrayExtraHours = [];
      for (let index = 0; index < 6; index++) {
        const extraHour = $moment(lastHour)
          .add(30 * (index + 1), 'm')
          .format();
        arrayExtraHours.push(extraHour);
      }
      this.extraHours = arrayExtraHours;
      if (this.selectedDateChange) {
        this.forceRerender();
      } else {
        this.selectedDateChange = false;
      }
    },
    tableModalOpen () {
      this.keyForModal = '';
      this.modalOpenFromTableId = '';
    },
    lastScroll () {

    }
  }
};
</script>
<style lang="scss" scoped>
.containerofZones{
  width: 100%;
}
#virtualScrollMap{
  scroll-behavior: smooth;
          &::-webkit-scrollbar {
            @media screen and (max-width:600px) {
&::-webkit-scrollbar {
  display: none;
  height: 0px;
  width: 0px;
}
            }
  width: 12px !important;
  height: 12px !important;

}

/* Track */
&::-webkit-scrollbar-track {
background: #a8adb55f !important;
border-radius: 3px;
}

/* Handle */
&::-webkit-scrollbar-thumb {
    box-sizing: border-box;
  background-color: #606773 !important;
border-radius: 8px !important;
border: unset !important;
border: solid 2px rgb(223 224 227) !important;
box-shadow: unset !important;
outline: unset;
}

/* Handle on hover */
&::-webkit-scrollbar-thumb:hover {
  background: #555;
}
  .scrollV{
    height: 0.2px;
  }
  overflow-x: scroll;
      position: fixed;
    left: 166px;
    width: 100%;
        max-width:calc(100% - 192px) ;
}
.lastblock{
  min-width: 51px;
}
  header {
    position: relative;
.TimeLineTime{
    z-index: 4000;
    position: absolute;
    left: 0;
    background-color: #444B57;
      color: white;
      padding: 3px;
      text-align: center;
      pointer-events: none;
      width: 80px;
      border-radius: 5px;
      padding-top: 2px;
      p{
        font-size: 14px;
        @media screen and (max-width:600px) {
          font-size: 11px;
          font-weight: 600;
        }
      }
  }
  pointer-events: none;
    overflow-x: scroll;
    overflow-y: visible;
    background-color: #f8f8f9;
    display: flex;
    flex-direction: row;
    height: 27px;
    @media screen and (max-width:600px) {
      height: 22px;
    }
    // transform:translateY(var(--scrollY))   ;
    background-color: white;
    z-index: 5200;
    // transform: translateY(var(--scrollY));
          overflow: scroll !important;
      -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */

}
    h2 {
      &::after{
        position: absolute;
        content: " ";
        margin: auto;
        left: 0;
        right: 0;
        bottom: -1px;
        background-color: red;
        width: 1.6px;
        height: 6.5px;
            display: inline-block;
            background: #a8adb5;
            border-radius: 100px;
      }
      cursor: alias;
      min-width: 80px;
      @media screen and (max-width: 600px) {
        min-width: 60px;
        max-width: 60px;
      }
      text-align: center;
      position: relative;
      background-color: white;
      z-index: 12;
      font-size: 14px;
      height: 100%;
      @media screen and (max-width:600px) {
        font-size:10px;
        height: 100%;
        line-height: 20px;
      }
      &.halfAfternoon {
        background: linear-gradient(90deg, #f8f8f9 50%, white 50%);
      }
      &.firstHalfMorning{
                background: linear-gradient(90deg, white 50%, #f8f8f9 50%);
      }
              &.firstOfType {
                margin-left: 1px;
          @media screen and (max-width:600px) {
            margin-left: 11px;
          }
              }
      &.afternon {
        background-color: #f8f8f9;
        &.firstOfType {
          background: linear-gradient(90deg, white 50%, #f8f8f9 50%);

        }
      }
      &:first-child {
        @media screen and (max-width: 600px) {
          margin-left: 11px;
        }
      }
    }
  }
#CronologyMap {
  position: relative;
  .hideBlock {
    z-index: 51 !important;
  }
  &::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #a8adb55f !important;
    border-radius: 3px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background-color: #606773 !important;
    border-radius: 4px !important;
    border: unset !important;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  display: flex;
  // position: relative;
  flex-direction: column;
  // &.frontOfTables {
  //   z-index: 10;
  // }
  min-width: fit-content;
  .headerSpace {
    display: block;
    height: 25px;
    width: 100%;
    @media screen and (max-width:600px) {
      height: 22px;
    }
  }

  flex: 1;
  overflow: visible;
  height: 100%;
  padding: unset;
  border: unset;
  .mainContent {
    cursor: cell;
    flex: 1;
    min-width: 100%;
    width: fit-content;
    position: relative;
  }
  .rowTable {
    width: 100%;
    display: flex;
    flex-direction: row;
    z-index: 11;
  }
  .hourSquare {
    min-width: 80px;
    max-width: 80px;
    @media screen and (max-width: 600px) {
      min-width: 60px;
      max-width: 60px;
    }
    height: 100%;
    position: relative;
    border-bottom: 1px solid #f3f3f3;
    border-top: 1px solid #f3f3f3;
    overflow: visible;
    background-color: white;
    &.first,
    &.last {
      min-width: 40px;
      &::after {
        display: none;
      }
    }
    .hourMask {
      background-color: transparent;
      width: 100%;
      height: 100%;
      display: block;
      pointer-events: none;
      &::after {
        content: " ";
        left: 0;
        top: -3px;
        margin: auto;
        position: absolute;
        display: inline-block;
        width: 2px;
        height: 2px;
        background: #a8adb5;
      }
    }
    &.occuped {
      background: rgb(255 242 242);
    }
    &.afternon {
      .hourMask {
        background-color: #444b5709;
      }
    }
    &::after {
      content: " ";
      left: 0;
      top: -1px;
      margin: auto;
      position: absolute;
      display: inline-block;
      width: 2px;
      height: 100%;
      background: #a8adb5;
    }
    &:not(.first):before {
      content: " ";
      left: 0;
      right: 0;
      margin: auto;
      position: absolute;
      display: block;
      width: 2px;
      height: 100%;
      background: #f3f3f3;
      z-index: 0;
    }
  }
  .TimeLine {
    display: block;
    background: #444b57;
    height: 100%;
        min-width: 3px;
        max-width: 4px;
    position: absolute;
    left: 0;
    top: 0px;
    z-index: 50;
    // cursor: crosshair;
    pointer-events: none;
    border: unset;
    padding: unset;
  }
}
.extraHourMask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  @media screen and (max-width: 600px) {
    width: 60px;
  }
  height: calc(100% + 2px);
  background-color: transparent;
  z-index: 50;
  pointer-events: none;
}

.first .extraHourMask {
  width: 40px;
  transform: translateX(-1px);
}
.tableModalOpen {
  .rowTable:not(.isTableSelected) .extraHourMask {
    background-color: rgba(0, 0, 0, 0.261);
    pointer-events: auto;
    cursor: crosshair;
  }
  .hideBlock .extraHourMask {
    z-index: 8;
    transform: scale(1.025);
  }
}
.titleForHiddenZone {
  position: relative;
  color: white;
  background: #444b57;
  text-align: center;
  height: 21px;
  font-size: 13px;
  mix-blend-mode: color-dodge;
  cursor: crosshair !important;
}
.isHidden {
  z-index: 10;
  position: relative;
  height: fit-content !important;
}
@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.zoneContainer {
  animation-name: appear;
  animation-duration: 0.5s;
  animation-timing-function: ease;
  animation-iteration-count: 1;
}
.floatButtonContainer{
  display: flex ;
  align-items: center;
  flex-direction: column;
  position: fixed;
  bottom: 170px;
  right: 20px;
  z-index: 400;
  gap: 10px;
  justify-content: flex-end;
}
  .floatButton{
    outline: unset;
    border-radius: 8px;
    height: 45px;
    width: 45px;
  color: white;
  border: unset;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.45);
  font-weight: bolder;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (min-width:700px) {
    display: none;
  }
  p{
     font-size: 28px;
     transform: translateY(-3px);
  }
  cursor: pointer;
  &.ReservationButton{
    bottom: 260px;
  }
  }
    .ReservationButton {
    background-color: #00adc6;
  }
  .WalkButton {
    background-color: #8a51b4;
  }
  .precompraButton {
    background-color: #BDC30E;
  }
  .noTouch{
    pointer-events: none;
  }
      .squaresContainer{
    width: 100%;
    display: flex;
    flex-direction: row;
    }
    .zonecontainer:not(.onview){
          .rowTable{
    display: hidden;
    }
    }
    section{
      transition: unset;
    }
    @keyframes loader {
  from {opacity: 0.2;}
  to {opacity: 1;}
}
    .loaderPage{
            background: rgba(128, 128, 128, 0.153);
      height: 100%;
      width: 100%;
      animation-name: loader;
       animation-duration: 0.3s;
       animation-direction: alternate;
       animation-iteration-count: infinite;
       animation-timing-function: ease;
       min-height: 400px;
    }
</style>
