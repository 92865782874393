<template>
    <div id="validationAction" v-if="Object.keys(hostList).length > 0">
      <p class="validate-text">Ingrese usuario y clave para bloquear</p>
      <div class="host-input-container">
        <span class="host-input-validate">
          <b-select
            @input="setHost(hostInput)"
            expanded
            placeholder="Seleccionar"
            v-model="hostInput"
          >
            <option
              :key="'host#' + IType"
              :value="itemType"
              v-for="(itemType, IType) in hostList"
            >{{ itemType.label }}</option>
          </b-select>
          <img src="@/assets/icons/arrow-down-white.svg" alt="hosts" v-if="!opened" class="input-icon">
        </span>
        <div :class="{'w-50': showPin}" class="ml-5" v-if="showPin">
          <b-field class="password-field" :type="{'is-danger': errorPin}" :message="{'Pin incorrecto' : errorPin}">
            <b-input type="password" autocomplete="new-password" v-model="pinHost" icon-pack="fa" placeholder="Ingrese pin" class="input-host">
            </b-input>
          </b-field>
        </div>
      </div>
    </div>
</template>
<script>
export default {
  data () {
    return {
      hostList: [],
      hostId: null,
      showPin: false,
      createdAt: null,
      pin: null,
      hostValue: null,
      pinHost: null,
      send: false,
      errorPin: false,
      opened: false,
      value: null,
      hostInput: null
    };
  },
  mounted () {
    this.getDataHost();
  },
  methods: {
    setHost (host) {
      this.showPin = false;
      this.createdAt = null;
      this.hostId = null;
      this.value = host.label;
      if (host.pin !== null) {
        if (this.createdAt === host.value) {
          this.createdAt = null;
          this.hostId = this.createdAt;
          this.send = true;
        } else {
          this.showPin = true;
          this.$emit('disableButton', true);
          this.pin = host.pin;
          this.hostValue = host.value;
        }
      } else {
        this.showPin = false;
        if (this.createdAt === host.value) {
          this.createdAt = null;
        } else {
          this.createdAt = host.value;
          this.hostValue = host.value;
        }
        this.send = true;
        this.hostId = this.createdAt;
        if (this.hostId != null) {
          this.setInfo();
        }
      }
      this.opened = false;
    },
    getDataHost () {
      this.$store.dispatch({
        type: 'reservation:searchHost',
        data: {
          vendorId: this.vendorId,
          userId: this.user.id
        }
      }).then((data) => {
        const aux = [];
        const hostes = data.data;
        hostes.map(function (data) {
          aux.push({
            value: data.id,
            label: data.nombres,
            pin: data.pin
          });
        });
        if (aux.length > 0) {
          this.hostList = [];
          this.hostList = aux;
          this.$emit('disableButton', true);
        } else {
          this.hostList = [];
          this.hostList = aux;
          this.$emit('disableButton', false);
        }
      }).catch(fallo => {
        const aux = [];
        this.hostList = [];
        this.hostList = aux;
        this.error = fallo;
        this.$emit('disableButton', false);
      });
    },
    setInfo () {
      if (this.hostId != null) {
        const data = {
          hostId: this.hostId,
          desactivateWaitingList: this.waitingYes
        };
        this.$emit('changeInfo', data);
      }
    }
  },
  watch: {
    pinHost () {
      if (this.pinHost === this.pin) {
        this.showPin = false;
        this.errorPin = false;
        if (this.createdAt === this.hostValue) {
          this.createdAt = null;
        } else {
          this.createdAt = this.hostValue;
        }
        this.hostId = this.createdAt;
        this.pinHost = null;
        this.send = true;
        if (this.hostId != null) {
          this.setInfo();
        }
      } else {
        this.errorPin = true;
      }
    }
  }
};
</script>
<style lang="scss">
#validationAction {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .ml-5 {
    margin-left: 5px;
  }
  .w-100 {
    width: 100%;
  }
  .w-50 {
    width: 50%;
    height: 30px!important;
    .field, .control, .input {
      height: 30px!important;
    }
  }
  .host-input-container{
    display: flex;
    width: 100%;
    .host-input-validate{
      position: relative;
      flex: 1;
      height: 30px;
      .select::after{
        display: none;
      }
      select{
        background-color: transparent;
        color: white;
        border: 1px solid white !important;
        z-index: 9;
        line-height: 1;
        option{
          background-color: #444B55;
          color: white;
        }
      }

      .input-icon{
        position: absolute;
        right: 11px;
        top: 11px;
      }
    }
    .password-field{
      span{
        justify-content: center;
        align-items: center;
        height: 100%;
        i{
          width: 15px;
          height: 15px;
          font-size: 15px;
          padding-top: 1px;
        }
      }
      .help{
        font-size: 11px;
        margin: 0;
      }
    }
  }
  .input {
    background-color: #444B55;
    color: white;
    &::placeholder {
      color: white;
    }
    &:focus, &:active, &:focus {
      border-color: white!important;
      caret-color: white;
    }
  }
  .validate-text{
    font-size: 12px;
    color: white;
    font-weight: 400;
    margin-bottom: 8px;
  }
}
</style>
