<template>
  <form @submit.prevent="submit" class="UserEdit">
    <b-loading :active.sync="sending" :can-cancel="false" :is-full-page="true"></b-loading>
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">Nuevo Host</p>
      </header>
      <section class="modal-card-body">
        <b-field
          :message="errors.first('name')"
          :type="{'is-danger': errors.has('name')}"
          label="Nombre"
        >
          <b-input
            icon="account"
            name="name"
            placeholder="Nombre"
            type="text"
            v-model="userAdd.name"
            v-validate="'required'"
          ></b-input>
        </b-field>
        <b-field v-if="userAdd.id">
          <b-button
            @click="togglePassword = !togglePassword"
            class="is-fullwidth"
            type="is-warning"
          >{{ togglePassword ? (userAdd.type !== 'host' ? 'No resetear contraseña' : 'No resetear PIN') : (userAdd.type !== 'host' ? 'Resetear contraseña' : 'Resetear PIN') }}</b-button>
        </b-field>
        <b-field
          :label="['admin', 'manager'].includes(userAdd.type) ? 'Contraseña' : 'PIN de acceso'"
          :message="errors.first('password')"
          :type="{'is-danger': errors.has('password')}"
          v-if="togglePassword || !userAdd.id"
        >
          <b-input
            :placeholder="['admin', 'manager'].includes(userAdd.type) ? 'Contraseña' : 'PIN de acceso'"
            name="password"
            password-reveal
            ref="password"
            type="password"
            v-model="userAdd.password"
            v-validate="['admin', 'manager'].includes(userAdd.type) ? 'min:6|required' : 'min:3'"
          ></b-input>
        </b-field>
        <b-field
          :label="['admin', 'manager'].includes(userAdd.type) ? 'Confirmar contraseña' : 'Confirmar PIN'"
          :message="errors.first('confirm_password')"
          :type="{'is-danger': errors.has('confirm_password')}"
          v-if="togglePassword || !userAdd.id"
        >
          <b-input
            :placeholder="['admin', 'manager'].includes(userAdd.type) ? 'Confirmar contraseña' : 'Confirmar PIN'"
            data-vv-as="password"
            name="confirm_password"
            type="password"
            v-model="userAdd.confirm"
            v-validate="['admin', 'manager'].includes(userAdd.type) ? 'required|confirmed:password' : 'min:3|confirmed:password'"
          ></b-input>
        </b-field>
      </section>
      <footer class="modal-card-foot">
        <button @click="$parent.close()" class="button" type="button">
          <b-icon class="mr-5" icon="close" size="is-small"></b-icon>
          {{ 'Cerrar' }}
        </button>
        <button class="button is-primary">
          <b-icon class="mr-5" icon="content-save" size="is-small"></b-icon>
          {{ 'Guardar' }}
        </button>
      </footer>
    </div>
  </form>
</template>

<script>
import _ from 'lodash';
import globalTypes from '@/store/types/global';
import serviceUser from '@/helpers/apis/serviceUser';
export default {
  name: 'UserEdit',
  props: ['title', 'userAdd', 'restaurant'],
  data () {
    return {
      togglePassword: false,
      sending: false,
      permissions: null,
      userPermissions: null,
      modulesHost: {
        reservations: 0,
        users: 0,
        searchs: 0,
        metrics: 0,
        polls: 0,
        giftCards: 0,
        schedules: 0
      }
    };
  },
  mounted () {
    if (!this.userAdd.indicativo) {
      this.userAdd.indicativo = '57';
    }
    if (this.userAdd.modules.length > 0) {
      const d = this.modulesHost;
      this.userAdd.modules.map(data => {
        if (data.name === 'reservations') {
          d.reservations = 1;
        }
        if (data.name === 'users') {
          d.users = 1;
        }
        if (data.name === 'searchs') {
          d.searchs = 1;
        }
        if (data.name === 'metrics') {
          d.metrics = 1;
        }
        if (data.name === 'polls') {
          d.polls = 1;
        }
        if (data.name === 'giftCards') {
          d.giftCards = 1;
        }
        if (data.name === 'schedules') {
          d.schedules = 1;
        }
      });
    }
    this.getPermissionsHost();
    if (this.userAdd.id) {
      this.getUserPermissions();
    }
  },
  methods: {
    validatePermission (permission) {
      const exists = _.includes(this.userPermissions, permission);
      if (exists) {
        return 1;
      } else {
        return 0;
      }
    },
    activatePermission (userId, permissionId, permissionName) {
      let isActive = 1;
      if (this.validatePermission(permissionName) === 1) {
        isActive = 0;
      }
      const data = {
        userId: userId,
        permissionId: permissionId,
        isActive: isActive
      };
      serviceUser
        .post('/permissions/activate', data)
        .then(() => {
          this.getUserPermissions();
        })
        .catch(() => {
          this.$buefy.toast.open({
            message: 'Lo siento, ha ocurrido un error al intentar procesar tu pretición',
            type: 'is-danger'
          });
        });
    },
    getPermissionsHost () {
      this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: true });
      serviceUser.get('/permissions/all').then(({ data }) => {
        this.permissions = data.permissions;
        this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
      }).catch(() => {
        this.sending = false;
        this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
        this.$buefy.toast.open({
          message: 'Lo siento, ha ocurrido un error al intentar procesar tu pretición',
          type: 'is-danger'
        });
      });
    },
    getUserPermissions () {
      this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: true });
      serviceUser.get('/permissions/user/' + this.userAdd.id).then(({ data }) => {
        this.userPermissions = data.permissions;
      }).catch(() => {
        this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
        this.$buefy.toast.open({
          message: 'Lo siento, ha ocurrido un error al intentar procesar tu pretición',
          type: 'is-danger'
        });
      });
    },
    submit () {
      var instance = this;
      instance.$validator.validateAll().then((result) => {
        if (result) {
          this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: true, typeMessage: 3 });
          this.userAdd.modules = this.modulesHost;
          serviceUser[instance.userAdd.id ? 'put' : 'post']('/restaurants/members', this.userAdd).then(() => {
            this.$buefy.toast.open({
              message: 'Se ha creado el host',
              type: 'is-success'
            });
            instance.$emit('update');
            instance.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
            instance.$parent.close();
          }).catch(() => {
            this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
            this.$buefy.toast.open({
              message: 'Lo siento, ha ocurrido un error al intentar procesar tu pretición',
              type: 'is-danger'
            });
          });
        } else {
          instance.$buefy.toast.open({
            message: 'El formulario no es válido! Por favor verifique los campos.',
            type: 'is-danger'
          });
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.mr-5 {
  margin-right: 0px !important;
}
</style>
