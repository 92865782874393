<template>
  <div id="Settings-ListMove">
    <div class="moving-container">
      <p class="moving-text">La reserva a nombre de <strong>{{reservMove?.displayName}}</strong> esta en la mesa <strong>{{reservMove?.tableName}}</strong></p>
      <p class="moving-text">¿A que mesa la deseas desplazar?</p>
      <!-- Zones -->
      <b-collapse class="card" :open="openZones" @open="openZones = true" v-if="getSections.length > 0">
          <div slot="trigger"
              slot-scope="props"
              class="card-header is-arrowless"
              :class="{'active': props.open}"
              role="button">
              <p class="card-header-title">Zonas</p>
              <a class="icon_left_filter move-icon icon-mks" :class="props.open ? 'opened' : 'closed'"></a>
          </div>
          <div class="card-content">
            <div class="option-content scroll">
              <div
                class="option-name"
                :class="{'active': activate(section.id, 1)}"
                v-for="(section, index) in getSections"
                :key="index"
                @click="SetSection(section.id)">
                <span class="option-text">{{section.displayName}}</span>
              </div>
            </div>
          </div>
      </b-collapse>
      <!-- Sub Sections -->
      <b-collapse class="card" :open="openSubSections" @open="openSubSections = true" v-if="subzoneId.length > 0">
          <div slot="trigger"
              slot-scope="props"
              class="card-header is-arrowless"
              :class="{'active': props.open}"
              role="button">
              <p class="card-header-title"> Sub zonas </p>
              <a class="icon_left_filter move-icon icon-mks" :class="props.open ? 'opened' : 'closed'"></a>
          </div>
          <div class="card-content">
            <div class="option-content scroll">
              <template v-if="subzoneId.length > 0">
                <div
                  class="option-name"
                  :class="{'active': activate(subSection.value, 2)}"
                  v-for="(subSection, index) in subzoneId"
                  :key="index"
                  @click="SetSubSection(subSection.value)">
                  <span class="option-text">{{subSection.label}}</span>
                </div>
              </template>
              <p class="moving-text" v-if="subzoneId.length === 0 && !isLoading">No existen subzonas disponibles o no se ha seleccionado una zona</p>
              <loader-custom-section v-if="isLoading" />
            </div>
          </div>
      </b-collapse>
      <!-- Tables -->
      <b-collapse class="card" :open="openTables" @open="openTables = true">
          <div slot="trigger"
            slot-scope="props"
            class="card-header is-arrowless"
            :class="{'active': props.open}"
            role="button">
              <div class="search-table-list-move" :style="{backgroundColor: props.open === false ? '#ECEDEE' : '#444B57'}" @click.stop v-if="seatingTables.length > 0">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" @click="activeSearchTableMove = !activeSearchTableMove" style="height: 100%;">
                  <rect width="20" height="20" rx="4" :fill="props.open === false ? '#ECEDEE' : '#444B57'"/>
                  <path d="M15.8598 15.1533L12.8777 12.1713C13.6904 11.1774 14.0899 9.90919 13.9937 8.62898C13.8975 7.34877 13.313 6.1545 12.3609 5.2932C11.4089 4.4319 10.1622 3.96948 8.87882 4.00157C7.5954 4.03365 6.37341 4.55781 5.46561 5.46561C4.55781 6.37341 4.03365 7.5954 4.00157 8.87882C3.96948 10.1622 4.4319 11.4089 5.2932 12.3609C6.1545 13.313 7.34877 13.8975 8.62898 13.9937C9.90919 14.0899 11.1774 13.6904 12.1713 12.8777L15.1533 15.8598C15.2476 15.9508 15.3738 16.0011 15.5048 16C15.6357 15.9988 15.761 15.9463 15.8537 15.8537C15.9463 15.761 15.9988 15.6357 16 15.5048C16.0011 15.3738 15.9508 15.2476 15.8598 15.1533ZM9.01189 13.0086C8.22141 13.0086 7.44869 12.7742 6.79143 12.335C6.13417 11.8959 5.6219 11.2717 5.3194 10.5414C5.0169 9.81106 4.93775 9.00745 5.09197 8.23217C5.24618 7.45688 5.62683 6.74473 6.18578 6.18578C6.74473 5.62683 7.45688 5.24618 8.23217 5.09197C9.00745 4.93775 9.81106 5.0169 10.5414 5.3194C11.2717 5.6219 11.8959 6.13417 12.335 6.79143C12.7742 7.44869 13.0086 8.22141 13.0086 9.01189C13.0074 10.0715 12.5859 11.0874 11.8367 11.8367C11.0874 12.5859 10.0715 13.0074 9.01189 13.0086Z" :fill="props.open === false ? '#444B57' : '#ECEDEE'"/>
                </svg>
                <input type="text" class="search-table-list-move-input" :style="{backgroundColor: props.open === false ? '#ECEDEE' : '#444B57', color: props.open === false ? '#444B57' : '#ECEDEE'}" id="searchTableMove" v-model="findTableSearchMove" placeholder="Buscar por nombre" v-show="activeSearchTableMove" @keyup.esc="findTableSearchMove = ''">
              </div>
              <p class="card-header-title" :class="{ajustTableMoveTitle: activeSearchTableMove}"> Mesas </p>
              <a class="icon_left_filter move-icon icon-mks" :class="props.open ? 'opened' : 'closed'"></a>
          </div>
          <div class="card-content">
              <div class="option-content">
                <template v-if="seatingTables && seatingTables.length > 0">
                  <div
                    class="option-name option-table"
                    :class="{'active': activate(table.value, 3)}"
                    v-for="(table, index) in seatingTables"
                    :key="index"
                    v-html="table.label"
                    @click="SetTable(table.value)">
                  </div>
                </template>
                <p class="moving-text fs-12" v-if="seatingTables.length === 0 && !isLoading">Selecciona una zona</p>
                <loader-custom-section v-if="isLoading" />
              </div>
          </div>
      </b-collapse>
    </div>
    <div class="selected-options-container">
      <button v-show="!showSelectedOptions" :disabled="selTable.length === 0" class="SelButon" @click="onMoveReservation">
        Desplazar reserva
        <i aria-hidden="true" class="fa fa-arrows"></i>
      </button>
      <div class="selected-options-popup animated-element" v-show="showSelectedOptions" :class="{ 'slide-up': slideUpAnimation, 'slide-down': slideDownAnimation }">
        <p class="selected-text" v-if="selSection.length > 0 || selSubSection.length > 0 || selTable.length > 0">Tienes seleccionado: </p>
        <div class="selected-options">
          <span v-if="selSection.length > 0" class="selected-opt">
            <strong>Zona: </strong>
            <span v-for="(section) in sectionNames" :key="section.id"> {{ section.displayName }}</span>
          </span>
          <span v-if="selSubSection.length > 0" class="selected-opt">
            <strong>Subzona: </strong>
            <span v-for="subzone in subSectionNames" :key="subzone.value"> {{ subzone.label }}</span>
          </span>
          <span v-if="selTable.length > 0" class="selected-opt">
            <strong>Mesa: </strong>
            <span v-for="(table) in tableNames" :key="table.value"> {{ table.displayName }}</span>
          </span>
        </div>
        <template v-if="hostList.length > 0">
          <p class="small-selected-text">Para desplazar la reserva debes ingresar tu usuario y contraseña</p>
          <div class="host-move-reservation">
            <b-field class="host-list">
              <span class="select-host">
                <b-select
                  @input="validatePin(host)"
                  expanded
                  placeholder="Nombre de usuario"
                  v-model="host"
                  class="host-input"
                  autocomplete="off"
                >
                  <option
                    :key="'selHost-' + option.value"
                    :value="option.value"
                    v-for="option in hostList"
                  >{{ option.label }}</option>
                </b-select>
                <i class="icon_arrow_right_map icon-mks black"></i>
              </span>
            </b-field>
            <b-field custom-class="has-text-weight-normal label-custom-cancel" v-show="showPin">
              <b-input
                id="host-password"
                @input="changePin(passwordHost)"
                type="password"
                v-model="passwordHost"
                placeholder="Clave host"
                autocomplete="chrome-off"
                class="password-input-cancel"
                v-show="showPassword"></b-input>
              <b-input
                id="host-password-text"
                type="text"
                v-model="passwordHost"
                placeholder="Clave host"
                autocomplete="off"
                class="password-input-cancel"
                v-show="showPasswordText"
                ></b-input>
            </b-field>
          </div>
        </template>
        <div class="confirmation-buttons">
          <button :disabled="selTable.length === 0 || disableConfirm" class="SelButon" @click="validateSelectedTable" v-if="hostList.length > 0">Confirmar</button>
          <button :disabled="selTable.length === 0" class="SelButon" @click="validateSelectedTable" v-else>Confirmar</button>
          <button class="SelButon light" @click="cancelPopup">Cancelar</button>
        </div>
      </div>
    </div>
    <!-- MODAL CANCELACIÓN -->
    <div id="confirm-move-reservation">
      <b-modal :active.sync="showConfirmMove" custom-class="modal-move-reservation" style="height: auto;">
        <div class="modal-card">
          <section class="modal-card-body">
            <div class="container-columns">
              <i class="icon_warning_outline_red icon-mks"></i>
              <p class="text center">
                La mesa seleccionada <b v-for="(table) in tableNames.filter(t => t.status === 1)" :key="table.value">{{ table?.displayName }}, </b> ya tiene una reserva y está ocupada.
              </p>
              <p class="text center">
                ¿Estás seguro de seleccionarla?
              </p>
            </div>
            <div class="buttons-container">
              <a @click="changeTable" class="btn-move">Si <i class="icon_check_fill icon-mks white"></i></a>
              <a @click="showConfirmMove = false" class="btn-move">No <i class="icon_close_fill icon-mks white"></i></a>
            </div>
          </section>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import reservationTypes from '@/store/types/reservation';
import sectionTypes from '@/store/types/section';
import axios from 'axios';
import LoaderCustomSection from '@/components/_shared/LoaderCustomSection.vue';
export default {
  props: ['reservMove'],
  components: { LoaderCustomSection },
  data () {
    return {
      selTable: [],
      selSection: [],
      selSubSection: [],
      seatingTables: [],
      seatingTableOri: [],
      subzoneId: [],
      open: false,
      openZones: true,
      openSubSections: false,
      openTables: false,
      showConfirmMove: false,
      loading: false,
      isLoading: false,
      selectIdZone: null,
      host: null,
      passwordHost: null,
      hostPin: null,
      hostList: [],
      showPin: false,
      disableConfirm: true,
      showSelectedOptions: false,
      slideDownAnimation: false,
      slideUpAnimation: false,
      showPassword: false,
      showPasswordText: true,
      cancelToken: axios.CancelToken,
      cancelTablesSections: null,
      activeSearchTableMove: false,
      findTableSearchMove: ''
    };
  },
  computed: {
    ...mapGetters({
      getSections: [sectionTypes.getters.sections],
      getTables: [reservationTypes.getters.tables]
    }),
    tableNames () {
      return this.seatingTables.filter(t => this.selTable.includes(t.value));
    },
    sectionNames () {
      return this.getSections.filter(s => this.selSection.includes(s.id));
    },
    subSectionNames () {
      return this.subzoneId.filter(s => this.selSubSection.includes(s.value));
    }
  },
  mounted () {
    this.getDataHost();
  },
  watch: {
    passwordHost () {
      if (this.passwordHost != null) {
        const password = document.getElementById('host-password');
        const passwordText = document.getElementById('host-password-text');
        if (this.passwordHost.length > 0) {
          this.showPasswordText = false;
          this.showPassword = true;
          setTimeout(() => {
            password.focus();
          }, 1);
        } else {
          this.showPassword = false;
          this.showPasswordText = true;
          setTimeout(() => {
            passwordText.focus();
          }, 1);
        }
      }
    },
    findTableSearchMove () {
      if (this.findTableSearchMove !== '') {
        const original = JSON.parse(JSON.stringify(this.seatingTableOri));
        const searchTerm = this.findTableSearchMove.toLowerCase();
        const result = original.filter(item => item.displayName.toLowerCase().includes(searchTerm));
        this.seatingTables = result;
      } else {
        this.seatingTables = this.seatingTableOri;
      }
    },
    activeSearchTableMove () {
      if (this.activeSearchTableMove) {
        setTimeout(() => {
          document.getElementById('searchTableMove').focus();
        }, 100);
      }
    }
  },
  methods: {
    onMoveReservation () {
      this.slideDownAnimation = false;
      this.slideUpAnimation = true;
      this.showSelectedOptions = true;
    },
    cancelPopup () {
      this.slideUpAnimation = false;
      this.slideDownAnimation = true;
      this.hostPin = null;
      this.passwordHost = null;
      this.host = null;
      this.showPin = false;
      setTimeout(() => {
        this.showSelectedOptions = false;
      }, 600);
    },
    changePin (pin) {
      if (pin === this.hostPin) {
        this.disableConfirm = false;
      } else {
        this.disableConfirm = true;
      }
    },
    validatePin (hostId) {
      this.hostPin = null;
      this.passwordHost = null;
      this.showPassword = false;
      this.showPasswordText = true;
      const data = _.find(this.hostList, { value: hostId });
      if (data !== undefined) {
        if (data.pin != null) {
          this.showPin = true;
          this.hostPin = data.pin;
          this.disableConfirm = true;
        } else {
          this.showPin = false;
          this.disableConfirm = false;
        }
      } else {
        this.showPin = false;
      }
    },
    total (data) {
      return _.size(data);
    },
    SetTable (table) {
      const lisTables = this.selTable;
      const index = lisTables.indexOf(table);

      if (index > -1) {
        lisTables.splice(index, 1);
        this.selTable = lisTables;
      } else {
        lisTables.push(table);
        this.selTable = lisTables;
      }
    },
    SetSection (section) {
      const lisSections = this.selSection;
      const index = lisSections.indexOf(section);
      if (index > -1) {
        lisSections.splice(index, 1);
        this.selSection = lisSections;
      } else {
        lisSections.push(section);
        this.selSection = lisSections;
      }
      this.getZoneId();
      this.getSectionTable();
      this.selSubSection = [];
      this.selTable = [];
      this.openTables = true;
      this.openSubSections = true;
    },
    SetSubSection (subSection) {
      const lisSubSections = this.selSubSection;
      const index = lisSubSections.indexOf(subSection);

      if (index > -1) {
        lisSubSections.splice(index, 1);
        this.selSubSection = lisSubSections;
      } else {
        lisSubSections.push(subSection);
        this.selSubSection = lisSubSections;
      }
      this.getZoneId();
      this.getSectionTable();
      this.selTable = [];
    },
    activate (optionId, option) {
      switch (option) {
        case 1 : {
          const lisSections = this.selSection;
          const index = lisSections.indexOf(optionId);

          if (index > -1) {
            return true;
          } else {
            return false;
          }
        }
        case 2: {
          const lisSubsections = this.selSubSection;
          const index = lisSubsections.indexOf(optionId);
          if (index > -1) {
            return true;
          } else {
            return false;
          }
        }
        default: {
          const lisTables = this.selTable;
          const index = lisTables.indexOf(optionId);
          if (index > -1) {
            return true;
          } else {
            return false;
          }
        }
      }
    },
    getZoneId () {
      const infoReser = JSON.parse(JSON.stringify(this.reservMove));
      this.isLoading = true;
      this.$store
        .dispatch({
          type: 'reservation:subSections',
          data: {
            sectionId: this.selSection,
            people: infoReser.people,
            date: parseInt(infoReser.date)
          }
        })
        .then((data) => {
          const aux = [];
          let auxId = 0;
          let name = '';
          const sections = data.data;
          if (sections.length === 1) {
            sections.map(function (data) {
              name = data.title;
              if (data.availabilityStatus === false) {
                name += ' (No Disp.) ';
              }
              auxId = data.id;
              aux.push({ value: data.id, label: name, selected: true });
            });
            this.selectIdZone = auxId;
          } else {
            sections.map(function (data) {
              name = data.title;
              if (data.availabilityStatus === false) {
                name += ' (No Disp.) ';
              }
              aux.push({ value: data.id, label: name });
            });
          }
          this.subzoneId = [];
          this.subzoneId = aux;
          this.isLoading = false;
        })
        .catch(() => {
          this.subzoneId = [];
          this.isLoading = false;
        });
    },
    getSectionTable () {
      if (typeof this.cancelTablesSections === 'function') {
        this.cancelTablesSections();
      }
      const source = this.cancelToken.source();
      this.cancelTablesSections = source.cancel;
      const infoReser = JSON.parse(JSON.stringify(this.reservMove));
      this.isLoading = true;
      this.seatingTables = [];
      this.seatingTableOri = [];
      const instance = this;
      if (this.selSection.length > 0) {
        if (this.selSection && infoReser.date) {
          this.isLoadingTable = true;
          this.$emit('loading', true);
          this.$store.dispatch({ type: 'reservation:tablesSections', data: { sectionId: this.selSection, people: infoReser.people, date: parseInt(infoReser.date), subzoneId: this.selSubSection, source } })
            .then((data) => {
              const aux = [];
              if (this.selSection !== undefined) {
                this.selSection.map(function (zone) {
                  const tables = data.data[zone];
                  let name = '';
                  let status = 0;
                  if (tables !== undefined) {
                    tables.map(function (data) {
                      name = data.displayName;
                      if (data.blocked && data.availability === false) {
                        status = 1;
                      } else {
                        if (data.availability === false) {
                          status = 1;
                        }
                        if (data.blocked) {
                          status = 1;
                        }
                        if (data.isEvent === 1) {
                          status = 1;
                        }
                      }
                      if (data.isShared && data.sharedFull === false) {
                        status = 2;
                      }
                      if (status === 1) {
                        aux.push({ displayName: data.displayName, value: data.id, label: '<span style="color: #F83037; font-family: \'Roboto\'; font-size: 12px;"><i class="fa fa-circle" style="color: #F83037; font-size: 8px; margin-rigth: 3px;"></i> ' + instance.shortText(name) + '</span><span style="line-height: 12px; font-size: 9px; font-family: \'Roboto\';">' + data.maxSize + 'pax </span>', status, section: data.seatingSectionId });
                      } else if (status === 2) {
                        aux.push({ displayName: data.displayName, value: data.id, label: '<span style="line-height: 12px; font-family: \'Roboto\'; font-size: 12px;"><i class="fa fa-circle" style="color: #006EB3; font-size: 8px; margin-rigth: 3px;"></i> ' + instance.shortText(name) + '</span><span style="line-height: 12px; font-size: 9px; font-family: \'Roboto\';">' + data.maxSize + ' pax </span>', section: data.seatingSectionId });
                      } else if (status === 0) {
                        aux.push({ displayName: data.displayName, value: data.id, label: '<span style="line-height: 12px; font-family: \'Roboto\'; font-size: 12px;"><i class="fa fa-circle" style="color: #47CC27; font-size: 8px; margin-rigth: 3px;"></i> ' + instance.shortText(name) + '</span><span style="line-height: 12px; font-size: 9px; font-family: \'Roboto\';">' + data.maxSize + ' pax </span>', section: data.seatingSectionId });
                      }
                      status = 0;
                    });
                  }
                });
                this.seatingTables = aux;
                this.seatingTableOri = aux;
              }
              this.isLoading = false;
            }).catch(() => {
              this.seatingTables = [];
              this.seatingTableOri = [];
              this.isLoading = false;
            });
        }
      }
    },
    getDataHost () {
      this.$store
        .dispatch({
          type: 'reservation:searchHost',
          data: { vendorId: this.vendorId, userId: this.user.id }
        })
        .then(data => {
          const aux = [];
          const hostes = data.data;
          hostes.map(function (data) {
            aux.push({ value: data.id, label: data.nombres, pin: data.pin });
          });

          this.hostList = [];
          this.hostList = aux;
        });
    },
    shortText (text) {
      let name = text;
      if (text.length > 5) {
        name = text.substr(0, 5) + '...';
      }
      return name;
    },
    getDataVendorReservationSection () {
      this.$store
        .dispatch({
          type: 'section:sections',
          data: { vendorId: this.idVendor }
        })
        .finally(() => {
          this.$store
            .dispatch({
              type: 'reservation:tables',
              data: { vendorId: this.getVendorId }
            })
            .finally(() => {
              this.$store
                .dispatch({
                  type: 'reservation:reservations',
                  data: {
                    vendorId: this.getVendorId,
                    date: this.selectedDate
                  }
                });
            });
        });
    },
    validateSelectedTable () {
      if (this.tableNames.find(t => t.status === 1)) {
        this.showConfirmMove = true;
      } else {
        this.changeTable();
      }
    },
    resetOptions () {
      this.selTable = [];
      this.selSubSection = [];
      this.selSection = [];
      this.seatingTables = [];
      this.seatingTableOri = [];
      this.subzoneId = [];
      this.openZones = true;
      this.openSubSections = false;
      this.openTables = false;
      this.showConfirmMove = false;
      this.showSelectedOptions = false;
      this.showPin = false;
      this.disableConfirm = true;
      this.host = null;
      this.passwordHost = null;
      this.showPassword = false;
      this.showPasswordText = true;
    },
    changeTable () {
      const infoReser = JSON.parse(JSON.stringify(this.reservMove));
      this.$store.commit(reservationTypes.mutations.setSideLateralMove, false);
      this.$store.dispatch({ type: 'reservation:DragReservation', data: { reservationId: infoReser.id, seatingTableIds: this.selTable, vendorId: parseInt(this.decryptMx(this.$store.state.vendorId)), userId: parseInt(this.user.id) } })
        .then(response => {
          if (response.message === 'Ok') {
            this.$buefy.notification.open({
              message: '<i class="icon_alert_success icon-mks green"></i>Se ha movido la reserva',
              type: 'is-success'
            });
            this.resetOptions();
            this.getDataVendorReservationSection();
          } else {
            this.$buefy.notification.open({
              message: '<i class="icon_alert_danger icon-mks red"></i>No es posible mover la reserva',
              type: 'is-danger'
            });
            this.resetOptions();
          }
        })
        .catch(() => {
          this.$buefy.notification.open({
            message: '<i class="icon_alert_danger icon-mks red"></i>No es posible mover la reserva',
            type: 'is-danger'
          });
          this.resetOptions();
        });
    }
  }
};
</script>
<style lang="scss" >
#Settings-ListMove{
  display: flex;
  flex-direction: column;
  overflow: auto;
  width: 100%;
  flex: 1;
  padding-top: 40px;

  .moving-container{
    padding: 15px;
  }

  .moving-text{
    font-size: 16px;
    text-align: center;
    margin: 10px 0;
    color: #444b57;
    width: 100%;
  }

  .big-tt{
    font-size: 15px;
    position: absolute;
    width: 99%;
    top: -3px;
    padding-top: 10px;
    padding-left: 22px;
    text-transform: capitalize;
    font-weight: 900;
  }

  .setContainer{
    overflow: auto;
    height: 100%;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
  }
  .status-button{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px 10px;
    position: absolute;
    color: #fff;
    border-radius: 100px;
    right: 10px;
    top: 10px;
    background: #444b57;
    cursor:pointer;
    i{
      margin-right: 5px;
    }
  }

  .card-header-title {
    align-items: center;
    color: #444B57;
    display: flex;
    flex-grow: 1;
    font-size: 12px;
    font-weight: 400;
    justify-content: center;
    padding: 0.1rem 0.4rem !important;
    i{
      font-size: 20px;
      margin-right: 10px;
      margin-left: 5px;
    }
  }

  .card-header-icon {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    padding: 0.7rem;
    font-size: 11px;
    i{
      color: #444b57;
    }
  }

  .card-content {
    background-color: transparent;
    padding: 0;
    font-size: 14px;
  }

  .card-header {
    background-color: #ECEDEE;
    color: #444b57;
    border-radius: 6px !important;
    border-bottom: 1px solid #fff;
    height: 26px;

    &.active{
      background-color: #444b57;
      color: #fff;
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;

      .card-header-title, .card-header-icon i{
        color: #fff;
      }

    }

    .move-icon{
      width: 7px;
      background-color: #444B57;
      position: absolute;
      right: 10px;
      top: 6px;
      transition: .2s ease all;

      &.opened{
        transform: rotate(90deg);
        background-color: #fff;
      }

      &.closed{
        transform: rotate(-90deg);
      }
    }
  }

  .loading-container{
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 0.5rem;
    align-items: center;
    color: #444b57;
  }

  .title-section{
    font-size: 14px;
    font-weight: bold;
    margin: 15px;
  }

  .card{
    background-color: transparent;
    color:#fff;
    margin-bottom: 10px;
    border-radius: 6px;
  }

  .SelButon{
    align-items: center;
    background-color: #444b57;
    border-radius: 13px;
    border: none;
    color: #fff;
    display: flex;
    font-size: 12px;
    font-weight: 500;
    gap: 12px;
    justify-content: center;
    margin: 10px auto;
    max-width: 100%;
    min-width: 50%;
    padding: 8px 17px;
    text-align: center;
    width: fit-content;
    transition: .3s ease;
    &:hover{
      background:rgba(0,0,0,0.3);
      color: #444b57;
      cursor: pointer;
    }
    &:disabled, &[disabled]{
      opacity:0.5;
      color: #444b57;
      background: #F2F2F2 !important;
      &:hover{
        color: #444b57;
        background: #F2F2F2 !important;
      }
    }

  }

  .option-content{
    border-radius: 5px;
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;

    &.scroll{
      max-height: 90px;
      overflow: auto;

      &::-webkit-scrollbar-track {
        width: 2px !important;
        background: transparent !important
      }

      &::-webkit-scrollbar-thumb {
        background-color: #444B57 !important;
        border-radius: 1px !important;
      }
    }

    .option-name{
      align-items: center;
      border-top: none;
      border: 0.5px solid #dcdcdc;
      color: #444b57;
      cursor:pointer;
      display:flex;
      flex-direction: column;
      flex: 1;
      height: 30px;
      justify-content:center;
      min-width: 33.3%;

      .option-text{
        text-align: center;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 90%;
        font-size: 12px;
      }

      &:hover{
        background:rgba(255,255,255, 0.5) ;
      }

      &.active{
         background:#444b57;
         color:#fff;
      }

      &.option-table{
        min-width: 25%;
      }
    }
  }

  .selected-options-container{
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: flex-end;

    .selected-options-popup{
      border: 1px solid #A1A5AB;
      padding: 10px;
      border-radius: 8px;

      .selected-options{
        font-size: 12px;
        display: flex;
        justify-content: center;
        gap: 12px;

        .selected-opt{
          text-align: center;
        }
      }

      .host-move-reservation{
        display: flex;
        gap: 10px;

        .field{
          width: 50%;
          height: 30px;
          margin: 0;
          flex: 1;

          input{
            box-shadow: none;
            border-radius: 4px;
          }
        }

        .select-host{
          position: relative;

          select{
            z-index: 2;
            background-color: transparent;
          }

          .host-input span::after{
            display: none;
          }

          .icon_arrow_right_map{
            transform: rotate(90deg);
            width: 9px;
            position: absolute;
            right: 10px;
            top: 8px;
          }
        }
      }

      .confirmation-buttons{
        display: flex;
        justify-content: center;
        gap: 10px;

        .SelButon{
          min-width: unset;
          width: 90px;
          margin: 10px 0;

          &.light{
            background-color: #fff;
            color: #444B57;
            border: 1px solid #444B57;
            transition: .3s ease;

            &:hover{
              background-color: #444B57;
              color: #fff;
            }
          }
        }
      }
    }

    .selected-text{
      color: #444B57;
      font-size: 12px;
      font-weight: 700;
      text-align: center;
    }

    .small-selected-text{
      font-size: 10px;
      text-align: center;
      margin-top: 10px;
      margin-bottom: 8px;
    }
  }

  .selected-opt span:not(:last-child)::after {
    content: ", ";
  }

  #confirm-move-reservation{
    .modal-background{
      background-color: transparent;
    }
    .modal-content{
      width: 340px !important;
      position: absolute;
      right: -3px;
      top: 25%;
      min-width: unset !important;
      text-align: center;

      .modal-card{
        width: 100%;
        border-radius: 10px;
        background: white;
        box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        border: 1px #ECEDEE solid;

        .icon_warning_outline_red{
          width: 41px;
          height: 41px;
          background-color: red;
          margin: 0 auto;
        }

        p{
          margin: 20px 0;
          font-size: 16px;
        }

        .buttons-container{
          display: flex;
          justify-content: center;
          gap: 2rem;
          margin-top: 1.5rem;

          .btn-move{
            align-items: center;
            background-color: #444b57;
            border-radius: 13px;
            color: #fff;
            display: flex;
            font-size: 12px;
            font-weight: 500;
            gap: 12px;
            justify-content: center;
            min-width: 75px;
            padding: 8px 17px;

            .icon_close_fill{
              width: 13px;
              height: 13px;
              margin: 0 auto;
            }
            .icon_check_fill{
              width: 17px;
              height: 17px;
              margin: 0 auto;
            }
          }
        }
      }

      .modal-close{
        display: none;
      }

    }
  }

  .search-table-list-move {
    height: 100%;
    display: flex;
    align-items: center;
    border-top-left-radius: 6px;
    &-input {
      border: unset;
      outline: unset;
      font-family: 'Source Sans Pro';
      font-size: 10px;
      font-style: italic;
      font-weight: 300;
      line-height: normal;
      &::placeholder {
        color: #A1A5AB;
        font-family: 'Source Sans Pro';
        font-size: 10px;
        font-style: italic;
        font-weight: 300;
      }
    }
  }
  .ajustTableMoveTitle {
    justify-content: flex-start;
    margin-left: 20px;
    @media screen and (max-width: 413px) {
      margin-left: 10px;
    }
  }
}

.fs-12{
  font-size: 12px !important;
}

.animated-element {
  transform: translateY(100%);
  transition: transform 0.5s ease;
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

.slide-up {
  animation: slideUp 0.5s ease both;
}

@keyframes slideDown {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
}

.slide-down {
  animation: slideDown 0.5s ease both;
}

@media (max-width: 625px){
  #Settings-ListMove{
    .selected-options-container{
    padding-bottom: 0;
    }
  }
}

@media (max-width: 376px){
  #confirm-move-reservation .modal-content{
    right: -10px !important;
  }
}

</style>
