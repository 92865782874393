<template>
  <div  v-show="!isHidden && totals.length > 0" class="box-zones" id="box-zones">
    <section class="header_sectionItems" @click="()=>hide=!hide"><h1>Zonas</h1><i class="icon_arrowDown " :class="{hidden:hide}"></i></section>
    <section class="itemsContainer" v-show="!hide">
      <i class="icon_arrowDown" @click="scrollToPrev" :class="{hideArrow:hideArrows}"></i>
      <div class="sectionsItemsContainer" ref="sectionItems">
    <SectionItem v-for="(total, index) in totals" :key="index" :total="total" :purchase="isPurchase"></SectionItem>
      </div>
      <i class="icon_arrowDown" @click="scrollToNext" :class="{hideArrow:hideArrows}"></i>
    </section>
  </div>
</template>
<script>
import SectionItem from '@/components/reservations/Center/Lista/SectionItem';
import _ from 'lodash';
export default {
  props: ['reservationsBySection'],
  components: { SectionItem },
  data () {
    return {
      isHidden: false,
      hide: false,
      windowWidth: 0
    };
  },
  mounted () {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  },
  computed: {
    isPurchase () {
      return !!this.vendorOpParams.purchaseCost;
    },
    totals () {
      return _(this.reservationsBySection)
        .mapValues(reservations => _.filter(reservations, reservation => reservation.codeStatus !== 4 && reservation.codeStatus !== 5))
        .mapValues(reservations => _.filter(reservations, reservation => _.includes(this.dateFilterSelect, this.$moment(parseInt(reservation.date)).format('YYYY-MM-DD HH:mm:ss')) || !this.dateFilterSelect.length))
        .map((reservations, sectionName) => ({
          displayName: sectionName,
          people: _(reservations).map('people').sum(),
          tables: _(reservations).map('tableName').filter(table => table).map(table => table.split(',').length).sum(),
          prepurchases: _(reservations).map('balancePaid').filter(balance => balance).map(() => 1).sum(),
          reservations: _(reservations).map().filter(reservation => {
            return !reservation.balancePaid && reservation.referrer !== 'Walk-In';
          }).map(() => 1).sum(),
          walking: _(reservations).map('referrer').filter(referrer => referrer === 'Walk-In').map(() => 1).sum()
        }))
        .value();
    },
    hideArrows () {
      if (this.windowWidth <= 600) {
        return this.totals.length < 2;
      } else if (this.windowWidth > 700 && this.windowWidth < 1150) {
        return this.totals.length <= 1;
      }
      return this.totals.length < 3;
    }
  },
  methods: {
    handleResize () {
      this.windowWidth = window.innerWidth;
    },
    scrollToNext () {
      const newValue = this.$refs.sectionItems.scrollLeft + 330;
      this.$refs.sectionItems.scrollLeft = newValue;
    },
    scrollToPrev () {
      const newValue = this.$refs.sectionItems.scrollLeft - 330;
      this.$refs.sectionItems.scrollLeft = newValue;
    }
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize);
  }
};
</script>
<style lang="scss">
#box-zones{
  box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    max-height: 109px;
    max-width: 100%;
    width: 100%;
    .itemsContainer{
      display: grid;
      align-items: center;
      grid-template-columns: 0.116fr 1fr 0.117fr;
      justify-items: center;
      .icon_arrowDown{
        width: 12px !important;
        height: 12px !important;
        align-items: center;
      }
      height: fit-content;
      width: 100%;
      margin: auto;
          padding-bottom: 10px;
          .sectionsItemsContainer{
            padding-top: 8px;
            max-width: min-content;
            display: flex;
            align-items: center;
            flex-shrink: 1;
            flex-direction:  row;
            gap: 6px;
            @media screen and (max-width: 500px) {
              gap: 0px;
            }
            overflow-x: auto;
            max-width: 100%;
            &::-webkit-scrollbar {
              display: none;
          }
          }
      .icon_arrowDown{
        height: 100%;
        width: 20px;
        background-color: black;
        cursor: pointer;
        mask-position: center;
        mask-repeat: no-repeat;
                transform: rotate(90deg);
        &:last-child{
        transform: rotate(-90deg);
        }

      }
    }
  .header_sectionItems{
    background: #F5F5F5;
    h1{
        font-family: 'Source Sans Pro';
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        color: #444B57;
    }
    padding-top: 3px;
    cursor: pointer;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    i{
      width: 20px;
      height: 20px;
      background-color: black;
      mask-position: center;
      mask-repeat: no-repeat;
      &.hidden{
        transform: rotate(180deg);
      }
    }
  }
  .box-zones{
    height: 108px;
  }
}
.hideArrow{
  visibility: hidden;
}

</style>
