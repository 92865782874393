<template>
  <span class="icons-box-detail">
    <div class="box1">
      <i class="icon-mks black icon_person_add_reservation i-icon-mks"></i>
      <span>{{value.people}}</span>
    </div>
    <div class="box2">
      <i class="icon-mks black icon_calendar_add_reservation i-icon-mks"></i>
      <span v-if="value.date !== null" ><div class="is-year"> {{ value.date | moment('MMM DD') }} </div></span>
      <span v-if="value.date === null" >-</span>
    </div>
    <div class="box3">
      <i class="icon-mks black icon_time i-icon-mks"></i>
      <span v-if="value.Hour" >{{ hourFormat(value.Hour) }}</span>
      <span v-else >-</span>
    </div>
  </span>
</template>
<script>
export default {
  props: ['value'],
  methods: {
    hourFormat (date) {
      return this.$moment(parseInt(date)).format('hh:mm a');
    }
  }
};
</script>
<style lang="scss">
.icons-box-detail {
  display:flex;
  align-items: stretch;
  justify-content: flex-end;
  flex:1.5;
  height: 30px;
  .is-year {
    text-transform:capitalize;
  }
  .box1,.box2,.box3 {
    display: flex;
    align-items: center;
    padding: 5px 11px;
    border-left: 1px solid #A8ADB5;
    border-top: 1px solid #A8ADB5;
    border-bottom: 1px solid #A8ADB5;
    position:relative;
    font-size: 10px;
    min-width: 43px;
    justify-content: center;
    align-items: center;
        box-shadow: 4px 0px 10px rgba(68, 75, 87, 0.1);
    i {
      margin-right:6px;
    }
    .i-icon-mks {
      margin-top: 1px;
      height: 9px;
      width: 9px;
    }
  }
  .box3 {
    border-right: 1px solid #A8ADB5;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
  .box1 {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }
}
</style>
