import namespace from '@/helpers/namespace';

export default namespace('marketing', {
  getters: [
    'package',
    'integrations',
    'integrationDefaults',
    'cardTypeInfo'
  ],
  actions: [
    'getIntegrations',
    'getIntegrationDefaults'
  ],
  mutations: [
    'setPackage',
    'setCardTypeInfo',
    'setIntegrations',
    'setIntegrationDefaults'
  ]
});
