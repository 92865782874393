<template>
  <div class="modal-card delete_card">
    <section class="modal-card-body">
      <div class="header">
        <label @click="$parent.close()" class="close_card_x">X</label>
      </div>
      <button class="button_icon_delete">
        <i class="icon-mks black icon_delete_info_schedule"></i>
      </button>
      <div class="text_card">
        <label>{{ textCard }}</label>
      </div>
      <div class="buttons_card">
        <button @click="$parent.close()">No</button>
        <button @click="deleteSchedules()">Si, eliminar</button>
      </div>
    </section>
  </div>
</template>
<script>
import serviceVendor from '@/helpers/apis/serviceVendor';
export default {
  name: 'ModalAddSchedules',
  props: ['arrayDeletes', 'scheduleDelete'],
  data () {
    return {
      textCard: '',
      deleteSchedulesArray: {},
      deleteSchedule: {}
    };
  },
  mounted () {
    this.deleteSchedulesArray = this.arrayDeletes;
    this.deleteSchedule = this.scheduleDelete;
    if (this.deleteSchedule == null) {
      this.textCard = '¿Esta seguro que desea eliminar los horarios?';
    } else {
      this.textCard = '¿Esta seguro que desea eliminar el horario?';
    }
  },
  methods: {
    deleteSchedules () {
      if (this.deleteSchedule == null) {
        const data = {
          ...this.deleteSchedulesArray,
          userId: this.user.id
        };
        serviceVendor
          .post('schedule/massDelete', data)
          .then(() => {
            this.$emit('updateSchedule');
            this.deleteSchedulesArray = null;
            this.$parent.close();
            this.$buefy.toast.open({
              message: 'Horarios eliminados!',
              type: 'is-success'
            });
          });
      } else {
        serviceVendor
          .delete('/schedules/' +
            this.deleteSchedule.type +
            '/' +
            this.deleteSchedule.id +
            '/' + this.user.id
          )
          .then(() => {
            this.$emit('updateSchedule');
            this.deleteSchedule = null;
            this.$parent.close();
          });
      }
    }
  }
};
</script>
<style lang="scss">
.delete_card {
  width: 570px;
  height: 267px;
  border-radius: 12px;
  .modal-card-body {
    padding: 0;
  }
}
.header {
  height: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.close_card_x {
  font-size: 22px;
  color: white;
  margin-right: 8px;
  cursor: pointer;
}
.button_icon_delete {
  border-radius: 25px;
  height: 48px;
  width: 48px;
  border: none;
  box-shadow: 0px 3px 6px #00000069;
  margin-left: 25px;
  margin-top: -18px;
  background-color: white;
  i {
    height: 26px;
    width: 26px;
    margin: auto;
  }
}
.text_card {
  height: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  font-weight: 400;
  color: black;
}
.buttons_card {
  height: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    width: 109px;
    height: 32px;
    box-shadow: 0px 3px 6px #00000066;
    border-radius: 25px;
    border: none;
    margin-right: 20px;
    margin-left: 20px;
    background-color: black;
    color: white;
    cursor: pointer;
  }
}
@media only screen and (max-width: 768px) {
  .delete_card {
    display: flex;
    align-items: center;
    .modal-card-body {
      width: 500px;
      border-radius: 20px;
    }
  }
}
@media only screen and (max-width: 490px) {
  .delete_card {
    .modal-card-body {
      width: 90%;
    }
  }
  .text_card {
    font-size: 17px;
    text-align: center;
  }
}
</style>
