<template>
  <div class="infoChart" :style="{'--percent': percent}">
    <div class="infoChart-title">
      <div class="infoChart-title-icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33" fill="none">
          <path d="M17.6801 0C17.3675 0 17.0677 0.124177 16.8466 0.345215C16.6256 0.566252 16.5014 0.866043 16.5014 1.17864V15.3223C16.5014 15.6349 16.6256 15.9347 16.8466 16.1557C17.0677 16.3767 17.3675 16.5009 17.6801 16.5009H31.8237C32.1363 16.5009 32.4361 16.3767 32.6571 16.1557C32.8782 15.9347 33.0024 15.6349 33.0024 15.3223C33.0024 13.3101 32.606 11.3177 31.836 9.4587C31.066 7.59971 29.9374 5.9106 28.5146 4.48779C27.0918 3.06499 25.4026 1.93636 23.5437 1.16634C21.6847 0.396323 19.6922 0 17.6801 0ZM18.8587 14.1436V2.40913C21.8781 2.68437 24.7055 4.00909 26.8494 6.15297C28.9933 8.29684 30.318 11.1243 30.5932 14.1436H18.8587ZM14.1442 5.94505C14.1442 5.78132 14.1101 5.61938 14.044 5.46956C13.978 5.31974 13.8815 5.18533 13.7606 5.07488C13.6397 4.96444 13.4972 4.88039 13.342 4.82809C13.1869 4.77579 13.0225 4.75639 12.8594 4.77112C10.1826 5.01518 7.63078 6.01695 5.50281 7.65909C3.37483 9.30123 1.75885 11.5157 0.844148 14.0432C-0.0705549 16.5707 -0.246103 19.3065 0.338067 21.9302C0.922237 24.5539 2.24194 26.9568 4.14258 28.8574C6.04323 30.7581 8.44611 32.0778 11.0698 32.6619C13.6935 33.2461 16.4293 33.0706 18.9568 32.1558C21.4843 31.2411 23.6988 29.6252 25.3409 27.4972C26.983 25.3692 27.9848 22.8174 28.2289 20.1406C28.2432 19.9779 28.2237 19.814 28.1713 19.6593C28.119 19.5046 28.0351 19.3624 27.925 19.2418C27.8148 19.1213 27.6808 19.0249 27.5314 18.9589C27.3821 18.8928 27.2206 18.8585 27.0573 18.8582H14.1442V5.94505ZM2.35779 18.8582C2.35723 16.1411 3.29547 13.5072 5.01371 11.4024C6.73196 9.29752 9.12466 7.85097 11.7869 7.30755V20.0368C11.7869 20.3494 11.9111 20.6492 12.1321 20.8703C12.3531 21.0913 12.6529 21.2155 12.9655 21.2155H25.6948C25.1033 24.071 23.4747 26.606 21.1234 28.3309C18.7722 30.0558 15.8652 30.8483 12.9638 30.5552C10.0625 30.2621 7.37267 28.9043 5.41388 26.7441C3.45509 24.5838 2.36629 21.7743 2.35779 18.8582Z" fill="#444B56"/>
        </svg>
      </div>
      <div class="infoChart-title-text" v-if="turnInfo">Resumen del turno</div>
      <div class="infoChart-title-text" v-else>Resumen del día</div>
    </div>
    <div class="infoChart-description">
      <div class="infoChart-description-item">
        <div class="infoChart-description-item-text">Llegaron</div>
        <div class="infoChart-description-item-value">{{ finish }}</div>
      </div>
      <div class="infoChart-description-item">
        <div class="infoChart-description-item-text">Próximas por llegar</div>
        <div class="infoChart-description-item-value">{{ pending }}</div>
      </div>
      <div class="infoChart-description-item">
        <div class="infoChart-description-item-text">No llegaron</div>
        <div class="infoChart-description-item-value">{{ notShow }}</div>
      </div>
      <div class="infoChart-description-item">
        <div class="infoChart-description-item-text">Canceladas</div>
        <div class="infoChart-description-item-value">{{ cancel }}</div>
      </div>
    </div>
    <div class="infoChart-bar">
      <div class="infoChart-bar-indicator">
        <div class="infoChart-bar-indicator-current" :style="{left: (percent > 0) ? 'calc(((144px * var(--percent)) / 100) - 15px)' : 'calc(((144px * var(--percent)) / 100) - 4px)'}">{{ percent }}%</div>
        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
          <path d="M7.35282 1.44497e-06L0.648084 1.1519e-06C0.136816 1.12955e-06 -0.173829 0.637035 0.104457 1.12762L3.45683 7.08792C3.51519 7.19227 3.59594 7.27822 3.69155 7.33778C3.78717 7.39734 3.89454 7.42857 4.00369 7.42857C4.11284 7.42857 4.22021 7.39734 4.31582 7.33778C4.41144 7.27822 4.49218 7.19227 4.55055 7.08792L7.89645 1.12762C7.95958 1.01718 7.9952 0.889507 7.99955 0.758069C8.00389 0.626631 7.9768 0.496303 7.92114 0.380833C7.86547 0.265363 7.78329 0.169034 7.68326 0.102006C7.58324 0.0349781 7.46907 -0.000262623 7.35282 1.44497e-06Z" fill="#444B57"/>
        </svg>
      </div>
      <div class="infoChart-bar-progress">
        <div class="infoChart-bar-progress-total"></div>
      </div>
    </div>
    <div class="infoChart-percent">
      <div class="infoChart-percent-init">0%</div>
      <div class="infoChart-percent-finish">100%</div>
    </div>
  </div>
</template>
<script>
import serviceActionReservation from '@/helpers/apis/serviceActionReservation';
export default {
  props: ['type', 'turnInfo'],
  data () {
    return {
      finish: 0,
      pending: 0,
      notShow: 0,
      cancel: 0,
      total: 0,
      percent: 0
    };
  },
  mounted () {
    this.getDataInfo();
  },
  methods: {
    getDataInfo () {
      serviceActionReservation.post('/counts/reservations/totals', {
        vendorId: this.vendor.id,
        fecha: this.$moment(this.dateComplete).format('YYYY-MM-DD'),
        type: this.type,
        dates: this.dateFilterSelect
      }).then(({ data }) => {
        this.finish = data.finish || 0;
        this.pending = data.pending || 0;
        this.notShow = data.notShow || 0;
        this.cancel = data.cancel || 0;
        this.total = data.total || 0;
        const percent = ((this.finish + this.pending) / this.total) * 100;
        if (percent) {
          if (percent < 100) {
            const redondeado = Math.floor(percent * 10) / 10;
            if (redondeado % 1 === 0) {
              this.percent = redondeado.toFixed(0);
            } else {
              this.percent = redondeado.toFixed(1);
            }
          } else {
            this.percent = percent;
          }
        }
      });
    }
  },
  watch: {
    turnInfo () {
      this.getDataInfo();
    },
    type () {
      this.getDataInfo();
    }
  }
};
</script>
<style lang="scss">
.infoChart {
  width: 169px;
  height: 196px;
  flex-shrink: 0;
  border-radius: 6px;
  border: 1px solid #CDD1D5;
  background: #FFF;
  display: flex;
  flex-direction: column;
  &-title {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 12px 12px 10px 12px;
    &-icon {
      width: 33.002px;
      height: 33px;
      flex-shrink: 0;
    }
    &-text {
      color: #4A4A4A;
      font-family: 'Source Sans Pro';
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
  &-description {
    padding-left: 12px;
    padding-right: 12px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    &-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &-text {
        color: #444B57;
        font-family: 'Source Sans Pro';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      &-value {
        color: #444B57;
        text-align: right;
        font-family: 'Source Sans Pro';
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
  }
  &-bar {
    display: flex;
    flex-direction: column;
    padding-top: 14px;
    padding-left: 10px;
    padding-right: 13px;
    padding-bottom: 5px;
    &-indicator {
      height: 8px;
      width: 100%;
      display: flex;
      position: relative;
      &-current {
        color:#4A4A4A;
        text-align: center;
        font-family: 'Source Sans Pro';
        font-size: 10px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        position: absolute;
        top: -12px;
        left: calc(((144px * var(--percent)) / 100) - 15px);
      }
      svg {
        // 4px es la mitad del simbolo que mide 8px
        // se debe calcular 144 - 0.90 o el porcentaje
        left: calc(((144px * var(--percent)) / 100) - 4px);
        position: absolute;
      }
    }
    &-progress {
      width: 146px;
      height: 10px;
      flex-shrink: 0;
      border-radius: 6px;
      border: 1px solid #CDD1D5;
      background: #EDEDED;
      &-total {
        width: calc(((144px * var(--percent)) / 100));
        height: 8px;
        flex-shrink: 0;
        border-radius: 6px 0px 0px 6px;
        background: #54D401;
      }
    }
  }
  &-percent {
    display: flex;
    padding-left: 5px;
    padding-right: 4px;
    height: 9px;
    position: relative;
    &-init {
      color:#4A4A4A;
      text-align: center;
      font-family: 'Source Sans Pro';
      font-size: 7px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      position: absolute;
      left: 8px;
    }
    &-finish {
      color:#4A4A4A;
      text-align: center;
      font-family: 'Source Sans Pro';
      font-size: 7px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      position: absolute;
      right: 5px;
    }
  }
}
</style>
