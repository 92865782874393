<template>
<div id="editUser-Component">
  <div class="header-edit"></div>
  <div class="close">
    <div class="tittle-close">EDITAR USUARIO</div>
    <i @click="$emit('close')" class="fa fa-times isWhite" aria-hidden="true"></i>
  </div>
    <div class="cont">
      <div class="title">Editar usuario</div>
      <div class="avatar">
        {{ getInitialName(dataUser.name) | uppercase }}
      </div>
      <div class="name">
        {{dataUser.name}}
      </div>
      <Stars class="stars" :starWidth="25" :starHeight="25" :rating="calification(dataUser.reservations)"></Stars>
      <div class="info">
        <div class="buttons columns">
          <button class="tab-button column" :class="{active: activeTab == 0}" @click="activeTab = 0">Datos Usuario</button>
          <button class="tab-button column" :class="{active: activeTab == 1}" @click="activeTab = 1">Reservas</button>
        </div>
        <b-tabs v-model="activeTab">
          <b-tab-item>
            <div class="user-form">
              <div class="up">
                <div class="phone">
                  <div class="phone-label">Celular:</div>
                  <div class="indicativo">
                    <div class="left-box">
                      <img :src="bandera" class="bandera_list">
                      <Multiselect @input="changedPais(option)" v-if="option" v-model="option"
                        :options="paises"
                        track-by="label_show"
                        label="label_show"
                      >
                      </Multiselect>
                    </div>
                    <input
                    v-mask="['##############']"
                    v-validate="validateTel"
                    type="text"
                    name="phone"
                    v-model="dataUser.phone"
                    autocomplete="off"
                    id="inlineFormInputGroup"
                    placeholder="Celular"
                    class="input-phone"/>
                  </div>
                   <div class="error-s" v-if="errors.first('phone')" >
                    <span class="">Ingrese un numero de telefonico valido</span>
                  </div>
                </div>
                <div class="mail">
                  <div class="mail-label">Mail:</div>
                  <b-field
                  :type="{'is-danger': errors.has('userEmail')}"
                  :message="{'Ingrese un email valido' : errors.has('userEmail')}">
                  <b-input
                    type="email"
                    name="userEmail"
                    v-model="dataUser.email"
                    v-validate ="'email'">
                  </b-input>
                  </b-field>
                </div>
              </div>
              <div class="down">
                <span>Documento Identidad</span>
                <input class="document" v-mask="['################']"  v-model="dataUser.identityDocument"/>
              </div>
            </div>
            <div class="info-reservas">
              <div class="data-table columns is-mobile">
                <div class="column">
                  <div class="visit">Visitas</div>
                  <div class="visit-counter">{{dataUser.visits || 0}}</div>
                </div>
                <div class="column">
                  <div class="up">
                    <i class="icon-mks gray-blue icon_reservation"></i>
                    <div class="msg">Reservas</div>
                  </div>
                  <div class="counter">{{dataUser.free || 0}}</div>
                </div>
                <div class="column">
                  <div class="up">
                    <i class="icn icon-mks gray-blue icon_walkin"></i>
                    <div class="msg">Walk-in</div>
                  </div>
                  <div class="counter">{{dataUser.walkin || 0}}</div>
                </div>
                <div class="column">
                  <div class="up">
                    <i class="icn icon-mks gray-blue icon_reservations"></i>
                    <div class="msg">Precompras</div>
                  </div>
                  <div class="counter">{{dataUser.pay || 0}}</div>
                </div>
                <div class="column">
                  <div class="up">
                    <i class="icn icon-mks gray-blue icon_reservation"></i>
                    <div class="msg">Cancelación</div>
                  </div>
                  <div class="counter">{{dataUser.cancel || 0}}</div>
                </div>
              </div>
            </div>
            <div class="tags-cont">
              <div class="user-tags">
                <b-field label="TAGS">
                  <b-taginput
                    v-model="userTags"
                    class="user-tag"
                    icon-pack ="fa"
                    icon="tag"
                    size="is-medium"
                    placeholder="Agregar tag"
                    rounded
                  >
                  </b-taginput>
                </b-field>
              </div>
            </div>
             <div class="notas-cont">
              <label>NOTAS</label>
              <div v-if="userNotes != ''" class="notes">
                <b-notification @close="deleteUserNote(ind)" v-for="(note, ind) in userNotes" :key="'note-'+ind+'-'+note" :id="'note-'+ind+'-'+note" class="note">
                  {{note}}
                </b-notification>
              </div>
               <i v-if="userNotes.length === 0" title="Agregar nota" @click="promptNotes()" class="add-note fa fa-plus" aria-hidden="true"></i>
            </div>
             <div class="updateUser">
            <a @click="updateUser()" class="updateButton">Actualizar</a>
            </div>
          </b-tab-item>
          <b-tab-item>
            <div class="reservationInfo">
              <div class="info-title">Información histórica de reservas</div>
              <div class="info-msg">A continuación se listaran las reservas realizadas por este usuario:</div>
              <div class="info-table">
                <div class="header-res">
                  <div>Id reserva</div>
                  <div>Fecha</div>
                  <div>Calificación</div>
                </div>
                  <div class="reservation-data-cont">
                    <div  class="reservation-data"  v-for="(reservation, index) in dataUser.reservations" :key="index">
                      <div class="reservation-id">{{reservation.id}}</div>
                      <div class="reservation-fecha">{{reservation.fechaCompleta}}</div>
                      <Stars v-if="reservation.score > 0" class="stars user-score" :starWidth="20" :starHeight="20" :rating="reservation.score"></Stars>
                      <Stars v-if="reservation.score == 0 || !reservation.score" class="stars user-score" :starWidth="20" :starHeight="20" :rating="0"></Stars>
                    </div>
                  </div>
              </div>
            </div>
          </b-tab-item>
        </b-tabs>
      </div>
    </div>
</div>
</template>
<script>
import Stars from '@/components/_shared/users/stars';
import Multiselect from 'vue-multiselect';
import callingCode from 'country-json/src/country-by-calling-code.json';
import BanderaPais from 'country-json/src/country-by-abbreviation.json';
import _ from 'lodash';
export default {
  data () {
    return {
      activeTab: 0,
      option: {
        value: 57,
        label: 'Colombia ( +57 )',
        label_show: '+57',
        selected: true
      },
      userTags: [],
      userNotes: [],
      bandera: null,
      paises: [],
      validateTel: ''
    };
  },
  components: { Stars, Multiselect },
  props: ['dataUser'],
  mounted () {
    this.listPaises();
    const indicativo = this.$store.getters['global:vendor'].params.indicativeRegion;
    if (this.dataUser.indicativoUser != null) {
      this.indicativeParam(this.dataUser.indicativoUser);
    } else {
      this.indicativeParam(indicativo);
    }
    if (this.dataUser.indicative !== undefined) {
      this.option.value = parseInt(this.dataUser.indicative);
      this.option.label = this.dataUser.flagLabel;
      this.option.label_show = '+' + this.dataUser.indicative;
    }
    this.changedPais(this.option);
    if (typeof this.dataUser.tags !== 'undefined') {
      this.userTags = this.dataUser.tags;
    }
    if (this.dataUser.notes) {
      this.userNotes = [this.dataUser.notes];
    }
    this.scrollToTop();
  },
  methods: {
    indicativeParam (indicativo) {
      const selectIndicative = this.paises.find(value => parseInt(value.value) === parseInt(indicativo));
      this.option = selectIndicative;
    },
    calification (data) {
      let calification = 0;
      let total = 0;
      let count = 0;
      Object.values(data).map(item => {
        count++;
        if (item.score != null) {
          total += parseInt(item.score);
        }
      });
      if (total > 0) {
        calification = (total * count) / 100;
      }
      return calification;
    },
    scrollToTop () {
      document.getElementById('editUser').scrollIntoView();
    },
    deleteUserNote (ind) {
      this.userNotes.splice(ind, 1);
    },
    promptNotes () {
      this.$buefy.dialog.prompt({
        type: 'is-dark',
        message: 'Añadir nota al usuario.',
        cancelText: 'Cancelar',
        confirmText: 'Agregar',
        inputAttrs: {
          placeholder: 'Ingrese la nota '
        },
        trapFocus: true,
        onConfirm: (value) => {
          this.userNotes.push(value);
        }
      });
    },
    updateUser () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          const dataUpdate = {
            userId: this.dataUser._id,
            email: this.dataUser.email,
            phone: this.dataUser.phone,
            identityDocument: this.dataUser.identityDocument,
            indicative: parseInt(this.dataUser.indicative),
            flagLabel: this.dataUser.flagLabel,
            tags: this.userTags,
            notes: this.userNotes ? this.userNotes[0] : ''
          };
          this.$store.dispatch({
            type: 'people:update',
            data: dataUpdate
          }).then((data) => {
            if (parseInt(data.code) === 200) {
              this.$buefy.notification.open({
                message: '<i class="icon_alert_success icon-mks green"></i>Datos actualizados',
                type: 'is-sucess'
              });
              this.$emit('close');
              this.$emit('changePagEdit');
              this.$emit('reloadTags');
            }
          });
        } else {
          this.$buefy.notification.open({
            message: '<i class="icon_alert_danger icon-mks red"></i>Por favor verifica los datos del usuario',
            type: 'is-danger'
          });
        }
      });
    },
    getInitialName (name) {
      return _((name || '').split(' '))
        .filter(text => text)
        .map(text => text[0])
        .take(2).value()
        .join('');
    },
    listPaises () {
      var data = [];
      var ind = this.dataUser.indicative != null ? this.dataUser.indicative : null;
      _.forEach(callingCode, function (item) {
        if (item.calling_code !== null) {
          if (ind != null && ind === item.calling_code && item.country !== 'Bouvet Island') {
            data.push({
              value: item.calling_code,
              label: item.country + ' ( +' + item.calling_code + ' )',
              label_show: '+' + item.calling_code,
              selected: true
            });
          } else if (item.country !== 'Bouvet Island') {
            data.push({
              value: item.calling_code,
              label: item.country + ' ( +' + item.calling_code + ' )',
              label_show: item.country + ' ( +' + item.calling_code + ' )'
            });
          }
        }
      });
      this.paises = data;
    },
    changedPais (indicativo) {
      this.option = indicativo;
      let aux2 = null;
      const aux3 = indicativo.label;
      this.pais = indicativo.label;
      this.dataUser.indicative = indicativo.value;
      this.dataUser.flagLabel = indicativo.label;
      const ind = indicativo.value;
      _.forEach(BanderaPais, function (item) {
        if (ind !== null) {
          const data = item.country + ' ( +' + ind + ' )';
          if (data === aux3) {
            aux2 = item.abbreviation.toLowerCase();
          }
        }
      });
      this.bandera =
      aux2 != null ? '/dashboard/banderas/' + aux2 + '.png' : null;
      this.option.label_show = '+' + ind;
      switch (indicativo.value) {
        case 57:
          this.validateTel = 'numeric|min:10|max:10';
          break;
        case 507:
          this.validateTel = 'numeric|min:7|max:8';
          break;
        default:
          this.validateTel = 'numeric|min:7|max:11';
          break;
      };
    }
  },
  watch: {
    dataUser () {
      this.listPaises();
      if (this.dataUser.indicative !== undefined) {
        this.option.value = this.dataUser.indicative;
        this.option.label_show = '+' + this.dataUser.indicative;
        this.option.label = this.dataUser.flagLabel;
      } else {
        this.option = {
          value: 57,
          label: 'Colombia ( +57 )',
          label_show: '+57',
          selected: true
        };
      }
      this.changedPais(this.option);
      if (typeof this.dataUser.tags !== 'undefined') {
        this.userTags = this.dataUser.tags;
      } else {
        this.userTags = [];
      }
      if (typeof this.dataUser.notes !== 'undefined') {
        this.userNotes = this.dataUser.notes;
      } else {
        this.userNotes = [];
      }
    }
  }

};
</script>
<style lang="scss">
#editUser-Component {
  width: 600px;
  height: calc(100% - 52px);
  background:#fff;
  display: flex;
  flex-direction: column;
  position: fixed;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  top: 52px;
  right: 0;
  padding: 15px;
  padding-bottom: 20px;
  -webkit-box-shadow: -7px 7px 5px 0px #00000029;
  -moz-box-shadow: -7px 7px 5px 0px #00000029;
  box-shadow: -7px 7px 5px 0px #00000029;
  z-index: 99;
  .tittle-close{
    display: none;
    color: white;
    font-weight: bold;

  }
  .control.has-icons-left .input {
    padding-left: 2.65em;
  }
  .error-s{
    display: block;
    font-size: 0.75rem;
    margin-top: 0.25rem;
    color: #ff3860;
  }
    .cont{
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      overflow-x: hidden;
      align-items: center;
      height: 100%;
      min-height: 800px;
      &::-webkit-scrollbar {
        width: 0;
      }
      .title {
        font-size: 15px;
        margin-bottom: 10px;
      }
      .avatar {
        width: 70px;
        height: 70px;
        max-width: 70px;
        min-width: 70px;
        max-height: 70px;
        min-height: 70px;
        color: #fff;
        background-color: #00ADC6;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        font-size: 35px;
        border-radius: 100%;
        box-shadow: 0px 3px 6px #00000029;
        margin-bottom: 10px;
      }
      .name {
        margin-bottom: 10px;
        font-size: 25px;
        font-weight: bold;
      }
      .stars {
      margin: 0;
      }
      .info {
        margin-top: 30px;
        height: 100%;
        display: flex;
        flex-direction: column;
        .tab-content{
        padding: 15px 0 0 0 !important;
        }
        .buttons{
          padding: 0 45px 0 45px;
          position: relative;
          &:before {
            left: 15%;
            width: 70%;
            top: -15px;
            height: 2px;
            background: #EBECED;
            content: "";
            position: absolute;

          }
          .tab-button{
            border: none;
            height: 35px;
            font-size: 15px;
            color: #444B57;
          }
          .active {
          background:#444B57;
          color: #fff;
          }
        }
        .b-tabs, .tab-content, .tab-item {
          height: 100%;
          display: flex;
          flex-direction: column;
        }
        .user-form {
          display: flex;
          flex-direction: column;
          padding: 0 45px;
          margin-bottom: 10px;
          .up {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            margin-bottom: 20px;
            .phone {
              display: flex;
              flex-direction: column;
              margin-right: 35px;
              &-label {
                font-weight: bold;
                font-size: 14px;
                margin-bottom: 5px;
              }
            }
            .mail {
              display: flex;
              flex-direction: column;
              width: 80%;
              &-label {
                font-weight: bold;
                font-size: 14px;
                margin-bottom: 5px;
              }
              input {
                padding: 8px 8px;
                border: 1px solid #EBECED;
                border-radius: 20px;
                outline: none;
                -webkit-box-shadow: none;
                box-shadow: none;
              }
            }
            .bandera_list {
              display: flex;
              margin-top: 0px;
              width: 25px;
              height: 17px;
              margin-right: 0;
            }
            .indicativo {
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: center;
              width: 100%;
              .left-box {
                display: flex;
                align-items: center;
                justify-content: center;
                background: transparent;
                padding: 6px;
                border: 1px solid #EBECED;
                border-right: 0;
                width: 65px;
              }
              .input-phone {
                width: 130px;
                padding: 8px 8px;
                border: 1px solid #EBECED;
                border-radius: 0 5px 5px 0;
                outline: none;
              }
            }
            .multiselect {
              box-sizing: content-box;
              display: block;
              position: relative;
              width: 100%;
              text-align: left;
              color: #35495e;
              min-height: auto;
              &__tags {
                min-height: 20px;
                display: block;
                padding: 0;
                border-radius: 0;
                border: none;
                background: transparent;
                font-size: 6px;
                padding-left: 30px;
                margin-left: -32px;
              }
              &__select {
                display: none;
              }
              &__option--highlight:after {
                content: "";
                background: #41b883;
                color: #fff;
              }
              &__option:after {
                display: none !important;
              }
              &__input,
              &__single {
                padding-left: 5px;
                margin-bottom: 0px;
                font-size: 13px;
                font-weight: 600;
                line-height: 21px;
                background: transparent;
              }
              &__content-wrapper {
                position: absolute;

                display: block;
                background: #fff;
                width: 200px;
                max-height: 239px !important;
                overflow: auto;
                border: 1px solid #e8e8e8;
                border-top: none;
                border-bottom-left-radius: 5px;
                border-bottom-right-radius: 5px;
                z-index: 50;
                will-change: transform !important;
                -webkit-overflow-scrolling: touch;
                -webkit-transform: translateZ(0px);
              }
            }
            .list-country {
              width: 100%;
            }
          }
          .down {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            height: 30px;
            span {
              font-weight: bold;
              font-size: 14px;
              margin-right: 15px;
            }
            input {
              padding: 8px 8px;
              border: 1px solid #EBECED;
              border-radius: 20px;
              outline: none;
              width: 65%;
            }
          }
        }
        .info-reservas {
          display: flex;
          flex-direction: row;
          height: 110px;
          padding: 0 45px;
          margin-bottom: 50px;
          .data-table {
            width: 100%;
            .column {
              display: flex;
              align-items: stretch;
              flex-direction: column;
              border: 1px solid #EBECED !important;
              border-right: 0 !important;
              .visit {
                border-bottom: 0;
                color: #444B57;
                font-size: 22px;
                font-weight: bold;
                &-counter {
                  font-size: 35px;
                  font-weight: bold;
                  display: flex;
                  justify-content: center;
                  flex-direction: column;
                  text-align: center;
                }
              }
              .counter {
                font-size: 25px;
                font-weight: bold;
                display: flex;
                justify-content: center;
                flex-direction: column;
                text-align: center;
              }
              .up {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-end;
                padding: 5px;
                .icon_reservation {
                  margin: 0;
                  -webkit-mask-size: contain;
                  width: 35px;
                  margin-bottom: 2px;
                }
                .icon_reservations {
                  margin: 0;
                  -webkit-mask-size: contain;
                  width: 35px;
                  margin-bottom: 2px;
                }
                .icon_walkin {
                  margin: 0;
                  -webkit-mask-size: contain;
                  width: 45px;
                  height: 45px;
                  margin-bottom: 4px
                }
                .msg {
                  margin-top: -7px;
                  font-size: 14px;
                  color: #444B57;
                }
              }
              &:first-child {
                border-right: 0 !important;
              }
              &:last-child {
                border-right:1px solid #EBECED !important;
              }
              & >
              div {
                flex: 1;
                padding: 5px;
                max-height: 50%;
                text-align: center;
                border-bottom: 1px solid #EBECED;
                &:last-child {
                  border-bottom: 0;
                }
              }
            }
          }
        }
        .tags-cont {
          display: flex;
          flex-direction: column;
          padding: 0 45px 10px 45px;
          label {
            color: #444B57;
            font-size: 15px;
            font-weight: bold;
            margin-bottom: 5px;
          }
          .taginput-container {
            overflow-y: auto;
            overflow-x: hidden;
            height: 90px!important;
            border: none;
            -webkit-box-shadow: none;
            box-shadow: none;
            display: flex;
            align-items: flex-start;

          }
          .user-tags {
            display: flex;
            flex-direction: row;
            border-bottom: 2px solid #EBECED;
            padding-bottom: 10px;
            .taginput-container .tag {
              background-color: #16A796;
              color: #fff;
              margin-right: 5px;
              font-size: 17px!important;
            }
          }
        }
        .notas-cont {
          display: flex;
          flex-direction: column;
          padding: 0 45px;
          label {
            color: #444B57;
            font-size: 15px;
            font-weight: bold;
            margin-bottom: 5px;
          }
          .notes {
            display: flex;
            flex-direction: column;
            overflow-y: auto;
            overflow-x: hidden;
            height: 150px;
            .note {
              border-radius: 20px;
              color: #454C58;
              margin: 0 5px 10px 0;
            }
          }
          .add-note {
            font-size: 25px;
            margin: 10px;
            color: #807C7C;
          }
        }
        .updateUser {
          display: flex;
          flex-direction: column;
          padding: 0 45px;
          height: auto;
          align-items: flex-start;
          justify-content: flex-end;
          .updateButton {
            background: #444B57;
            color: #fff;
            font-weight: 600;
            font-size: 18px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            padding: 5px 20px 5px 20px;
            border-radius: 50px;
            line-height: 22px;
            box-shadow: 0px 3px 6px #00000029;
          }
        }
        .reservationInfo {
          display: flex;
          flex-direction: column;
          overflow: hidden;
          height: 100%;
          .info-title {
            font-size: 28px;
            text-align: center;
            font-weight: bold;
            margin-bottom: 15px;
            position: relative;
          }
          .info-msg {
            font-size: 18px;
            text-align: center;
            padding: 5px 4%;
            margin: 10px 15%;
            width: 70%;
            border-top: 1px solid #EBECED;
            border-bottom: 1px solid #EBECED;
          }
          .info-table {
            width: 70%;
            height: calc(100% - 15%);
            display: flex;
            flex-direction: column;
            margin: 10px 15%;
            .header-res {
              display: flex;
              align-items: stretch;
              width: 100%;
              flex-direction: row;
              & >
              div {
                flex: 1;
                padding: 5px;
                text-align: center;
                align-items: stretch;
                font-weight: bold;
                font-size: 15px;
                min-height: 100%;
                border: 1px solid #EBECED !important;
                &:first-child {
                  border-right: 0 !important;
                }
                &:last-child {
                border-left:0 !important ;
                  flex: 2;
                }
              }
            }
          }
            .reservation-data-cont {
              display: flex;
              flex-direction: column;
              overflow-y: scroll;
              -webkit-overflow-scrolling: touch;
              height:calc(100% - 15%);
              &::-webkit-scrollbar {
                width: 0;
              }
              .reservation-data {
                width: 100%;
                display: flex;
                flex-direction: row;
                border-top: 0;
                & >
                div {
                  flex: 1;
                  padding: 5px;
                  text-align: center;
                  align-items: stretch;
                  font-size: 13px;
                  min-height: 100%;
                  border: 1px solid #EBECED !important;
                  border-top: 0 !important;
                  &:first-child {
                    border-right: 0 !important;
                  }
                  &:last-child {
                    border-left:0 !important ;
                    flex: 2;
                  }
                }
                .user-score {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }
              }
            }
        }
      }
    }
}
</style>
