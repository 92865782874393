<template>
  <div id="module-history">
    <b-loading :can-cancel="false" :is-full-page="true" :active.sync="loading"></b-loading>
    <a @click="resetModule" class="return">
      <img src="@/assets/icons/marketing/return.svg" alt="precompro.com" class="return-icon">
      <span class="return-text">Volver</span>
    </a>
    <div class="tableDetail">
      <span v-if="showTransaction === 0 && !details">Historial de campañas enviadas por {{ (provider === 'sms') ? 'mensaje de texto' : 'correo electrónico' }}</span>
      <span v-if="showTransaction === 1 && !details">Historial de transacciones {{ (provider === 'sms') ? 'mensaje de texto' : 'correo electrónico' }}</span>
      <span v-if="details">Detalle campaña {{ (provider === 'sms') ? 'mensaje de texto' : 'correo electrónico' }}</span>
    </div>
    <div style="display: flex; justify-content: space-between; align-items: center; height: 40px; padding: 0px 10px;" :style="'space-between'">
      <b-field label="Mostrar transacciones" class="switchHistory">
        <b-switch v-model="showTransaction" :true-value="1" :false-value="0">
          {{ (showTransaction === 0) ? 'No' : 'Si' }}
        </b-switch>
      </b-field>
      <b-button icon-left="refresh" style="height: 30px !important; width: 40px; background-color: #444B57; color: white;" title="Actualizar" type="is-default" @click="updateInfo()"></b-button>
    </div>
    <!--PC-->
    <div class="history">
      <!--HEADER-->
      <div class="tableHistory" v-if="showTransaction === 0 && !details">
        <div class="tableHistory-header item1">
          <div class="tableHistory-header-item">
            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="5" viewBox="0 0 9 5" fill="none">
              <path d="M1 4.5L4.5 1L8 4.5" stroke="#A8ADB5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="5" viewBox="0 0 9 5" fill="none">
              <path d="M8 1L4.5 4.5L1 0.999999" stroke="#A8ADB5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
          <div class="tableHistory-header-item-text">
            <span v-if="provider === 'email'">Asunto</span>
            <span v-if="provider === 'sms'">Nombre de la campaña</span>
          </div>
        </div>
        <div class="tableHistory-header item2">
          <div class="tableHistory-header-item">
            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="5" viewBox="0 0 9 5" fill="none">
              <path d="M1 4.5L4.5 1L8 4.5" stroke="#A8ADB5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="5" viewBox="0 0 9 5" fill="none">
              <path d="M8 1L4.5 4.5L1 0.999999" stroke="#A8ADB5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
          <div class="tableHistory-header-item-text">
            <span>Fecha de envío</span>
          </div>
        </div>
        <div class="tableHistory-header item3">
          <div class="tableHistory-header-item">
            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="5" viewBox="0 0 9 5" fill="none">
              <path d="M1 4.5L4.5 1L8 4.5" stroke="#A8ADB5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="5" viewBox="0 0 9 5" fill="none">
              <path d="M8 1L4.5 4.5L1 0.999999" stroke="#A8ADB5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
          <div class="tableHistory-header-item-text">
            <span>Estado</span>
          </div>
        </div>
        <div class="tableHistory-header item4">
          <div class="tableHistory-header-item">
            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="5" viewBox="0 0 9 5" fill="none">
              <path d="M1 4.5L4.5 1L8 4.5" stroke="#A8ADB5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="5" viewBox="0 0 9 5" fill="none">
              <path d="M8 1L4.5 4.5L1 0.999999" stroke="#A8ADB5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
          <div class="tableHistory-header-item-text">
            <span>Enviados/Efectivos</span>
          </div>
        </div>
        <div class="tableHistory-header item5">
          <div class="tableHistory-header-item">
            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="5" viewBox="0 0 9 5" fill="none">
              <path d="M1 4.5L4.5 1L8 4.5" stroke="#A8ADB5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="5" viewBox="0 0 9 5" fill="none">
              <path d="M8 1L4.5 4.5L1 0.999999" stroke="#A8ADB5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
          <div class="tableHistory-header-item-text">
            <span>Previsualizar</span>
          </div>
        </div>
      </div>
      <!--HEADER TRANSACTIONS-->
      <div class="tableHistory" v-if="showTransaction === 1 && !details">
        <div class="tableHistory-header item1t">
          <div class="tableHistory-header-item">
            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="5" viewBox="0 0 9 5" fill="none">
              <path d="M1 4.5L4.5 1L8 4.5" stroke="#A8ADB5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="5" viewBox="0 0 9 5" fill="none">
              <path d="M8 1L4.5 4.5L1 0.999999" stroke="#A8ADB5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
          <div class="tableHistory-header-item-text">
            <span>Movimiento</span>
          </div>
        </div>
        <div class="tableHistory-header item2t">
          <div class="tableHistory-header-item">
            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="5" viewBox="0 0 9 5" fill="none">
              <path d="M1 4.5L4.5 1L8 4.5" stroke="#A8ADB5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="5" viewBox="0 0 9 5" fill="none">
              <path d="M8 1L4.5 4.5L1 0.999999" stroke="#A8ADB5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
          <div class="tableHistory-header-item-text">
            <span>Monto</span>
          </div>
        </div>
        <div class="tableHistory-header item3t">
          <div class="tableHistory-header-item">
            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="5" viewBox="0 0 9 5" fill="none">
              <path d="M1 4.5L4.5 1L8 4.5" stroke="#A8ADB5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="5" viewBox="0 0 9 5" fill="none">
              <path d="M8 1L4.5 4.5L1 0.999999" stroke="#A8ADB5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
          <div class="tableHistory-header-item-text">
            <span>Proveedor</span>
          </div>
        </div>
        <!-- <div class="tableHistory-header item4t">
          <div class="tableHistory-header-item">
            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="5" viewBox="0 0 9 5" fill="none">
              <path d="M1 4.5L4.5 1L8 4.5" stroke="#A8ADB5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="5" viewBox="0 0 9 5" fill="none">
              <path d="M8 1L4.5 4.5L1 0.999999" stroke="#A8ADB5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
          <div class="tableHistory-header-item-text">
            <span>Fecha de envio</span>
          </div>
        </div> -->
        <div class="tableHistory-header item4t">
          <div class="tableHistory-header-item">
            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="5" viewBox="0 0 9 5" fill="none">
              <path d="M1 4.5L4.5 1L8 4.5" stroke="#A8ADB5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="5" viewBox="0 0 9 5" fill="none">
              <path d="M8 1L4.5 4.5L1 0.999999" stroke="#A8ADB5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
          <div class="tableHistory-header-item-text">
            <span>Estado</span>
          </div>
        </div>
        <!-- <div class="tableHistory-header item4t">
          <div class="tableHistory-header-item">
            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="5" viewBox="0 0 9 5" fill="none">
              <path d="M1 4.5L4.5 1L8 4.5" stroke="#A8ADB5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="5" viewBox="0 0 9 5" fill="none">
              <path d="M8 1L4.5 4.5L1 0.999999" stroke="#A8ADB5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
          <div class="tableHistory-header-item-text">
            <span>Enviados/Efectivos</span>
          </div>
        </div> -->
        <div class="tableHistory-header item5t">
          <div class="tableHistory-header-item">
            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="5" viewBox="0 0 9 5" fill="none">
              <path d="M1 4.5L4.5 1L8 4.5" stroke="#A8ADB5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="5" viewBox="0 0 9 5" fill="none">
              <path d="M8 1L4.5 4.5L1 0.999999" stroke="#A8ADB5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
          <div class="tableHistory-header-item-text">
            <span>Previsualizar</span>
          </div>
        </div>
      </div>
      <!--HEADER DETAILS-->
      <div class="tableHistory" v-if="details">
        <div class="tableHistory-header" style="width: 33.3%;">
          <div class="tableHistory-header-item">
            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="5" viewBox="0 0 9 5" fill="none">
              <path d="M1 4.5L4.5 1L8 4.5" stroke="#A8ADB5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="5" viewBox="0 0 9 5" fill="none">
              <path d="M8 1L4.5 4.5L1 0.999999" stroke="#A8ADB5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
          <div class="tableHistory-header-item-text">
            <span>Destinatario</span>
          </div>
        </div>
        <div class="tableHistory-header" style="width: 33.3%;">
          <div class="tableHistory-header-item">
            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="5" viewBox="0 0 9 5" fill="none">
              <path d="M1 4.5L4.5 1L8 4.5" stroke="#A8ADB5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="5" viewBox="0 0 9 5" fill="none">
              <path d="M8 1L4.5 4.5L1 0.999999" stroke="#A8ADB5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
          <div class="tableHistory-header-item-text">
            <span>Mensaje Id</span>
          </div>
        </div>
        <div class="tableHistory-header" style="width: 33.3%;">
          <div class="tableHistory-header-item">
            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="5" viewBox="0 0 9 5" fill="none">
              <path d="M1 4.5L4.5 1L8 4.5" stroke="#A8ADB5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="5" viewBox="0 0 9 5" fill="none">
              <path d="M8 1L4.5 4.5L1 0.999999" stroke="#A8ADB5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
          <div class="tableHistory-header-item-text">
            <span>Estado</span>
          </div>
        </div>
      </div>
      <!--SIN DATA-->
      <div class="tableHistory-body" v-if="data.length === 0 && !details">
        <div class="tableHistory-body-item-empty">
          <span>No hay información para mostrar</span>
        </div>
      </div>
      <!--DATA GLOBAL-->
      <div class="tableHistory-body" v-if="data.length > 0 && !details">
        <div class="tableHistory-body-row" v-for="(item, index) in data" :key="'transaction-' + item._id + index">
          <div class="tableHistory-body-item" :class="showTransaction !== 1 ? 'bItem1': 'bItem1t'">
            <span>{{ item.title }}</span>
          </div>
          <div class="tableHistory-body-item bItem2t" v-if="showTransaction === 1">
            <span>{{ item.detail | currency('$ ', 0, { thousandsSeparator: '.' }) }}</span>
          </div>
          <div class="tableHistory-body-item bItem3t" v-if="showTransaction === 1">
            <span>{{ item.dateSend }}</span>
          </div>
          <div class="tableHistory-body-item" v-if="showTransaction === 0" :class="showTransaction === 0 ? 'bItem2': 'bItem4t'">
            <span v-if="item.dateProgramming">{{ item.dateProgramming | moment('DD[/]MM[/]YYYY')}}</span>
            <span v-else>DD/MM/YYYY</span>
          </div>
          <div class="tableHistory-body-item" :class="showTransaction === 0 ? 'bItem3': 'bItem4t'">
            <b-tag type="is-primary" style="color: #ffffff; margin: unset;" v-if="item.statistics === 'Programado'">
              <b-icon icon="clock" size="is-small" style="width: 18px; color: #ffffff; font-size: 12px;"></b-icon>
              <span style="color: #ffffff; font-size: 12px;">{{ parseInt(item.dateProgramming) | moment('timezone', timezone, 'YYYY-MM-DD HH:mm') }}</span>
            </b-tag>
            <b-tag type="is-danger" style="color: #ffffff; margin: unset;" v-else-if="item.statistics === 'Cancelado'">
              <b-icon icon="clock" size="is-small" style="width: 18px; color: #ffffff; font-size: 12px;"></b-icon>
              <span style="color: #ffffff;">Cancelado</span>
            </b-tag>
            <b-tag type="is-info" style="color: #ffffff; margin: unset;" v-else-if="item.statistics === 'Enviando'">
              <span style="color: #ffffff;">Enviando</span>
            </b-tag>
            <b-tag type="is-success" style="color: #ffffff; margin: unset;" v-else-if="item.statistics === 'Enviado'">
              <span style="color: #ffffff;">Enviado</span>
            </b-tag>
            <b-tag type="is-success" style="color: #ffffff; margin: unset;" v-else-if="item.statistics === 'Queue'">
              <span style="color: #ffffff;">En cola</span>
            </b-tag>
            <b-tag type="is-danger" style="color: #ffffff; margin: unset;" v-else-if="item.statistics === 'Rechazada'">
              <span style="color: #ffffff;">Rechazada</span>
            </b-tag>
            <b-tag type="is-success" style="color: #ffffff; margin: unset;" v-else-if="item.statistics === 'Aprobada'">
              <span style="color: #ffffff;">Aprobada</span>
            </b-tag>
            <b-tag type="is-warning" v-else>
              <span style="color: #ffffff;">Pendiente</span>
            </b-tag>
          </div>
          <div class="tableHistory-body-item" v-if="showTransaction === 0" :class="showTransaction !== 1 ? 'bItem4': 'bItem6t'">
            <span v-if="item.statistics !== 'Enviado'">
              0/0
            </span>
            <span v-if="item.statistics === 'Enviado'">{{ item.total }}/{{ item.send }}</span>
          </div>
          <div class="tableHistory-body-item bItem5" v-if="showTransaction === 0">
            <button class="tableHistory-body-item-button" @click="showInfoItem(item.infoId, item.title, item.detail, item.statistics, item.type, parseInt(item.dateProgramming))">
              <span>Más</span>
            </button>
          </div>
          <div class="tableHistory-body-item bItem5t" v-if="showTransaction === 1">
            <button class="tableHistory-body-item-button" @click="showInfo(item.infoId, item.provider)">
              <span>Más</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!--MOBILE-->
    <div class="historyMobile">
      <!--WITOUT INFORMATION-->
      <div class="historyMobile-List" v-if="data.length === 0 && !details">
        <div class="historyMobile-List-item-empty">
          <span>No hay información para mostrar</span>
        </div>
      </div>
      <div class="historyMobile-List" v-if="detailInfo.length === 0 && details">
        <div class="historyMobile-List-item-empty">
          <span>No hay información para mostrar</span>
        </div>
      </div>
      <!--<img src="" alt="">-->
      <!--INFORMATION OF SEND-->
      <div class="historyMobile-List" v-if="data.length > 0 && showTransaction === 0 && !details">
        <div class="historyMobile-List-item" v-for="(item, index) in data" :key="'campaign-' + item._id + index" :id="'campaign-' + item._id + index">
          <div class="historyMobile-List-item-text">
            <span class="historyMobile-List-item-text-title" v-if="provider === 'email'">Asunto</span>
            <span class="historyMobile-List-item-text-title" v-if="provider === 'sms'">Nombre de la campaña</span>
            <span class="historyMobile-List-item-text-value">{{ item.title }}</span>
          </div>
          <div class="historyMobile-List-item-text-row">
            <div class="historyMobile-List-item-text-row-text" v-if="!item.isProgramming">
              <span class="historyMobile-List-item-text-row-text-title">Fecha de envió</span>
              <span class="historyMobile-List-item-text-row-text-value">{{ item.dateSend || 'Pendiente' }}</span>
            </div>
            <div class="historyMobile-List-item-text-row-text" v-if="item.isProgramming">
              <span class="historyMobile-List-item-text-row-text-title">Fecha de programación</span>
              <span class="historyMobile-List-item-text-row-text-value">{{ parseInt(item.dateProgramming) | moment('timezone', timezone, 'YYYY-MM-DD HH:mm') }}</span>
            </div>
            <div class="historyMobile-List-item-text-row-text" v-if="item.statistics !== 'Programado'">
              <span class="historyMobile-List-item-text-row-text-title">Enviados/Efectivos</span>
              <span class="historyMobile-List-item-text-row-text-value">
                <b-tag type="is-warning" v-if="item.statistics === 'Pendiente'">{{ item.statistics }}</b-tag>
                <b-tag type="is-info" v-if="item.statistics === 'Enviando'">{{ item.statistics }}</b-tag>
                <b-tag type="is-danger" v-if="item.statistics === 'Cancelado'">{{ item.statistics }}</b-tag>
                <b-tag type="is-success" v-if="item.statistics === 'Queue'">En cola</b-tag>
                <b-tag type="is-danger" v-if="item.statistics === 'Rechazada'">{{ item.statistics }}</b-tag>
                <b-tag type="is-success" v-if="item.statistics === 'Aprobada'">{{ item.statistics }}</b-tag>
                <span v-if="item.statistics === 'Enviado'">{{ item.send }}/{{ item.total }}</span>
              </span>
            </div>
            <div class="historyMobile-List-item-text-row-text">
              <span class="historyMobile-List-item-text-row-text-value">
                <button class="historyMobile-List-item-text-row-text-value-eye" @click="showInfoItem(item.infoId, item.title, item.detail, item.statistics, item.type, parseInt(item.dateProgramming))">
                  <span>Más</span>
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
      <!-- PAYMENT INFORMATION -->
      <div class="historyMobile-List" v-if="data.length > 0 && showTransaction === 1 && !details">
        <div class="historyMobile-List-item historyMobile-List-item-transaction-b" v-for="(item, index) in data" :key="'transaction-' + item._id + index"  :id="'transaction-' + item._id + index">
          <div class="historyMobile-List-item-text-row">
            <div class="historyMobile-List-item-text-row-text">
              <span class="historyMobile-List-item-text-title">Movimiento</span>
              <span class="historyMobile-List-item-text-value">{{ item.title }}</span>
            </div>
            <div class="historyMobile-List-item-text-row-text">
              <span class="historyMobile-List-item-text-title">Monto</span>
              <span class="historyMobile-List-item-text-value">{{ item.detail | currency('$ ', 0, { thousandsSeparator: '.' }) }}</span>
            </div>
          </div>
          <div class="historyMobile-List-item-text-row">
            <div class="historyMobile-List-item-text-row-text">
              <span class="historyMobile-List-item-text-row-text-title">Proveedor</span>
              <span class="historyMobile-List-item-text-row-text-value">{{ item.dateSend }}</span>
            </div>
            <div class="historyMobile-List-item-text-row-text">
              <span class="historyMobile-List-item-text-row-text-title">Estado</span>
              <span class="historyMobile-List-item-text-row-text-value">
                <b-tag type="is-success" v-if="item.statistics === 'Aprobada'">{{ item.statistics }}</b-tag>
                <b-tag type="is-warning" v-if="item.statistics === 'Pendiente'">{{ item.statistics }}</b-tag>
                <b-tag type="is-danger" v-if="item.statistics === 'Rechazada'">{{ item.statistics }}</b-tag>
              </span>
            </div>
            <div class="historyMobile-List-item-text-row-text">
              <div class="historyMobile-List-item-text-row-text-value">
                <button class="historyMobile-List-item-text-row-text-value-eye" @click="showInfo(item.infoId, item.provider)">
                  <span>Más</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--DETAILS INFORMATION-->
      <div class="historyMobile-List" v-if="detailInfo.length > 0 && details">
        <div class="historyMobile-List-item" v-for="(item, index) in detailInfo" :key="'transaction-' + item._id + index"  :id="'transaction-' + item._id + index">
          <div class="historyMobile-List-item-text">
            <span class="historyMobile-List-item-text-title">Destinatario</span>
            <span class="historyMobile-List-item-text-value">{{ item.to }}</span>
          </div>
          <div class="historyMobile-List-item-text">
            <span class="historyMobile-List-item-text-title">Mensaje Id</span>
            <span class="historyMobile-List-item-text-value">{{ item.messageId }}</span>
          </div>
          <div class="historyMobile-List-item-text">
            <span class="historyMobile-List-item-text-title">Estado</span>
            <span class="historyMobile-List-item-text-value">
              <b-tag type="is-success" v-if="item.status === 200">Enviado</b-tag>
              <b-tag type="is-warning" v-if="item.status !== 200">No enviado</b-tag>
            </span>
          </div>
        </div>
      </div>
    </div>
    <!--INFO TRANSACCIÓN-->
    <b-modal :active.sync="modalShow" :can-cancel="false">
      <div class="modal-card transactionInfo">
        <section class="modal-card-body transactionInfo-body">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" @click="modalShow = false">
            <path d="M13.863 1.02935L13.8629 1.02937L8 6.89231L2.13706 1.02937L2.13704 1.02935C1.99014 0.882498 1.79093 0.8 1.58322 0.8C1.3755 0.8 1.17629 0.882498 1.0294 1.02935L1.02935 1.0294C0.882498 1.17629 0.8 1.3755 0.8 1.58322C0.8 1.79093 0.882498 1.99014 1.02935 2.13704L1.02937 2.13706L6.89231 8L1.02937 13.8629L1.02935 13.863C0.882498 14.0099 0.8 14.2091 0.8 14.4168C0.8 14.6245 0.882498 14.8237 1.02935 14.9706L1.0294 14.9706C1.17629 15.1175 1.3755 15.2 1.58322 15.2C1.79093 15.2 1.99014 15.1175 2.13704 14.9706L2.13706 14.9706L8 9.10769L13.8629 14.9706L13.863 14.9706C14.0099 15.1175 14.2091 15.2 14.4168 15.2C14.6245 15.2 14.8237 15.1175 14.9706 14.9706L14.9706 14.9706C15.1175 14.8237 15.2 14.6245 15.2 14.4168C15.2 14.2091 15.1175 14.0099 14.9706 13.863L14.9706 13.8629L9.10769 8L14.9706 2.13706L14.9706 2.13704C15.1175 1.99014 15.2 1.79093 15.2 1.58322C15.2 1.3755 15.1175 1.17629 14.9706 1.0294L14.9706 1.02935C14.8237 0.882498 14.6245 0.8 14.4168 0.8C14.2091 0.8 14.0099 0.882498 13.863 1.02935Z" fill="#444B57" stroke="#444B57" stroke-width="0.4"/>
          </svg>
          <div v-if="modalDetalle.transaction !== null && modalDetalle.transaction.payment_provider === 'payu'" class="transactionInfo-body-item">
            <div style="display: flex; justify-content: center; flex-direction: column; align-items: center;">
              <span
                :style="{ 'color': colorStatus(modalDetalle.status) }"
                class="material-icons-outlined ic"
              >{{ statusLogo(modalDetalle.status) }}</span>
              <span style="font-weight: bold;">{{ transforHuman(modalDetalle.status) }}</span>
            </div>
            <div style="display: flex; justify-content: center; align-items: center; flex-direction: column; margin-top: 20px;">
              <h2>Detalle de la transacción:</h2>
              <strong>{{ modalDetalle.transaction.transactionId || 'Sin Información' }}</strong>
            </div>
            <div style="display: flex; justify-content: center; align-items: center; margin-top: 20px;">
              <table border="1">
                <thead>
                  <tr>
                    <th style="padding: 10px; text-align: center;">Paquete</th>
                    <th style="padding: 10px; text-align: center;">Cantidad</th>
                    <th style="padding: 10px; text-align: center;">Precio Unidad</th>
                    <th style="padding: 10px; text-align: center;">Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style="padding: 10px; text-align: center;">{{ modalDetalle.transaction.type.toUpperCase() }}</td>
                    <td style="padding: 10px; text-align: center;">{{ modalDetalle.transaction.quantity | currency('', 0, { thousandsSeparator: '.' }) }}</td>
                    <td style="padding: 10px; text-align: center;">{{ modalDetalle.transaction.perMessage | currency('$ ', 0, { thousandsSeparator: '.' }) }}</td>
                    <td style="padding: 10px; text-align: center; font-weight: bold;">{{ modalDetalle.transaction.amount | currency('$ ', 0, { thousandsSeparator: '.' }) }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div v-if="modalDetalle.transaction !== null && modalDetalle.transaction.payment_provider === 'mercadopago'" class="transactionInfo-body-item">
            <div style="display: flex; justify-content: center; flex-direction: column; align-items: center;">
              <span
                :style="{ 'color': colorStatus(modalDetalle.status) }"
                class="material-icons-outlined ic"
              >{{ statusLogo(modalDetalle.status) }}</span>
              <span style="font-weight: bold;">{{ transforHuman(modalDetalle.status) }}</span>
            </div>
            <div style="display: flex; justify-content: center; align-items: center; flex-direction: column; margin-top: 20px;">
              <h2>Detalle de la transacción:</h2>
              <strong>{{ modalDetalle.transaction.transactionId || 'Sin Información' }}</strong>
            </div>
            <div style="display: flex; justify-content: center; align-items: center; margin-top: 20px;">
              <table border="1">
                <thead>
                  <tr>
                    <th style="padding: 10px; text-align: center;">Paquete</th>
                    <th style="padding: 10px; text-align: center;">Cantidad</th>
                    <th style="padding: 10px; text-align: center;">Precio Unidad</th>
                    <th style="padding: 10px; text-align: center;">Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style="padding: 10px; text-align: center;">{{ modalDetalle.transaction.type.toUpperCase() }}</td>
                    <td style="padding: 10px; text-align: center;">{{ modalDetalle.transaction.quantity | currency('', 0, { thousandsSeparator: '.' }) }}</td>
                    <td style="padding: 10px; text-align: center;">{{ modalDetalle.transaction.perMessage | currency('$ ', 0, { thousandsSeparator: '.' }) }}</td>
                    <td style="padding: 10px; text-align: center; font-weight: bold;">{{ modalDetalle.transaction.transaction_amount | currency('$ ', 0, { thousandsSeparator: '.' }) }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </section>
      </div>
    </b-modal>
    <!--INFO TRANSACCIÓN-->
  </div>
</template>
<script>
import serviceMarketing from '@/helpers/apis/serviceMarketing';
import servicePayment from '@/helpers/apis/servicePayment.js';
import DetailMessage from '@/components/Marketing/history/DetailMessage.vue';

export default {
  props: {
    provider: {
      type: String
    }
  },
  data () {
    return {
      data: [],
      showTransaction: 0,
      loading: false,
      modalShow: false,
      details: false,
      detailInfo: [],
      showId: null,
      modalDetalle: {
        status: null,
        transaction: null
      }
    };
  },
  mounted () {
    this.getHistory();
  },
  methods: {
    updateInfo () {
      if (this.showTransaction) {
        this.getTransactions();
      } else {
        if (this.details) {
          this.showInfoItem(this.showId);
        } else {
          this.getHistory();
        }
      }
    },
    transforHuman (status) {
      if (status) {
        const statusFormat = status.toLowerCase();
        switch (statusFormat) {
          case 'approved':
            return 'Aprobada';
          case 'pending':
            return 'Pendiente';
          default:
            return 'Rechazada';
        }
      } else {
        return 'Rechazada';
      }
    },
    colorStatus (status) {
      if (status) {
        const statusFormat = status.toLowerCase();
        switch (statusFormat) {
          case 'approved':
            return '#3DAF41';
          case 'pending':
            return '#102C3A';
          default:
            return '#FF0101';
        }
      } else {
        return '#FF0101';
      }
    },
    statusLogo (status) {
      if (status) {
        const statusFormat = status.toLowerCase();
        switch (statusFormat) {
          case 'approved':
            return 'task_alt';
          case 'pending':
            return 'schedule';
          default:
            return 'highlight_off';
        }
      } else {
        return 'highlight_off';
      }
    },
    showInfoItem (id, title, emailMessage, status, type, dateProgramming) {
      this.showId = id;
      this.loading = true;
      serviceMarketing.get('marketing-campaign/detail/' + id).then(({ data }) => {
        if (this.provider === 'sms') {
          this.detailInfo = data.data;
        }
        if (this.provider === 'email') {
          this.detailInfo = data.data;
        }
        this.showModalDetailMessage(title, emailMessage, status, type, dateProgramming, id);
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    },
    showInfo (id, provider) {
      this.loading = true;
      servicePayment.post('marketing/transaction', { transactionId: id, provider: provider }).then(({ data }) => {
        this.modalShow = true;
        this.modalDetalle.status = data.data.status;
        this.modalDetalle.transaction = data.data;
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    },
    resetModule () {
      if (!this.details) {
        window.localStorage.removeItem('marketing_package');
        this.$emit('resetModule', {
          module: 'reset'
        });
      } else {
        this.details = false;
        this.detailInfo = [];
        this.showId = null;
      }
    },
    getHistory () {
      this.loading = true;
      serviceMarketing.post('history/' + this.vendor.id, { type: this.provider, module: this.provider }).then(({ data }) => {
        this.data = data.data;
        this.loading = false;
      }).catch(() => {
        this.data = [];
        this.loading = false;
      });
    },
    getTransactions () {
      this.loading = true;
      serviceMarketing.post('history/' + this.vendor.id, { type: 'payment', module: this.provider }).then(({ data }) => {
        this.data = data.data;
        this.loading = false;
      }).catch(() => {
        this.data = [];
        this.loading = false;
      });
    },
    showModalDetailMessage (title, emailMessage, status, type, dateProgramming, id) {
      const thes = this;
      this.$buefy.modal.open({
        parent: thes,
        hasModalCard: true,
        canCancel: false,
        component: DetailMessage,
        props: {
          title: title,
          detailInfo: thes.detailInfo,
          id: id,
          emailMessage: emailMessage,
          status: status,
          type: type,
          dateProgramming: dateProgramming
        },
        events: {
          reloadList () {
            thes.updateInfo();
          }
        }
      });
    }
  },
  watch: {
    provider () {
      if (this.showTransaction) {
        this.getTransactions();
      } else {
        this.getHistory();
      }
    },
    showTransaction () {
      if (this.showTransaction) {
        this.getTransactions();
      } else {
        this.getHistory();
      }
    }
  }
};
</script>
<style lang="scss">
#module-history-detail {
  background: #FFF;
  height: 100%;
  position: relative;
}
#module-history {
  .switch input[type=checkbox]:checked + .check {
    background: #444B57;
  }
  background: #FFF;
  height: 100%;
  position: relative;
  .transactionInfo {
    border-radius: 10px;
    border: 1px solid #C9C9C9;
    background: #FFF;
    margin: 0 auto;
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.40);
    position: relative;
    height: 529px;
    svg {
      float: right;
      top: 23px;
      right: 12px;
      position: absolute;
      cursor: pointer;
    }
    &-body {
      padding: 10px;
      .ic {
        font-size: 70px;
      }
      &-item {
        display: flex;
        overflow-y: auto;
        overflow-x: hidden;
        width: 100%;
        justify-content: center;
        height: 100%;
        flex-direction: column;
      }
    }
  }
  .switchHistory {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 40px;
    padding-left: 20px;
    margin-bottom: unset;
    margin-top: 10px;
    label {
      font-weight: normal;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      margin-bottom: unset;
      margin-right: 5px;
    }
    .switch {
      margin-right: unset;
      &:hover input[type=checkbox]:checked + .check {
        background: #444B57;
      }
      &:focus input[type=checkbox]:checked + .check {
        -webkit-box-shadow: 0 0 0.5em #444B57;
        box-shadow: 0 0 0.5em #444B57;
      }
      &:active input[type=checkbox]:checked + .check {
        -webkit-box-shadow: 0 0 0.5em #444B57;
        box-shadow: 0 0 0.5em #444B57;
      }
    }
  }
  .return {
    color: #444B57;
    text-decoration: none;
    display: flex;
    width: 80px;
    align-content: center;
    padding: 15px 0px 0px 15px;
    position: relative;
    z-index: 99;
    &-text{
      color: #444B57;
      font-size: 14px;
      font-family: 'Source Sans Pro';
      @media screen and (max-width: 500px) {
        display: none;
      }
    }
    &-icon {
      width: 8px;
      height: 18px;
      flex-shrink: 0;
      margin-right: 12px;
    }
  }
  .tableDetail {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: -20px;
    margin-bottom: 13px;
    @media screen and (max-width: 500px) {
      padding: 0 40px;
    }
    span {
      color: #444B57;
      text-align: center;
      font-family: 'Source Sans Pro';
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
  .history {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: calc(100% - 88px);
    padding: 13px 5px 0px 10px;
    overflow-y: auto;
    @media screen and (max-width: 767px) {
      display: none;
    }
    .tableHistory {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 30px;
      flex-shrink: 0;
      width: 100%;
      border-radius: 4px;
      background: #FAFBFC;
      padding: 0;
      .item1  {
        width: 30%;
        justify-content: left;
        padding-left: 30px;
      }
      .item2  {
        width: 25%;
        padding-left: 30px;
      }
      .item3{
        width: 15%;
      }
      .item4 {
        width: 15%;
      }
      .item5 {
        width: 15%;
      }
      .item1t{
        width: 30%;
      }
      .item2t{
        width: 20%;
      }
      .item3t{
        width: 15%;
      }
      .item4t{
        width: 20%;
      }
      .item5t{
        width: 15%;
      }
      @media screen and (min-width: 768px) and (max-width: 1023px) {
      }
      @media screen and (max-width: 768px) {
        .item2  {
          width: 18%;
          padding-left: 0;
        }
        .item3{
          width: 20%;
        }
        .item4{
          width: 17%;
        }
      }
      &-header {
        display: flex;
        justify-content: center;
        align-items: center;
        @media screen and (min-width: 768px) and (max-width: 1023px) {

        }
        &-item {
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          margin-right: 8px;
          user-select: none;
          &-text {
            cursor: pointer;
            color: #444B57;
            font-family: 'Source Sans Pro';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            user-select: none;
            @media screen and (min-width: 768px) and (max-width: 1023px) {
              font-size: 12px;
            }
          }
        }
      }
      &-body {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 8px;
        height: 100%;
        .bItem1 {
          width: 30%;
          justify-content: left;
          padding-left: 42px;
        }
        .bItem2{
          width: 25%;
          padding-left: 42px;
        }
        .bItem3 {
          width: 15%;
        }
        .bItem4{
          width: 15%;
        }
        .bItem5 {
          width: 15%;
        }
        .bItem1t{
          width: 30%;
        }
        .bItem2t{
          width: 20%;
        }
        .bItem3t{
          width: 15%;
        }
        .bItem4t{
          width: 20%;
        }
        .bItem5t{
          width: 15%;
        }
        @media screen and (min-width: 768px) and (max-width: 1023px) {
        }
        @media screen and (max-width: 768px) {
          .bItem2{
            width: 18%;
            padding-left: 0;
          }
          .bItem3 {
            width: 20%;
          }
          .bItem4{
            width: 17%;
          }
        }
        &-row {
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: center;
          height: 40px;
          background: rgb(168 173 181 / 10%);
          border-radius: 4px;
          margin-bottom: 10px;
          padding: 0px
        }
        &-item {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          span {
            color: #444B57;
            font-family: 'Source Sans Pro';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            @media screen and (min-width: 768px) and (max-width: 1023px) {
              font-size: 12px;
              display: flex;
              align-items: center;
              justify-content: space-around;
            }
          }
          &-empty {
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;
            height: 40px;
            background: rgb(168 173 181 / 10%);
            border-radius: 4px;
            margin-bottom: 10px;
            span {
              color: #444B57;
              font-family: 'Source Sans Pro';
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
          &-button {
            width: 68px;
            height: 30px;
            border-radius: 4px;
            border: 1px solid #444B57;
            background: #FFF;
            flex-shrink: 0;
            cursor: pointer;
            span {
              color: #444B57;
              text-align: center;
              font-family: 'Source Sans Pro';
              font-size: 12px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
            }
            &:hover {
              background: #000;
              span {
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
  .historyMobile {
    display: none;
    align-items: center;
    flex-direction: column;
    height: calc(100% - 88px);
    padding: 20px;
    overflow-y: auto;
    @media screen and (max-width: 767px) {
      display: flex;
    }
    @media screen and (max-width: 460px) {
      padding: 10px 20px 20px 20px;
    }
    &-List {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      flex-direction: column;
      &-item {
        width: 100%;
        height: 110px;
        flex-shrink: 0;
        border-radius: 4px;
        background: rgba(168, 173, 181, 0.1);
        padding: 12px;
        margin-bottom: 10px;
        &-transaction-b{
          /* height: 155px; */
        }
        &-text {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          &-title {
            color: #444B57;
            font-family: 'Source Sans Pro';
            font-size: 12px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
          }
          &-value {
            color: #444B57;
            font-family: 'Source Sans Pro';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-bottom: 10px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 100%;
          }
          &-row {
            display: flex;
            gap: 15px;
            justify-content: space-between;
            width: 100%;
            &-text {
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: flex-start;
              width: 30%;
              &:last-child{
                align-self: center;
              }
              &-title {
                color: #444B57;
                font-family: 'Source Sans Pro';
                font-size: 12px;
                font-style: normal;
                font-weight: 300;
                line-height: normal;
                margin-bottom: 5px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                width: 100%;
              }
              &-value {
                color: #444B57;
                font-family: 'Source Sans Pro';
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                @media screen and (max-width: 500px) {
                  margin-bottom: 0px;
                }
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                width: 100%;
                &-eye {
                  width: 100%;
                  height: 30px;
                  flex-shrink: 0;
                  border-radius: 4px;
                  border: 1px solid #444B57;
                  background: #FFF;
                  cursor: pointer;
                  span {
                    color: #444B57;
                    text-align: center;
                    font-family: 'Source Sans Pro';
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                  }
                  @media screen and (max-width: 500px) {
                    margin-top: 15px;
                  }
                }
              }
            }
          }
        }
        &-empty {
          display: flex;
          width: 100%;
          justify-content: center;
          align-items: center;
          height: 164px;
          background: rgb(168 173 181 / 10%);
          border-radius: 4px;
          margin-bottom: 10px;
          span {
            color: #444B57;
            font-family: 'Source Sans Pro';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
      }
    }
  }
}
</style>
