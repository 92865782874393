<template>
  <div class="modal_redeem">
    <div class="close_container" @click="$emit('close')"><img src="@/assets/icons/polls/close_black.svg" alt=""></div>
    <img src="@/assets/icons/giftCards/redeem_gift.svg" alt="">
    <h2>Deseas redimir el bono de regalo?</h2>
    <div class="code_value">
      <div>Código: {{giftCard.code}}</div>
      <div>Valor: {{giftCard.value| currency('$ ', 0, { thousandsSeparator: ',' }) }}</div>
    </div>
    <div class="d-flex actions">
      <button @click="redeemGiftCard">Confirmar</button>
      <button @click="$emit('close')">Descartar</button>
    </div>
  </div>
</template>

<script>
export default {
  props: ['giftCard'],
  methods: {
    redeemGiftCard () {
      this.$emit('redeem', this.giftCard);
      this.$emit('close');
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/styles/giftCards/_modal_redeem";
</style>
