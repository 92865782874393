<template>
<div class="container-qualification" v-if="validateNotShowVendorTemporal()">
  <div class="container-qualification__card">
    <div class="container-qualification__card__info">
      <div class="d-flex text-center container-qualification__card__info__title">
          <div v-if="icon === 'general_qualification'">
            <img src="@/assets/icons/polls/general_qualification.svg" alt="">
          </div>
          <div v-if="icon === 'avg_qualification'">
            <img src="@/assets/icons/polls/avg_qualification.svg" alt="">
          </div>
          <h2 class="d-flex"> {{title}}  </h2>
      </div>
      <div v-if ="generalVendorQualify">
        <div class="position-relative text-center container-qualification__card__info__score">
          <img src="@/assets/icons/polls/progress_static.svg" alt="">
          <div>
            <label for="">Promedio</label>
            <span>{{ generalVendorQualify != null ? generalVendorQualify.toFixed(2) : 0}}</span>
          </div>
        </div>
      </div>
      <div class="">
        <div class="columns flex-flow-wrap" v-for="(data, i) in pullStars" :key="i">
          <div class="column columns is-12 container-qualification__card__info__rows is-mobile" v-for="(item, y) in data" :key="y">
            <div class="column is-4">{{item[0]}}</div>
            <div class="column is-6 d-flex" >
              <b-rate
                v-model="item[1]"
                :max="5"
                icon-pack="fa"
                :disabled="true"
              >
              </b-rate>
            </div>
            <div class="column is-2 d-flex" >
              {{ item[1].toFixed(2) }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-collapse
      aria-id="contentIdForA11y2"
      class="panel container-qualification__card__charts"
      animation="slide"
      position="is-bottom"
      v-model="isOpen"
      :open="false"
    >
      <template #trigger>
          <div
            class="container-collapse"
            role="button"
            aria-controls="contentIdForA11y2"
          >
            <img src="@/assets/icons/polls/arrow_down_collapse.svg" alt="" srcset="">
          </div>
      </template>
      <div class="container-qualification__card__charts__resume">
        <div class="container-qualification__card__charts__resume_total">
          <div class="total_qualifications columns is-mobile">
            <div class="column is-8">Total calificaciones:</div>
            <div class="column is-4">{{qualify}} </div>
          </div>
          <div class="total_qualifications columns is-mobile">
            <div class="column is-8">Total comentarios:</div>
            <div class="column is-4">{{comments}} </div>
          </div>
          <div class="total_qualifications columns is-mobile">
            <div class="column is-8">Total usuarios:</div>
            <div class="column is-4">{{users}} </div>
          </div>
        </div>
        <div class="container-qualification__card__charts__resume_chart"
          v-if="checkboxArray.length"
          v-for="(item, i) in checkboxArray"
          :key="i"
        >
            <h3 class="text-center">{{ item.label }}</h3>
            <div class="container-chart">
              <apexcharts type="donut" :options="item.options" :series="item.series"></apexcharts>
            </div>
        </div>
      </div>
    </b-collapse>
  </div>
</div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
export default {
  props: ['title', 'icon', 'generalVendorQualify', 'pullStars', 'pullStarsLength', 'qualify', 'comments', 'users', 'checkbox'],
  components: {
    apexcharts: VueApexCharts
  },
  data () {
    return {
      isOpen: false,
      options: {
      },
      series: [],
      checkboxArray: []
    };
  },
  methods: {
    validateNotShowVendorTemporal () {
      const id = this.decryptMx(this.vendorId);
      const index = [143, 144, 145, 226].find(vendor => vendor === id);
      if (index >= 0) {
        return;
      }
      return true;
    },
    getObjectKeysValues (obj, type, pos = null) {
      let objKey = null;
      if (type === 'keys') {
        objKey = Object.keys(obj);
        if (pos != null) {
          objKey = objKey[pos];
        }
        return objKey;
      } else {
        objKey = Object.values(obj);
        return objKey;
      }
    }
  },
  watch: {
    checkbox () {
      this.checkboxArray = [];
      Object.entries(this.checkbox).forEach(([key, value]) => {
        if (Array.isArray(value)) return;
        this.checkboxArray.push({
          label: key,
          options: {
            chart: {
              id: 'basic-bar'
            },
            labels: Object.keys(value),
            dataLabels: {
              enabled: true,
              distributed: false,
              formatter: function (val, opts) {
                return val.toFixed(0) + '%';
              }
            },
            legend: {
              position: 'left',
              fontSize: '12px',
              margin: '5px',
              fontWeight: 600,
              width: 100,
              height: 200,
              markers: {
                radius: 4,
                offsetX: 30
              },
              itemMargin: {
                vertical: 3
              }
            }
          },
          series: Object.values(value)
        });
      });
    }
  }
};
</script>

<style lang="scss">
  @import "@/assets/styles/polls/_card_poll_qualification";
</style>
