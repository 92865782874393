<template>
  <div class id="calendar-component">
    <div class="months-title">
      <div @click.prevent.stop="anteriorMes()" v-if="!loadingReservationCalendar">
        <em class="fa fa-chevron-left"></em>
      </div>
      <div>
        <span @click="showMonthChange()">{{monthName}}&nbsp;</span>
        <span @click="showYearChange()">{{yearName}}</span>
      </div>
      <div @click.prevent.stop="siguienteMes()" v-if="!loadingReservationCalendar">
        <em class="fa fa-chevron-right"></em>
      </div>
    </div>
    <div class="calendar" v-if="!showMonthAll && !showYearAll">
      <div :style="{color: vendorParams.toolbarColor}" class="header columns is-mobile">
        <div class="cell header column">
          <div class="cell header column">DOM</div>
        </div>
        <div class="cell header column">
          <div class="cell header column">LUN</div>
        </div>
        <div class="cell header column">
          <div class="cell header column">MAR</div>
        </div>
        <div class="cell header column">
          <div class="cell header column">MIE</div>
        </div>
        <div class="cell header column">
          <div class="cell header column">JUE</div>
        </div>
        <div class="cell header column">
          <div class="cell header column">VIE</div>
        </div>
        <div class="cell header column">
          <div class="cell header column">SAB</div>
        </div>
      </div>
      <div :key="row" class="header-row columns is-mobile" v-for="row in (isLargeMonth ? 6 : 5)">
        <div :key="cell" class="column" v-for="cell in 7">
          <div class="cell" v-show="!dates[row - 1][cell - 1]"></div>
          <div
            :class="{selected: timestamps[row - 1][cell - 1] === reservationDashboard.date, holiday: isDayHoliday(timestamps[row - 1][cell - 1]), isDisabled: timestamps[row -1][cell - 1] < todayTimes}"
            @click="setDateSelect(timestamps[row - 1][cell - 1])"
            class="cell date available pointer"
            v-if="dates[row - 1][cell - 1]"
          >
            <div
              :class="{daySelect: timestamps[row - 1][cell - 1] === reservationDashboard.date}"
              class="day"
            >{{days[row - 1][cell - 1]}}</div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showMonthAll">
      <div class>
        <div class>
          <span @click="showMonthAll = false" class>
            Cerrar
            <em class="fa fa-times-circle"></em>
          </span>
        </div>
      </div>
      <div class>
        <div class>
          <button @click="setChangeMonth(0)" class>Ene - {{ currentYear }}</button>
          <button @click="setChangeMonth(1)" class>Feb - {{ currentYear }}</button>
          <button @click="setChangeMonth(2)" class>Mar - {{ currentYear }}</button>
        </div>
      </div>
      <div class>
        <div class>
          <button @click="setChangeMonth(3)" class>Abr - {{ currentYear }}</button>
          <button @click="setChangeMonth(4)" class>May - {{ currentYear }}</button>
          <button @click="setChangeMonth(5)" class>Jun - {{ currentYear }}</button>
        </div>
      </div>
      <div class>
        <div class>
          <button @click="setChangeMonth(6)" class>Jul - {{ currentYear }}</button>
          <button @click="setChangeMonth(7)" class>Ago - {{ currentYear }}</button>
          <button @click="setChangeMonth(8)" class>Sep - {{ currentYear }}</button>
        </div>
      </div>
      <div class>
        <div class>
          <button @click="setChangeMonth(9)" class>Oct - {{ currentYear }}</button>
          <button @click="setChangeMonth(10)" class>Nov - {{ currentYear }}</button>
          <button @click="setChangeMonth(11)" class>Dic - {{ currentYear }}</button>
        </div>
      </div>
    </div>
    <div v-if="showYearAll">
      <div class>
        <div class>
          <select @change="setChangeYear(year)" class v-model="year">
            <option value>-- Seleccione --</option>
            <option value="2024">2024</option>
            <option value="2025">2025</option>
            <option value="2026">2026</option>
            <option value="2027">2027</option>
            <option value="2028">2028</option>
            <option value="2029">2029</option>
            <option value="2030">2030</option>
          </select>
        </div>
      </div>
      <div class>
        <div class>
          <span @click="showYearAll = false" class>
            Cerrar
            <em class="fa fa-times-circle"></em>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from 'lodash';

export default {
  props: ['selectDateTime'],
  data: () => ({
    currentMonth: null,
    currentYear: null,
    year: null,
    showMonthAll: false,
    showYearAll: false,
    todayTimes: null,
    timeCalendar: null
  }),
  mounted () {
    this.timeCalendar = this.selectDateTime;
    if (!this.timeCalendar) {
      const month = parseInt(this.$moment().format('M'));
      const date = this.monthConvert(month);
      this.currentMonth = date;
      const year = parseInt(this.$moment().format('YYYY'));
      this.currentYear = year;
      this.year = year;
      this.todayTimes = this.$moment().format('YYYY-MM-DD');
      return;
    }
    const dater = this.timeCalendar + 'T00:00:00';
    const month = parseInt(this.$moment(dater).format('M'));
    const date = this.monthConvert(month);
    this.currentMonth = date;
    this.todayTimes = this.$moment().format('YYYY-MM-DD');
    const year = parseInt(this.$moment(dater).format('YYYY'));
    this.currentYear = year;
    this.year = year;
  },
  computed: {
    monthName () {
      return this.$moment([this.currentYear, this.currentMonth, 1]).format('MMMM');
    },
    yearName () {
      return this.$moment([this.currentYear, this.currentMonth, 1]).format('YYYY');
    },
    dates () {
      const listDates = _.map(_.range(6), row => _.map(_.range(7), cell => {
        const initialDate = this.$moment([this.currentYear, this.currentMonth, 1]).startOf('month');
        const date = initialDate.clone().add(cell - initialDate.day() + (7 * row), 'days');
        return date.month() === initialDate.month() ? date : null;
      }));
      return listDates;
    },
    days () {
      return _.map(this.dates, row => _.map(row, cell => cell ? cell.format('D') : null));
    },
    timestamps () {
      return _.map(this.dates, row => _.map(row, cell => cell ? cell.format('YYYY-MM-DD') : null));
    },
    isLargeMonth () {
      return _.some(this.dates[5], date => !!date);
    }
  },
  methods: {
    setChangeMonth (month) {
      const monthNew = this.$moment([this.currentYear, month, 1]).format('M');
      this.currentMonth = this.monthConvert(monthNew);
      this.showMonthAll = false;
    },
    setChangeYear (year) {
      this.currentYear = parseInt(year);
      this.showYearAll = false;
    },
    showMonthChange () {
      this.showMonthAll = true;
    },
    showYearChange () {
      this.showYearAll = true;
    },
    anteriorMes () {
      this.currentMonth--;
      if (this.currentMonth < 0) {
        this.currentMonth = 11;
        this.currentYear--;
      }
    },
    siguienteMes () {
      this.currentMonth++;
      if (this.currentMonth > 11) {
        this.currentMonth = 0;
        this.currentYear++;
      }
    },
    setDateSelect (date) {
      this.$emit('clicked', true);
      if (this.timeCalendar === date) {
        this.timeCalendar = null;
        this.setChangeMonth(this.currentMonth);
      } else {
        this.timeCalendar = date;
        const datec = date + 'T00:00:00';
        const monthNew = this.$moment(datec).format('M');
        this.currentMonth = this.monthConvert(monthNew);
      }
      this.$emit('dateSelected', this.timeCalendar);
    }
  }
};
</script>
<style lang="scss">
#calendar-component {
  background: #444b57 !important;
  padding: 20px 10px 5px 10px;
  position: absolute;

  z-index: 99;
  top: 100%;
  right: 0;
  border-radius: 10px;
  font-size: 12px;
  .columns {
    margin: 0;
    padding: 0;
    &:first-child {
      margin: 0 !important;
    }
    &:last-child {
      margin: 0 !important;
    }
    .column {
      margin: 0;
      padding: 0;
    }
  }

  .months-title {
    display: flex;
    font-size: 12px;
    text-transform: uppercase;
    align-items: center;
    justify-content: center;
    text-indent: 0;
    line-height: 0;
    color: #fff;
    em {
      padding: 5px;
      font-size: 15px;
      padding: 0px 10px;
      margin-top: 1px;
    }
  }
  .calendar {
    .header {
      color: #fff;
      margin: 5px 0 3px !important;
      display: flex;
      text-align: center;
      justify-content: center;
      padding: 2px !important;
    }
    .cell {
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px;
      margin: 0px;
      &.selected {
        color: white;
        background: #411d39;
        border-radius: 50%;
        border: 1px solid #fff;
        .dot-text2 {
          top: -4px !important;
          right: -4px !important;
        }
      }
      .daySelect {
        font-weight: 500;
      }
      .day {
        line-height: 0;
      }
    }
    .cell.date {
      position: relative;
      color: #fff;
      font-weight: normal;
      cursor: pointer;
      .dot-text2 {
        cursor: pointer;
        border: inherit !important;
        border-radius: 50%;
        width: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        flex-direction: column;
        position: absolute;

        padding: 6px 4px 7px 4px;
        line-height: 0;
        font-size: 7px;
        top: -3px;
        right: -3px;
        height: 15px;
        width: 15px;
        background: #411d39;
        transition: 0.1s;
      }
    }
  }

  .today {
    color: #fff;
    border: 1px solid #fff;
    position: absolute;

    top: 23px;
    right: 1rem;
    padding: 2px 8px;
    border-radius: 6px;
    font-size: 10px;
  }
  .load-reservations {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 340px;
    p {
      color: #fff;
      font-weight: normal;
      font-size: 20px;
    }
  }

  .isDisabled {
    opacity: 0.5;
    pointer-events: none;
  }
}
</style>
