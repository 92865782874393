<template>
  <div id="select-personalizado" @mouseleave="opened = false">
    <div class="select-personalizado" :class="{selectorOpen:opened}">
      <div class="select-personalizado-icon-left with-selector" :class="{'select-input-error': errors.has(field)}" v-if="iconLeft">
        <img src="@/assets/icons/addReservation/allergy.svg" alt="icon" style="width: 16px;" v-if="iconLeft == 'Alergias'">
        <img src="@/assets/icons/addReservation/reservation.svg" alt="icon" style="width: 18px;" v-if="iconLeft == 'origins'">
        <img src="@/assets/icons/addReservation/company.svg" alt="icon" style="width: 16px;" v-if="iconLeft == 'company'">
        <img src="@/assets/icons/addReservation/usuario.svg" alt="icon" style="width: 16px;" v-if="iconLeft == 'people'">
        <img src="@/assets/icons/addReservation/clock.svg" alt="icon" style="width: 16px;" v-if="iconLeft == 'clock'">
      </div>
      <div class="select-personalizado-icon-selector" :class="{'select-input-error': errors.has(field)}">
        <div class="dropdown-list"  @click="opened = !opened">
          <div class="dropdown-list-left" :class="{comeFrom:typeData == 'company' || typeData == 'people'}">
            {{ (value) ? value : placeholder }}
          </div>
          <div>
            <img src="@/assets/icons/addReservation/arrow-down.svg" alt="banderas" v-if="!opened">
            <img src="@/assets/icons/addReservation/arrow-up-big.svg" alt="banderas" v-if="opened">
          </div>
        </div>
        <div class="dropdown-list-content" :style="{display: opened ? 'flex' : 'none', zIndex: 7}" v-if="typeData == 'company'">
                <div class="filterFlagsContainer">
        <input type="text" class="filterFlags" @input="(e)=>filter=e.target.value ">
        <span></span>
      </div>
          <div v-for="(itemType, IType) in companies" :key="'companies#' + IType" class="dropdown-list-content-group">
            <div class="small_indent"> <b> {{itemType.group}}</b><button class="buttonAddPersons" v-show="itemType.item.filter((e)=>e.name.toLowerCase().includes(filter.toLowerCase())).length<1" @click="addCompany(filter,itemType.group)"><p>Agregar</p> <span>+</span></button></div>
            <div class="dropdown-list-content-item" v-for="(name, ITName) in itemType.item.filter((e)=>e.name.toLowerCase().includes(filter.toLowerCase()))" :key="'companies#' + ITName" @click="value = name.name, opened = false,valueToEmit={id:name.id,name:name.name}">
            <div class="dropdown-list-content-item-left">
              {{ name.name }}
            </div>
          </div>
          </div>
        </div>
        <div class="dropdown-list-content" :style="{display: opened ? 'flex' : 'none', zIndex: 6}" v-if="typeData == 'origins'">
          <div class="dropdown-list-content-item" v-for="(itemType, IType) in origins" :key="'origins#' + IType" @click="value = itemType, opened = false">
            <div class="dropdown-list-content-item-left">
              {{ itemType }}
            </div>
          </div>
        </div>
                <div class="dropdown-list-content" :style="{display: opened ? 'flex' : 'none', zIndex: 6}" v-if="typeData == 'clock'">
          <div class="dropdown-list-content-item" v-for="(itemType, IType) in times" :key="'origins#' + IType" @click="value = itemType, opened = false">
            <div class="dropdown-list-content-item-left">
              {{ itemType }}
            </div>
          </div>
        </div>
        <div class="dropdown-list-content" :style="{display: opened ? 'flex' : 'none', zIndex: 5}" v-if="typeData == 'Alergias'">
          <div class="dropdown-list-content-item" v-for="(itemType, IType) in allergies" :key="'allergies#' + IType" @click="value = itemType.description, opened = false">
            <div class="dropdown-list-content-item-left">
              {{ itemType.description }}
            </div>
          </div>
        </div>
        <div class="dropdown-list-content" :style="{display: opened ? 'flex' : 'none', zIndex: 4}" v-if="typeData == 'people'">
                          <div class="filterFlagsContainer">
        <input type="text" class="filterFlags" @input="(e)=>filter=e.target.value ">
        <span></span>
      </div>
          <div class="dropdown-list-content-item" v-for="(itemType, IType) in peoples.filter((e)=>e.name.toLowerCase().includes(filter.toLowerCase()))" :key="'peoples#' + IType" @click="value = itemType.name, opened = false,valueToEmit={id:itemType.id,name:itemType.name}">
            <div class="dropdown-list-content-item-left">
              {{ itemType.name }}
            </div>
          </div>
            <div v-if="peoples.filter((e)=>e.name.toLowerCase().includes(filter.toLowerCase())).length<1" class="dropdown-list-content-item addItemPersons" ><button class="buttonAddPersons" @click="addPerson(filter)"><p>Agregar</p> <span>+</span></button></div>
        </div>
        <div class="dropdown-list-content" :style="{display: opened ? 'flex' : 'none', zIndex: 2}" v-if="typeData == 'referrer'">
          <div class="dropdown-list-content-item" v-for="(itemType, IType) in referrers" :key="'referrers#' + IType" @click="value = itemType, opened = false">
            <div class="dropdown-list-content-item-left">
              {{ itemType }}
            </div>
          </div>
        </div>
        <div class="dropdown-list-content" :style="{display: opened ? 'flex' : 'none', zIndex: 3}" v-if="typeData == 'experience'">
          <div class="dropdown-list-content-item" v-for="(itemType, IType) in experiences" :key="'experiences#' + IType" @click="value = itemType, opened = false">
            <div class="dropdown-list-content-item-left">
              {{ itemType }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <span class="text-error" v-if="errors.has(field)">* {{ errors.first(field) }}</span>
  </div>
</template>
<script>
export default {
  props: {
    iconLeft: {
      type: String
    },
    placeholder: {
      type: String
    },
    field: {
      type: String
    },
    typeData: {
      type: String
    },
    info: {
      type: Object
    },
    options: {
      type: Array
    },
    initValue: {
      type: String
    }
  },
  data () {
    return {
      value: '',
      companies: [],
      allergies: [],
      origins: [],
      referrers: [],
      experiences: [],
      peoples: [],
      times: [],
      opened: false,
      valueToEmit: null,
      filter: ''
    };
  },
  beforeMount () {
    if (this.typeData === 'company') {
      this.companies = this.options;
      if (this.initValue) {
        this.value = this.initValue;
      }
    }
    if (this.typeData === 'Alergias') {
      this.allergies = this.options;
      if (this.initValue) {
        this.value = this.initValue;
      }
    }
    if (this.typeData === 'origins') {
      this.origins = this.options;
      if (this.initValue) {
        this.value = this.initValue;
      }
    }
    if (this.typeData === 'clock') {
      this.times = this.options;
      if (this.initValue) {
        this.value = this.initValue;
      }
    }
    if (this.typeData === 'people') {
      this.peoples = this.options;
      if (this.initValue) {
        this.value = this.initValue;
      }
    }
    if (this.typeData === 'referrer') {
      this.referrers = this.options;
      if (this.initValue) {
        this.value = this.initValue;
      }
    }
    if (this.typeData === 'experience') {
      this.experiences = this.options;
      if (this.initValue) {
        this.value = this.initValue;
      }
    }
  },
  methods: {
    showList () {
      this.opened = !this.opened;
    },
    addCompany (companyName, companyType) {
      this.$emit('addNewCompany', { companyName, companyType });
    },
    addPerson (personName) {
      this.$emit('addNewPerson', personName);
    }

  },
  watch: {
    value () {
      if (this.valueToEmit) {
        this.$emit('valueChange', { [this.field]: this.valueToEmit });
      } else {
        this.$emit('valueChange', { [this.field]: this.value });
      }
    },
    initValue (value) {
      this.value = value;
    },
    options () {
      if (this.typeData === 'company') {
        this.companies = this.options;
        if (this.initValue) {
          this.value = this.initValue;
          if (this.typeData === 'company') {
            this.companies = this.options;
            this.options.forEach(group => {
              const index = group.item.findIndex((e) => e.name === this.value);
              if (index >= 0) {
                this.valueToEmit = { id: group.item[index].id, name: this.value };
                this.$emit('valueChange', { [this.field]: this.valueToEmit });
              }
            });
          }
        }
      }
      if (this.typeData === 'Alergias') {
        this.allergies = this.options;
        if (this.initValue) {
          this.value = this.initValue;
        }
      }
      if (this.typeData === 'origins') {
        this.origins = this.options;
        if (this.initValue) {
          this.value = this.initValue;
        }
      }
      if (this.typeData === 'people') {
        this.peoples = this.options;
        if (this.initValue) {
          this.value = this.initValue;
          if (this.typeData === 'people') {
            this.peoples = this.options;
            const index = this.peoples.findIndex((e) => e.name === this.value);
            if (index >= 0) {
              this.valueToEmit = { id: this.peoples[index].id, name: this.value };
              this.$emit('valueChange', { [this.field]: this.valueToEmit });
            }
          }
        }
      }
      if (this.typeData === 'referrer') {
        this.referrers = this.options;
        if (this.initValue) {
          this.value = this.initValue;
        }
      }
      if (this.typeData === 'experience') {
        this.experiences = this.options;
        if (this.initValue) {
          this.value = this.initValue;
        }
      }
    }
  }
};
</script>
<style lang="scss">
.addItem{
  padding-left: 10px;
  font-size: 14px;
}
  .filterFlags{
    border: unset;
    outline: none;
  }
.filterFlagsContainer{
  background-color: #ECEDEE;
  padding: 2px;
  display: flex;
  align-items: center;
  width: 93%;
  min-height: 25px;
  margin-top: 5px;
  margin-bottom: 5px;
      border-radius: 3px;
      overflow: hidden;

  input{
    height: 100%;
    width: 100%;
    background-color: transparent;
          color: #606773;

  }
  span{
    background-image: url('../../assets/icons/searchIcon.svg');
    background-size: contain;
    background-repeat: no-repeat;
    display: block;
    width: 15px;
    height: 14px;
    position: relative;
    right: 3px;
  }
}
.small_indent{
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  width: 93%;
  margin: auto;
  color: #606773;
  padding-top: 4px;
  padding-bottom: 5px;
  .buttonAddPersons{
  width: 70%;
  @media screen and (max-width:1023px) {
    width: 60%;
  }
  }
}
.dropdown-list-content-group{
  width: 100%;
}
.rotate{
  transform:rotate(180deg)
}
#select-personalizado {
  margin-bottom: 0.7rem;
  position: relative;
  .select-personalizado {
    display: flex;
    width: 100%;
    height: 40px;
    &.selectorOpen{
      &::after{
  content: " ";
  background-color: #A8ADB5;
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  height: 1px;
  margin: auto;
  width: 91%;
  display: block;
  transform: translateX(-4px);
}
    }
    &-icon-selector {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 40px;
      width: 100%;
      border-right: 1px solid #444B57;;
      border-top: 1px solid #444B57;;
      border-bottom: 1px solid #444B57;;  //! a tener en cuenta
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
      .dropdown-list {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding-right: 0.5rem;
        cursor: pointer;
        img {
          cursor: pointer;
          width: 15px;
          height: 7px;
        }
        user-select: none;

              @media (max-width: 400px) {
        &-left {
font-size: 0.7rem;
        }
  }
}

      .dropdown-list-content {
        position: absolute;
        top: 40px;
        left: 0px;
        background-color: #ffffff;
        width: 100%;
        overflow-y: auto;
        max-height: 125px;
        display: none;
        justify-content: flex-start;
        align-items: center;
        z-index: 1;
        flex-direction: column;
        border: 1px solid #444B57;

        border-top: unset;
        border-radius: 6px;
                border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        user-select: none;
        /* width */
&::-webkit-scrollbar {
  width: 10px;
}

/* Track */
&::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
&::-webkit-scrollbar-thumb {
  background: #606773 !important;
  box-shadow: unset !important;
  border: unset;
}

/* Handle on hover */
&::-webkit-scrollbar-thumb:hover {
  background: #555;
}
        &-item {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          width: 93%;
          height: 40px;
          margin: auto;
          cursor: pointer;
          user-select: none;
          &-left {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 40px;
            user-select: none;
          }
          &:hover {
            background-color: #ddd;
          }
        }
      }
    }
    &-icon-left {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      width: 50px;
      border-left: 1px solid #444B57;;
      border-top: 1px solid #444B57;;
      border-bottom: 1px solid #444B57;; //! otro a tener en cuenta
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }
    &.selectorOpen{
      .select-personalizado-icon-selector{
        border-bottom:  solid 1px transparent;
              border-bottom-right-radius: 0px;
      }
      .select-personalizado-icon-left{
        border-bottom:  solid 1px transparent;
        border-bottom-left-radius: 0px;
      }
    }
    &-center {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      width: 100%;
      &-text {
        width: 100%;
        height: 40px;
        border-left: none;
        border-top: 1px solid rgba(68, 75, 87, .5);;
        border-bottom: 1px solid rgba(68, 75, 87, .5);;
        border-right: none;
      }
    }
    &-icon-right {
      display: flex;
      justify-content: center;
      flex-direction: row;
      align-items: center;
      height: 40px;
      width: 60px;
      border-left: none;
      border-top: 1px solid rgba(68, 75, 87, .5);;
      border-bottom: 1px solid #444B57;
      border-right: 1px solid #444B57;
      position: relative;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
      &-item {
        display: flex;
        border-left: 1px solid rgba(68, 75, 87, .5);;
        width: 60px;
        justify-content: center;
        align-items: center;
        height: 30px;
        cursor: pointer;
      }
    }
    .borderRight {
      border-right: 1px solid rgba(68, 75, 87, .5)!important;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
      .select-personalizado-center-text {
        margin-right: 3px;
      }
    }
    &-bandera {
      height: 25px;
      width: 25px;
      border-radius: 50%;
      margin-left: 20px;
    }
  }
  .text-error {
    color: red;
    font-family: 'Roboto';
    font-size: 12px;
  }
  .select-input-error {
    border-color: red;
  }
  .select-input-item {
    border: none;
    height: 30px;
    width: 100%;
  }
  .select-personalizado-icon-selector .dropdown-list-content-item.addItemPersons{
  display: flex;
  align-items: center;
  justify-content: center;
}
}
.dropdown-list-left{
    font-size: 14px;
  @media screen and (max-width:600px) {
    font-size: 12px;
  }
 &.comeFrom{
    @media screen and (min-width:850px) and (max-width:1100px) {
      font-size: 10.1px;
    }
 }
}

.buttonAddPersons{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 28px;
  background-color:#444B57 ;
  color: white;
  border-radius: 5px;
  outline: unset;
  border: unset;
  gap: 15px;
  padding: 4px;
  cursor: pointer;

  p{
  font-size: 16px;
  }
  span{
    font-weight: bolder;
    color: white;
    font-size: 25px;
    transform: translateY(-2px);
  }
}
</style>
