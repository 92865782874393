<template>
    <div class="user-item" :class="{ last: last }"  @click="$emit('showEditUser')">
        <div class="mini-avatar">
            <div>
                {{ getInitials(userItem.name)}}
            </div>
        </div>
        <div>
            <div class="subuser-item">
                {{ userItem.name }}
            </div>
            <div v-if="userItem?.phone" class="subuser-item">
                <span class="mdi mdi-cellphone u-icon"></span>
                {{ userItem.phone }}
            </div>
            <div v-if="userItem?.email" class="subuser-item">
                <span class="mdi mdi-email-outline u-icon"></span>
                {{ userItem.email }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
  props: {
    userItem: {
      type: Object,
      required: true
    },
    last: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped>
.mini-avatar {
    box-sizing:border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    color: #444b57;
    cursor: pointer;
    font-size: 1.25rem;
    font-weight: 600;
    margin: 0 8px;
    width: 52px;
    height: 52px;
    text-transform: uppercase;
    border: 2px solid #00ADC6;
}
.user-item {
    display: flex;
    padding: 10px 0px;
    align-items: center;
    border-top: 1px solid #e0e0e0;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
}
.subuser-item {
    display: flex;
    align-items: center;
    margin-top: 0.01rem;
}
.u-icon {
    margin-right: 5px;
}

.last {
    border-bottom: 1px solid #e0e0e0;
    margin-bottom: 25px;
}
</style>
