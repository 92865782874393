import { render, staticRenderFns } from "./Check.vue?vue&type=template&id=42b58fc6"
import script from "./Check.vue?vue&type=script&lang=js"
export * from "./Check.vue?vue&type=script&lang=js"
import style0 from "./Check.vue?vue&type=style&index=0&id=42b58fc6&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports