const reservationCodes = {
  0: null, // no confirmada
  1: 'isConfirmed',
  2: 'isSeated',
  3: 'isFinish',
  4: 'isNoshow',
  5: 'isCancelled',
  6: 'isPartialSeated',
  7: null // esperando pago
};

export { reservationCodes };
