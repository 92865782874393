<template>
  <div id="PseMarketing">
    <b-loading :can-cancel="true" :is-full-page="true" :active.sync="loading"></b-loading>
    <div
      class="payInfo__debitInformation"
      id="precompraPlatform"
    >
      <div class="select-bank-title">
        <span>Escoge la etidad bancaria</span>
      </div>
      <div class="selectorContent--two">
        <Selector
          :options="banks"
          :placeHolder="'Selecciona tu banco'"
          :required="true"
          @value="(e) => (bank = e, setProvider(e))"
        />
      </div>
      <input placeholder="Nombre" required type="text" v-model="name" id="namePse"/>
      <div class="selectorContentDocoument">
        <inputSelector
          @validatedRulesMax="maxLenChange"
          @validatedRulesMin="minLenChange"
          @value="(e) => (typePerson = e.countrieInfo, documentNumber = e.inputValue)"
          :mode="'identify'"
        />
      </div>
      <div class="inputContainerC">
        <inputSelector
          @validatedRulesMax="maxLenChange"
          @validatedRulesMin="minLenChange"
          @value="inputNumberChange"
        />
      </div>
      <input placeholder="Correo" required type="email" id="emailPse" v-model="email" />
      <section class="bottomPart">
        <div class="switchesContainer">
          <div>
            <SwitchBool :value="personaNatural" @change="(e) => (personaNatural = e)" />
            <span>Persona Natural</span>
          </div>
          <div>
            <SwitchBool :value="personaJuridica" @change="(e) => (personaJuridica = e)" />
            <span>Persona Juridica</span>
          </div>
        </div>
        <div class="securePay">
          <p>Pagos seguros a través de</p>
          <span :class="{ mercadoPagoImg: !payu, payuImg: payu }"></span>
        </div>
      </section>
    </div>
    <div class="smsTermsOfConditions">
      <div class="smsTermsOfConditions-info">
        <Box v-if="termsConditions" @changeOpt="termsConditions = $event" :valueFaul="termsConditions"></Box>
        <img src="@/assets/icons/marketing/noBox.svg" @click="termsConditions = !termsConditions" class="items-check" alt="precompro.com" v-else>
        <span>Al darle clic en “Pagar” y continuar declara que conoce y acepta
          el tratamiento de datos personales previamente informado y está aceptando los
          <strong style="cursor: pointer;" @click="showModalTerms()">Términos y condiciones</strong> y la <strong style="cursor: pointer;" @click="showModalPol()">política de privacidad*</strong>
          de Precompro y del restaurante.
        </span>
      </div>
    </div>
    <button style="width: 100%;" :class="{ readyToSubmit: submitReady }" @click="postPSETransaction()">Pagar</button>
    <div id="modal-terms">
      <b-modal :active.sync="showTerms">
        <div class="terms-body">
          <h3 class="title-modal">Política Tratamiento de Datos Personales de Click2buy S.A.S.</h3>
          <terms :fecha="'22 de Noviembre del 2019'"></terms>
        </div>
      </b-modal>
    </div>
    <div id="modal-terms">
      <b-modal :active.sync="showTermsCondiciones">
        <div class="terms-body">
          <h3 class="title-modal">Términos y condiciones</h3>
          <terms2 :fecha="'22 de Noviembre del 2019'"></terms2>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
import _ from 'lodash';
import Selector from '@/components/Marketing/GeneralSelector.vue';
import SwitchBool from '@/components/Marketing/SwitchBool.vue';
import Box from '@/components/Marketing/Box';
import terms from '@/components/Marketing/Terms';
import terms2 from '@/components/Marketing/Terms2';
import inputSelector from './NumberInput.vue';
import servicePayment from '@/helpers/apis/servicePayment.js';
import api from '@/helpers/api.js';
import marketingTypes from '@/store/types/marketing';
import { v4 as uuidv4 } from 'uuid';
export default {
  name: 'precompraPlatform',
  components: {
    SwitchBool,
    Selector,
    inputSelector,
    Box,
    terms,
    terms2
  },
  data () {
    return {
      showTerms: false,
      showTermsCondiciones: false,
      showtermsOne: false,
      showtermsTwo: false,
      loading: false,
      showPse: false,
      banks: [],
      bank: '',
      monthOptions: [],
      yearOptions: [],
      termsConditions: true,
      pse: {
        transaction_amount: 0,
        net_amount: 0,
        binary_mode: true,
        taxes: [
          {
            value: 0,
            type: 'IVA'
          }
        ],
        description: null,
        provider: null,
        external_reference: null,
        payment_method_id: 'pse',
        payment_type_id: 'bank_transfer',
        payer: {
          email: null,
          entity_type: null,
          identification: {
            type: null,
            number: null
          }
        },
        transaction_details: {
          financial_institution: null
        },
        callback_url: null,
        vendorId: null,
        phone: null,
        name: null
      },
      psePAYU: {
        bank: null,
        provider: null,
        name: null,
        email: null,
        typeDocument: null,
        identification: null,
        phone: null,
        balance: 0,
        typePeople: null,
        url: null,
        vendorId: null,
        referencia: null,
        description: null
      },
      typePerson: ' ',
      minDocumentnumbers: 9,
      maxDocumentnumbers: 9,
      documentNumber: '',
      email: '',
      name: '',
      client: {
        name: '',
        email: '',
        phone: '',
        identification: ''
      },
      payu: true,
      personaNatural: true,
      personaJuridica: false,
      phone: '',
      country: 'Colombia',
      bandera: 'https://api.precompro.com/banderas/co.png',
      indicativo: 57,
      balancePaid: 0,
      provider: null,
      minLength: 10,
      maxLength: 10
    };
  },
  mounted () {
    this.$store.commit(marketingTypes.mutations.setPackage);
    this.payu = this.params.paymentTypePSE === 'PAYU';
    this.getPaymentOptionsPSE();
    if (this.vendor !== undefined) {
      if (this.vendor.timezone) {
        if (this.vendor.timezone !== 'America/Bogota') {
          this.$store.dispatch({ type: 'global:getServerTime' }).then((data) => {
            this.serverTimeCurrent = this.$moment(data.data.datetime);
          });
        } else {
          this.$store.dispatch({ type: 'global:getServerTime', data: { timezone: this.vendor.timezone } }).then((data) => {
            this.serverTimeCurrent = this.$moment(data.data.datetime);
          });
        }
      }
    }
    this.balancePaid = parseInt(this.package.total);
  },
  methods: {
    showModalTerms () {
      this.showTermsCondiciones = true;
    },
    showModalPol () {
      this.showTerms = true;
    },
    inputNumberChange (value) {
      this.bandera = value.countrieInfo.bandera;
      this.indicativo = value.countrieInfo.indicativo;
      this.country = value.countrieInfo.pais;
      this.phone = value.inputValue;
    },
    minLenChange (value) {
      this.minLength = value;
    },
    maxLenChange (value) {
      this.maxLength = value;
    },
    getPaymentOptionsPSE () {
      api.get('payment/banks/' + this.vendor.id).then(({ data }) => {
        if (data.code === 200) {
          const info = _.find(data.data, { id: 'pse' });
          if (info) {
            this.showPse = true;
          } else {
            this.showPse = false;
          }
          info.financial_institutions.forEach((bank) => {
            this.banks.push({ value: bank.id, name: bank.description });
          });
        } else {
          this.banks = [];
          this.showPse = false;
        }
      });
    },
    postPayuPSE () {
      this.submitDisabled = true;
      this.loading = true;
      servicePayment.post('/payu/marketing/pse', this.psePAYU).then(({ data }) => {
        if (data.code === 799) {
          this.loading = false;
          this.submitDisabled = false;
          this.$buefy.toast.open({
            duration: 8000,
            message: data.message,
            type: 'is-danger',
            position: 'is-top'
          });
        }
        if (data.transactionResponse.state === 'PENDING') {
          window.location.href = data.transactionResponse.extraParameters.BANK_URL;
        }
      }).catch((err) => {
        this.loading = false;
        this.submitDisabled = false;
        this.$buefy.toast.open({
          duration: 8000,
          message: JSON.parse(err.request.response).message || 'Error en la petición',
          type: 'is-danger',
          position: 'is-top'
        });
      });
    },
    postmercadoPagoPSE () {
      this.submitDisabled = true;
      this.loading = true;
      servicePayment.post('/mercadopago/marketing/pse', this.pse).then(({ data }) => {
        if (data.code === 799) {
          this.loading = false;
          this.submitDisabled = false;
          this.$buefy.toast.open({
            duration: 8000,
            message: data.message,
            type: 'is-danger',
            position: 'is-top'
          });
        }
        if (data.code === 200) {
          window.location.href = data.url;
        }
      }).catch((err) => {
        this.loading = false;
        this.submitDisabled = false;
        this.$buefy.toast.open({
          duration: 8000,
          message: JSON.parse(err.request.response).message || 'Error en la petición',
          type: 'is-danger',
          position: 'is-top'
        });
      });
    },
    setProvider (id) {
      const index = this.banks.findIndex((e) => parseInt(e.value) === parseInt(id));
      if (index > -1) {
        this.provider = this.banks[index].name;
      }
    },
    postPSETransaction () {
      if (!this.termsConditions) {
        this.$buefy.toast.open({
          duration: 5000,
          message: 'debes aceptar los términos y condiciones , al igual que el tratamiento de datos personales',
          type: 'is-warning',
          position: 'is-bottom',
          queue: false
        });
      } else {
        if (this.payu) {
          this.psePAYU.bank = this.bank;
          this.psePAYU.provider = this.provider;
          this.psePAYU.name = this.name;
          this.psePAYU.email = this.email;
          this.psePAYU.typeDocument = this.typePerson;
          this.psePAYU.identification = this.documentNumber;
          this.psePAYU.phone = this.phone;
          this.psePAYU.indicativo = this.indicativo;
          this.psePAYU.bandera = this.bandera;
          this.psePAYU.country = this.country;
          this.psePAYU.typePeople = this.personaNatural ? 'N' : 'J';
          this.psePAYU.balance = this.balancePaid;
          this.psePAYU.url = window.location.href;
          this.psePAYU.vendorId = this.vendor.id;
          this.psePAYU.description = 'Compra paquete ' + this.package.type + ' para ' + this.vendor.displayName + ' por ' + this.package.quantity;
          this.psePAYU.referencia = 'package-' + this.vendor.displayName + '-' + this.package.type + '-' + uuidv4();
          this.psePAYU.package = this.package;
          this.postPayuPSE();
        } else {
          this.pse.payer.email = this.email;
          this.pse.provider = this.provider;
          this.pse.payer.identification.type = this.typePerson;
          this.pse.payer.identification.number = this.documentNumber;
          this.pse.payer.entity_type = this.personaNatural
            ? 'individual'
            : 'association';
          this.pse.transaction_details.financial_institution = this.bank;
          this.pse.transaction_amount = this.balancePaid;
          this.pse.net_amount = this.balancePaid;
          this.pse.callback_url = window.location.href + '?source_utm=mp';
          this.pse.vendorId = this.vendor.id;
          this.pse.description = 'Compra paquete ' + this.package.type + ' para ' + this.vendor.displayName + ' por ' + this.package.quantity;
          this.pse.external_reference = 'package-' + this.vendor.displayName + '-' + this.package.type + '-' + uuidv4();
          this.pse.name = this.name;
          this.pse.phone = this.phone;
          this.pse.indicativo = this.indicativo;
          this.pse.bandera = this.bandera;
          this.pse.country = this.country;
          this.pse.package = this.package;
          this.postmercadoPagoPSE();
        }
      }
    }
  },
  watch: {
    personaJuridica () {
      if (this.personaJuridica) {
        this.personaNatural = false;
      }
      if (!this.personaJuridica && !this.personaNatural) {
        this.personaNatural = true;
      }
    },
    personaNatural (value) {
      if (this.personaNatural) {
        this.personaJuridica = false;
      }
      if (!this.personaJuridica && !this.personaNatural) {
        this.personaJuridica = true;
      }
    }
  },
  computed: {
    submitReady () {
      if (
        this.name &&
        this.documentNumber &&
        (this.phone && this.phone.length >= this.minLength && this.phone.length <= this.maxLength) &&
        this.typePerson &&
        this.bank &&
        this.email
      ) {
        return true;
      }
      return false;
    }
  }
};
</script>
<style lang="scss" scoped>
@mixin imageSet($width, $height, $url) {
  background-image: url($url);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: $width;
  height: $height;
  display: block;
}
.cardSelected {
  box-shadow: 0px 0px 0px 3px white, 0px 0px 0px 4px black;
}
#PseMarketing {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  overflow: hidden;
  @media screen and (min-width: 1280px) {
    width: 900px;
  }
  @media screen and (min-width: 1024px) and (max-width: 1279px)  {
    width: 870px;
  }
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    width: 870px;
  }
  @media screen and (max-width: 768px) {
    width: 668px;
  }
  @media screen and (max-width: 500px) {
    width: 382px;
  }
  .smsTermsOfConditions {
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    margin-top: 20px;
    margin-bottom: 20px;
    @media screen and (max-width: 767px) {
      width: 370px;
    }
    @media screen and (min-width: 768px) and (max-width: 1023px) {
      width: 668px;
    }
    @media screen and (min-width: 1024px) {
      width: 100%;
    }
    &-info {
      justify-content: center;
      height: 60px;
      display: flex;
      align-items: flex-start;
      width: 100%;
      @media screen and (min-width: 768px) {
        height: 30px;
      }
      span {
        margin-left: 7px;
        color: #444B57;
        font-family: "Source Sans Pro";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
  #modal-terms {
    .animation-content.modal-content {
      max-width: 980px !important;
    }
    .terms-body {
      background: #fff;
      padding: 30px 40px 40px;
      color: #000;
      border-radius: 10px;

      .title-modal {
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 20px;
      }
    }
    .modal-close{
      display: block !important;
      position: absolute;

      &::after, &::before{
        background-color: #000;
      }
    }
    @media screen and (max-width:700px) {
      .terms-body {
        padding: 40px 0px;
        #terms-html2{
          padding: 0px 20px;
        }
      }
      .modal-close{
        top: 5px;
        right: 5px;
      }
    }
  }
}
@keyframes loader {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(2);
  }
  100% {
    transform: scale(1);
  }
}
#precompraPlatform {
  margin: 0 auto;
  margin-top: -0.5px;
  border: solid #102c3a 1px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 0px 10px 10px 10px;
  gap: 16px;
  height: 1px;
  justify-content: flex-start;
  height: -moz-fit-content;
  height: fit-content;
  position: relative;
  top: -1px;
  width: 100%;
  @media screen and (min-width: 1280px) {
  }
  @media screen and (min-width: 768px) and (max-width:1020px) {
  }
  @media screen and (max-width: 767px) {
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  @mixin imageSet($width, $height, $url) {
    background-image: url($url);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: $width;
    height: $height;
    display: block;
  }
  input {
    color: #444b57;
    font-size: 12px;
    border-radius: 5px;
    outline: unset;
    border: solid 1px #A1A5AB;
    min-height: 30px;
    text-indent: 10px;
  }
  .switchesContainer {
    width: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 50px;
    & > div {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 20px;
    }
    span {
      font-size: 12px;
    }
  }
  .select-bank-title {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin-top: 12px;
    span {
      color: #444B57;
      font-family: 'Source Sans Pro';
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
  .selectorContent--two {
    width: 100%;
    display: flex;
    flex: 1 100%;
  }
  .selectorContentDocoument {
    display: flex;
    flex: 3 45%;
    @media screen and (min-width: 768px) {
      flex: 3 25%;
    }
  }
  #namePse {
    display: flex;
    flex: 2 100%;
    @media screen and (min-width: 768px) {
      flex: 1 40%;
    }
  }
  #docPse {
    display: flex;
    flex: 4 45%;
    @media screen and (min-width: 768px) {
      flex: 4 25%;
    }
  }
  #emailPse {
    display: flex;
    flex: 6 100%;
    @media screen and (min-width: 768px) {
      flex: 6 60%;
    }
  }
  & input:nth-child(2) {
    width: 48%;
    @media screen and (max-width: 1000px) {
      min-width: 52%;
      flex: 1;
    }
    @media screen and (max-width: 600px) {
      min-width: 100%;
      flex: 1;
    }
  }
  & input:nth-child(4) {
    min-width: 20%;
    flex: 1;
    @media screen and (max-width: 1000px) {
      flex: unset;
      min-width: unset;
      width: 28%;
    }
    @media screen and (max-width: 600px) {
      min-width: 40%;
      flex: 1;
    }
  }
  & input:nth-child(5) {
    width: 28%;
    @media screen and (max-width: 1000px) {
      width: 33%;
    }
    @media screen and (max-width: 600px) {
      width: 50%;
    }
  }
  & div:nth-child(3) {
    width: 25%;
    @media screen and (max-width: 1000px) {
      width: 30%;
    }
    @media screen and (max-width: 600px) {
      width: 50%;
    }
  }
  & .inputContainerC {
    width: 22.5%;
    @media screen and (max-width: 1000px) {
      width: 30%;
    }
    @media screen and (max-width: 767px) {
      flex: 5 100%;
    }
    @media screen and (min-width: 768px) {
      flex: 5 25%;
    }
  }
  @media (min-width: 1020px) {
    overflow: hidden;
  }
  .generalInputContainer {
    width: 100%;
    @media (min-width: 768px) {
      width: 36%;
    }
    @media (min-width: 1024px) {
      width: 46%;
    }
  }
  font-family: "Source Sans Pro", sans-serif;
  .principalMain {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    @media (max-width: 1020px) {
      min-height: 86vh;
    }
    @media (min-width: 1024px) and (max-width: 1240px) {
      height: 82vh;
      align-items: start;
    }
    @media (min-width: 1224px) and (max-width: 1600px) {
      height: 82vh;
      align-items: flex-start;
    }
    @media (min-width: 1600px) {
      height: 82.8vh;
      align-items: flex-start;
    }

    @media (min-width: 1024px) {
      margin: unset;
      flex-direction: row;
      justify-content: space-evenly;
    }
    @media (min-width: 1280px) {
      justify-content: center;
      gap: 30px;
    }
    .payInfo--cantUse {
      pointer-events: none;
    }
    .information,
    .payInfo {
      margin-top: 10px;
      @media (min-width: 1024px) {
        margin-top: 40px;
        height: 464px;
        max-height: 464px;
        width: 463px;
        max-width: 463px;
      }
      @media (min-width: 1280px) {
        margin-top: 40px;
        height: 464px;
        max-height: 464px;
        width: 543px;
        max-width: 543px;
      }
      font-size: 10px;
      display: flex;
      align-items: center;
      flex-direction: column;
      padding: 5px;
      width: 96%;
      background-color: white;
      @media (max-width: 600px) {
        min-height: 485px;
        max-height: 455px;
      }

      border-radius: 8px;
      font-size: 14px;
      @media (min-width: 768px) and (max-width: 1020px) {
        max-width: 688px;
      }
      button {
        width: 60%;
        min-height: 30px;
        font-weight: 600;
        font-size: 12px;
        outline: unset;
        border: unset;
        color: white;
        background-color: #444b57;
        border-radius: 6px;
        opacity: 0.6;
        @media (min-width: 768px) {
          max-width: 230px;
        }
      }
    }
    .information {
      @media (min-width: 1024px) {
        margin-top: 40px;
        height: 464px;
        max-height: 464px;
        width: 481px;
        max-width: 481px;
      }
      @media (min-width: 1280px) {
        margin-top: 40px;
        height: 464px;
        max-height: 464px;
        width: 607px;
        max-width: 607px;
      }
      @media (min-width: 1280px) {
        width: 46%;
        max-width: 46%;
        height: 466px;
      }
      position: relative;
      header {
        padding: 7px 0 7px 0;
        border-bottom: solid 1px black;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 97%;
        .header__leftSide {
          width: fit-content;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          h2 {
            font-family: "Source Sans Pro", sans-serif;
            font-weight: 600;
            margin-left: 5px;
            font-size: 14px;
          }
        }
        .header__rightSide {
          h2 {
            font-family: "Source Sans Pro", sans-serif;
            font-size: 16px;
            font-weight: 600;
          }
          min-width: 52%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          @media (min-width: 768px) {
            min-width: 40%;
            justify-content: flex-end;
            gap: 15px;
          }
          p {
            color: #444b57;
            font-size: 10px;
            font-weight: 400;
            @media (min-width: 768px) {
              font-size: 12px;
            }
          }
        }
      }
      &__mainContent {
        width: 100%;
        @media (min-width: 768px) {
          display: flex;
          align-items: center;
          flex-direction: column;
        }
        .mainContent__cardContainer {
          padding: 1rem 0;
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          @media (min-width: 768px) and (max-width: 1020px) {
            width: 60%;
          }
          @media (min-width: 1020px) {
            width: 90%;
            padding-top: 28px;
          }
          @media (min-width: 1280px) {
            padding-top: 35px;
          }
          .cardContainer__card {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            flex-direction: column;
            border: solid 2px #eeefef;
            border-radius: 5px;
            width: 100px;
            height: 100px;
            h4 {
              font-size: 12px;
              font-weight: 400;
            }
            p {
              font-weight: 600;
            }
          }
        }
        .mainContent__detailsCardsContainer {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          margin: unset;
          @media (min-width: 768px) {
            width: 96%;
            justify-content: space-between;
          }
          .detailsCardsContainer__detail {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            flex-direction: row;
            font-size: 12px;
            @media (min-width: 768px) {
              font-size: 14px;
            }
            span {
              margin-left: 5px;
              font-weight: 600;
            }
          }
        }
        .mainContent__smallLetter {
          font-size: 11px;
          font-weight: 300;
          margin: 1rem 0;
          color: #444b57;
          @media (min-width: 768px) {
            width: 100%;
            font-size: 12px;
            margin-left: 20px;
          }
        }
        .mainContent__clientData {
          padding: 0.4rem;
          border-radius: 8px;
          background-color: #f5f6f6;
          display: flex;
          flex-direction: column;
          align-items: center;
          @media (min-width: 1024px) {
            margin-top: 20px;
            position: relative;
            bottom: 4px;
          }
          @media (min-width: 1224px) {
            padding-top: 25px;
          }
          @media (min-width: 1280px) {
            margin-top: 15px;
            padding-top: 5px;
          }
        }
      }
    }
    .payInfo {
      height: 464px;
      @media (min-width: 200px) and (max-width: 600px) {
        min-height: 515px;
      }
      @media (min-width: 720px) and (max-width: 1000px) {
        min-height: 450px;
        height: 455px;
      }
      @media (min-width: 1280px) {
        width: 46%;
        height: 466px;
      }
      @media (min-width: 1680px) {
        width: 46%;
        max-width: 40%;
        height: 466px;
      }
      &--cantUse {
        pointer-events: none;
        opacity: 0.8;
      }
      &__title {
        font-weight: 600;
        margin: 8px 0;
        font-family: "Source Sans Pro", sans-serif;
        @media (min-width: 768px) and (max-width: 1020px) {
        }
        @media (min-width: 1024px) {
          margin: 1px 0;
        }
      }
      button {
        cursor: not-allowed;
      }
      button:hover {
        filter: brightness(1.2);
      }
      .readyToSubmit {
        background-color: #bdc30e;
        color: white;
        cursor: pointer;
      }

      form {
        @media (min-width: 1024px) {
          padding-top: 12px;
        }
        padding: 15px 4px 0 4px;
        display: flex;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 12px;
        justify-content: center;
        padding-bottom: 1.8rem;
        padding: 20px 10px;
        .additionalInfo {
          & > p {
            font-size: 10px;
            font-weight: 300;
            width: 97%;
          }
        }
        .inputContainerC {
          width: 40%;
          z-index: 39;
          position: relative;
          input {
            width: 100%;
          }
          @media (min-width: 768px) and (max-width: 1020px) {
            width: 28%;
          }
          input {
            // padding-left: 25px;
          }
        }
      }
    }
  }
}
.bottomPart {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  @media screen and (max-width: 767px) {
    flex-direction: column;
    .securePay {
      margin-top: 15px;
    }
  }
  & .additionalInfo {
    width: 50%;
    @media screen and (max-width: 1000px) {
      width: 60%;
    }
    @media screen and (max-width: 600px) {
      min-width: 100%;
    }
  }
  p {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    line-height: 13px;

    color: #102c3a;
  }
}
button {
  background: #102c3a62;
  border-radius: 6px;
  width: 416px;
  color: white;
  height: 30px;
  margin: auto;
  border: unset;
  cursor: not-allowed;
}

.readyToSubmit {
  background: #102c3a;
  cursor: pointer;
}

.securePay {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 5px;
  .mercadoPagoImg {
    @include imageSet(40px, 28px, "../../../assets/icons/marketing/mercadoPagoImg.svg");
  }
  .payuImg {
    @include imageSet(40px, 28px, "../../../assets/icons/marketing/payuIcon.svg");
  }

  @media screen and (max-width: 600px) {
    min-width: 100%;
  }
}
#CardNumberInput {
  position: relative;
  &::after {
    position: absolute;
    background-color: red;
    width: 100%;
    height: 100%;
    display: block;
    left: 0;
    content: attr(afterContent) "";
  }
}
</style>
