<template>
  <div class="component-box component-box-people-and-boys">
    <div class="container-only-people" :class="{'container-only-people-full' : params.activeSelectorBoy === 0}">
      <div class="header-component">
        <i class="icon-mks black icon_person_add_reservation i-people-component"></i> No. de {{ params.activeSelectorBoy === 0 ? 'personas' : 'adultos'}}
        <PopUpLargeQuantity @setVisualQuantity="setVisualQuantity" type="people" @setQuantity="setPeople" />
      </div>
      <div class="people-component">
        <a href="javascript:void(0)" class="user-item" @click="peopleDel()" v-if="initial > 1">
          <i class="fa fa-minus"></i>
        </a>
        <a
          href="javascript:void(0)"
          class="user-item"
          v-for="peopleNumber in (peoples - deletePeople)"
          :key="peopleNumber"
          :class="{selectedPeople: ((params.activeSelectorBoy === 0 ? people : adults) == peopleNumber + addPeople)}" @click="setPeople(peopleNumber + addPeople)"
        >
          <span class="number-person">{{ peopleNumber + addPeople }}</span>
        </a>
        <a href="javascript:void(0)" class="user-item" @click="peopleAdd()">
          <!-- <i class="fa fa-plus"></i> -->
          <i class="icon-mks icon_new_plus_reservation"></i>
        </a>
      </div>
    </div>
    <div class="container-only-boys" v-if="params.activeSelectorBoy === 1">
      <div class="header-component">
        <i class="icon-mks icon_boys_add_reservation i-people-component"></i> No. de niños
        <PopUpLargeQuantity @setVisualQuantity="setVisualQuantity" type="boys" @setQuantity="setPeople" />
      </div>
      <div class="people-component">
        <a href="javascript:void(0)" class="user-item" @click="peopleDel('boys')" v-if="initialBoys > 1">
          <i class="fa fa-minus"></i>
        </a>
        <a href="javascript:void(0)" class="user-item" v-for="boysNumber in (peopleBoys - deleteBoys)" :key="boysNumber" :class="{selectedPeople: (boys == boysNumber + addBoys)}" @click="setPeople(boysNumber + addBoys, 'boys')">
          <span class="number-person">{{ boysNumber + addBoys }}</span>
        </a>
        <a href="javascript:void(0)" class="user-item" @click="peopleAdd('boys')">
          <i class="icon-mks icon_new_plus_reservation"></i>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import PopUpLargeQuantity from '@/components/_shared/FormReservation/PopUpLargeQuantity.vue';

export default {
  props: ['people', 'adults', 'boys'],
  components: {
    PopUpLargeQuantity
  },
  data () {
    return {
      initial: 1,
      initialBoys: 1,
      peoples: 7,
      peopleBoys: 4,
      addPeople: 0,
      deletePeople: 0,
      addBoys: 0,
      deleteBoys: 0,
      isMobile: false
    };
  },
  created () {
    window.addEventListener('resize', this.checkWindowSize);
    this.checkWindowSize();
  },
  destroyed () {
    window.removeEventListener('resize', this.checkWindowSize);
  },
  methods: {
    setListPeopleAdd (type) {
      if (type === 'people') {
        if (this.params.activeSelectorBoy === 1) {
          const deleteIncrement = (this.initial === 1) ? 7 : 6;
          const addIncrement = (this.initial === 1) ? 7 : 6;
          this.deletePeople += deleteIncrement;
          this.addPeople += addIncrement;
          this.peoples += 6;
          this.initial += 1;
        } else {
          const deleteIncrement = (this.initial === 1) ? 8 : 7;
          const addIncrement = (this.initial === 1) ? 8 : 7;
          this.deletePeople += deleteIncrement;
          this.addPeople += addIncrement;
          this.peoples += 7;
          this.initial += 1;
        }
      } else {
        let deleteIncrement = 0;
        let addIncrement = 0;
        if (this.isMobile) {
          deleteIncrement = (this.initialBoys === 1) ? 7 : 6;
          addIncrement = (this.initialBoys === 1) ? 7 : 6;
        } else {
          deleteIncrement = (this.initialBoys === 1) ? 4 : 3;
          addIncrement = (this.initialBoys === 1) ? 4 : 3;
        }
        this.deleteBoys += deleteIncrement;
        this.addBoys += addIncrement;
        this.peopleBoys += this.isMobile ? 6 : 3;
        this.initialBoys += 1;
      }
    },
    peopleAdd (type = 'people') {
      this.setListPeopleAdd(type);
    },
    peopleDel (type = 'people') {
      const isPeople = type === 'people';
      let deleteCount = null;
      if (this.params.activeSelectorBoy === 1) {
        deleteCount = (isPeople ? this.initial : this.initialBoys) <= 2 ? 7 : 6;
      } else {
        deleteCount = (isPeople ? this.initial : this.initialBoys) <= 2 ? 8 : 7;
      }
      let addCount = deleteCount;
      if (isPeople) {
        this.deletePeople -= deleteCount;
        this.addPeople -= addCount;
        if (this.params.activeSelectorBoy === 1) {
          this.peoples -= 6;
        } else {
          this.peoples -= 7;
        }
        this.initial -= 1;
      } else {
        if (this.isMobile) {
          deleteCount = (this.initialBoys <= 2) ? 7 : 6;
        } else {
          deleteCount = (this.initialBoys <= 2) ? 4 : 3;
        }
        addCount = deleteCount;
        this.deleteBoys -= deleteCount;
        this.addBoys -= addCount;
        this.peopleBoys -= this.isMobile ? 6 : 3;
        this.initialBoys -= 1;
      }
    },
    setPeople (value, type = 'people') {
      if (this.params.activeSelectorBoy === 1) {
        this.$emit('setAdultsBoys', type, value);
      } else {
        this.$emit('selected', value);
      }
    },
    checkWindowSize () {
      this.isMobile = window.innerWidth <= 600;
    },
    setVisualQuantity (value, type) {
      if (type === 'people') {
        if (this.params.activeSelectorBoy === 1) {
          if (value > 7 || value <= 7) {
            const valueRounded = (Math.round((value + 1) / 6) * 6) + 1;
            let newCalc = null;
            if (value <= 7) {
              newCalc = valueRounded - 7;
            } else {
              newCalc = valueRounded - 6;
            }
            this.peoples = valueRounded;
            this.addPeople = newCalc;
            this.deletePeople = newCalc;
            console.log(valueRounded, newCalc);
            this.initial = Math.round((value + 1) / 6);
          }
        } else {
          if (value <= 8) {
            this.peoples = 8;
            this.addPeople = 0;
            this.deletePeople = 0;
          } else {
            const block = Math.ceil(value / 7);
            const valueRounded = block * 7;
            const newCalc = valueRounded - 7;
            if (value <= 8) {
              this.peoples = 8;
              this.addPeople = 0;
              this.deletePeople = 0;
            } else {
              this.peoples = valueRounded + 1;
              this.addPeople = newCalc + 1;
              this.deletePeople = newCalc + 1;
            }
            this.initial = block;
          }
        }
      } else {
        const quantityForDelete = this.isMobile ? 6 : 3;
        if (value > quantityForDelete + 1) {
          const valueRounded = (Math.round((value + 1) / quantityForDelete) * quantityForDelete) + 1;
          this.peopleBoys = valueRounded;
          this.addBoys = valueRounded - quantityForDelete;
          this.deleteBoys = valueRounded - quantityForDelete;
          this.initialBoys = Math.round((value + 1) / quantityForDelete);
        }
      }
    }
  },
  mounted () {
    if (this.params.activeSelectorBoy === 1) {
      this.$emit('setAdultsBoys', 'people', this.adults);
      this.$emit('setAdultsBoys', 'boys', this.boys);
      if (this.adults > 7) {
        this.setVisualQuantity(this.adults, 'people');
      }
      if ((this.isMobile && this.boys > 7) || (!this.isMobile && this.boys > 4)) {
        this.setVisualQuantity(this.boys, 'boy');
      }
    } else {
      this.peoples = 8;
      if (this.people > 7) {
        this.setVisualQuantity(this.people, 'people');
      }
      this.$emit('selected', this.people);
    }
  },
  watch: {
    isMobile (value) {
      this.addBoys = 0;
      this.deleteBoys = 0;
      this.initial = 1;
      this.initialBoys = 1;
      if (value) {
        this.peopleBoys = 7;
      } else {
        this.peopleBoys = 4;
      }
    }
  }
};
</script>
<style scoped lang="scss">
.selectedPeople {
  background-color: #444B57!important;
  color: #fff!important;
  font-weight: 700!important;
}
.people-component {
  font-family: "Source Sans Pro"!important;
  display:flex;
  .fa-minus{
    font-size: 9px;
  }
  .user-item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    border-right: 1px solid #F0F1F2;
    flex:1;
    color:#444B57;
    /* font-family: 'Roboto'; */
    font-size: 11px;
    .number-person {
      font-size: 11px;
      font-weight: 600;
    }
    &:last-child {
      border-right: none;
    }
    i {
      /* height: 15px;
      width: 15px; */
      margin-top: 0px;
    }
  }
}
.i-people-component {
  height: 12px;
  width: 12px;
  margin-top: 1px;
}
@media only screen and (min-width: 1000px) and (max-width: 1280px) {
  .people-component {
    max-width: 341px;
    min-width: 100%;
  }
  .user-item {
    width: 50px;
    max-width: 60px;
  }
}
</style>
