<template>
  <b-modal :active.sync="changedPassword" class="" id="modalChnagePass">
    <div class="columns title-bar">
      <div class="column"></div>
      <div class="column center-box">
        <span>Cambiar Contraseña</span>
      </div>
      <div class="column close-later">
        <button class="close-btn" @click="isOpen = false" ><i class="fa fa-times"></i>Cerrar</button>
      </div>
    </div>
    <div class="content-pass-modal">
      <form autocomplete="off" @submit.prevent="validateBeforeSubmit">
        <div class="">
          <label>Nueva Contraseña</label>
          <div class="positionar">
            <span class=""><i class="fa fa-lock"></i></span>
            <input type="password" class="" id="password" :class="{ 'has-error' : errors.has('password') }" placeholder="Nueva Contraseña" autocomplete="off" ref="password" v-validate="'required|min:6'" name="password" v-model="password" @keypress="setMsgNull()">
          </div>
          <div class="error-s" v-if="errors.first('password') && msg === null" >
            <span class="">{{ errors.first('password') }}</span>
          </div>
        </div>
        <div class="">
          <label>Confirmar Contraseña</label>
          <div class="positionar">
            <span class="" ><i class="fa fa-lock"></i></span>
            <input type="password" class="" id="password_confirmation" :class="{ 'has-error' : errors.has('password_confirmation') }" placeholder="Confirmar Contraseña" autocomplete="off" v-validate="'required|confirmed:password'" name="password_confirmation" v-model="repassword" @keypress="setMsgNull()">
          </div>
          <div class="error-s" v-if="errors.first('password_confirmation') && msg === null" >
            <span class="">{{ errors.first('password_confirmation') }}</span>
          </div>
        </div>
        <hr>
        <div class="error-s" v-if="error != null">
          <span class="">{{ error }}</span>
        </div>
        <div class="alert" v-if="msg != null">
          <span class="error-s">{{ msg }}</span>
        </div>
        <div class="">
          <button class="" v-if="!loading" type="submit"><i class="fa fa-refresh"></i>Cambiar</button>
          <span class="" v-if="loading"><i class="fa fa-spinner fa-spin"></i>Cambiando Contraseña... </span>
          <button class="" @click="isOpen = false"><i class="fa fa-times"></i>Cancelar</button>
        </div>
      </form>
    </div>
  </b-modal>
</template>

<script>
import { mapActions } from 'vuex';
import authTypes from '@/store/types/auth';
export default {
  data () {
    return {
      loading: false,
      password: null,
      repassword: null,
      error: null,
      msg: null
    };
  },
  computed: {
    isOpen: {
      get () {
        return this.value;
      },
      set () {
        this.setClosePassword();
      }
    }
  },
  methods: {
    ...mapActions({
      changed: authTypes.actions.changedPassword
    }),
    validateBeforeSubmit () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.changedPasswordUser();
        }
      });
    },
    changedPasswordUser () {
      this.loading = true;
      this.changed({
        userId: this.idUser,
        password: this.password,
        password_confirmation: this.repassword
      }).then(response => {
        if (parseInt(response.status) === 200) {
          this.loading = false;
          this.password = null;
          this.repassword = null;
          this.msg = 'Se actualizó con éxito!';
          this.isOpen = false;
        } else {
          this.loading = false;
          this.error = response;
        }
      }).catch(fallo => {
        this.loading = false;
        this.error = fallo;
      });
    },
    setMsgNull () {
      this.msg = null;
    }
  }
};
</script>
<style lang="scss">
#modalChnagePass{
.title-bar{
  background: #444B57;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 10px !important;
  border-radius:10px 10px 0 0;
  .center-box{
    display:flex;
    justify-content:center;
    font-weight:700;
  }
  .close-later{
    display:flex;
    align-items:center;
    justify-content:flex-end;
    cursor:pointer;
    .close-btn{
      background: transparent;
      color: #fff;
      font-weight: 600;
      padding: 5px;
      font-size: 13px;
      display: flex;
      border: none;
      flex-direction: row-reverse;
      cursor:pointer;
      i{
        font-size: 17px;
        top: -1px;
        position: relative;
        margin-left: 6px;
      }
    }
  }
}
.content-pass-modal{
  padding: 30px;
  background: #fff;
  border-radius: 0 0 10px 10px;
  input{
    width: 100%;
    padding: 10px 20px 10px 40px;
    border-radius: 50px;
    border: 1px solid #444B57;
    margin: 10px 0;
  }
  .positionar{
    position:relative;
  }
  i{
    position: absolute;

    top: 20px;
    left: 19px;
  }
  .error-s{
    color:red;
    font-size:10px;
    margin-top:-5px;
    margin-bottom:5px;
  }
  button{
    padding: 10px 20px;
    border: none;
    background: #444B57;
    color: #fff;
    font-weight: bold;
    border-radius: 50px;
    margin: 5px 20px 2px 0;
    position:relative;
    i{
      position: relative;
      top: auto;
      left: auto;
      margin-right: 10px;
      font-size: 15px;
    }
  }
}
}

</style>
