<template>
  <div id="integrations-module">
    <div class="columns is-desktop styles-columns">
      <div class="column is 12">
        <SectionDivider title="Integraciones">
          <div class="columns is-multiline circle-container">
            <div
              :key="integration.name"
              :style="{backgroundImage: `url(${integration.image})`}"
              @click="setNewForm(integration)"
              class="column is-3 is-6-mobile circle-container__circle-view"
              v-for="(integration) in integrations"
            ></div>
          </div>
        </SectionDivider>
      </div>
    </div>
    <div class="columns styles-columns">
      <div class="column is 12">
        <SectionDivider :title="selectedIntegration.name" v-if="selectedIntegration !== null">
          <component
            :dataIntegrations="savedIntegrations"
            :integration="selectedIntegration"
            :is="selectedIntegration.component"
            :restaurant="restaurant"
            @cleanUpIntegration="cleanUpIntegration"
            @deleteIntegration="deleteIntegration"
            @saveUpdateIntegration="saveUpdateIntegration"
            class="animation-fade"
            v-if="selectedIntegration !== null"
          ></component>
        </SectionDivider>
      </div>
    </div>
  </div>
</template>

<script>
import SectionDivider from '@/components/_shared/SectionDivider.vue';
import PixelForm from '@/components/schedules/integrations/PixelForm.vue';
import PayuForm from '@/components/schedules/integrations/PayuForm.vue';
import MailchimpForm from '@/components/schedules/integrations/MailchimpForm.vue';
import globalTypes from '@/store/types/global';
import serviceVendor from '@/helpers/apis/serviceVendor.js';
export default {
  name: 'Integrations',
  props: ['restaurant'],
  components: {
    SectionDivider,
    PixelForm,
    PayuForm,
    MailchimpForm
  },
  data () {
    return {
      integrations: [],
      selectedIntegration: null,
      savedIntegrations: []
    };
  },
  mounted () {
    this.buildArrayIntegrations();
    this.listIntegrations();
  },
  methods: {
    buildArrayIntegrations () {
      if (this.restaurant.activateFacebookPixelIntegration) {
        this.integrations.push({
          name: 'facebook',
          form: 1,
          image: `${require('@/assets/images/integrations/facebook-pixel-color.png')}`,
          component: 'PixelForm'
        });
      }
      if (this.restaurant.activateMailchimpIntegration) {
        this.integrations.push({
          name: 'mailchimp',
          form: 2,
          image: `${require('@/assets/images/integrations/mailchimp-color.png')}`,
          component: 'MailchimpForm'
        });
      }
      if (this.restaurant.activatePixelManagerIntegration) {
        this.integrations.push({
          name: 'pixelGoogle',
          form: 3,
          image: `${require('@/assets/images/integrations/google-adwords-logo-4.png')}`,
          component: 'PixelForm'
        });
      }
      if (this.restaurant.activatePayuIntegration) {
        this.integrations.push({
          name: 'payu',
          form: 4,
          image: `${require('@/assets/images/integrations/payu-color.png')}`,
          component: 'PayuForm'
        });
      }
    },
    listIntegrations () {
      this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: true });
      serviceVendor.get(`/integrations/${this.restaurant.vendorId}`).then(({ data }) => {
        this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
        this.savedIntegrations = data.data;
        this.$buefy.toast.open({
          message: data.message,
          type: 'is-success'
        });
      }).catch(() => {
        this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
        this.$buefy.toast.open({
          message: 'Lo siento, ha ocurrido un error al intentar procesar tu pretición',
          type: 'is-danger'
        });
      });
    },
    setNewForm (integration) {
      this.selectedIntegration = null;
      setTimeout(() => {
        this.selectedIntegration = integration;
      }, 10);
    },
    saveUpdateIntegration (data) {
      this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: true, typeMessage: 3 });
      if (data.id === null) {
        delete data.id;
      }
      const dataSend = {
        ...data,
        vendorId: this.restaurant.vendorId
      };
      serviceVendor.post('/integrations/save', dataSend).then(({ data }) => {
        this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
        this.$buefy.toast.open({
          message: data.message,
          type: 'is-success'
        });
        this.listIntegrations();
        this.cleanUpIntegration();
      }).catch(() => {
        this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
        this.$buefy.toast.open({
          message: 'Lo siento, ha ocurrido un error al intentar procesar tu pretición',
          type: 'is-danger'
        });
      });
    },
    cleanUpIntegration () {
      this.selectedIntegration = null;
    },
    deleteIntegration (id) {
      this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: true, typeMessage: 3 });
      const dataSend = {
        id: id,
        vendorId: this.restaurant.vendorId
      };
      serviceVendor.post('/integrations/delete', dataSend).then(({ data }) => {
        this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
        this.$buefy.toast.open({
          message: data.message,
          type: 'is-success'
        });
        this.listIntegrations();
        this.cleanUpIntegration();
      }).catch(() => {
        this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
        this.$buefy.toast.open({
          message: 'Lo siento, ha ocurrido un error al intentar procesar tu pretición',
          type: 'is-danger'
        });
      });
    }
  }
};
</script>

<style lang="scss">
#integrations-module {
  .styles-columns {
    margin-top: 60px;
    padding: 10px 10px;
  }
  .animation-fade {
    -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 1s; /* Firefox < 16 */
    -ms-animation: fadein 1s; /* Internet Explorer */
    -o-animation: fadein 1s; /* Opera < 12.1 */
    animation: fadein 1s;
  }
  .circle-container {
    display: flex;
    gap: 2rem;
    padding-left: 2rem;
    &__circle-view {
      width: 140px;
      height: 140px;
      border-radius: 50%;
      -webkit-box-shadow: 2px 3px 16px 7px rgba(0, 0, 0, 0.74);
      box-shadow: 2px 3px 16px 7px rgba(0, 0, 0, 0.74);
      background-size: 80%;
      background-repeat: no-repeat;
      background-position: center;
      cursor: pointer;
    }
  }
  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Firefox < 16 */
  @-moz-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Safari, Chrome and Opera > 12.1 */
  @-webkit-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Internet Explorer */
  @-ms-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Opera < 12.1 */
  @-o-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}
</style>
