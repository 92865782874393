<template>
  <div class="card_new_schedules">
    <div class="columns">
      <div class="column is-full">
        <b-loading :active.sync="isLoading" :can-cancel="true" :is-full-page="true"></b-loading>
        <div
          :class="{ more_input: type == 'dateFree' }"
          :key="'lista#' + ind"
          class="card card_new_schedule"
          v-for="(item, ind) in list"
        >
          <div class="card-content">
            <div class="end_new_card">
              <label
                class="title_new_card"
                v-if="
                  type !== 'dateFree' &&
                  type !== 'dateAtention' &&
                  type !== 'dateZone'
                "
              >{{ item.numberCard }}. Selecciona uno o varios Días</label>
              <div
                class="title_new_card"
                v-if="
                  type == 'dateFree' ||
                  type == 'dateAtention' ||
                  type == 'dateZone'
                "
              >
                <b-switch
                  @input="verifySwitch(item,1)"
                  size="is-small"
                  type="is-success"
                  v-model="item.dateRange"
                >Rango</b-switch>
                <b-switch
                  @input="verifySwitch(item,2)"
                  size="is-small"
                  type="is-success"
                  v-if="type == 'dateFree' && type != 'dateZone'"
                  v-model="item.automatic"
                >Manual</b-switch>
              </div>
              <div
                class="days_new"
                v-if="
                  type !== 'dateFree' &&
                  type !== 'dateAtention' &&
                  type !== 'dateZone'
                "
              >
                <div class="first_days">
                  <button
                    :class="{ active: activeDay(item, 1) }"
                    @click="addWeekDay(item, 1)"
                    class="button_day"
                  >
                    <div class="day_new">
                      <i class="icon-mks black-pre icon_days_schedule"></i>
                      <label>Lunes</label>
                    </div>
                  </button>
                  <button
                    :class="{ active: activeDay(item, 2) }"
                    @click="addWeekDay(item, 2)"
                    class="button_day"
                  >
                    <div class="day_new">
                      <i class="icon-mks black-pre icon_days_schedule"></i>
                      <label>Martes</label>
                    </div>
                  </button>
                  <button
                    :class="{ active: activeDay(item, 3) }"
                    @click="addWeekDay(item, 3)"
                    class="button_day"
                  >
                    <div class="day_new">
                      <i class="icon-mks black-pre icon_days_schedule"></i>
                      <label>Miércoles</label>
                    </div>
                  </button>
                  <button
                    :class="{ active: activeDay(item, 4) }"
                    @click="addWeekDay(item, 4)"
                    class="button_day"
                  >
                    <div class="day_new">
                      <i class="icon-mks black-pre icon_days_schedule"></i>
                      <label>Jueves</label>
                    </div>
                  </button>
                </div>
                <div class="second_days">
                  <button
                    :class="{ active: activeDay(item, 5) }"
                    @click="addWeekDay(item, 5)"
                    class="button_day"
                  >
                    <div class="day_new">
                      <i class="icon-mks black-pre icon_days_schedule"></i>
                      <label>Viernes</label>
                    </div>
                  </button>
                  <button
                    :class="{ active: activeDay(item, 6) }"
                    @click="addWeekDay(item, 6)"
                    class="button_day"
                  >
                    <div class="day_new">
                      <i class="icon-mks black-pre icon_days_schedule"></i>
                      <label>Sábado</label>
                    </div>
                  </button>
                  <button
                    :class="{ active: activeDay(item, 0) }"
                    @click="addWeekDay(item, 0)"
                    class="button_day"
                  >
                    <div class="day_new">
                      <i class="icon-mks black-pre icon_days_schedule"></i>
                      <label>Domingo</label>
                    </div>
                  </button>
                </div>
              </div>
              <div class="times_new">
                <div
                  class="inicio_final_time"
                  v-if="item.interval && ((item.dayOfWeek.length > 0 || (item.date !== '' && item.date != null))  || item.days.length > 0)"
                >
                  <div class="inicio_time">
                    <button class="button_time_des">
                      <div>
                        <label>Hora de inicio</label>
                      </div>
                    </button>
                    <button class="button_time">
                      <div class="time_select">
                        <i class="icon-mks black icon_hours_schedule"></i>
                        <select
                          @change="generateTimeClose(item.interval, item)"
                          class="select_new"
                          name="HoraInicio"
                          placeholder="00 minutos"
                          v-model="item.open"
                          v-validate="'required'"
                        >
                          <option hidden selected value="null">00:00 am</option>
                          <option :key="index" :value="time" v-for="(time, index) in item.times">
                            {{
                            new Date(time)
                            | moment("timezone", timezone, "h:mm a")
                            }}
                          </option>
                        </select>
                      </div>
                    </button>
                  </div>
                  <div
                    class="final_time"
                    v-if="item.interval && ((item.dayOfWeek.length > 0 || (item.date !== '' && item.date != null)) || item.days.length > 0)"
                  >
                    <button class="button_time_des">
                      <div>
                        <label>Hora final</label>
                      </div>
                    </button>
                    <button class="button_time">
                      <div class="time_select">
                        <i class="icon-mks black icon_hours_schedule"></i>
                        <select
                          class="select_new"
                          name="HoraFin"
                          placeholder="00 minutos"
                          v-model="item.close"
                          v-validate="'required'"
                        >
                          <option hidden selected value="null">00: 00 pm</option>
                          <option
                            :key="index"
                            :value="time"
                            v-for="(time, index) in item.timeCloseSchedule"
                          >
                            {{
                            new Date(time)
                            | moment("timezone", timezone, "h:mm a")
                            }}
                          </option>
                        </select>
                      </div>
                    </button>
                  </div>
                </div>
                <div
                  class="interval_time"
                  v-if="(item.dayOfWeek.length > 0 || (item.date !== '' && item.date != null)) || item.days.length > 0"
                >
                  <button class="button_time_des">
                    <div>
                      <label>Intervalo</label>
                    </div>
                  </button>
                  <button class="button_time">
                    <div class="time_select">
                      <i class="icon-mks black icon_hours_schedule"></i>
                      <select
                        @change="generateTimes(item.interval, item)"
                        class="select_new"
                        name="Intervalo"
                        placeholder="00 minutos"
                        v-model="item.interval"
                        v-validate="'required'"
                      >
                        <option hidden selected value="null">00: 00 pm</option>
                        <option value="15">15 minutos</option>
                        <option value="30">30 minutos</option>
                        <option value="60">60 minutos</option>
                      </select>
                    </div>
                  </button>
                </div>
                <div class="label_delete">
                  <label @click="delList(ind)" class="label_info">
                    <div class="icons_info_schedules_delete">
                      <i class="icon-mks white icon_delete_info_schedule"></i>
                    </div>Eliminar
                  </label>
                </div>
              </div>
              <div
                class="dates_specific"
                v-if="
                  type == 'dateFree' ||
                  type == 'dateAtention' ||
                  type == 'dateZone' ||
                  type == 'zone'
                "
              >
                <div class="start_date" v-if="type == 'dateZone' || type == 'zone'">
                  <button class="button_time_des">
                    <div>
                      <label>Zona</label>
                    </div>
                  </button>
                  <button class="button_time">
                    <div class="time_select">
                      <select
                        class="select_new"
                        name="Zona"
                        placeholder="Zona"
                        v-model="item.seatingSectionId"
                        v-validate="'required'"
                      >
                        <option hidden selected value="null">Seleccione</option>
                        <option
                          :key="index"
                          :value="zone.id"
                          v-for="(zone, index) in zonas"
                        >{{ zone.displayName }}</option>
                      </select>
                    </div>
                  </button>
                </div>
                <div class="start_date" v-if="!item.automatic && type !== 'zone'">
                  <button class="button_time_des">
                    <div>
                      <label>Fecha</label>
                    </div>
                  </button>
                  <button class="button_time">
                    <div class="time_select">
                      <datetime
                        :min-datetime="minDatetime"
                        :value-zone="vendor.timezone"
                        format="yyyy-MM-dd"
                        input-id="date"
                        name="date"
                        type="date"
                        v-model="item.date"
                        v-validate="'required'"
                        @input="changeDate($event, 0, type, item)"
                      ></datetime>
                    </div>
                  </button>
                </div>
                <div class="end_date" v-if="item.dateRange">
                  <button class="button_time_des">
                    <div>
                      <label>Fecha Final</label>
                    </div>
                  </button>
                  <button class="button_time">
                    <div class="time_select">
                      <datetime
                        :min-datetime="getMinDate(item.date)"
                        format="yyyy-MM-dd"
                        input-id="date"
                        name="fechaFinal"
                        type="date"
                        v-model="item.dateEnd"
                        v-validate="'required'"
                        value-zone="America/Bogota"
                        @input="changeDate($event, 1, type, item)"
                      ></datetime>
                    </div>
                  </button>
                </div>
              </div>
              <div class="date_manual">
                <div class="date-cont" v-if="item.automatic">
                  <v-calendar :attributes="item.attributes" @dayclick="onDayClick($event, item)">
                    <template v-slot:footer>
                      <div class="center-button">
                        <b-button
                          @click="addAllFestives(item)"
                          type="is-small is-primary btnAddHours"
                        >Festivos</b-button>
                      </div>
                    </template>
                  </v-calendar>
                </div>
                <div class="container_years" v-if="item.automatic">
                  <b-select
                    @input="addYear(selectYear, item)"
                    class="selectYearsFestives"
                    v-model="selectYear"
                  >
                    <option :value="null" hidden>Seleccione años</option>
                    <option
                      :class="{ active: verifyYear(year) }"
                      :key="i"
                      :value="year"
                      v-for="(year, i) in years"
                    >{{ year }}</option>
                  </b-select>
                  <b-field label="Años para selección de festivos">
                    <div>
                      <label :key="i" v-for="(year, i) in selectedYears">
                        {{ year }}
                        <label v-if="i < selectedYears.length - 1">,</label>
                      </label>
                    </div>
                  </b-field>
                </div>
              </div>
            </div>
          </div>
          <div
            style="text-align: center; margin-top: 5px; color: red; display: flex; flex-direction: column;"
          >
            <span
              class="warning"
              v-if="item.dayOfWeek.length == 0 && requiredDayOfWeek.includes(schedule.type)"
            >Debes escoger al menos un dia</span>
            <span
              class="warning"
              v-if="item.seatingSectionId == null && requiredZone.includes(schedule.type)"
            >Debes escoger la zona</span>
            <span class="warning" v-if="item.open == null">Escoger la hora inicio</span>
            <span class="warning" v-if="item.close == null">Escoger la hora final</span>
            <span class="warning" v-if="item.interval == null">Escoger el intervalo</span>
            <span
              class="warning"
            >* Recuerda: Escoger el intervalo del mismo valor en todos los horarios</span>
            <span
              class="warning"
              v-if="(item.date == null || item.date == '') && requiredDate.includes(schedule.type) && !item.automatic"
            >Escoger la fecha</span>
            <span
              class="warning"
              v-if="(item.dateEnd == null || item.dateEnd == '') && item.dateRange && requiredDate.includes(schedule.type)"
            >Escoger la fecha final</span>
            <span
              class="warning_years"
              v-if="validateYears && item.automatic"
            >Seleccione al menos 1 año</span>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer horarios footer_horarios_new">
      <button @click="cancelSchedules()">
        <i class="icon-mks white icon_cancel"></i>Cancelar
      </button>
      <button @click="addSchedules()">
        <i class="icon-mks white icon_save_schedule"></i>Guardar
      </button>
      <button @click="addList()" v-if="list.length === 0">
        <i class="icon-mks white icon_new_schedule"></i>Nuevo
      </button>
    </div>
  </div>
</template>

<script>
import { getColombiaHolidaysByYear } from 'colombia-holidays';
import serviceVendor from '@/helpers/apis/serviceVendor';
export default {
  props: ['type', 'publicTime', 'publicTimeFixed', 'general'],
  data () {
    return {
      radio: null,
      requiredDayOfWeek: ['public', 'free', 'zone'],
      requiredZone: ['dateZone', 'zone'],
      requiredDate: ['dateZone', 'dateAtention', 'dateFree'],
      schedule: {
        vendorId: this.$store.state.vendor.vendor.id,
        type: this.type,
        schedules: []
      },
      list: [
        {
          dayOfWeek: [],
          open: null,
          close: null,
          date: null,
          dateEnd: null,
          dateRange: false,
          seatingSectionId: null,
          interval: null,
          numberCard: 1,
          timeCloseSchedule: null,
          valDay: false,
          purchase: 0,
          purchaseAdult: null,
          purchaseBoy: null,
          cover: null,
          automatic: false,
          attributes: [],
          days: [],
          times: []
        }
      ],
      valDays: false,
      times: [],
      timeClose: [],
      attributes: [],
      numberCards: 1,
      tittleNewCard: null,
      timeZoneNew: null,
      selectYear: null,
      selectedYears: [],
      years: [],
      validateYears: false,
      minDatetime: this.$moment().toISOString(),
      list2: [],
      idsList: [],
      zonas: null,
      isLoading: false
    };
  },
  mounted () {
    this.timeZoneNew = this.timezone;
    let holidays = {};
    for (let i = 0; i < 10; i++) {
      this.years.push(new Date().getFullYear() + i);
      if (i === 0) {
        holidays = getColombiaHolidaysByYear(new Date().getFullYear() + i);
      } else {
        holidays = holidays.concat(
          getColombiaHolidaysByYear(new Date().getFullYear() + i)
        );
      }
    }
    const dates = holidays.map((holi) =>
      this.$moment(holi.holiday, 'YYYY-MM-DD').toDate()
    );
    this.list.forEach((element) => {
      element.attributes = [
        ...element.attributes,
        {
          bar: 'red',
          dates: dates
        }
      ];
    });
    this.getZone();
  },
  methods: {
    cancelSchedules () {
      this.$emit('newSchedules', this.type);
    },
    changeDate (e, mod, type, item) {
      if (this.$moment(e).isValid()) {
        const formatDate = this.$moment(e).format('YYYY-MM-DD');
        switch (type) {
          case 'dateFree':
            if (mod) {
              // finish
              if (!this.publicTimeFixed) {
                this.$buefy.toast.open({
                  duration: 9000,
                  message: 'Para este día no hay horarios de <b>ATENCIÓN ESPECIFICOS</b> y sin eso no se puede agregar',
                  position: 'is-top',
                  type: 'is-danger'
                });
                setTimeout(() => {
                  item.dateEnd = null;
                }, 200);
              }
              if (this.publicTimeFixed) {
                if (!this.publicTimeFixed.find(item => item.fecha === formatDate)) {
                  this.$buefy.toast.open({
                    duration: 9000,
                    message: 'Para este día no hay horarios de <b>ATENCIÓN ESPECIFICOS</b> y sin eso no se puede agregar',
                    position: 'is-top',
                    type: 'is-danger'
                  });
                  setTimeout(() => {
                    item.dateEnd = null;
                  }, 200);
                }
              }
            } else {
              // init
              if (!this.publicTimeFixed) {
                this.$buefy.toast.open({
                  duration: 9000,
                  message: 'Para este día no hay horarios de <b>ATENCIÓN ESPECIFICOS</b> y sin eso no se puede agregar',
                  position: 'is-top',
                  type: 'is-danger'
                });
                setTimeout(() => {
                  item.date = null;
                }, 200);
              }
              if (this.publicTimeFixed) {
                if (!this.publicTimeFixed.find(item => item.fecha === formatDate)) {
                  this.$buefy.toast.open({
                    duration: 9000,
                    message: 'Para este día no hay horarios de <b>ATENCIÓN ESPECIFICOS</b> y sin eso no se puede agregar',
                    position: 'is-top',
                    type: 'is-danger'
                  });
                  setTimeout(() => {
                    item.date = null;
                  }, 200);
                }
              }
            }
            break;
        }
      }
    },
    verifySwitch (item, i) {
      switch (i) {
        case 1:
          item.automatic = false;
          break;
        case 2:
          item.dateRange = false;
          break;
      }
    },
    getZone () {
      const vendorId = this.idVendor;
      serviceVendor.post('section/dashboard', { vendorId }).then(({ data }) => {
        this.zonas = data;
      });
    },
    addYear (year, item) {
      const yearToAdd = this.selectedYears.find((e) => e === this.selectYear);
      if (yearToAdd === undefined) {
        this.selectedYears.push(this.selectYear);
        setTimeout(() => {
          this.selectYear = null;
        }, 100);
        return;
      }
      var index = this.selectedYears.indexOf(this.selectYear);
      this.selectedYears.splice(index, 1);
      setTimeout(() => {
        this.deleteYearFestives(year, item);
        this.selectYear = null;
      }, 100);
    },
    delList (key) {
      this.numberCards = 0;
      this.list.splice(key, 1);
      for (let i = 0; i < this.list.length; i++) {
        this.numberCards++;
        this.list[i].numberCard = this.numberCards;
      }
    },
    addAllFestives (item) {
      let holidays = {};
      this.selectedYears.forEach((element, i) => {
        const holidaysYear = getColombiaHolidaysByYear(element);
        if (i === 0) {
          holidays = holidaysYear;
        } else {
          holidays = holidays.concat(holidaysYear);
        }
      });
      if (holidays.length === 0 || holidays.length === undefined) {
        this.validateYears = true;
        return;
      }
      this.validateYears = false;
      holidays.forEach((holi) => {
        const date = this.$moment(holi.holiday, 'YYYY-MM-DD').toDate();
        const index = item.days.findIndex((d) => d.id === holi.holiday);
        if (index >= 0) {
          return;
        } else {
          item.days.push({
            id: holi.holiday,
            date: date
          });
        }
        return date;
      });
      this.daysCalendar(item);
    },
    verifyYear (year) {
      const yearActive = this.selectedYears.find((e) => e === year);
      if (yearActive !== undefined) {
        return true;
      }
      return false;
    },
    onDayClick (day, item) {
      const idx = item.days.findIndex((d) => d.id === day.id);
      if (idx >= 0) {
        item.days.splice(idx, 1);
      } else {
        item.days.push({
          id: day.id,
          date: day.date
        });
      }
      this.daysCalendar(item);
    },
    daysCalendar (item) {
      let holidays = {};
      for (let i = 0; i < this.years.length; i++) {
        if (i === 0) {
          holidays = getColombiaHolidaysByYear(new Date().getFullYear() + i);
        } else {
          holidays = holidays.concat(
            getColombiaHolidaysByYear(new Date().getFullYear() + i)
          );
        }
      }
      const dates = holidays.map((holi) =>
        this.$moment(holi.holiday, 'YYYY-MM-DD').toDate()
      );
      item.attributes = [
        {
          bar: 'red',
          dates: dates
        },
        {
          highlight: true,
          dates: item.days.map((day) => day.date)
        }
      ];
    },
    deleteYearFestives (year, item) {
      const holidays = getColombiaHolidaysByYear(year);
      holidays.forEach((holi) => {
        const date = this.$moment(holi.holiday, 'YYYY-MM-DD').toDate();
        const index = item.days.findIndex((d) => d.id === holi.holiday);
        if (index >= 0) {
          item.days.splice(index, 1);
        } else {
          item.days.push({
            id: holi.holiday,
            date: date
          });
        }
        return date;
      });
      this.daysCalendar(item);
    },
    getMinDate (day) {
      if (day) {
        const date = this.$moment(day)
          .add(1, 'd')
          .format('YYYY-MM-DDTHH:mm:ss.SSSSZ');
        return date;
      } else {
        return this.$moment()
          .add(1, 'd')
          .format('YYYY-MM-DDTHH:mm:ss.SSSSZ');
      }
    },
    addWeekDay (item, day) {
      const key = item.dayOfWeek.indexOf(day);
      if (key >= 0) {
        item.dayOfWeek.splice(key, 1);
      } else {
        if (this.type !== 'public') {
          if (this.publicTime.length > 0) {
            if (this.publicTime[day] !== undefined && this.publicTime[day].length > 0) {
              item.dayOfWeek.push(day);
            } else {
              this.$buefy.toast.open({
                duration: 9000,
                message: 'Para este día no hay horarios de <b>ATENCIÓN ESPECIFICOS</b> y sin eso no se puede agregar',
                position: 'is-top',
                type: 'is-danger'
              });
            }
          }
        } else {
          item.dayOfWeek.push(day);
        }
      }
    },
    activeDay (item, day) {
      const key = item.dayOfWeek.indexOf(day);
      if (key >= 0) {
        return true;
      }
      return false;
    },
    addList () {
      this.numberCards++;
      this.list.push({
        dayOfWeek: [],
        open: null,
        close: null,
        date: null,
        dateEnd: null,
        dateRange: false,
        seatingSectionId: null,
        interval: null,
        numberCard: 1,
        timeCloseSchedule: null,
        valDay: false,
        purchase: 0,
        purchaseAdult: null,
        purchaseBoy: null,
        cover: null,
        automatic: false,
        attributes: [],
        days: []
      });
    },
    generateTimes (interval, item) {
      let initial = parseInt(
        this.$moment('1970-01-01 00:00').milliseconds(0).seconds(0).format('x')
      );
      const finish = parseInt(
        this.$moment('1970-01-01 23:50').milliseconds(0).seconds(0).format('x')
      );
      const data = [];
      item.times = data;

      while (initial <= finish) {
        data.push(initial);
        initial = initial + 60000 * interval;
      }
      item.times = data;
    },
    generateTimeClose (interval, item) {
      if (item.open >= item.close) {
        item.close = null;
      }
      let initial = item.open;
      const finish = parseInt(
        this.$moment('1970-01-01 23:50').milliseconds(0).seconds(0).format('x')
      );
      const data = [];
      this.timeClose = data;
      while (initial <= finish) {
        data.push(initial);
        initial = initial + 60000 * interval;
      }
      item.timeCloseSchedule = data;
    },
    checkValidateRangeFixed (item) {
      const thes = this;
      if (thes.publicTimeFixed === undefined) {
        return true;
      }
      const data = thes.publicTimeFixed.filter(info => {
        if (parseInt(info.date) === parseInt(item.date)) {
          return info;
        }
      });
      let okTime = 0;
      let errorTime = 0;
      let response = false;
      let conflict = '';
      let timesRang = '';
      if (data.length === 0) {
        return item;
      }
      data.forEach(info => {
        const init = thes.$moment(parseInt(info.open)).format('x');
        const initHuman = thes.$moment(parseInt(info.open)).add(parseInt(info.interval), 'minutes').format('hh:mm a');
        const initToday = thes.$moment(parseInt(item.open)).format('x');
        const fini = thes.$moment(parseInt(info.close)).format('x');
        const finiHuman = thes.$moment(parseInt(info.close)).subtract(parseInt(info.interval), 'minutes').format('hh:mm a');
        const finiToday = thes.$moment(parseInt(item.close)).format('x');
        conflict += '<br/>Desde las ' + initHuman + ' hasta las ' + finiHuman + ' con intervalo de ' + parseInt(info.interval) + ' minutos';
        if (parseInt(initToday) < parseInt(init) && parseInt(initToday) < parseInt(fini) && parseInt(finiToday) < parseInt(init) && parseInt(finiToday) < parseInt(fini)) {
          okTime = okTime + 1;
        } else if (parseInt(initToday) > parseInt(init) && parseInt(initToday) > parseInt(fini) && parseInt(finiToday) > parseInt(init) && parseInt(finiToday) > parseInt(fini)) {
          okTime = okTime + 1;
        } else {
          errorTime = errorTime + 1;
          item.date = this.$moment(item.date).format();
          if (item.dateEnd) {
            item.dateEnd = this.$moment(item.dateEnd).format();
          }
        }
      });
      if (okTime > 0) {
        response = true;
      }
      if (errorTime > 0 && okTime === 0) {
        timesRang += '<br/>Horarios disponibles: ' + conflict;
        response = false;
        this.$buefy.toast.open({
          duration: 9000,
          message: timesRang,
          position: 'is-top',
          type: 'is-danger'
        });
        thes.valDays = true;
        response = false;
      }
      okTime = 0;
      errorTime = 0;
      return response;
    },
    checkValidateAtention (item) {
      const thes = this;
      let okTime = 0;
      let errorTime = 0;
      let response = false;
      let timesRang = '';
      let conflict = '';
      item.valDay = false;
      item.dayOfWeek.forEach(day => {
        if (thes.publicTime[day] !== undefined && thes.publicTime[day].length > 0) {
          thes.publicTime[day].forEach(hour => {
            const init = thes.$moment(hour.open).format('x');
            const initHuman = thes.$moment(hour.open).add(parseInt(hour.interval), 'minutes').format('hh:mm a');
            const initToday = thes.$moment(item.open).format('x');
            const fini = thes.$moment(hour.close).format('x');
            const finiHuman = thes.$moment(hour.close).subtract(parseInt(hour.interval), 'minutes').format('hh:mm a');
            const finiToday = thes.$moment(item.close).format('x');
            conflict += '<br/>Desde las ' + initHuman + ' hasta las ' + finiHuman + ' con intervalo de ' + parseInt(hour.interval) + ' minutos';
            if (parseInt(hour.interval) !== parseInt(item.interval)) {
              timesRang = 'Error: <br/> Se espera intervalo de ' + parseInt(hour.interval) + ' minutos y está enviando de ' + parseInt(item.interval) + ' minutos';
              thes.valDays = true;
              response = false;
              errorTime = errorTime + 1;
            } else if (parseInt(initToday) >= parseInt(init) && parseInt(initToday) <= parseInt(fini) && parseInt(finiToday) >= parseInt(init) && parseInt(finiToday) <= parseInt(fini)) {
              okTime = okTime + 1;
            } else {
              errorTime = errorTime + 1;
            }
          });
        } else {
          this.$buefy.toast.open({
            duration: 9000,
            message: 'No hay horarios disponibles',
            position: 'is-top',
            type: 'is-danger'
          });
          thes.valDays = true;
          response = false;
        }
      });
      if (okTime > 0) {
        response = true;
      }
      if (errorTime > 0 && okTime === 0) {
        timesRang += '<br/>Horarios disponibles: ' + conflict;
        response = false;
        this.$buefy.toast.open({
          duration: 9000,
          message: timesRang,
          position: 'is-top',
          type: 'is-danger'
        });
        thes.valDays = true;
        response = false;
      }
      okTime = 0;
      errorTime = 0;
      return response;
    },
    checkInRangeAtention (item) {
      const thes = this;
      let okTime = 0;
      let errorTime = 0;
      let response = false;
      let timesRang = '';
      let conflict = '';
      item.valDay = false;
      item.dayOfWeek.forEach(day => {
        if (thes.publicTime !== undefined && thes.publicTime[day] !== undefined && thes.publicTime[day].length > 0) {
          thes.publicTime[day].forEach(hour => {
            const init = thes.$moment(hour.open).format('x');
            const initHuman = thes.$moment(hour.open).format('hh:mm a');
            const initToday = thes.$moment(item.open).format('x');
            const fini = thes.$moment(hour.close).format('x');
            const finiHuman = thes.$moment(hour.close).format('hh:mm a');
            const finiToday = thes.$moment(item.close).format('x');
            conflict += '<br/>Desde las ' + initHuman + ' hasta las ' + finiHuman;
            if (parseInt(initToday) < parseInt(init) && parseInt(initToday) < parseInt(fini) && parseInt(finiToday) < parseInt(init) && parseInt(finiToday) < parseInt(fini)) {
              okTime = okTime + 1;
            } else if (parseInt(initToday) > parseInt(init) && parseInt(initToday) > parseInt(fini) && parseInt(finiToday) > parseInt(init) && parseInt(finiToday) > parseInt(fini)) {
              okTime = okTime + 1;
            } else {
              errorTime = errorTime + 1;
            }
          });
        } else {
          return item;
        }
      });
      if (okTime > 0 && errorTime === 0) {
        response = item;
      }
      if (errorTime > 0) {
        timesRang = '<br/>El rango ingresado tiene conflicto con una configuración ya guardada: ' + conflict;
        response = false;
        this.$buefy.toast.open({
          duration: 9000,
          message: 'Error: ' + timesRang,
          position: 'is-top',
          type: 'is-danger'
        });
        thes.valDays = true;
        response = item;
      }
      okTime = 0;
      errorTime = 0;
      return response;
    },
    checkInRangeFree (item) {
      const thes = this;
      let response = false;
      let okTime = 0;
      let errorTime = 0;
      let timesRang = '';
      let conflict = '';
      item.valDay = false;
      item.dayOfWeek.forEach(day => {
        if (thes.general.free !== undefined && thes.general.free[day] !== undefined && thes.general.free[day].length > 0) {
          thes.general.free[day].forEach(hour => {
            const init = thes.$moment(hour.open).format('x');
            const initHuman = thes.$moment(hour.open).format('hh:mm a');
            const initToday = thes.$moment(item.open).format('x');
            const fini = thes.$moment(hour.close).format('x');
            const finiHuman = thes.$moment(hour.close).format('hh:mm a');
            const finiToday = thes.$moment(item.close).format('x');
            conflict += '<br/>Desde las ' + initHuman + ' hasta las ' + finiHuman;
            if (parseInt(initToday) < parseInt(init) && parseInt(initToday) < parseInt(fini) && parseInt(finiToday) < parseInt(init) && parseInt(finiToday) < parseInt(fini)) {
              okTime = okTime + 1;
            } else if (parseInt(initToday) > parseInt(init) && parseInt(initToday) > parseInt(fini) && parseInt(finiToday) > parseInt(init) && parseInt(finiToday) > parseInt(fini)) {
              okTime = okTime + 1;
            } else {
              errorTime = errorTime + 1;
            }
          });
        } else {
          return item;
        }
      });
      if (okTime > 0 && errorTime === 0) {
        response = item;
      }
      if (errorTime > 0) {
        timesRang = '<br/>El rango ingresado tiene conflicto con una configuración ya guardada:  ' + conflict;
        response = false;
        this.$buefy.toast.open({
          duration: 9000,
          message: 'Error: ' + timesRang,
          position: 'is-top',
          type: 'is-danger'
        });
        thes.valDays = true;
        response = false;
      }
      okTime = 0;
      errorTime = 0;
      return response;
    },
    checkInRangeZone (item) {
      const thes = this;
      let response = false;
      let okTime = 0;
      let errorTime = 0;
      let timesRang = '';
      let conflict = '';
      item.valDay = false;
      item.dayOfWeek.forEach(day => {
        if (thes.general.zone !== undefined && thes.general.zone[day] !== undefined && thes.general.zone[day].length > 0) {
          const data = thes.general.zone[day].filter(i => {
            if (i.seatingSectionId === item.seatingSectionId) {
              return i;
            }
          });
          if (data.length === 0) {
            return item;
          }
          data.forEach(hour => {
            const init = thes.$moment(hour.open).format('x');
            const initHuman = thes.$moment(hour.open).format('hh:mm a');
            const initToday = thes.$moment(item.open).format('x');
            const fini = thes.$moment(hour.close).format('x');
            const finiHuman = thes.$moment(hour.close).format('hh:mm a');
            const finiToday = thes.$moment(item.close).format('x');
            conflict += '<br/>Desde las ' + initHuman + ' hasta las ' + finiHuman;
            if (parseInt(initToday) < parseInt(init) && parseInt(initToday) < parseInt(fini) && parseInt(finiToday) < parseInt(init) && parseInt(finiToday) < parseInt(fini)) {
              okTime = okTime + 1;
            } else if (parseInt(initToday) > parseInt(init) && parseInt(initToday) > parseInt(fini) && parseInt(finiToday) > parseInt(init) && parseInt(finiToday) > parseInt(fini)) {
              okTime = okTime + 1;
            } else {
              errorTime = errorTime + 1;
            }
          });
        } else {
          return item;
        }
      });
      if (okTime > 0 && errorTime === 0) {
        response = item;
      }
      if (errorTime > 0) {
        timesRang = '<br/>El rango ingresado tiene conflicto con una configuración ya guardada:  ' + conflict;
        response = false;
        this.$buefy.toast.open({
          duration: 9000,
          message: 'Error: ' + timesRang,
          position: 'is-top',
          type: 'is-danger'
        });
        thes.valDays = true;
        response = false;
      }
      okTime = 0;
      errorTime = 0;
      return response;
    },
    checkInRangeFixed (type, item) {
      const thes = this;
      let okTime = 0;
      let errorTime = 0;
      let response = false;
      let timesRang = '';
      let conflict = '';
      item.valDay = false;
      let data = [];
      if (type === 'dateAtention') {
        if (thes.general.dateAtention === undefined) {
          return item;
        }
        data = thes.general.dateAtention.filter(info => {
          if (parseInt(info.date) === parseInt(item.date)) {
            return info;
          }
        });
        if (data.length === 0) {
          return item;
        }
      } else if (type === 'dateFree') {
        if (thes.general.dateFree === undefined) {
          return item;
        }
        data = thes.general.dateFree.filter(info => {
          if (parseInt(info.date) === parseInt(item.date)) {
            return info;
          }
        });
        if (data.length === 0) {
          return item;
        }
      } else if (type === 'dateZone') {
        if (thes.general.dateZone === undefined) {
          return item;
        }
        data = thes.general.dateZone.filter(info => {
          if (parseInt(info.date) === parseInt(item.date) && info.seatingSectionId === item.seatingSectionId) {
            return info;
          }
        });
        if (data.length === 0) {
          return item;
        }
      }
      data.forEach(hour => {
        const init = thes.$moment(hour.open).format('x');
        const initHuman = thes.$moment(hour.open).format('hh:mm a');
        const initToday = thes.$moment(item.open).format('x');
        const fini = thes.$moment(hour.close).format('x');
        const finiHuman = thes.$moment(hour.close).format('hh:mm a');
        const finiToday = thes.$moment(item.close).format('x');
        conflict += '<br/>Desde las ' + initHuman + ' hasta las ' + finiHuman;
        if (parseInt(initToday) < parseInt(init) && parseInt(initToday) < parseInt(fini) && parseInt(finiToday) < parseInt(init) && parseInt(finiToday) < parseInt(fini)) {
          okTime = okTime + 1;
        } else if (parseInt(initToday) > parseInt(init) && parseInt(initToday) > parseInt(fini) && parseInt(finiToday) > parseInt(init) && parseInt(finiToday) > parseInt(fini)) {
          okTime = okTime + 1;
        } else {
          errorTime = errorTime + 1;
        }
      });
      if (okTime > 0 && errorTime === 0) {
        response = item;
      }
      if (errorTime > 0) {
        timesRang = '<br/>El rango ingresado tiene conflicto con una configuración ya guardada: ' + conflict;
        response = false;
        this.$buefy.toast.open({
          duration: 9000,
          message: 'Error: ' + timesRang,
          position: 'is-top',
          type: 'is-danger'
        });
        thes.valDays = true;
        response = item;
      }
      okTime = 0;
      errorTime = 0;
      return response;
    },
    validateTimestampFixed (type, item) {
      const thes = this;
      if (thes.requiredDate.includes(type)) {
        if (item.date) {
          const dateFormat = (Number.isInteger(item.date)) ? parseInt(item.date) : parseInt(thes.$moment(item.date).format('x'));
          if (item.open == null || item.close == null) {
            item.valDay = true;
            thes.valDays = true;
            return item;
          } else {
            item.open = thes.$moment(
              thes.$moment(dateFormat).format('YYYY-MM-DD') +
              ' ' +
              thes.$moment(item.open).format('HH:mm:ss')
            ).format('x');
            item.close = thes.$moment(
              thes.$moment(dateFormat).format('YYYY-MM-DD') +
              ' ' +
              thes.$moment(item.close).format('HH:mm:ss')
            ).format('x');
            item.date = dateFormat;
          }
        } else {
          item.valDay = true;
          thes.valDays = true;
          return item;
        }
        if (item.dateRange) {
          const dateFormatTwo = (Number.isInteger(item.dateEnd)) ? parseInt(item.dateEnd) : parseInt(thes.$moment(item.dateEnd).format('x'));
          item.dateEnd = dateFormatTwo;
        } else {
          item.dateEnd = null;
        }
        /* if (type === 'dateAtention') {
          return thes.checkInRangeFixed(type, item);
        } else if (type === 'dateFree') {
          const resp = thes.checkValidateRangeFixed(item);
          if (resp !== false) {
            return thes.checkInRangeFixed(type, item);
          }
        } else if (type === 'dateZone') {
          const resp = thes.checkValidateRangeFixed(item);
          if (resp !== false) {
            return thes.checkInRangeFixed(type, item);
          }
        } */
        return item;
      }
      return item;
    },
    validateTimestampZone (type, item) {
      const thes = this;
      if (thes.requiredZone.includes(type)) {
        if (item.seatingSectionId == null) {
          thes.valDays = true;
          item.date = null;
          item.open = null;
          item.close = null;
          this.$buefy.toast.open({
            duration: 9000,
            message: 'Debe seleccionar una zona',
            type: 'is-danger'
          });
          return item;
        } else {
          if (item.open == null || item.close == null) {
            item.valDay = true;
            thes.valDays = true;
            return item;
          }
          const resp = thes.checkValidateAtention(item);
          if (resp !== false) {
            return thes.checkInRangeZone(item);
          }
        }
      }
      return item;
    },
    validateTimestampDayOfWeek (type, item) {
      const thes = this;
      if (thes.requiredDayOfWeek.includes(type)) {
        if (item.dayOfWeek.length === 0) {
          thes.valDays = true;
          item.valDay = true;
          return item;
        } else {
          if (item.open == null || item.close == null) {
            item.valDay = true;
            thes.valDays = true;
            return item;
          }
          if (type === 'public') {
            return thes.checkInRangeAtention(item);
          }
          if (type === 'free') {
            const response = thes.checkValidateAtention(item);
            if (response) {
              return thes.checkInRangeFree(item);
            }
          }
        }
      }
      return item;
    },
    async addSchedules () {
      const thes = this;
      if (thes.list2.length > 0) {
        thes.list2.forEach((item) => {
          item.date = thes.list[item.indexList].date;
          item.dateEnd = thes.list[item.indexList].dateEnd;
          item.purchase = thes.list[item.indexList].purchase;
          item.purchaseAdult = thes.list[item.indexList].purchaseAdult;
          item.purchaseBoy = thes.list[item.indexList].purchaseBoy;
          item.seatingSectionId = thes.list[item.indexList].seatingSectionId;
          item.automatic = thes.list[item.indexList].automatic;
          item.dateRange = thes.list[item.indexList].dateRange;
          item.days = thes.list[item.indexList].days;
        });
      }
      thes.schedule.schedules =
        thes.list2.length > 0 ? thes.list.concat(thes.list2) : thes.list;
      thes.schedule.schedules = thes.schedule.schedules.sort((a, b) =>
        a.open > b.open ? 1 : b.open > a.open ? -1 : 0
      );
      if (thes.list2.length > 0) {
        thes.addSchedulesManualMultiple(thes.schedule.schedules);
      } else {
        thes.schedule.schedules.forEach((element) => {
          if (element.automatic) {
            thes.addSchedulesManual(element);
          }
        });
      }
      thes.idsList.forEach((element) => {
        const index = thes.schedule.schedules.findIndex(
          (d) => d.idList === element
        );
        thes.schedule.schedules.splice(index, 1);
      });
      if (thes.schedule.schedules.length > 0) {
        thes.valDays = false;
        thes.$validator.validateAll().then((result) => {
          if (result) {
            thes.schedule.schedules.forEach((item) => {
              if (thes.requiredDayOfWeek.includes(thes.schedule.type)) {
                if (thes.schedule.type !== 'zone') {
                  // Entra solo public y free
                  item = thes.validateTimestampDayOfWeek(thes.schedule.type, item);
                }
              }
              if (thes.requiredZone.includes(thes.schedule.type)) {
                if (thes.schedule.type !== 'dateZone') {
                  // Entra solo zone
                  item = thes.validateTimestampZone(thes.schedule.type, item);
                }
              }
              if (thes.requiredDate.includes(thes.schedule.type)) {
                // Entra dateAtention dateZone y dateFree
                item = thes.validateTimestampFixed(thes.schedule.type, item);
              }
            });
            if (
              !thes.valDays
            ) {
              thes.isLoading = true;
              const data = {
                ...thes.schedule,
                userId: thes.user.id
              };
              serviceVendor
                .post('schedules', data)
                .then(() => {
                  thes.isLoading = false;
                  thes.$buefy.toast.open({
                    message: 'Horarios agregados!',
                    type: 'is-success'
                  });
                  thes.list = [
                    {
                      dayOfWeek: [],
                      open: null,
                      close: null,
                      date: thes.$moment().format(),
                      dateEnd: null,
                      dateRange: false,
                      seatingSectionId: null,
                      interval: null,
                      numberCard: 1,
                      timeCloseSchedule: null,
                      valDay: false,
                      purchase: 0,
                      purchaseAdult: null,
                      purchaseBoy: null,
                      cover: null,
                      automatic: false,
                      attributes: [],
                      days: [],
                      times: []
                    }
                  ];
                  thes.$emit('newSchedules', thes.schedule.type);
                })
                .catch(() => {
                  thes.isLoading = false;
                  thes.$toast.open({
                    message: thes.$t('errors.request'),
                    type: 'is-danger'
                  });
                });
            }
          }
        });
      }
    },
    addSchedulesManual (element) {
      let dates = [];
      if (element.days.length <= 0) {
        this.$buefy.toast.open({
          duration: 9000,
          message: 'Debe seleccionar al menos una fecha',
          type: 'is-danger'
        });
        return;
      }
      dates = element.days.map((day) => {
        day.open = this.$moment(
          this.$moment(day.date).format('YYYY-MM-DD') +
          ' ' +
          this.$moment(element.open).format('HH:mm:ss')
        ).format('x');
        day.close = this.$moment(
          this.$moment(day.date).format('YYYY-MM-DD') +
          ' ' +
          this.$moment(element.close).format('HH:mm:ss')
        ).format('x');
        day.interval = element.interval;
        day.date = this.$moment(day.date).format('x');
        day.purchase = element.purchase;
        day.purchaseAdult = element.purchaseAdult;
        day.purchaseBoy = element.purchaseBoy;
        return day;
      });
      this.idsList.push(element.idList);
      const send = {
        ...this.schedule,
        dates: dates,
        userId: this.user.id
      };
      this.isLoading = true;
      const instance = this;
      serviceVendor
        .post('schedules', send)
        .then(() => {
          instance.isLoading = false;
          this.$buefy.toast.open({
            message: 'Horarios agregados!',
            type: 'is-success'
          });
          this.$emit('newSchedules', this.type);
        })
        .catch(() => {
          this.isLoading = false;
          this.$buefy.toast.open({
            duration: 9000,
            message: this.$t('errors.request'),
            type: 'is-danger'
          });
        });
    },
    addSchedulesManualMultiple (schedules) {
      const dates = [];
      schedules.forEach((sche) => {
        if (sche.automatic) {
          sche.days.forEach((day) => {
            const dayAdd = {};
            dayAdd.open = this.$moment(
              this.$moment(day.date).format('YYYY-MM-DD') +
              ' ' +
              this.$moment(sche.open).format('HH:mm:ss')
            ).format('x');
            dayAdd.close = this.$moment(
              this.$moment(day.date).format('YYYY-MM-DD') +
              ' ' +
              this.$moment(sche.close).format('HH:mm:ss')
            ).format('x');
            dayAdd.interval = sche.interval;
            dayAdd.date = this.$moment(day.date).format('x');
            dates.push(dayAdd);
          });
          this.idsList.push(sche.idList);
        }
      });
      if (dates.length > 0) {
        const send = {
          ...this.schedule,
          dates: dates,
          userId: this.user.id
        };
        this.isLoading = true;
        serviceVendor
          .post('/schedules', send)
          .then(() => {
            this.isLoading = false;
            this.$buefy.toast.open({
              message: 'Horarios agregados!',
              type: 'is-success'
            });
            this.$emit('newSchedules', this.type);
          })
          .catch(() => {
            this.isLoading = false;
            this.$toast.open({
              duration: 9000,
              message: this.$t('errors.request'),
              type: 'is-danger'
            });
          });
      }
    }
  }
};
</script>

<style lang="scss">
.center-button {
  display: flex;
  justify-content: center;
  align-items: center;
}
.vdatetime-input {
  width: 100%;
}
.container_years {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}
.date_manual {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}
.button_day {
  cursor: pointer;
  &.active {
    background-color: #1cc9ed;
    color: white;
    i {
      background-color: white !important;
    }
  }
}
.card_new_schedules {
  .columns {
    max-height: 74vh;
    overflow: auto;
  }
}
.card_new_schedule {
  min-height: 230px;
  margin-top: 15px;
  .card-content {
    padding: 0;
    display: flex;
  }
  &.more_input {
    min-height: 300px;
  }
}
.first_new_card {
  width: 15%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.end_new_card {
  margin-top: 20px;
  width: 100%;
  .title_new_card {
    font-size: 24px;
    margin-left: 27%;
  }
}
.days_new {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  button {
    width: 140px;
    height: 55px;
    border-radius: 20px;
    margin-right: 10px;
    margin-left: 10px;
    border: none;
    box-shadow: 0px 3px 6px #9c9c9c !important;
  }
}
.day_new {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  i {
    width: 28px;
    height: 28px;
  }
}
.select_new {
  appearance: none;
  width: 100px;
  height: 40px;
  background-image: url("../../../assets/icons/icon_select_schedule.svg");
  background-position: center;
  background-size: 8px;
  background-repeat: no-repeat;
  background-position-x: right;
  border: none;
  padding-left: 5px;
  font-size: 15px;
}
.times_new {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  button {
    width: 140px;
    height: 55px;
    border-radius: 20px;
    border: none;
    box-shadow: 0px 3px 6px #9c9c9c !important;
  }
}
.dates_specific {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  button {
    width: 140px;
    height: 55px;
    border-radius: 20px;
    border: none;
    box-shadow: 0px 3px 6px #9c9c9c !important;
  }
}
.time_select {
  display: flex;
  align-items: center;
  margin-left: 40px;
}
.inicio_final_time,
.interval_time {
  display: flex;
}
.button_time {
  margin-right: 15px;
  margin-left: -40px;
  width: 190px !important;
}
.button_time_des {
  background-color: white;
  position: relative;
  label {
    font-size: 15px;
  }
}
.select_new {
  option:hover {
    background-color: black !important;
  }
}
.select_new:focus-visible {
  outline: none;
}
.inicio_time,
.final_time {
  display: flex;
}
@media only screen and (max-width: 1440px) {
  .title_new_card {
    margin-left: 8% !important;
  }
}
@media only screen and (max-width: 1245px) {
  .days_new {
    flex-direction: column;
    align-items: center;
  }
  .second_days {
    margin-top: 10px;
  }
  .times_new {
    margin-bottom: 10px;
  }
  .dates_specific {
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 1125px) {
  .days_new {
    button {
      width: 105px;
    }
  }
  .day_new {
    font-size: 13px;
    i {
      width: 24px;
      height: 24px;
    }
  }
  .title_new_card {
    margin-left: 3% !important;
    font-size: 21px !important;
  }
  .button_time_des {
    width: 115px !important;
    label {
      font-size: 13px;
    }
  }
  .button_time {
    width: 155px !important;
    select {
      font-size: 13px;
    }
  }
  .select_new {
    background-color: transparent;
  }
}
@media only screen and (max-width: 945px) {
  .days_new {
    flex-direction: column;
    align-items: center;
    button {
      height: 45px !important;
    }
  }
  .second_days {
    margin-top: 10px;
  }
  .title_new_card {
    margin-left: 12% !important;
  }
  .times_new {
    flex-direction: column;
    button {
      height: 45px;
    }
  }
  .dates_specific {
    flex-direction: column;
    button {
      height: 45px;
    }
  }
  .interval_time {
    margin-top: 10px;
  }
  .label_delete {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 425px) {
  .days_new {
    button {
      margin-right: 5px;
      margin-left: 5px;
      height: 35px !important;
      width: 90px;
    }
  }
  .day_new {
    font-size: 11px;
    i {
      width: 20px;
      height: 20px;
    }
  }
  .title_new_card {
    margin-left: 2% !important;
    font-size: 19px !important;
  }
  .time_select {
    i {
      margin-right: 0;
      height: 15px;
      width: 15px;
    }
  }
  .button_time_des {
    width: 88px !important;
    height: 27px !important;
    label {
      font-size: 11px;
    }
  }
  .button_time {
    height: 27px !important;
    width: 143px !important;
    margin-right: 5px;
  }
  .select_new {
    font-size: 11px !important;
    width: 75px;
    height: 28px;
  }
  .card_new_schedules {
    .horarios {
      font-size: 15px;
      justify-content: center;
      button {
        height: 27px;
        width: 102px;
      }
      i {
        height: 35px;
        width: 35px;
      }
    }
  }
}
@media only screen and (max-width: 620px) {
  .first_days {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}
@media only screen and (max-width: 615px) {
  .inicio_final_time {
    flex-direction: column;
  }
  .final_time {
    margin-top: 10px;
  }
}
@media only screen and (max-width: 410px) {
  .first_days {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    button {
      margin-top: 5px;
    }
  }
  .inicio_time,
  .final_time {
    display: flex;
  }
  .inicio_final_time {
    flex-wrap: wrap;
    justify-content: center;
  }
}

@media only screen and (max-height: 850px) {
  .card_new_schedules {
    .columns {
      max-height: 72vh;
    }
  }
}

@media only screen and (max-height: 740px) {
  .card_new_schedules {
    .columns {
      max-height: 68vh;
    }
  }
}

@media only screen and (max-height: 630px) {
  .card_new_schedules {
    .columns {
      max-height: 60vh;
    }
  }
}
</style>
