<template>
  <div class="card" id="newInformationModal">
    <div class="card-content">
      <div class="content">
        <div class="newInformationModalTitle">{{ titleDefault }}</div>
        <div class="newInformationModalDescription">{{ descriptionDefault }}</div>
        <div class="newInformationModalImagen" :style="backgroundImageStyle"></div>
        <div class="newInformationModalClose">
          <button @click="setClose()">
            <span>Cerrar</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['info'],
  data () {
    return {
      titleDefault: '¡Conoce el nuevo modulo de marketing!',
      descriptionDefault: 'Podrás crear tus mensajes de texto y correos electrónicos con toda la información para tus clientes. Este nuevo modulo lo tendrás disponible en la barra de herramientas costado izquierdo de la pantalla principal del dashboard.',
      imageDefault: null,
      idDefault: null,
      fallbackImageUrl: 'https://images.homify.com/c_fill,f_auto,q_0,w_740/v1526483607/p/photo/image/2561426/3.jpg',
      backgroundImageStyle: {}
    };
  },
  mounted () {
    const data = this.info;
    if (data) {
      if (data.title) {
        this.titleDefault = data.title;
      }
      if (data.description) {
        this.descriptionDefault = data.description;
      }
      if (data.image) {
        this.imageDefault = data.image;
      }
      if (data._id) {
        this.idDefault = data._id;
      }
      this.checkImage(this.imageDefault, this.fallbackImageUrl);
    }
  },
  methods: {
    setClose () {
      this.$emit('close', true);
    },
    checkImage (url, fallbackUrl) {
      if (url) {
        const img = new Image();
        img.src = url;

        img.onload = () => {
          this.backgroundImageStyle = {
            backgroundImage: `url(${url})`
          };
        };

        img.onerror = () => {
          this.backgroundImageStyle = {
            backgroundImage: `url(${fallbackUrl})`
          };
        };
      } else {
        this.backgroundImageStyle = {
          backgroundImage: `url(${fallbackUrl})`
        };
      }
    }
  }
};
</script>
<style lang="scss">
#newInformationModal {
  .newInformationModalTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    text-align: center;
    font-family: "Source Sans Pro";
    font-size: 21px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 12px;
    @media screen and (max-width: 767px) {
      font-size: 18px;
    }
  }
  .newInformationModalDescription {
    width: 580px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    text-align: center;
    font-family: "Source Sans Pro";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 20px;
    @media screen and (max-width: 767px) {
      font-size: 14px;
      width: 292px;
    }
  }
  .newInformationModalImagen {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 597px;
    height: 348px;
    flex-shrink: 0;
    margin-bottom: 20px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    @media screen and (max-width: 767px) {
      width: 335px;
      height: 224px;
      flex-shrink: 0;
    }
  }
  .newInformationModalClose {
    display: flex;
    justify-content: center;
    align-items: center;
    button {
      width: 200px;
      height: 35px;
      flex-shrink: 0;
      border-radius: 8px;
      border: 1px solid #444B57;
      background: #FFF;
      cursor: pointer;
      span {
        color: #444B57;
        text-align: center;
        font-family: "Source Sans Pro";
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
  }
}
</style>
