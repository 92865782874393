<template>
  <div class="content-modal_calendar" id="modal-blockHours">
    <button @click.prevent.stop="closeBlocking" class="close_button" v-if="!isLoading">
      <i class="fa fa-times"></i>
    </button>
    <p class="Atention">¡Atención!</p>
    <p class="Litle-text">Desea bloquear este día</p>
    <div class>
      <div class="box-loading" v-if="loading">
        <i class="icon-mks icon_reservations loading rotating"></i>
        <span>Buscando horarios ...</span>
      </div>
      <span v-if="!loading && !dates.length">No hay horas disponibles</span>
      <div class="content-hours" v-if="!loading">
        <button
          :class="{blokedhor: isBlocked(date.dateComplete) }"
          :key="index"
          :style="{background: isBlocked(date.dateComplete) ? '#F44336 !important' : 'transparent'}"
          @click.prevent.stop="toggleBlocked(date.dateComplete)"
          class="btn-block-hour"
          v-for="(date, index) in dates"
        >
          <i
            :style="{color: date.status == null ? '#ffbe2b' :date.status == true ? '#47CC27' : '#F83037'}"
            class="fa fa-circle"
          ></i>
          {{ date.dateComplete | moment('hh:mm a')}}
        </button>
      </div>
    </div>
    <div class="availability-hours">
      <span
        :key="index"
        class="availability-option"
        v-for="(lector,index) in readerHours"
      >
        <i :style="{color:lector.color}" class="fa fa-circle"></i>
        {{ lector.text }}
      </span>
    </div>
    <ValidateAction @changeInfo="setData($event)" @disableButton="disableButtonOk = $event" />
    <section class="waiting-list" v-if="params.activateWaitingList && !loading">
      <p class="waiting-text">¿Deseas desactivar la lista de espera?</p>
      <div class="waiting-checkbox-container">
        <b-field>
          <b-checkbox v-model="waitingYes" @input="waitingNo = false; updateCheckbox()" type="is-warning">
            Si
          </b-checkbox>
        </b-field>
        <b-field>
          <b-checkbox v-model="waitingNo" @input="waitingYes = false; updateCheckbox()" type="is-warning">
            No
          </b-checkbox>
        </b-field>
      </div>
    </section>
    <button
      :class="{'button-ok-disable': disableButtonOk}"
      @click.prevent.stop="blockSlots"
      class="button-white-ok"
      v-if="!loading"
    >
      <i class="fa fa-check"></i> Aceptar
    </button>
  </div>
</template>
<script>
import _ from 'lodash';
import ValidateAction from './ValidationAction.vue';
import reservationTypes from '@/store/types/reservation';
export default {
  props: ['value'],
  components: {
    ValidateAction
  },
  data: () => ({
    dates: {},
    blockedHourInfo: {},
    slotBlocked: [],
    slotUnBlocked: [],
    disableButtonOk: false,
    loading: false,
    isLoading: false,
    hostId: null,
    waitingYes: false,
    waitingNo: true,
    readerHours: [
      { color: '#f83037', text: 'Ocupado' },
      { color: '#47cc27', text: 'Disponible' },
      { color: '#ffbe2b', text: 'Disponible para restaurante' }
    ]
  }),
  mounted () {
    this.loading = true;
    const timeHour = this.$moment(this.dateComplete).format('x');
    let statusHours = [];
    Promise.all([
      this.$store.dispatch({ type: 'reservation:blockedHours', data: { vendorId: this.vendor.id, date: this.selectedDate } })
        .then((response) => {
          this.blockedHourInfo = response.data.data;
          if (response.data.blockedList) {
            this.waitingYes = true;
            this.waitingNo = false;
          } else {
            this.waitingYes = false;
            this.waitingNo = true;
          }
        }).catch(() => {
          this.loading = false;
        }),
      this.$store.dispatch({
        type: 'reservation:availability',
        data: {
          vendorId: this.vendorId,
          date: timeHour,
          people: 2
        }
      }).then(function (response) {
        if (response.data.length !== 0) {
          statusHours = response.data;
        }
      }).catch(() => {
        this.loading = false;
      })
    ]).then(() => {
      const datesFinal = [];
      const m = this.$moment(this.dateComplete).format('MM');
      const y = this.$moment(this.dateComplete).format('YYYY');
      const d = this.$moment(this.dateComplete).format('DD');
      const dayOfWeek = this.$moment(this.dateComplete).format('d');
      if (Object.keys(this.timesDashboard.extraPublic).length > 0) {
        if (this.timesDashboard.extraPublic[this.selectedDate] !== undefined) {
          this.timesDashboard.extraPublic[this.selectedDate].forEach(function (element) {
            const hora = element.hora;
            const horas = hora.split(':');
            const newDate = `${y}-${m}-${d} ${horas[0]}:${horas[1]}:00`;
            datesFinal.push(newDate);
          });
        } else {
          if (Object.keys(this.timesDashboard[dayOfWeek]).length > 0) {
            this.timesDashboard[dayOfWeek].forEach(function (element) {
              const hora = element.hora;
              const horas = hora.split(':');
              const newDate = `${y}-${m}-${d} ${horas[0]}:${horas[1]}:00`;
              datesFinal.push(newDate);
            });
          }
        }
      } else if (Object.keys(this.timesDashboard[dayOfWeek]).length > 0) {
        this.timesDashboard[dayOfWeek].forEach(function (element) {
          const hora = element.hora;
          const horas = hora.split(':');
          const newDate = `${y}-${m}-${d} ${horas[0]}:${horas[1]}:00`;
          datesFinal.push(newDate);
        });
      }
      this.dates = this.updateHours(datesFinal, statusHours);
      this.loading = false;
    });
  },
  computed: {
    isOpen: {
      get () {
        return this.value;
      },
      set (value) {
        this.$emit('input', value);
      }
    }
  },
  methods: {
    updateCheckbox () {
      setTimeout(() => {
        if (!this.waitingYes && !this.waitingNo) {
          this.waitingNo = true;
        }
      }, 100);
    },
    closeBlocking () {
      this.isOpen = false;
      this.$store.commit(reservationTypes.mutations.setBlockingTime, false);
    },
    setData (data) {
      this.hostId = data.hostId || null;
      this.disableButtonOk = false;
    },
    updateHours (datesFinal, statusHours) {
      const hoursFinal = [];
      if (statusHours.length > 0) {
        statusHours.forEach((e) => {
          datesFinal.forEach((el) => {
            if (e.dateTime.includes(el)) {
              hoursFinal.push({ dateComplete: el, status: e.status });
            }
          });
        });
      } else {
        // eslint-disable-next-line
        datesFinal.forEach((el) => {
          // eslint-disable-next-line
          if (e.dateTime.includes(el)) {
            // eslint-disable-next-line
            hoursFinal.push({ dateComplete: el, status: null })
          }
        });
      }
      return hoursFinal;
    },
    isBlocked (timestamp) {
      return _.includes(this.slotBlocked, timestamp);
    },
    toggleBlocked (timestamp) {
      if (_.includes(this.blockedHourInfo, timestamp)) {
        if (_.includes(this.slotUnBlocked, timestamp) === false) {
          this.slotUnBlocked.push(timestamp);
        }
        const is = this.slotBlocked.indexOf(timestamp);
        this.slotBlocked.splice(is, 1);
      } else {
        this.slotBlocked.push(timestamp);
        if (_.includes(this.slotUnBlocked, timestamp)) {
          const iss = this.slotUnBlocked.indexOf(timestamp);
          this.slotUnBlocked.splice(iss, 1);
        }
      }
    },
    blockSlots () {
      this.isLoading = true;
      if (this.slotBlocked.length > 0 || this.slotUnBlocked.length > 0) {
        this.$store.dispatch({
          type: 'reservation:blockedUnBlocked',
          data: {
            vendorId: this.vendor.id,
            userId: this.user.id,
            hostId: this.hostId,
            isWaitinglistBlocked: this.waitingYes,
            slots: {
              blocked: this.slotBlocked,
              unblocked: this.slotUnBlocked
            }
          }
        }).then(() => {
          this.isLoading = false;
          this.isOpen = false;
          this.$emit('eventBlockCalendar');
          this.$store.commit(reservationTypes.mutations.setBlockingTime, false);
        });
      } else {
        this.isLoading = false;
        this.isOpen = false;
        this.$store.commit(reservationTypes.mutations.setBlockingTime, false);
      }
    }
  },
  watch: {
    selectedDate () {
      this.loading = true;
      const timeHour = this.$moment(this.dateComplete).format('x');
      let statusHours = [];
      Promise.all([
        this.$store.dispatch({ type: 'reservation:blockedHours', data: { vendorId: this.vendor.id, date: this.selectedDate, hostId: this.hostId, description: this.description } })
          .then((response) => {
            this.blockedHourInfo = response.data.data;
            if (response.data.blockedList) {
              this.waitingYes = true;
              this.waitingNo = false;
            } else {
              this.waitingYes = false;
              this.waitingNo = true;
            }
          }).catch(() => {
            this.loading = false;
          }),
        this.$store.dispatch({
          type: 'reservation:availability',
          data: {
            vendorId: this.vendorId,
            date: timeHour,
            people: 1
          }
        }).then(function (response) {
          if (response.data.length !== 0) {
            statusHours = response.data;
          }
        }).catch(() => {
          this.loading = false;
        })
      ]).then(() => {
        const datesFinal = [];
        const m = this.$moment(this.dateComplete).format('MM');
        const y = this.$moment(this.dateComplete).format('YYYY');
        const d = this.$moment(this.dateComplete).format('DD');
        const dayOfWeek = this.$moment(this.dateComplete).format('d');
        if (Object.keys(this.timesDashboard.extraPublic).length > 0) {
          if (this.timesDashboard.extraPublic[this.selectedDate] !== undefined) {
            this.timesDashboard.extraPublic[this.selectedDate].forEach(function (element) {
              const hora = element.hora;
              const horas = hora.split(':');
              const newDate = `${y}-${m}-${d} ${horas[0]}:${horas[1]}:00`;
              datesFinal.push(newDate);
            });
          } else {
            if (Object.keys(this.timesDashboard[dayOfWeek]).length > 0) {
              this.timesDashboard[dayOfWeek].forEach(function (element) {
                const hora = element.hora;
                const horas = hora.split(':');
                const newDate = `${y}-${m}-${d} ${horas[0]}:${horas[1]}:00`;
                datesFinal.push(newDate);
              });
            }
          }
        } else if (Object.keys(this.timesDashboard[dayOfWeek]).length > 0) {
          this.timesDashboard[dayOfWeek].forEach(function (element) {
            const hora = element.hora;
            const horas = hora.split(':');
            const newDate = `${y}-${m}-${d} ${horas[0]}:${horas[1]}:00`;
            datesFinal.push(newDate);
          });
        }
        this.dates = this.updateHours(datesFinal, statusHours);
        this.loading = false;
      });
    },
    blockedHourInfo () {
      this.slotBlocked = this.blockedHourInfo;
    }
  }
};
</script>

<style lang="scss" scoped>
#modal-blockHours {
  &.content-modal_calendar {
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: space-between;
    border-right: 1px solid #fff;
    width: 335px;
    padding: 20px 10px;
  }

  .content-hours {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    max-height: 80px;
    overflow: auto;
    background: transparent;
    border: 1px solid #fff;
    border-radius: 10px 0 0 10px;
    border-right: none;
    .btn-block-hour {
      font-size: 12px;
      display: flex;
      padding: 5px;
      align-items: center;
      justify-content: center;
      flex: 1;
      white-space: nowrap;
      background: transparent;
      color: #fff;
      border: none;
      border-right: 1px solid #fff;
      border-bottom: 1px solid #fff;
      i {
        font-size: 10px;
        margin-right: 5px;
      }
    }
  }

  .close_button {
    background: transparent;
    color: #fff;
    border: none;
    position: absolute;
    cursor: pointer;
    top: 12px;
    right: 8px;
  }
  .Atention {
    font-weight: bold;
    color: #fff !important;
    font-size: 18px;
    line-height: 1;
  }
  .Litle-text {
    color: #fff;
    font-size: 14px;
    font-weight: 400;
  }
  .icon_box {
    position: relative;
    .number-d {
      color: #fff;
      position: absolute;

      top: 42%;
      left: 42%;
      font-size: 14px !important;
    }
    i {
      padding: 0px 10px;
      margin-top: -4px;
      height: 50px;
      width: 50px;
      margin-left: 10px;
    }
  }
  .date {
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    text-transform: capitalize;
  }
  .availability-hours{
    margin-top: -8px;
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 20px;
    .availability-option{
      align-items: center;
      font-size: 11px;
      display: flex;
      gap: 10px;
      font-weight: 400;
      i{
        font-size: 10px;
      }
    }
  }
  button.button-white-ok {
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    padding: 5px 20px 5px 20px;
    font-size: 13px;
    font-weight: 700;
    border: 1px solid black;
    cursor: pointer;
    i {
      padding: 0px;
      margin-top: 0px;
      font-size: 20px;
      height: auto;
      width: auto;
      margin-right: 5px;
    }
  }
  .button-ok-disable {
    pointer-events: none;
    cursor: no-drop;
    opacity: 0.7;
    user-select: none;
  }
}
</style>

<style lang="scss">
@import "@/assets/styles/waitingList/_index";
#modal-blockHours{

  #validationAction{
    margin-top: 10px;
  }

  .waiting-list{
    display: flex;
    margin-top: 0px;
    align-items: center;
    justify-content: center;
    gap: 20px;
    .waiting-text{
      width: auto;
    }
    .waiting-text, .waiting-checkbox-container{
      margin-top: 0;
    }
    .waiting-checkbox-container{
      gap: 10px;
      padding-top: 5px;
      .field{
        margin: 0;
      }
    }
  }

  ::-webkit-scrollbar{
    width: 4px !important;
    height: 4px !important;
  }
  ::-webkit-scrollbar-thumb {
    border: 1px solid #2B313A !important;
    background-color: #2B313A !important;
    box-shadow: none !important;
  }
}
</style>
