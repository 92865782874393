import namespace from '@/helpers/namespace';

export default namespace('global', {
  actions: [
    'changeLanguage',
    'vendorId',
    'vendor',
    'reservation',
    'getPermissions',
    'getServerTime',
    'setVersion',
    'getReferrers',
    'getCustomPDF',
    'trackInfoUserTermsConditions',
    'getUserLogTermsConditionsAccepted'
  ],
  getters: [
    'language',
    'vendorUrl',
    'url',
    'vendorId',
    'idVendor',
    'vendor',
    'online',
    'reservation',
    'width',
    'height',
    'socket',
    'permissions',
    'sideBarState',
    'serverTime',
    'editMap',
    'showMessage',
    'version',
    'versionApp',
    'showSideBar',
    'referrers',
    'chooseVendor',
    'showTurnDetail'
  ],
  mutations: [
    'startProcessing',
    'stopProcessing',
    'setLanguage',
    'setVendorUrl',
    'setOnline',
    'setVendor',
    'setVendorId',
    'setId',
    'setReservation',
    'setWidth',
    'setHeight',
    'setSocket',
    'setUrl',
    'setPermissions',
    'setSideBarState',
    'setServerTime',
    'setEditMap',
    'setShowMessage',
    'setVersion',
    'setVersionApp',
    'setShowSideBar',
    'setLoaderPrecompro',
    'setReferrers',
    'setChooseVendor',
    'setShowTurnDetail'
  ]
});
