<template>
  <div class="component-box">
    <div class="header-component">
      <i class="icon-mks black icon_adjunt_add_reservation i-adjunt-component"></i>Archivos Adjuntos
    </div>
    <div class="adjuntFile-component">
      <b-loading :is-full-page="true" :active.sync="loading" :can-cancel="false"></b-loading>
      <div class="adjuntFile-component-actions" v-if="!loading">
        <div class="custom-file">
          <label for="file" class="custom-file-label">Elegir archivo</label>
          <span class="custom-file-text" v-if="!adjuntFile">Ningún archivo seleccionado</span>
          <input type="file" id="file" class="custom-file-opacity" @change="uploadFileAdjunt('file')" multiple="multiple">
        </div>
        <div>
          <b-switch v-model="sendToClient" size="is-small"
            :true-value="1"
            :false-value="0">
              {{ (sendToClient == 1) ? 'Enviar al cliente' : 'No enviar al cliente' }}
          </b-switch>
        </div>
      </div>
      <div style="width: 100%" v-if="!loading">
        <div style="width: 100%">
          <b-tag type="is-black"
            v-if="adjuntFile"
            style="margin-right: 5px;"
            closable aria-close-label="Close tag"
            @close="deleteFileAdjunt()"
          >
          {{ transf(adjuntFile) }}
          <i class="fa fa-cloud-download" style="cursor: pointer;" @click="download(adjuntFile)" title="Descargar"></i>
        </b-tag>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import serviceAdmin from '@/helpers/apis/serviceAdmin';
import LocalStorage from 'store';
export default {
  props: ['editAdjunt', 'initValue'],
  data () {
    return {
      loading: false,
      sendToClient: false,
      adjuntFile: null,
      value: {}
    };
  },
  mounted () {
    if (this.initValue) {
      this.adjuntFile = this.initValue;
    }
    this.listFileStorage();
  },
  methods: {
    download (link) {
      window.open(link);
    },
    listFileStorage () {
      if (this.editAdjunt) {
        const urlStorage = LocalStorage.get('fileUploaded');
        if (urlStorage !== undefined) {
          this.adjuntFile = urlStorage;
        }
      }
    },
    transf (file) {
      const name = file.split('/').pop();
      return name;
    },
    uploadFileAdjunt (id) {
      const files = document.getElementById(id).files;
      let listAll = null;
      if (LocalStorage.get('fileUploaded') !== undefined) listAll = LocalStorage.get('fileUploaded');
      for (var i = 0; i < files.length; i++) {
        var formData = new FormData();
        formData.append('content', files[i]);
        const Id = (Math.random() * 10 + '').replace('.', '');
        const fileName = 'Adjunt/' + this.vendor.displayName + '/' + Id + '_' + files[i].name;
        formData.append('fileName', fileName.replace(/ /g, '_'));
        formData.append('vendorId', this.vendor.id);
        formData.append('optimize', 0);
        this.loading = true;
        serviceAdmin.post('/cloudStorage/upload/image', formData)
          .then(({ data }) => {
            LocalStorage.set('fileUploaded', listAll);
            this.loading = false;
            this.$buefy.notification.open({
              message: '<i class="icon_alert_success icon-mks green"></i>' + data.message,
              type: 'is-success'
            });
            this.adjuntFile = data.data;
            LocalStorage.set('fileUploaded', data.data);
          }).catch(err => {
            this.loading = false;
            this.$buefy.notification.open({
              message: '<i class="icon_alert_danger icon-mks red"></i>' + err.response.data.message,
              type: 'is-danger'
            });
          });
      }
    },
    deleteFileAdjunt () {
      this.loading = true;
      this.adjuntFile = null;
      LocalStorage.remove('fileUploaded');
      this.loading = false;
      this.$buefy.notification.open({
        message: '<i class="icon_alert_success icon-mks green"></i>Se han eliminado con éxito los archivos',
        type: 'is-success'
      });
    }
  },
  watch: {
    adjuntFile: function (val) {
      this.$emit('adjuntFile', { adjuntFile: val, sendToClient: this.sendToClient });
    },
    sendToClient: function (val) {
      this.$emit('adjuntFile', { adjuntFile: this.adjuntFile, sendToClient: val });
    }
  }
};
</script>
<style lang="scss">
.adjuntFile-component {
  display:flex;
  overflow: hidden;
  border-radius: 0 0 5px 5px;
  max-height: 150px;
  overflow: auto;
  flex-wrap: wrap;
  margin: 10px;
  &-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 30px;
    width: 100%;
    input[type="file" i]::-webkit-file-upload-button {
      background-color: white;
      border-radius: 8px;
      outline: unset;
      border: 1px solid #ECEDEE;
      padding: 5px;
    }
  }
  .custom-file {
    height: 100%;
    display: flex;
    &-label {
      border: 1px solid #ECEDEE;
      border-radius: 4px;
      padding: 3px;
      font-family: 'Roboto';
      font-size: 12px;
    }
    &-opacity {
      opacity: 0;
      width: 0px;
    }
    &-text {
      font-family: "Roboto";
      font-size: 10px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 10px;
    }
  }
  .switch:hover input[type=checkbox]:checked + .check {
    background: #000000 !important;
  }
  .switch input[type=checkbox]:checked + .check {
    background: #000000 !important;
  }
  .switch:hover input[type=checkbox] + .check {
    background: rgba(181, 181, 181, 0.9);
  }
}
.i-adjunt-component {
  height: 12px;
  width: 12px;
  margin-top: 1px;
}

@media (max-width: 820px) {
  .adjuntFile-component{
    .custom-file {
    &-label {
      width: 6rem;
      font-size: 11px;
      line-height: 2.2em;
      text-align: center;
    }
  }
  }

}
</style>
