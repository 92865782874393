import namespace from '@/helpers/namespace';

export default namespace('reservation', {
  getters: [
    'groupUrlVendor',
    'groupVendor',
    'typeApiReportDownload',
    'apiReportDownload',
    'loadingReservation',
    'showBlockedHours',
    'showBlockedDay',
    'showBlockedRange',
    'selectTableInfo',
    'tables',
    'sections',
    'selectTableId',
    'notifications',
    'times',
    'blocked',
    'blockedHours',
    'availability',
    'reservations',
    'reservationsDay',
    'schedules',
    'map',
    'list',
    'selectedMonth',
    'selectedDate',
    'dateFilterSelect',
    'sectionFilterSelect',
    'addReservation',
    'addWalkIn',
    'type',
    'infoReservation',
    'editReservation',
    'sectionSelected',
    'tableSelected',
    'tableSelectedId',
    'viewReservation',
    'verticalReservation',
    'requirements',
    'originVendor',
    'originVendorPeople',
    'updateSystem',
    'allEvents',
    'addOriginPeople',
    'addOriginDetail',
    'additionalQuestions',
    'modules',
    'sidelateral',
    'sidelateralMove',
    'sidelateralSearch',
    'fromMap',
    'theTurns',
    'companionsData',
    'rotation',
    'reservationDashboard',
    'reservationDashboardClean',
    'frontModal',
    'getReservationHourTimeLine',
    'fromTimeLine',
    'blockingDay',
    'blockingTime',
    'mealTypeDay'
  ],
  actions: [
    'getModules',
    'allEvents',
    'getGroup',
    'deleteReport',
    'generateReport',
    'generateReportPrevius',
    'generateReportUsers',
    'validateReservation',
    'updateReservationWalkIn',
    'generateReportUsersEvent',
    'getStatusReport',
    // 'reservationEliminar',
    'getInfoTracking',
    'getReservationUser',
    'getListUsers',
    'reservationEmail',
    'blockedDay',
    'unBlockedDay',
    'blockedDayTable',
    'unBlockedDayTable',
    'blockedHourTable',
    'unBlockedHourTable',
    'blockedUnBlocked',
    'blockedRange',
    'blockedHourSeatingSection',
    'blockedHourSeatingSubSection',
    'unBlockedHourSeatingSection',
    'unBlockedHourSeatingSubSection',
    'blockedHourSeatingSubSection',
    'blockedDaySeatingSection',
    'unBlockedDaySeatingSection',
    'getZoneIsBlockedDetail',
    'unBlockedDaySeatingSubSection',
    'blockedDaySeatingSubSection',
    'sections',
    'tables',
    'tableSection',
    'tablesSections',
    'notificationId',
    'notifications',
    'addWalkIn',
    'addReservation',
    'addReservationLink',
    'updateReservation',
    'reservationIsCancelled',
    'reservationIsConfirmed',
    'reservationIsSeatedPartial',
    'reservationIsSeated',
    'reservationIsFinish',
    'reservationIsNoShow',
    'undoReservationStatus',
    'blocked',
    'blockedHours',
    'availability',
    'sectionStatus',
    'subSections',
    'reservations',
    'reservationsDay',
    'reservationNew',
    'reservationNewDate',
    'times',
    'schedules',
    'map',
    'list',
    'searchHost',
    'notificationIsArch',
    'notificationIsSeen',
    'setBlockedSection',
    'setUnBlockedSection',
    'setBlockedTableSection',
    'setUnBlockedTableSection',
    'getRequirements',
    'getOriginVendor',
    'getReferersVendors',
    'getOriginVendorPeople',
    'undoReservationStatusCancelled',
    'notificationIsReviewed',
    'validateReservationTable',
    'addOriginPeople',
    'addOriginDetail',
    // 'reservationIsChefCooking',
    'getAdditionalQuestions',
    'saveAnswersQuestions',
    'getAnswers',
    'editValueConsumed',
    'getGiftCardParams',
    'getGiftCardVendor',
    'getGiftCardConfig',
    'updateGiftCardConfig',
    'newGiftCardConfig',
    'giftCardRedemption',
    'validateGiftCard',
    'DragReservation',
    'sidelateral',
    'sidelateralMove',
    'sidelateralSearch',
    'getSearched',
    'theTurns',
    'companions',
    'companionList',
    'companionDelete',
    'companionUpdate',
    'getRotation',
    'doReservationHourTimeLine'
  ],
  mutations: [
    'setModules',
    'setAllEvents',
    'setGroupUrlVendor',
    'setGroupVendor',
    'setTypeApiReportDownload',
    'setApiReportDownload',
    'setLoadingReservation',
    'setSections',
    'setTables',
    'setNotifications',
    'setSelectTableId',
    'setBlocked',
    'setTimes',
    'setMap',
    'setList',
    'setBlockedHours',
    'setAvailability',
    'setReservations',
    'setReservationsDay',
    'setSelectTableInfo',
    'setSelectedMonth',
    'setSelectedDate',
    'setSchedules',
    'setDateFilterSelect',
    'setSectionFilterSelect',
    'setShowBlockedHours',
    'setShowBlockedDay',
    'setShowBlockedDay',
    'setShowBlockedRange',
    'setAddReservation',
    'setAddWalkIn',
    'setInfoReservation',
    'setViewReservation',
    'setEditReservation',
    'setSectionSelected',
    'setTableSelected',
    'setTableSelectedId',
    'setResetDateFilterSelect',
    'setResetSectionFilterSelect',
    'setType',
    'setVerticalReservation',
    'setRequirements',
    'setOriginVendor',
    'setOriginVendorPeople',
    'setUpdateSystem',
    'setAddOriginPeople',
    'setAddOriginDetail',
    'setAdditionalQuestions',
    'setSideLateral',
    'setSideLateralMove',
    'setSideLateralSearch',
    'setFromMap',
    'setTurns',
    'setCompanionsData',
    'setRotation',
    'setReservationDashboard',
    'setFrontModal',
    'setReservationHourTimeLine',
    'hourFromTimeLine',
    'setFromTimeLine',
    'setBlockingDay',
    'setBlockingTime',
    'setMealTypeDay'
  ]
});
