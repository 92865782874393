<template>
  <div>
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <circle cx="9" cy="9" r="8.5" fill="#F3F3F3" stroke="#444B57"/>
      <circle cx="9" cy="9" r="5.5" fill="#444B57" stroke="#444B57" v-if="check"/>
    </svg>
  </div>
</template>
<script>
export default {
  props: {
    check: {
      type: Boolean,
      default: false
    }
  }
};
</script>
<style lang="scss">
.box-template {
  border-radius: 4px;
  border: 1px solid #444B57;
  background: #444B57;
  width: 15.75px;
  height: 18px;
  flex-shrink: 0;
  cursor: pointer;
}
</style>
