<template>
  <div id="malchimp-form">
    <div class="columns is-desktop style-columns">
      <div class="column is-4">
        <b-field
          :message="errors.first('key')"
          :type="{'is-danger': errors.has('key')}"
          label="MAILCHIMP KEY"
        >
          <b-input
            @icon-right-click="getListMailchimp"
            icon-right="check"
            icon-right-clickable
            name="key"
            v-model="key"
            v-validate="'required|min:30'"
          ></b-input>
        </b-field>
      </div>
      <div class="column is-4" v-if="listsMailChimp.length > 0">
        <b-field
          :message="errors.first('list')"
          :type="{'is-danger': errors.has('list')}"
          label="MAILCHIMP LIST"
          name="list"
        >
          <b-select
            expanded
            placeholder="Seleccione Una lista"
            v-model="list"
            v-validate="'required'"
          >
            <option
              :key="'selHost-' + option.id"
              :value="option.id"
              v-for="option in listsMailChimp"
            >{{ option.name }}</option>
          </b-select>
        </b-field>
      </div>
    </div>
    <div class="columns is-desktop style-columns">
      <div class="column is-4">
        <b-field>
          <b-button
            :loading="sending"
            @click="saveFeatures"
            icon-left="content-save"
            type="is-primary"
          >
            <span>Guardar</span>
          </b-button>
          <b-field>
            <b-button
              :loading="sending"
              @click="cleanUp"
              style="margin-left:0.5rem;"
              type="is-primary"
            >
              <span>Volver</span>
            </b-button>
          </b-field>
        </b-field>
      </div>
    </div>
  </div>
</template>

<script>
import globalTypes from '@/store/types/global';
import serviceVendor from '@/helpers/apis/serviceVendor';

export default {
  props: ['restaurant', 'dataIntegrations'],
  data () {
    return {
      sending: false,
      key: '',
      type: 'mailchimp',
      list: null,
      id: null,
      listsMailChimp: []
    };
  },
  mounted () {
    const integrationItems = this.dataIntegrations.find(inte => inte.type === this.type);
    if (integrationItems) {
      this.key = integrationItems.key;
      this.list = integrationItems.login;
      this.id = integrationItems.id;
      this.getListMailchimp();
    }
  },
  methods: {
    cleanUp () {
      this.$emit('cleanUpIntegration');
    },
    saveFeatures () {
      this.sending = true;
      this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: true, typeMessage: 3 });
      try {
        this.$validator.validateAll().then(result => {
          if (result) {
            this.$emit('saveUpdateIntegration', { key: this.key, type: this.type, id: this.id, login: this.list });
            this.sending = false;
            this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
            return;
          }
          this.sending = false;
          this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
        });
      } catch (error) {
        this.sending = false;
        this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
      }
    },
    getListMailchimp () {
      if (this.key.length < 30) {
        this.$buefy.toast.open({
          message: 'Debe ingregar una llave valida',
          type: 'is-danger'
        });
        return;
      }
      this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: true });
      serviceVendor.post('/mailchimp/getLists', { vendorId: this.restaurant.vendorId, key: this.key }).then(({ data }) => {
        this.listsMailChimp = data.data;
        this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
      }).catch((err) => {
        this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
        this.$buefy.toast.open({
          message: JSON.parse(err.request.response).message || 'Lo siento, ha ocurrido un error al intentar procesar tu petición',
          type: 'is-danger'
        });
      });
    }
  }
};
</script>

<style lang="scss">
#malchimp-form {
  .style-columns {
    display: flex;
    gap: 1rem;
    margin-top: 60px;
    padding: 10px 10px;
  }
  .textCelebrationArea {
    border: 2px solid #bbbbbba3;
    border-radius: 5px;
    font-size: 15px;
    height: 300px;
    width: -webkit-fill-available;
    resize: none;
  }
}
</style>
