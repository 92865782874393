<template>
  <div id="cardMarketing">
    <b-loading :can-cancel="false" :is-full-page="true" :active.sync="loading"></b-loading>
    <form
      class="payInfo__creditInformation"
      id="pay"
      name="pay"
    >
      <div class="payer-title">
        <span>Datos del pagador</span>
      </div>
      <input
        data-checkout="cardholderName"
        id="cardholderName"
        placeholder="Nombre como aparece en la tarjeta"
        required
        type="text"
        v-model="nameLikeCard"
      />
      <div class="selector-type-identity">
        <inputSelector
          @validatedRulesMax="maxLenChange"
          @validatedRulesMin="minLenChange"
          @value="(e) => (typePerson = e.countrieInfo, documentNumber = e.inputValue)"
          :mode="'identify'"
        />
      </div>
      <div class="phoneInput">
        <inputSelector
          @validatedRulesMax="maxLenChange"
          @validatedRulesMin="minLenChange"
          @value="inputNumberChange"
          :mode="'phone'"
        />
      </div>
      <input id="email" placeholder="Correo" required type="email" v-model="email" />

      <div class="card-title">
        <span>Datos de la tarjeta crédito</span>
      </div>
      <!-- Mercado pago V2-->
      <div class="mp_fields" v-if="!creditpaymentTypePAYU">
        <div
          :class="{ cvvHidden: !showCVV }"
          class="mp_fields-item w50 mr10"
          id="cardNumber"
          placeholder="Numero de la tarjeta"
          v-if="!creditpaymentTypePAYU"
        ></div>
        <div
          :class="{ cvvHidden: !showCVV }"
          class="mp_fields-item w20 mr10"
          id="securityCode"
          placeholder="CVV"
          v-if="!creditpaymentTypePAYU"
        ></div>
        <div
          :class="{ cvvHidden: !showCVV }"
          class="mp_fields-item w20"
          id="expirationDate"
          placeholder="Vencimiento"
          v-if="!creditpaymentTypePAYU"
        ></div>
      </div>
      <input
        :maxlength="maxAllowed"
        data-checkout="cardNumber"
        id="CardNumberInput"
        pattern="[0-9]{14,21}"
        placeholder="Numero de la tarjeta"
        required
        type="text"
        v-if="creditpaymentTypePAYU"
        v-model="cardNumber"
      />
      <div class="mesContainer" v-if="creditpaymentTypePAYU">
        <GeneralSelector
          :checkout="'cardExpirationMonth'"
          :id="'cardExpirationMonth'"
          :options="monthOptions"
          :placeHolder="'Mes'"
          :required="true"
          @value="(e) => (month = e)"
        />
      </div>
      <div class="anoContainer" v-if="creditpaymentTypePAYU">
        <GeneralSelector
          :checkout="'cardExpirationYear'"
          :id="'cardExpirationYear'"
          :options="yearOptions"
          :placeHolder="'Año'"
          :required="true"
          @value="(e) => (year = e)"
        />
      </div>
      <div class="NoCuotasContainer">
        <GeneralSelector
          :options="CoutesLimit"
          :placeHolder="'Cuotas'"
          :required="true"
          @value="(e) => (cuots = e)"
          v-if="cuotesReady"
        />
      </div>
      <div class="securePay">
        <p>Pagos seguros a través de</p>
        <span
          :class="{
            mercadoPagoImg: !creditpaymentTypePAYU,
            payuImg: creditpaymentTypePAYU,
          }"
        ></span>
      </div>
      <input id="saveCard" style="display: none" type="checkbox" value="false" />
      <input
        id="transactionAmount"
        name="transactionAmount"
        type="hidden"
        v-model="cardMercadoPago.net_amount"
      />
    </form>
    <div class="smsTermsOfConditions">
      <div class="smsTermsOfConditions-info">
        <Box v-if="termsConditions" @changeOpt="termsConditions = $event" :valueFaul="termsConditions"></Box>
        <img src="@/assets/icons/marketing/noBox.svg" @click="termsConditions = !termsConditions" class="items-check" alt="precompro.com" v-else>
        <span>Al darle clic en “Pagar” y continuar declara que conoce y acepta
          el tratamiento de datos personales previamente informado y está aceptando los
          <strong style="cursor: pointer;" @click="showModalTerms()">Términos y condiciones</strong> y la <strong style="cursor: pointer;" @click="showModalPol()">política de privacidad*</strong>
          de Precompro y del restaurante.
        </span>
      </div>
    </div>
    <button style="width: 100%;" :class="{ readyToSubmit: submitReady }" :disabled="!submitReady" @click="canPay()">Pagar</button>
    <div id="modal-terms">
      <b-modal :active.sync="showTerms">
        <div class="terms-body">
          <h3 class="title-modal">Política Tratamiento de Datos Personales de Click2buy S.A.S.</h3>
          <terms :fecha="'22 de Noviembre del 2019'"></terms>
        </div>
      </b-modal>
    </div>
    <div id="modal-terms">
      <b-modal :active.sync="showTermsCondiciones">
        <div class="terms-body">
          <h3 class="title-modal">Términos y condiciones</h3>
          <terms2 :fecha="'22 de Noviembre del 2019'"></terms2>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import GeneralSelector from '@/components/Marketing/GeneralSelector';
import servicePayment from '@/helpers/apis/servicePayment.js';
import inputSelector from '@/components/Marketing/payment/NumberInput.vue';
import marketingTypes from '@/store/types/marketing';
import terms from '@/components/Marketing/Terms';
import terms2 from '@/components/Marketing/Terms2';
import Box from '@/components/Marketing/Box';
import { v4 as uuidv4 } from 'uuid';
import LCrypt from 'lcrypt';
const lcrypt = new LCrypt(process.env.VUE_APP_TOKEN_API);
export default {
  name: 'CardComponent',
  components: {
    GeneralSelector,
    inputSelector,
    Box,
    terms,
    terms2
  },
  data () {
    return {
      showTerms: false,
      showTermsCondiciones: false,
      cvv: '',
      showPending: false,
      showCancel: false,
      reasonDeclined: null,
      monthOptions: [],
      yearOptions: [],
      termsConditions: true,
      cardType: '',
      typePersonOptions: [],
      typePerson: ' ',
      minDocumentnumbers: 9,
      maxDocumentnumbers: 9,
      documentNumber: '',
      email: '',
      name: '',
      cardNumber: '',
      nameLikeCard: '',
      payu: true,
      phone: '',
      CoutesLimit: [],
      timer: '',
      cardMercadoPago: {
        payer: {
          first_name: null,
          identification: {
            type: null,
            number: null
          },
          email: null
        },
        token: null,
        installments: null,
        description: null,
        provider: null,
        external_reference: null,
        payment_method_id: null,
        payment_type_id: 'credit_card',
        transaction_amount: 0,
        net_amount: 0,
        binary_mode: true,
        vendorId: null,
        phone: null,
        country: 'Colombia',
        bandera: 'https://api.precompro.com/banderas/co.png',
        indicativo: 57,
        taxes: [
          {
            value: 0,
            type: 'IVA'
          }
        ]
      },
      card: {
        name: null,
        identification: null,
        phone: null,
        address: null,
        city: null,
        card: null,
        mes: null,
        ano: null,
        cuota: null,
        expiration: null,
        balance: 0,
        checked: true
      },
      month: '',
      year: '',
      cuots: 0,
      cuotesReady: false,
      lock: false,
      loading: false,
      creditpaymentTypePAYU: false,
      creditOPtionsTypePerson: [],
      showCVV: true,
      submitDisabled: false,
      maxAllowed: 19,
      initBandera: '',
      serverTimeCurrent: null,
      mercadopago: null,
      country: 'Colombia',
      bandera: 'https://api.precompro.com/banderas/co.png',
      indicativo: 57,
      minLength: 10,
      maxLength: 10,
      cardDigits: null
    };
  },
  mounted () {
    this.$store.commit(marketingTypes.mutations.setPackage);
    this.creditpaymentTypePAYU = this.params.paymentType !== 'MP';
    if (this.creditpaymentTypePAYU) {
      this.showCVV = false;
    }
    if (this.vendor !== undefined) {
      if (this.vendor.timezone) {
        if (this.vendor.timezone !== 'America/Bogota') {
          this.$store.dispatch({ type: 'global:getServerTime' }).then((data) => {
            this.serverTimeCurrent = this.$moment(data.data.datetime);
          });
        } else {
          this.$store.dispatch({ type: 'global:getServerTime', data: { timezone: this.vendor.timezone } }).then((data) => {
            this.serverTimeCurrent = this.$moment(data.data.datetime);
          });
        }
      }
    }
    this.$store.dispatch({
      type: 'marketing:getIntegrations',
      data: { vendorId: this.vendor.id }
    });
    if (this.integrations.length > 0) {
      const mercadopago = this.integrations.find(int => int.type === 'mercadopago' && int.environment === process.env.NODE_ENV);
      if (mercadopago) {
        // eslint-disable-next-line
        this.mercadopago = new MercadoPago(lcrypt.decode(mercadopago.login));
      } else {
        // eslint-disable-next-line
        this.mercadopago = new MercadoPago(process.env.VUE_APP_MERCADOPAGO);
      }
    } else {
      // eslint-disable-next-line
      this.mercadopago = new MercadoPago(process.env.VUE_APP_MERCADOPAGO);
    }
    this.initYearOptions();
    this.initMonthOptions();
    this.initCoutes(36);
    // Mercadopago V2
    if (!this.creditpaymentTypePAYU) {
      this.cardMercadoPago.net_amount = parseInt(this.package.total);
      this.cardMercadoPago.transaction_amount = parseInt(this.package.total);
      this.setConfigMercadoPago();
    } else {
      this.card.balance = parseInt(this.package.total);
    }
  },
  computed: {
    submitReady () {
      if (
        this.nameLikeCard &&
        this.documentNumber &&
        (this.phone && this.phone.length >= this.minLength && this.phone.length <= this.maxLength) &&
        this.typePerson &&
        this.cuots
      ) {
        return true;
      } else if (
        this.creditpaymentTypePAYU &&
        this.nameLikeCard &&
        this.documentNumber &&
        (this.phone && this.phone.length >= this.minLength && this.phone.length <= this.maxLength) &&
        this.typePerson &&
        this.cardNumber &&
        this.month &&
        this.email &&
        this.year &&
        this.cuots
      ) {
        return true;
      }
      return false;
    }
  },
  methods: {
    showModalTerms () {
      this.showTermsCondiciones = true;
    },
    showModalPol () {
      this.showTerms = true;
    },
    setConfigMercadoPago () {
      const cardNumberElement = this.mercadopago.fields.create('cardNumber', {
        placeholder: 'Número de la tarjeta'
      }).mount('cardNumber');
      this.mercadopago.fields.create('expirationDate', {
        placeholder: 'MM/YY'
      }).mount('expirationDate');
      this.mercadopago.fields.create('securityCode', {
        placeholder: 'CVV'
      }).mount('securityCode');
      cardNumberElement.on('binChange', this.getPaymentMethods);
    },
    async getPaymentMethods (data) {
      const { bin } = data;
      if (bin != null && bin !== undefined) {
        this.cardDigits = bin;
        await this.mercadopago.getPaymentMethods({ bin }).then(({ results }) => {
          this.setPaymentMethod(results);
        });
        await this.getInstallments(bin);
      } else {
        this.cardMercadoPago.payment_method_id = null;
        this.cardDigits = null;
        this.maxNumber = 19;
        this.initCoutes(36);
      }
    },
    setPaymentMethod (response) {
      const paymentMethodId = response[0].id;
      this.cardMercadoPago.payment_method_id = paymentMethodId;
      this.$store.commit(marketingTypes.mutations.setCardTypeInfo, paymentMethodId);
    },
    async getInstallments (bin) {
      await this.mercadopago.getInstallments({
        amount: document.getElementById('transactionAmount').value,
        bin,
        paymentTypeId: 'credit_card'
      }).then((installments) => {
        this.cuotesReady = false;
        const data = [];
        if (installments[0] !== undefined) {
          installments[0].payer_costs.forEach(installment => {
            const opt = {
              name: installment.recommended_message,
              value: installment.installments
            };
            data.push(opt);
          });
        }
        this.CoutesLimit = [];
        this.CoutesLimit = data;
        this.cuotesReady = true;
      }).catch(() => {
        this.cuotesReady = false;
        this.initCoutes(36);
      });
    },
    setCardTokenAndPay (token) {
      const form = document.getElementById('pay');
      const card = document.createElement('input');
      card.setAttribute('name', 'token');
      card.setAttribute('type', 'hidden');
      card.setAttribute('value', token);
      form.appendChild(card);
      this.doSubmit = true;
      this.postMercadopagoCreditCard();
    },
    inputNumberChange (value) {
      this.bandera = value.countrieInfo.bandera;
      this.indicativo = value.countrieInfo.indicativo;
      this.country = value.countrieInfo.pais;
      this.phone = value.inputValue;
    },
    minLenChange (value) {
      this.minLength = value;
    },
    maxLenChange (value) {
      this.maxLength = value;
    },
    async postCreditCardTransaction () {
      if (this.creditpaymentTypePAYU) {
        let month = this.month;
        if (month < 10) {
          month = '0' + this.month;
        }
        this.card.name = this.nameLikeCard;
        this.card.typeDocument = this.typePerson;
        this.card.identification = this.documentNumber;
        this.card.phone = this.phone;
        this.card.email = this.email;
        this.card.mes = this.month;
        this.card.ano = this.year;
        this.card.expiration = this.year + '/' + month;
        this.card.cuota = this.cuots;
        this.card.card = this.cardNumber.split(/\s+/).join('');
        this.card.address = 'Bogotá Dc';
        this.card.city = 'Bogotá Dc';
        this.card.balance = parseInt(this.package.total);
        this.card.provider = 'TC';
        this.card.vendorId = this.vendor.id;
        this.card.bandera = this.bandera;
        this.card.indicativo = this.indicativo;
        this.card.country = this.country;
        this.card.userId = this.user.id;
        this.card.description = 'Compra paquete ' + this.package.type + ' para ' + this.vendor.displayName + ' por ' + this.package.quantity;
        this.card.referencia = 'package-' + this.vendor.displayName + '-' + this.package.type + '-' + uuidv4();
        this.card.package = this.package;
        this.cardDigits = this.card.card.slice(-4);
        this.postPayuCreditCard();
      }
      if (!this.creditpaymentTypePAYU) {
        this.cardMercadoPago.payer.first_name = this.nameLikeCard;
        this.cardMercadoPago.payer.identification.type = this.typePerson;
        this.cardMercadoPago.payer.identification.number = this.documentNumber;
        this.cardMercadoPago.payer.email = this.email;
        this.cardMercadoPago.transaction_amount = parseInt(this.package.total);
        this.cardMercadoPago.net_amount = parseInt(this.package.total);
        this.cardMercadoPago.installments = this.cuots;
        this.cardMercadoPago.provider = 'TC';
        this.cardMercadoPago.vendorId = this.vendor.id;
        this.cardMercadoPago.phone = this.phone;
        this.cardMercadoPago.bandera = this.bandera;
        this.cardMercadoPago.indicativo = this.indicativo;
        this.cardMercadoPago.country = this.country;
        this.cardMercadoPago.description = 'Compra paquete ' + this.package.type + ' para ' + this.vendor.displayName + ' por ' + this.package.quantity;
        this.cardMercadoPago.external_reference = 'package-' + this.vendor.displayName + '-' + this.package.type + '-' + uuidv4();
        const token = await this.mercadopago.fields.createCardToken({
          cardholderName: document.getElementById('cardholderName').value,
          identificationType: this.typePerson,
          identificationNumber: this.documentNumber
          /* identificationType: document.getElementById('docType').value,
          identificationNumber: document.getElementById('docNumber').value */
        });
        this.cardMercadoPago.token = token.id;
        this.cardMercadoPago.package = this.package;
        this.setCardTokenAndPay(token.id);
      }
    },
    postPayuCreditCard () {
      this.submitDisabled = true;
      this.loading = true;
      servicePayment.post('/payu/marketing/credit', this.card).then(({ data }) => {
        if (data.code === 705) {
          this.submitDisabled = false;
          this.loading = false;
          const msg =
            data.paymentNetworkResponseErrorMessage != null
              ? data.paymentNetworkResponseErrorMessage
              : data.responseCode;
          this.reasonDeclined = msg;
          const dataPayment = {
            state: 'error',
            pse: false,
            cardItem: this.cardDigits
          };
          this.$emit('statusOfTransaction', dataPayment);
        }
        if (data.code === 703 || data.code === 704) {
          this.submitDisabled = false;
          this.loading = false;
          this.reasonDeclined = data.message;
          const dataPayment = {
            state: 'error',
            pse: false,
            cardItem: this.cardDigits
          };
          this.$emit('statusOfTransaction', dataPayment);
        }
        if (data.code === 710 || data.code === 711) {
          this.submitDisabled = false;
          this.loading = false;
          this.$buefy.snackbar.open({
            duration: 5000,
            message: 'Ocurrio un error <br><strong>Intenta realizar la reserva de nuevo</strong> ',
            type: 'is-warning',
            position: 'is-bottom',
            actionText: 'X',
            queue: false
          });
        }
        if (data.code === 780) {
          this.submitDisabled = false;
          this.loading = false;
          const dataPayment = {
            state: 'schedule',
            pse: false,
            cardItem: this.cardDigits
          };
          this.$emit('statusOfTransaction', dataPayment);
        }
        if (data.code === 200) {
          const dataPayment = {
            state: 'ok',
            pse: false,
            cardItem: this.cardDigits
          };
          this.$emit('statusOfTransaction', dataPayment);
        }
      }).catch((err) => {
        this.submitDisabled = false;
        this.loading = false;
        this.$buefy.toast.open({
          duration: 5000,
          message: JSON.parse(err.request.response).message || 'Error en la petición',
          type: 'is-danger',
          position: 'is-bottom',
          queue: false
        });
      });
    },
    postMercadopagoCreditCard () {
      this.submitDisabled = true;
      this.loading = true;
      servicePayment.post('/mercadopago/marketing/credit', this.cardMercadoPago).then(({ data }) => {
        if (data.code === 799) {
          this.loading = false;
          this.submitDisabled = false;
          this.$buefy.toast.open({
            duration: 5000,
            message: data.msg,
            type: 'is-warning',
            position: 'is-bottom',
            queue: false
          });
        }
        if (data.code === 705) {
          this.submitDisabled = false;
          this.loading = false;
          this.reasonDeclined = data.message;
          const dataPayment = {
            state: 'error',
            pse: false,
            cardItem: this.cardDigits,
            isMercadoPago: true
          };
          this.$emit('statusOfTransaction', dataPayment);
        }
        if (data.code === 780) {
          this.submitDisabled = false;
          this.loading = false;
          const dataPayment = {
            state: 'schedule',
            pse: false,
            cardItem: this.cardDigits,
            isMercadoPago: true
          };
          this.$emit('statusOfTransaction', dataPayment);
        }
        if (data.code === 200) {
          const dataPayment = {
            state: 'ok',
            pse: false,
            cardItem: this.cardDigits,
            isMercadoPago: true
          };
          this.$emit('statusOfTransaction', dataPayment);
        }
      }).catch((err) => {
        this.submitDisabled = false;
        this.loading = false;
        if (err.response.data.code === 500) {
          const dataPayment = {
            state: 'error',
            pse: false,
            cardItem: this.cardDigits,
            isMercadoPago: true
          };
          this.$emit('statusOfTransaction', dataPayment);
        } else {
          this.$buefy.toast.open({
            duration: 5000,
            message: JSON.parse(err.request.response).message || 'Error en la petición',
            type: 'is-danger',
            position: 'is-bottom',
            queue: false
          });
        }
      });
    },
    canPay () {
      if (!this.termsConditions) {
        this.$buefy.toast.open({
          duration: 5000,
          message: 'debes aceptar los términos y condiciones , al igual que el tratamiento de datos personales',
          type: 'is-warning',
          position: 'is-bottom',
          queue: false
        });
      } else {
        this.postCreditCardTransaction();
      }
    },
    initYearOptions () {
      const thisYear = parseInt(this.$moment().format('YYYY'));
      for (let year = thisYear; year < thisYear + 25; year++) {
        this.yearOptions.push({ name: year, value: year });
      }
    },
    initMonthOptions () {
      for (let month = 0; month < 12; month++) {
        this.monthOptions.push({ name: month + 1, value: month + 1 });
      }
    },
    initCoutes (installments) {
      this.CoutesLimit = [];
      for (let index = 1; index < installments + 1; index++) {
        this.CoutesLimit.push({ name: index, value: index });
      }
      this.cuotesReady = true;
    }
  }
};
</script>

<style lang="scss">
#cardMarketing {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 870px;
  margin: 0 auto;
  overflow: hidden;
  @media screen and (min-width: 1025px) {
    width: 900px;
  }
  @media screen and (min-width: 1024px) and (max-width: 1279px) {
    width: 870px;
  }
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    width: 870px;
  }
  @media screen and (max-width: 768px) {
    width: 668px;
  }
  @media screen and (max-width: 500px) {
    width: 383px;
  }
  #cardholderName {
    @media screen and (min-width: 768px) {
      flex: 1 40%;
    }
    @media screen and (min-width: 1024px) {
      flex: 1 30%;
    }
    @media screen and (min-width: 1280px) {
      flex: 1 30%;
    }
  }
  #docNumber {
    @media screen and (min-width: 768px) {
      width: 55%;
    }
    @media screen and (min-width: 1280px) {
      width: 50%;
    }
  }
  #email {
    @media screen and (min-width: 1024px) {
      width: 30%;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      flex: 4 60%;
    }
  }
  .mp_fields {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    &-item {
      height: 29px;
      border: 1px solid #A1A5AB;
      background-color: white;
      border-color: #444b57;
      border-radius: 4px;
      position: relative;
      vertical-align: top;
      display: inline-flex;
      justify-content: flex-start;
      align-items: center;
      color: #363636;
      padding-bottom: calc(0.2em - 1px);
      padding-left: calc(0.5em - 1px);
      padding-right: calc(0.5em - 1px);
      padding-top: calc(0.2em - 1px);
    }
    .w50 {
      width: 50%;
    }
    .w20 {
      width: 20%;
    }
  }
  @mixin imageSet($width, $height, $url) {
    background-image: url($url);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: $width;
    height: $height;
    display: block;
  }
  form {
    position: relative;
    top: -1px;
    border: solid 1px #eaebed;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    @media screen and (max-width: 768px) {
      width: 100%;
      max-width: 100%;
    }
    .securePay {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      gap: 5px;
      @media (min-width: 1024px) {
        padding-bottom: 3px;
      }
      .mercadoPagoImg {
        @include imageSet(40px, 24px, "../../../assets/icons/marketing/mercadoPagoImg.svg");
      }
      .payuImg {
        @include imageSet(40px, 24px, "../../../assets/icons/marketing/payuIcon.svg");
      }
    }
  }
  .payInfo__creditInformation {
    border: solid #444B57 1px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 0px 10px 10px 10px;
    gap: 16px;
    height: 1px;
    justify-content: flex-start;
    height: fit-content;
    margin-top: -0.5px;
    @media screen and (min-width: 1280px) {
      width: 100%;
      max-width: 900px;
    }
    @media screen and (min-width: 1024px) and (max-width: 1279px) {
      gap: 12px;
      padding: 20px 26px;
      padding-top: 15px;
      width: 870px;
      max-width: 870px;
    }
    @media (min-width: 769px) and (max-width: 1020px) {
      max-width: 870px;
      width: 870px;
    }
    @media (max-width: 768px) {
      width: 100%;
    }
    .payer-title {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      margin-top: 12px;
      @media screen and (min-width: 768px) and (max-width: 1024px) {
        margin-top: 10px;
      }
      @media screen and (min-width: 1280px) {
        margin-top: 10px;
      }
      span {
        color: #444B57;
        font-family: 'Source Sans Pro';
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
    .selector-type-identity {
      display: flex;
      width: 100%;
      gap: 5px;
      @media (min-width: 1024px) {
        flex: 2 36%;
      }
      @media (max-width: 1020px) {
        flex: 2 55%;
      }
      @media (min-width: 1280px) {
        flex: 2 30%;
      }
    }
    .card-title {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      @media (min-width: 768px) {
        margin-top: 10px;
      }
      span {
        color: #444B57;
        font-family: 'Source Sans Pro';
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
    .inputContainer {
      width: 40%;
      z-index: 39;
      position: relative;
      @media (min-width: 768px) and (max-width: 1020px) {
        width: 30%;
      }
      @media (min-width: 1024px) {
        width: 41%;
      }
      @media (min-width: 1280px) {
        width: 39%;
      }
      & > span {
        position: absolute;
        left: 10px;
        top: 0;
        bottom: 0;
        margin: auto;
      }
    }
    input {
      width: 100%;
      font-size: 12px;
      border-radius: 5px;
      outline: unset;
      border: solid 1px #A1A5AB;
      min-height: 30px;
      text-indent: 10px;
      &:nth-child(1):not(.phoneInput) {
        width: 100%;
        @media (min-width: 768px) and (max-width: 1020px) {
          width: 65%;
        }
        @media (min-width: 1024px) {
          width: 50%;
        }
      }
      &:nth-child(3) {
        width: 54%;
        @media screen and (max-width: 600px) {
          width: 25%;
          flex: 1;
        }
        @media (min-width: 768px) and (max-width: 1020px) {
          width: 25%;
        }
        @media (min-width: 1024px) and (max-width: 1279px) {
          width: 46%;
          flex: 1;
        }
        @media (min-width: 1280px) {
          flex: 1;
        }
      }
      &:nth-child(4) {
        width: 100%;
        @media (min-width: 768px) and (max-width: 1020px) {
          width: 40%;
          flex: 0.92;
        }
        @media (min-width: 1024px) {
          width: 25%;
        }
        @media (min-width: 1254px) {
          width: 27%;
        }
      }
      &:nth-child(7) {
        width: 100%;
        @media (min-width: 768px) and (max-width: 1020px) {
          width: 31%;
          flex: 1;
          &.cvvHidden {
            width: 48%;
          }
        }
        @media (min-width: 1024px) {
          width: 30%;
        }
        @media (min-width: 1280px) {
          width: 33%;
          &.cvvHidden {
            flex: 1;
          }
        }
        @media (min-width: 1600px) {
          width: 47.3%;
          &.cvvHidden {
            width: 66%;
          }
        }
      }
    }
    .cvvContainer {
      display: flex;
      align-items: center;
      width: fit-content;
      justify-content: space-between;
      input {
        text-indent: unset;
        text-align: center;
      }
      @media (max-width: 600px) {
        width: 14.5%;
      }
      @media (min-width: 1280px) {
        width: 10%;
      }
      @media (min-width: 900px) and (max-width: 1100px) {
        width: 8%;
      }
      #securityCode {
        width: 100%;
      }
      @media (min-width: 768px) and (max-width: 1020px) {
        width: 10%;
      }

      @media (min-width: 1600px) {
        width: 8%;
      }
      p {
        color: #444b57;
        font-weight: 400;
        font-size: 12px;
        max-width: 25px;
      }
      input {
        width: 70%;
      }
    }
    .mesContainer {
      width: 21%;
      @media (min-width: 768px) and (max-width: 1020px) {
        width: 12%;
      }
      @media (min-width: 950px) and (max-width: 1100px) {
        width: 11%;
      }
      @media (min-width: 1280px) {
        width: 12%;
      }
      @media (min-width: 1600px) {
        width: 12%;
      }
    }
    .anoContainer {
      position: relative;
      width: 23%;
      @media (min-width: 768px) {
        right: unset;
      }
      @media (min-width: 768px) and (max-width: 1020px) {
        width: 12%;
      }
      @media (min-width: 968px) and (max-width: 1100px) {
        width: 11%;
      }
      @media (min-width: 1024px) {
      }
      @media (min-width: 1280px) {
        width: 12%;
        right: 0;
      }
      &.cvvHidden {
        right: initial;
      }
    }
    .selectorContentDocoument {
      width: 40%;
      @media screen and (max-width: 600px) {
        width: 48%;
      }
      @media (min-width: 768px) and (max-width: 1020px) {
        width: 26%;
        flex: 1;
      }
      @media (min-width: 1024px) {
        width: 50%;
      }
      @media (min-width: 1224px) {
        width: 50%;
      }
    }
    .NoCuotasContainer {
      position: relative;
      width: 27.1%;
      justify-self: start;
      @media (max-width: 600px) {
        flex: 1;
      }
      &.cvvHidden {
      }
      @media (min-width: 768px) {
        right: unset;
      }
      @media (min-width: 768px) and (max-width: 1020px) {
        position: initial;
        width: 20%;
        right: unset;
      }
      @media (min-width: 1024px) {
        width: 17%;
        &.cvvHidden {
        }
      }
      @media (min-width: 1260px) {
        right: unset;
        width: 16.5%;
        &.cvvHidden {
          right: unset;
        }
      }
      @media (min-width: 1600px) {
        width: 14%;
      }
    }
  }
  button {
    background: #444B57;
    border-radius: 6px;
    width: 416px;
    color: white;
    height: 30px;
    margin: auto;
    border: unset;
    cursor: not-allowed;
  }

  .readyToSubmit {
    background: #444B57;
    cursor: pointer;
  }

  .phoneInput {
    width: 100%;
    @media (min-width: 1024px) {
      flex: 3 30%
    }
    @media (min-width: 768px) {
      flex: 3 30%;
    }
    @media (min-width: 1280px) {
      flex: 3 20%;
    }
  }

  .securePay {
    p {
      font-size: 10px;
    }
  }

  .smsTermsOfConditions {
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    margin-top: 20px;
    margin-bottom: 20px;
    @media screen and (min-width: 1024px) {
      width: 100%;
    }
    @media screen and (min-width: 768px) and (max-width: 1023px) {
      width: 668px;
    }
    @media screen and (max-width: 767px) {
      width: 100%;
    }
    &-info {
      justify-content: center;
      height: 60px;
      display: flex;
      align-items: flex-start;
      width: 100%;
      @media screen and (min-width: 768px) {
        height: 30px;
      }
      span {
        margin-left: 7px;
        color: #444B57;
        font-family: "Source Sans Pro";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
  #modal-terms {
    .animation-content.modal-content {
      max-width: 980px !important;
    }
    .terms-body {
      background: #fff;
      padding: 30px 40px 40px;
      color: #000;
      border-radius: 10px;

      .title-modal {
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 20px;
      }
    }
    .modal-close{
      display: block !important;
      position: absolute;

      &::after, &::before{
        background-color: #000;
      }
    }
    @media screen and (max-width:700px) {
      .terms-body {
        padding: 40px 0px;
        #terms-html2{
          padding: 0px 20px;
        }
      }
      .modal-close{
        top: 5px;
        right: 5px;
      }
    }
  }
}
</style>
