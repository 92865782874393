import pollTypes from '@/store/types/poll';
import servicePoll from '@/helpers/apis/servicePoll';

const state = {
  poll: [],
  pollInformation: [],
  pollId: null,
  pollData: null,
  pollDescription: ''
};

const actions = {
  [pollTypes.actions.encuesta]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      servicePoll.post('/data', data.data)
        .then(response => {
          if (response.data.code === 200) {
            commit(pollTypes.mutations.setPoll, response.data.data);
          }
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [pollTypes.actions.list]: (state, data) => {
    return new Promise((resolve, reject) => {
      servicePoll.post('/list', data.data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [pollTypes.actions.result]: (state, data) => {
    return new Promise((resolve, reject) => {
      servicePoll.post('/result', data.data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [pollTypes.actions.sendAnswer]: (state, data) => {
    return new Promise((resolve, reject) => {
      servicePoll.post('/answer', data.data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [pollTypes.actions.generalPollData]: (state, data) => {
    return new Promise((resolve, reject) => {
      servicePoll.post('/generalPollData', data.data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [pollTypes.actions.filteredPollData]: (state, data) => {
    return new Promise((resolve, reject) => {
      servicePoll.post('/filteredPollData', data.data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

const getters = {
  [pollTypes.getters.poll]: state => state.poll,
  [pollTypes.getters.pollInformation]: state => state.pollInformation,
  [pollTypes.getters.pollId]: state => state.pollId,
  [pollTypes.getters.pollData]: state => state.pollData,
  [pollTypes.getters.pollDescription]: state => state.pollDescription
};

const mutations = {
  [pollTypes.mutations.setPoll]: (state, data) => {
    state.poll = data;
  },
  [pollTypes.mutations.setPollInformation]: (state, data) => {
    state.pollInformation = data;
  },
  [pollTypes.mutations.setPollId]: (state, data) => {
    state.pollId = data;
  },
  [pollTypes.mutations.setPollData]: (state, data) => {
    state.pollData = data;
  },
  [pollTypes.mutations.setPollDescription]: (state, data) => {
    state.pollDescription = data;
  }
};

export default {
  state,
  actions,
  getters,
  mutations
};
