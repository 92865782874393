<template>
  <form class="MenuMultiple">
    <div class="modal-card" style="width: 100%;">
      <header class="modal-card-head">
        <p class="modal-card-title" v-if="id == null">Nuevo Menú Multiple</p>
        <p class="modal-card-title" v-else>Editar Menú Multiple</p>
      </header>
      <section class="modal-card-body">
        <b-field>
          <b-checkbox :false-value="0" :true-value="1" v-model="programing">Programar Menú</b-checkbox>
        </b-field>
        <div v-if="programing">
          <div style="display: flex;">
            <b-field
              :message="{'El título es obligatorio': errors.first('initPrograming')}"
              :type="{'is-danger': errors.has('initPrograming')}"
              label="Inicio"
            >
              <b-input
                name="initPrograming"
                placeholder="06:00"
                type="text"
                v-model="info.initPrograming"
              ></b-input>
            </b-field>
            <b-field
              :message="{'El título es obligatorio': errors.first('finishPrograming')}"
              :type="{'is-danger': errors.has('finishPrograming')}"
              label="Fin"
              style="margin-left: 20px;"
            >
              <b-input
                name="finishPrograming"
                placeholder="18:00"
                type="text"
                v-model="info.finishPrograming"
              ></b-input>
            </b-field>
          </div>
          <b-field label="Dias de la semana">
            <div class="add_experience-day-cont">
              <a :class="{ active: activeDay(1) }" @click="addWeekDay(1)" class="btn-day">LUN</a>
              <a :class="{ active: activeDay(2) }" @click="addWeekDay(2)" class="btn-day">MAR</a>
              <a :class="{ active: activeDay(3) }" @click="addWeekDay(3)" class="btn-day">MIE</a>
              <a :class="{ active: activeDay(4) }" @click="addWeekDay(4)" class="btn-day">JUE</a>
              <a :class="{ active: activeDay(5) }" @click="addWeekDay(5)" class="btn-day">VIE</a>
              <a :class="{ active: activeDay(6) }" @click="addWeekDay(6)" class="btn-day">SAB</a>
              <a :class="{ active: activeDay(0) }" @click="addWeekDay(0)" class="btn-day">DOM</a>
            </div>
          </b-field>
        </div>
        <span
          style="color: red; font-size: 14px;"
          v-if="programing"
        >* El formato de las horas es militar, Ejemplo: 06:00, 12:00</span>
        <b-field
          :message="{'El título es obligatorio': errors.first('title')}"
          :type="{'is-danger': errors.has('title')}"
          label="Título"
        >
          <b-input name="title" placeholder="Título" type="text" v-model="info.title"></b-input>
        </b-field>
        <b-field label="Título Ingles">
          <b-input name="title" placeholder="Título Ingles" type="text" v-model="info.titleEnglish"></b-input>
        </b-field>
        <b-field label="Imagen Español">
          <b-upload style="width: 100%;" v-model="fileRestaurantCover">
            <div class="MenuMultiple-cover">
              <div class="MenuMultiple-coverButton">
                <b-icon icon="camera" size="is-small"></b-icon>
                <span>Clic para cambiar imagen</span>
              </div>
            </div>
          </b-upload>
        </b-field>
        <img :src="image" v-if="image != null" />
        <b-field label="Imagen Ingles">
          <b-upload style="width: 100%;" v-model="fileImageRestaurantEnglish">
            <div class="MenuMultiple-cover">
              <div class="MenuMultiple-coverButton">
                <b-icon icon="camera" size="is-small"></b-icon>
                <span>Clic para cambiar imagen</span>
              </div>
            </div>
          </b-upload>
        </b-field>
        <img :src="imageEnglish" v-if="imageEnglish != null" />
        <b-field label="Orden">
          <b-input
            min="0"
            name="order"
            placeholder="Orden"
            type="number"
            v-model="info.order"
            v-validate="'required|numeric'"
          ></b-input>
        </b-field>
        <center>
          <b-button
            @click="validate"
            icon-left="content-save"
            style="margin-top: 10px;"
            type="is-success"
          >Guardar</b-button>
          <b-button
            @click="$parent.close()"
            icon-left="close"
            style="margin-top: 10px; margin-left: 10px;"
            type="is-danger"
          >Cerrar</b-button>
        </center>
      </section>
    </div>
  </form>
</template>
<script>
import globalTypes from '@/store/types/global';
import serviceQr from '@/helpers/apis/serviceQr.js';
export default {
  props: ['id', 'info', 'restaurant', 'restaurantData'],
  data () {
    return {
      fileRestaurantCover: null,
      fileImageRestaurantEnglish: null,
      image: null,
      imageEnglish: null,
      programing: 0,
      dayOfWeek: []
    };
  },
  mounted () {
    this.image = this.info.url;
    this.programing = this.info.programing;
    if (this.info.dayOfWeek) {
      this.dayOfWeek = this.info.dayOfWeek;
    }
  },
  methods: {
    activeDay (day) {
      const key = this.dayOfWeek.indexOf(day);
      if (key >= 0) {
        return true;
      }
      return false;
    },
    addWeekDay (day) {
      const key = this.dayOfWeek.indexOf(day);
      if (key >= 0) {
        this.dayOfWeek.splice(key, 1);
      } else {
        this.dayOfWeek.push(day);
      }
    },
    setDayOfWeek () {
      if (this.dayOfWeek.length > 0) {
        this.info.dayOfWeek = this.info.programing ? this.dayOfWeek.join(',') : null;
      } else {
        this.info.dayOfWeek = null;
      }
    },
    validate () {
      this.$validator.validateAll().then(result => {
        if (result) {
          if (this.id == null) {
            this.setMenu();
          } else {
            this.updateMenu();
          }
        }
      });
    },
    setMenu () {
      this.setDayOfWeek();
      const data = { ...this.info, userId: this.user.id };
      this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: true, typeMessage: 3 });
      serviceQr.post('/menudigital/multimenu', data).then(({ data }) => {
        this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
        this.$buefy.toast.open({
          message: data.message,
          type: 'is-success'
        });
        this.$emit('update');
        this.$parent.close();
      }).catch(() => {
        this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
        this.$buefy.toast.open({
          message: 'Lo siento, ha ocurrido un error al intentar procesar tu petición',
          type: 'is-danger'
        });
      });
    },
    updateMenu () {
      this.setDayOfWeek();
      const data = { ...this.info, userId: this.user.id };
      this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: true, typeMessage: 3 });
      serviceQr.put('/menudigital/multimenu/' + this.id, data).then(({ data }) => {
        this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
        this.$buefy.toast.open({
          message: data.message,
          type: 'is-success'
        });
        this.$emit('update');
        this.$parent.close();
      }).catch(() => {
        this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
        this.$buefy.toast.open({
          message: 'Lo siento, ha ocurrido un error al intentar procesar tu petición',
          type: 'is-danger'
        });
      });
    },
    uploadImage (image, name, language, optimize = 0) {
      this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: true, typeMessage: 3 });
      this.uploadImageGlobal(
        image,
        `multiple/${language}/${name}`,
        null,
        this.restaurantData.vendor,
        'menu',
        optimize,
        name,
        `multiple/${language}/`
      ).then(({ data, code }) => {
        if (code === 200) {
          if (language === 'spanish') {
            this.image = data.data;
            this.info.image = data.data;
            return;
          }
          this.imageEnglish = data.data;
          this.info.imageEnglish = data.data;
          return;
        }
        this.$buefy.toast.open({
          message: 'Lo siento, ha ocurrido un error al intentar procesar tu petición',
          type: 'is-danger'
        });
      }).finally(() => {
        this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
      });
    },
    bytesToSize (bytes) {
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
      if (bytes === 0) return '0 Byte';
      const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return { size: Math.round(bytes / Math.pow(1024, i), 2), format: sizes[i] };
    }
  },
  watch: {
    programing () {
      this.info.programing = this.programing;
    },
    fileRestaurantCover (value) {
      if (value == null) {
        return;
      }
      const fileInfo = this.bytesToSize(value.size);
      if (fileInfo.format === 'MB' && fileInfo.size > 2) {
        this.$buefy.dialog.confirm({
          title: 'Imagen Muy Pesada.',
          message: 'Se recomienda subir imagenes con tamaño inferior a <b>2 MB</b>. ¿Desea que optimicemos la imagen que esta cargando?. El peso actual de tu imagen es ' + `<b>${fileInfo.size} ${fileInfo.format}</b>`,
          confirmText: 'Si, subir',
          cancelText: 'No, cancelar',
          type: 'is-danger',
          hasIcon: true,
          onConfirm: () => {
            const name = value.name.split('.');
            this.uploadImage(value, name[0], 'spanish', 1);
          },
          onCancel: () => {
            this.fileRestaurantCover = null;
          }
        });
        return;
      }
      const name = value.name.split('.');
      this.uploadImage(value, name[0], 'spanish');
    },
    fileImageRestaurantEnglish (value) {
      if (value === null) {
        return;
      }
      const fileInfo = this.bytesToSize(value.size);
      if (fileInfo.format === 'MB' && fileInfo.size > 2) {
        this.$buefy.dialog.confirm({
          title: 'Imagen Muy Pesada.',
          message: 'Se recomienda subir imagenes con tamaño inferior a <b>2 MB</b>. ¿Desea que optimicemos la imagen que esta cargando?. El peso actual de tu imagen es ' + `<b>${fileInfo.size} ${fileInfo.format}</b>`,
          confirmText: 'Si, subir',
          cancelText: 'No, cancelar',
          type: 'is-danger',
          hasIcon: true,
          onConfirm: () => {
            const name = value.name.split('.');
            this.uploadImage(value, name[0], 'english', 1);
          },
          onCancel: () => {
            this.fileImageRestaurantEnglish = null;
          }
        });
        return;
      }
      const name = value.name.split('.');
      this.uploadImage(value, name[0], 'english');
    }
  }
};
</script>
<style lang="scss" scoped>
.add_experience-day-cont {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
  height: 50px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;

  .btn-day {
    border: 1px solid rgb(112, 112, 112);
    width: 90px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: #000;
    background: #fff;

    &:hover {
      background: rgb(68, 75, 87);
      -webkit-transform: translateY(-1px);
      transform: translateY(-1px);
      -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
      color: #fff;
    }

    &.active {
      background: rgb(68, 75, 87);
      color: #fff;
    }
  }
}
.modal-card-body {
  min-width: 400px;
  width: 400px;
  max-width: 100%;
}
.MenuMultiple {
  &-cover {
    background-color: #f1f1f1;
    background-size: cover;
    height: 30px;
    width: 100%;
    cursor: pointer;
  }
  &-coverButton,
  &-logoButton {
    background-color: #fff;
    border: 1px solid #f1f1f1;
    border-radius: 2px;
    color: #00a7b5;
    display: initial;
    font-size: 0.75rem;
    margin: 1px;
    padding: 0.3rem;
    white-space: nowrap;
  }
  &-coverButtonText {
    display: none;
    opacity: 0;
    transition: all 1s;
  }
  &-logo {
    display: block;
    padding: 10px 0;
    margin-bottom: 30px;
    position: relative;
    &:before {
      background: #00adc6;
      content: "";
      width: 50px;
      height: 2px;
      position: absolute;
      bottom: -5px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  &-logoImage {
    max-height: 100px;
    max-width: 100%;
  }
  &-logoButton {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  &-icon {
    opacity: 0.5;
    vertical-align: middle;
  }
  &-block {
    background-color: #f5f5f5;
    color: #7a7a7a;
    margin: 1px;
    padding: 1.25rem 0;
    position: relative;
    text-align: center;
    &.is-small {
      padding: 0.7rem 0;
    }
  }
}
.MenuMultiple-cover:hover .MenuMultiple-coverButtonText {
  display: block;
  opacity: 1;
}
</style>
