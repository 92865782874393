import waitingList from '../types/waitingList';
import serviceWaitingList from '@/helpers/apis/serviceWaitingList';

const state = {};

const actions = {
  [waitingList.actions.addWaitingListItem]: (state, data) => {
    return new Promise((resolve, reject) => {
      serviceWaitingList.post('/add/waitTime', data.data)
        .then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error);
        });
    });
  },
  [waitingList.actions.getWaitingList]: (state, data) => {
    return new Promise((resolve, reject) => {
      serviceWaitingList.post('/getWaitingList', data.data)
        .then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error);
        });
    });
  },
  [waitingList.actions.updateWaitingListItem]: (state, data) => {
    return new Promise((resolve, reject) => {
      serviceWaitingList.put('/update/waitTime', data.data)
        .then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error);
        });
    });
  },
  [waitingList.actions.deleteWaitingListItem]: (state, data) => {
    return new Promise((resolve, reject) => {
      serviceWaitingList.delete(`/deleteItem/waitTime/${data.data.id}`, data.data)
        .then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error);
        });
    });
  },
  [waitingList.actions.addMoreWaitTime]: (state, data) => {
    return new Promise((resolve, reject) => {
      serviceWaitingList.post('/addMoreWaitTime', data.data)
        .then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error);
        });
    });
  },
  [waitingList.actions.addReservationFromWaitingList]: (state, data) => {
    return new Promise((resolve, reject) => {
      serviceWaitingList.post('/addReservationFromWaitingList', data.data)
        .then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error);
        });
    });
  },
  [waitingList.actions.sendSMSnotification]: (state, data) => {
    return new Promise((resolve, reject) => {
      serviceWaitingList.post('/sendSMSnotify', data.data)
        .then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error);
        });
    });
  }
};

const getters = {
};

const mutations = {
};

export default {
  state,
  actions,
  getters,
  mutations
};
