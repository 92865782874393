<template>
  <div>
    <LoaderPrecompro />
    <Poll v-if="vendor && vendorParams"></Poll>
  </div>
</template>
<script>
import authTypes from '@/store/types/auth';
import globalTypes from '@/store/types/global';
import Poll from '@/components/polls_v2';
import LoaderPrecompro from '@/components/_shared/LoaderPrecompro';
import moment from 'moment-timezone';
import verifyUser from '@/helpers/verifyUser';

export default {
  metaInfo: {
    title: 'Encuestas'
  },
  components: {
    Poll,
    LoaderPrecompro
  },
  mounted () {
    this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: true, typeMessage: 1 });
    const vendorUrl = this.subDomain();
    if (vendorUrl !== undefined && vendorUrl != null) {
      this.$store.commit(authTypes.mutations.setUser);
      this.$store.commit(globalTypes.mutations.setVendorUrl, vendorUrl);
      this.$store.dispatch({
        type: 'global:vendorId',
        data: {
          url: vendorUrl
        }
      }).then((response) => {
        if (response.data.vendorId) {
          this.$store.dispatch({
            type: 'reservation:getModules',
            data: {
              vendorId: this.idVendor,
              userId: this.user.id
            }
          });
          this.$store.dispatch({
            type: 'global:vendor',
            data: {
              vendorId: this.idVendor
            }
          }).then((resp) => {
            if (resp.data.vendor.timezone != null) {
              moment.tz.setDefault(resp.data.vendor.timezone);
            } else {
              moment.tz.setDefault('America/Bogota');
            }
            this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false, typeMessage: 1 });
          }).finally(() => {
            document.querySelector('title').text = this.vendor.displayName + ' - Encuestas';
            document.querySelector("link[rel*='icon']").href = this.vendor.thumbnailURL;
          });
        }
        this.$store.dispatch({
          type: 'global:getPermissions',
          data: { userId: this.user.id }
        });
      });
    }
  },
  watch: {
    user () {
      verifyUser(this.user);
    }
  }
};
</script>
