<template>
  <div id="Reservations" :class="{ 'results-reservations' : !noResuts, 'no-results-reservations': noResuts }">
    <div class="search-container">
      <b-field class="search-camp" label=''>
        <b-input id="searcherList"
          :loading="loading" v-model="search"
            @input="getSearched"  type="search" expanded
            icon-pack="fa" icon="search"
        ></b-input>
      </b-field>
      <div class="exit" @click="$emit('exitSearch')">&times;</div>
    </div>
    <div v-if="!noResuts" class="results" >
      <h2 style="margin-bottom: 3px;">Reservas</h2>
      <div v-for="(fecha, indexf) in Object.keys(reservations_response)" :key="indexf">
        <div v-for="(hora, indexh) in reservations_response[fecha]" :key="indexh" class="bock-box">
          <div class="header-hour" :class="{ 'mat-0': indexf === 0 }">
            <div class="side">
              <i class="icon-mks white icon_calendar_voild"></i>
              <div class="datecap">{{ getconvertDate(fecha) }}</div>
            </div>
            <div class="side" style="width:fit-content">
              <i class="icon-mks white icon_dashboard_clock"></i>
              <div class="datecap">{{ getconvertHour(indexh) }}</div>
            </div>
          </div>
          <ReservationItem v-for="(reserva, indexr) in hora" :key="'searchListItem-' + indexr" :reservation="reserva"></ReservationItem>
        </div>
      </div>
      <h2 style="margin-bottom: 3px; margin-top: 5px;">Usuarios</h2>
      <userSearchList
        v-for="(user, indexp) in people_response"
        :key="indexp"
        @showEditUser="showEditUser(user._id)"
        :last="indexp === people_response.length - 1"
        :userItem="user"
      >
      </userSearchList>
    </div>
    <div v-if="noResuts" class="no-results" style="padding-left: 10px; font-weight: lighter;">
      {{ message }}
    </div>
    <editUser @close="closedDetailUser" :dataUser="dataUser" v-if="showDetail"></editUser>
  </div>
</template>
<script>
import userSearchList from '@/components/users/users/userSearchList.vue';
import editUser from '@/components/_shared/users/editUser';
import ReservationItem from '@/components/reservations/Reservations/ReservationItemSearchList';
import reservationTypes from '@/store/types/reservation';
import { mapGetters } from 'vuex';
export default {
  components: {
    ReservationItem,
    editUser,
    userSearchList
  },
  data () {
    return {
      search: '',
      loading: false,
      reservations_response: [],
      people_response: [],
      noResuts: true,
      retrievedObject: null,
      showDetail: false,
      dataUser: [],
      message: 'No hay Resultados',
      timeout: null
    };
  },
  watch: {
    sidelateralSearch () {
      if (!this.sidelateralSearch) {
        this.search = '';
        this.reservations_response = [];
        this.people_response = [];
        if (this.search === '') {
          this.noResuts = true;
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      sidelateralSearch: [reservationTypes.getters.sidelateralSearch]
    })
  },
  methods: {
    getconvertDate (date) {
      return this.$moment(String(date)).format('MMMM DD');
    },
    getconvertHour (date) {
      return this.$moment(parseInt(date)).format('hh:mm A');
    },
    getSearched () {
      clearTimeout(this.timeout);
      const thes = this;
      this.timeout = setTimeout(function () {
        if (thes.search.length > 3) {
          thes.loading = true;
          thes.$store.dispatch({ type: 'reservation:getSearched', data: { vendorId: parseInt(thes.decryptMx(thes.$store.state.vendorId)), search: thes.search } })
            .then(response => {
              if (response.message === 'Ok') {
                thes.reservations_response = response.reservations;
                thes.people_response = response.people;
                thes.noResuts = false;
              } else {
                if (response.message === 'empty') {
                  thes.noResuts = true;
                } else {
                  thes.message = 'No hay Resultados';
                }
              }
              thes.loading = false;
            })
            .catch(() => {
              thes.message = 'Hubo un Error';
            });
        }
      }, 500);
    },
    showEditUser (idUser) {
      this.$store.dispatch({ type: 'people:getOneUser', data: { id: idUser } }).then((data) => {
        this.dataUser = data.data;
        this.showDetail = true;
      });
    },
    closedDetailUser () {
      this.showDetail = false;
    }
  }
};
</script>
<style lang="scss">

  #lgMenu.SearchLateral #exit {
      margin-left: -17px;
      top: -14px;
      right: 0;
      font-size: 37px;
      z-index: 999;
  }

  .exit {
      font-size: 43px;
      margin-right:  5px;
      margin-bottom: 10px;
      cursor: pointer;
      user-select: none;
  }

   #lgMenu.SearchLateral{
      overflow:visible !important;
      border-radius: 10px;
      #Reservations .column {
        margin: 0;
        padding:0;
      }
   }

   #Reservations {
    border-radius: 10px;
   }

   .results-reservations {
    background-color: white !important;
   }

   .no-results-reservations {
    background-color: #EDEDED;
   }

   .mat-0 {
    margin-top: 0px !important;
   }

   .search-container {
    margin-bottom: 10px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    background: white;
    border: 1px solid #ccc;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
   }

  .search-camp{
    width: 100%;
    margin: 0 !important;
    padding: 12px 10px 12px;
    z-index: 1;
    .input {
      border: 2px solid #EDEDED !important;
      background: transparent;
      color: #444B57;
      &::-webkit-input-placeholder {
        color: #444B57;
        opacity:0.7;
      }
    }
    .fa-lg {
      font-size: 1em;
      margin-top: -3px;
    }
    .control.is-loading::after {
      position: absolute !important;
      right: 0.625em;
      top: 0.425em !important;
      z-index: 4;
    }

    .control.has-icons-left .input, .control.has-icons-left .taginput .taginput-container.is-focusable, .taginput .control.has-icons-left .taginput-container.is-focusable, .control.has-icons-left .select select {
      padding-left: 2.25em;
    }
    .control.has-icons-right .input, .control.has-icons-right .select select {
      padding-right: 10px !important;
    }

  }
  .results{
    height: calc(100vh - 50px);
    overflow: scroll;
    background-color: white;
    h2{
      font-weight: bold;
      margin-bottom: 10px;
      margin-top: 10px;
      margin-left: 5px;
      &:first-child{
        margin-top:0px;
      }
    }

    .header-hour {
      align-items: center;
      justify-content:space-between;
      background: #444B57;
      padding: 5px 10px;
      color: #fff;
      font-size: 11px;
      margin: 5px;
      flex-direction: row;
      display: flex;
      border-radius: 5px;
      font-weight: medium;
      .side {
        display: flex;
        align-items: center;
        width: 50%;
        position: relative;
        .datecap{
          text-transform:capitalize;
        }
        i{
          width:13px;
          height:13px;
        }
      }
    }
    .box-user{
      display: flex;
      align-items: stretch;
      margin: 0 5px 5px 5px !important;
      min-height: 60px;
      border: 1px solid #ccc;
      border-radius: 5px;
      cursor: pointer;
      .shrtName{
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        max-width: 60px;
        background:#00ADC6;
        font-size:20px;
        font-weight:bold;
        color:#fff;
      }
      .infoUser{
        min-height: 100%;
        display: flex;
        flex-direction: column;
        width: 0;
        ul{
          font-size: 11px;
          font-weight: 700;
          text-transform: capitalize;
          white-space: nowrap;
          display: flex;
          flex: 1;
          padding-left:10px;
          &:first-child{
            margin-top:3px;
          }
        }
      }
    }
  }
</style>
