<template>
  <article
    id="MapItem"
    ref="itemTocreate"
    :style="{ '--rotation': rotationTime ,'--top':bountyClient.top+'px','--left':bountyClient.left+'px'}"

  >
    <section>
      <span class="personIcon"></span>
      <div class="modalOfItem" :class="{ lastTable: lastTable }" v-if="windowWidth>600">
      <span
        class="closeIcon"
        @click.self.stop="close"
      ></span>
        <h1>
          {{ `¿Que deseas agregar a las ${formatedHour} en ${textFormatter(alltableInformation.displayName,20)}?` }}
        </h1>
        <div class="buttonContainer">
          <button
            v-if="!isWalkingItem"
            class="ReservationButton"
            @click.prevent.stop="viewReservation()"
          >
            <p>Reserva</p>
            <span>+</span>
          </button>
          <button v-else class="WalkButton" @click.prevent.stop="viewWalkIn()">
            <p>Walk-In</p>
            <span>+</span>
          </button>
        </div>
      </div>
        <portal selector="#mainContainer" v-else-if="windowWidth<=600" >
        <div class="modalOfItem" id="modalOfItem" :class="{ lastTable: lastTable }">
      <span
        class="closeIcon"
        @click.stop="close"
      ></span>
        <h1>
          {{ `¿Que deseas agregar a las ${formatedHour} en ${textFormatter(alltableInformation.displayName,20)}?` }}
        </h1>
        <div class="buttonContainer">
          <button
            v-if="!isWalkingItem"
            class="ReservationButton"
            @click.prevent.stop="viewReservation()"
          >
            <p>Reserva</p>
            <span>+</span>
          </button>
          <button v-else class="WalkButton" @click.prevent.stop="viewWalkIn()">
            <p>Walk-In</p>
            <span>+</span>
          </button>
        </div>
      </div>
            </portal>
    </section>
  </article>
</template>
<script>
import reservationTypes from '@/store/types/reservation';
import { Portal as portal } from '@linusborg/vue-simple-portal';
import moment from 'moment-timezone';
export default {
  props: ['alltableInformation', 'actualHour', 'lastTable', 'lastScrollY', 'lastScrollX', 'minutesBetweenTurns'],
  components: { portal },
  data () {
    return {
      Zones: [],
      allTables: [],
      reservationList: [],
      filter: '',
      formatedHour: '',
      rotationSet: 2,
      bountyClient: { top: 0 },
      lastBounty: 0,
      windowWidth: window.innerWidth

    };
  },
  mounted () {
    this.formatedHour = this.$moment(this.actualHour.dateComplete).format('hh:mm:a');
    const hour = parseInt(
      this.$moment(this.actualHour.dateComplete).tz(this.timezone).format('x')
    );
    this.$store.commit(
      reservationTypes.mutations.setReservationHourTimeLine,
      hour
    );
    this.rotationSet = this.isConfigRotation(
      this.actualHour,
      this.alltableInformation
    );
  },
  methods: {
    textFormatter (word, maxSize) {
      if (word.length > maxSize) {
        return word.slice(0, maxSize) + '... ';
      }
      return word;
    },
    viewWalkIn () {
      this.$store.commit(
        reservationTypes.mutations.setSectionSelected,
        this.alltableInformation.zoneId
      );
      this.$store.commit(
        reservationTypes.mutations.setTableSelected,
        this.alltableInformation.displayName
      );
      this.$store.commit(
        reservationTypes.mutations.setTableSelectedId,
        this.alltableInformation.id
      );
      this.$store.commit(reservationTypes.mutations.setFromMap, true);
      this.setAddWalkin();
      this.close();
    },
    viewReservation () {
      this.$store.commit(
        reservationTypes.mutations.setSectionSelected,
        this.alltableInformation.zoneId
      );
      this.$store.commit(
        reservationTypes.mutations.setTableSelected,
        this.alltableInformation.displayName
      );
      this.$store.commit(
        reservationTypes.mutations.setTableSelectedId,
        this.alltableInformation.id
      );
      this.$store.commit(
        reservationTypes.mutations.setReservationHourTimeLine,
        this.actualHour.dateComplete
      );
      this.$store.commit(reservationTypes.mutations.setFromMap, true);
      this.setAddReservation();
      this.close();
    },
    isConfigRotation (reservation, tableInformation) {
      let response = null;
      const dayOfWeek = parseInt(this.$moment(reservation.dateComplete).format('d'));
      if (Object.values(this.rotation).length > 0) {
        this.rotation.map((item) => {
          if (item.dayOfWeek != null) {
            if (item.dayOfWeek === dayOfWeek) {
              response = item.rotationTime;
            }
          } else if (item.subZoneId) {
            if (tableInformation.subZoneId === item.subZoneId) {
              response = item.rotationTime;
            }
          }
          if (tableInformation.zoneId === item.zoneId) {
            response = item.rotationTime;
          }
        });
      }
      return response;
    },
    close () {
      this.$emit('close');
    }
  },
  updated () {
    if (this.bountyClient.top === 0) {
      this.bountyClient = this.$refs.itemTocreate.getBoundingClientRect();
      if (window.innerWidth <= 600) {
        document.getElementById('Cronology').style.setProperty('--topItemModal', this.bountyClient.top + 'px');
      }
    }
  },
  computed: {
    rotationTime () {
      return moment.duration(this.rotationSet).hours()
        ? moment.duration(this.rotationSet).hours()
        : 1;
    },
    isWalkingItem () {
      const $moment = this.$moment;
      return this.$moment(this.actualHour.dateComplete).diff($moment(), 'minutes') < this.minutesBetweenTurns / 4;
    }
  }
};
</script>
<style lang="scss" scoped>
@mixin icon($url, $w: 15px, $h: 15px, $color: black) {
  width: $w;
  height: $h;
  mask-image: url($url);
  mask-repeat: no-repeat;
  mask-position: center;
  background-color: $color;
}
  .modalOfItem {
    align-items:center;
    flex-direction: column;
    justify-content:  flex-start;
    display: flex;
    position: absolute;
    z-index: 1000000;
    top: 115%;
    gap: 12px;
    // top: var(--top);
    // left: calc(var(--left) - 55px);
    .closeIcon {
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 6px;
  transform: rotate(45deg) scale(1.3);
  background-image: unset !important;
  @include icon(
    "../../assets/icons/allTimeLineIcons/plus.svg",
    18px,
    18px,
    #374957
  );
}
    // @media screen and (max-width:1024px) {
    //   top:calc(var(--top) - 40px) ;
    // }
    // @media screen and (max-width:600px) {
    //       left: 0;
    //       right: 0;
    //       top: 0;
    //       bottom: 0;
    //       margin: auto;
    //       width: 80%;
    // }
    padding: 12px;
    border-radius: 6px;
    width: 220px;
    min-height: 100px;
    background-color: white;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.45);
    overflow: visible;
    pointer-events: auto;
        @media screen and (max-width:600px) {
          position: fixed;
          width: 250px;
          top: calc(var(--topItemModal) - 100px);
          left: 90px;
          z-index: 1000;
    }
    &::after {
      content: " ";
      display: block;
      width: 10px;
      height: 10px;
      position: absolute;
      top: -5px;
      left: 14px;
      transform: rotate(45deg);
      background-color: white;
              @media screen and (max-width:600px) {
          transform: translateX(40px) rotate(45deg);
    }
    }
    &.lastTable {
        top:-230%;
      @media screen and (max-width:600px) {
          position: fixed;
          width: 250px;
  top:calc(var(--topItemModal) - 250px) ;
      }
      &::after {
        content: " ";
        display: block;
        width: 10px;
        height: 10px;
        position: absolute;
        top: unset;
        bottom: -5px;
        left: 10px;
        transform: rotate(45deg);
        background-color: white;
                      @media screen and (max-width:600px) {
          transform: translateX(40px) rotate(45deg);
    }
      }
    }
    h1 {
      text-align: center;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #444b57;
      max-width:80%;
    }
    .buttonContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;
      button {
        font-size: 15px;
        color: white;
        border-radius: 7px;
        padding: 5px 10px;
        outline: unset;
        border: unset;
        white-space: nowrap;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 15px;
        font-weight: bold;
        font-size: 12px;
        height: 30px;
        width:200px;
        @media screen and (max-width:600px) {
          width: 225px;
          min-width: 100%;
          max-width: unset;
        }
        padding-bottom: 6px;
        p{
          font-size: 13px;
          font-weight: 500;
        }
        cursor: pointer;
        span {
          font-size: 25px;
          max-height: 20px;
          line-height: 0em;
          position: relative;
          bottom: 2px;
          gap: 3px;
        }
      }
      .ReservationButton {
        background-color: #00adc6;
      }
      .WalkButton {
        background-color: #8a51b4;
      }
    }
  }
#MapItem {

  --rotation: 1;
  position: absolute;
  left: 1px;
  z-index: 300;
  height: 100%;
  min-width: calc(100% + (100% * var(--rotation)) - 1px);
  background: #ffffff;
  max-height: 50px;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 1px solid #444b57;
  box-shadow: 6px 6px 10px 2px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
    pointer-events: none;
    @media screen and (max-width:600px){
      pointer-events:auto;
    }
  section{
    width: 100%;
    height: 100%;
    pointer-events: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .personIcon {
    display: block;
    height: 15px;
    width: 15px;
    mask-image: url(../../assets/icons/icon_dashboard_person.svg);
    mask-repeat: no-repeat;
    mask-position: center;
    background-color: black;
    margin-left: 8px;
  }

}
</style>
