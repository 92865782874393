<template>
  <form class="MenuMultiple">
    <div class="modal-card" style="width: 100%;">
      <header class="modal-card-head">
        <p class="modal-card-title" v-if="id == null">Nuevo Menú</p>
        <p class="modal-card-title" v-else>Editar Menú</p>
      </header>
      <section class="modal-card-body">
        <b-field v-if="(!update || typeof update ==='undefined') && image == null">
          <b-checkbox v-model="multipleCheck">Multiple</b-checkbox>
        </b-field>
        <b-upload
          style="width: 100%;"
          v-if="update || !multipleCheck"
          v-model="fileRestaurantCover"
        >
          <div class="MenuMultiple-cover">
            <div class="MenuMultiple-coverButton">
              <b-icon icon="camera" size="is-small"></b-icon>
              <span>Clic para cambiar imagen</span>
            </div>
          </div>
        </b-upload>
        <b-upload
          multiple
          style="width: 100%;"
          v-if="(multipleCheck == true)"
          v-model="selectedImages"
        >
          <div class="MenuMultiple-cover">
            <div class="MenuMultiple-coverButton">
              <b-icon icon="camera" size="is-small"></b-icon>
              <span>Clic para seleccionar imagenes</span>
            </div>
          </div>
        </b-upload>
        <img :src="image" v-if="image != null" />
        <b-field label="Orden" v-if="!multipleCheck">
          <b-input
            min="0"
            name="order"
            placeholder="Orden"
            type="number"
            v-model="info.order"
            v-validate="'required|numeric'"
          ></b-input>
        </b-field>
        <b-field>
          <b-radio-button name="spanish" native-value="spanish" v-model="typeLanguage">Español</b-radio-button>
          <b-radio-button name="english" native-value="english" v-model="typeLanguage">Inglés</b-radio-button>
        </b-field>
        <center>
          <b-button
            @click="uploadImages()"
            icon-left="upload"
            style="margin-top: 10px;"
            type="is-primary"
            v-if="(!!selectedImages && multipleCheck)"
          >Subir {{selectedImages.length}} imagenes</b-button>
          <b-button
            @click="deleteImages()"
            icon-left="delete"
            style="margin-top: 10px; margin-left: 10px;"
            type="is-danger"
            v-if="(!!selectedImages && multipleCheck)"
          >Quitar</b-button>
          <b-button
            @click="validate"
            icon-left="content-save"
            style="margin-top: 10px;"
            type="is-success"
            v-if="update || !multipleCheck"
          >Guardar</b-button>
          <b-button
            @click="setMenuMultiple"
            icon-left="content-save"
            style="margin-top: 10px;"
            type="is-success"
            v-if="multipleCheck && !!selectedImages && imagesSend.length == selectedImages.length"
          >Guardar</b-button>
          <b-button
            @click="$parent.close()"
            icon-left="close"
            style="margin-top: 10px; margin-left: 10px;"
            type="is-danger"
          >Cerrar</b-button>
        </center>
      </section>
    </div>
  </form>
</template>
<script>
import globalTypes from '@/store/types/global';
import { mapGetters } from 'vuex';
import serviceQr from '@/helpers/apis/serviceQr.js';
export default {
  props: ['id', 'info', 'restaurant', 'update'],
  data () {
    return {
      fileRestaurantCover: null,
      selectedImages: null,
      multipleCheck: false,
      imagesSend: [],
      image: null,
      typeLanguage: null
    };
  },
  computed: {
    ...mapGetters({
      restaurantData: [globalTypes.getters.vendor]
    })
  },
  mounted () {
    this.image = this.info.url;
    this.typeLanguage = this.info.language;
  },
  methods: {
    setMenuMultiple () {
      this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: true, typeMessage: 3 });
      serviceQr.post('/menudigital/multiple', { images: this.imagesSend, vendorId: this.restaurant.vendorId, userId: this.user.id, language: this.typeLanguage }).then(({ data }) => {
        this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
        this.$buefy.toast.open({
          message: data.message,
          type: 'is-success'
        });
        this.$emit('update');
        this.$parent.close();
      }).catch(() => {
        this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
        this.$buefy.toast.open({
          message: 'Lo siento, ha ocurrido un error al intentar procesar tu petición',
          type: 'is-danger'
        });
      });
    },
    uploadImages () {
      this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: true, typeMessage: 3 });
      const sortedArray = this.selectedImages.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
      sortedArray.forEach((file) => {
        file.realSize = this.bytesToSize(file.size);
      });
      const pass = sortedArray.filter(sa => (sa.realSize.format === 'MB' && sa.realSize.size >= 2));
      if (pass.length > 0) {
        this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
        this.$buefy.dialog.alert({
          title: 'Error',
          message: `Las siguientes imagenes tienen un peso mayor a <b>2MB</b> (${pass.map(p => p.name).join(',')}). Por favor dirijase a la pestaña de información y use el optimizador.`,
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true,
          confirmText: 'Cerrar'
        });
        return;
      }
      this.uploadImageMultiple(sortedArray, this.restaurantData.vendor, 'menu').then(({ data, code }) => {
        if (code === 200) {
          data.data.forEach((url, index) => {
            this.imagesSend.push({ url: url, order: index + 1 });
          });
        }
      });
      this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
    },
    uploadImage (image, name, optimize = 0) {
      this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: true, typeMessage: 3 });
      this.uploadImageGlobal(
        image,
        name,
        null,
        this.restaurantData.vendor,
        'menu',
        optimize
      ).then(({ data, code }) => {
        if (code === 200) {
          this.image = data.data;
          this.info.url = data.data;
          this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
          return;
        }
        this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
        this.$buefy.toast.open({
          message: 'Lo siento, ha ocurrido un error al intentar procesar tu petición',
          type: 'is-danger'
        });
      });
    },
    deleteImages () {
      this.selectedImages = null;
    },
    validate () {
      this.$validator.validateAll().then(result => {
        if (result) {
          if (this.id == null) {
            this.setMenu();
          } else {
            this.updateMenu();
          }
        }
      });
    },
    setMenu () {
      const data = {
        ...this.info,
        userId: this.user.id
      };
      this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: true, typeMessage: 3 });
      serviceQr.post('/menudigital/single', data).then(({ data }) => {
        this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
        this.$buefy.toast.open({
          message: data.message,
          type: 'is-success'
        });
        this.$emit('update');
        this.$parent.close();
      }).catch(() => {
        this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
        this.$buefy.toast.open({
          message: 'Lo siento, ha ocurrido un error al intentar procesar tu petición',
          type: 'is-danger'
        });
      });
    },
    updateMenu () {
      const data = {
        ...this.info,
        userId: this.userId
      };
      this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: true, typeMessage: 3 });
      serviceQr.put('/menudigital/single/' + this.id, data).then(({ data }) => {
        this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
        this.$buefy.toast.open({
          message: data.message,
          type: 'is-success'
        });
        this.$emit('update');
        this.$parent.close();
      }).catch(() => {
        this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
        this.$buefy.toast.open({
          message: 'Lo siento, ha ocurrido un error al intentar procesar tu petición',
          type: 'is-danger'
        });
      });
    },
    bytesToSize (bytes) {
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
      if (bytes === 0) return '0 Byte';
      const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return { size: Math.round(bytes / Math.pow(1024, i), 2), format: sizes[i] };
    }
  },
  watch: {
    fileRestaurantCover (value) {
      if (value == null) {
        return;
      }
      const fileInfo = this.bytesToSize(value.size);
      if (fileInfo.format === 'MB' && fileInfo.size > 2) {
        this.$buefy.dialog.confirm({
          title: 'Imagen Muy Pesada.',
          message: 'Se recomienda subir imagenes con tamaño inferior a <b>2 MB</b>. ¿Desea que optimicemos la imagen que esta cargando?. El peso actual de tu imagen es ' + `<b>${fileInfo.size} ${fileInfo.format}</b>`,
          confirmText: 'Si, subir',
          cancelText: 'No, cancelar',
          type: 'is-danger',
          hasIcon: true,
          onConfirm: () => {
            const name = value.name.split('.');
            this.uploadImage(value, name[0], 1);
          },
          onCancel: () => {
            this.fileRestaurantCover = null;
          }
        });
        return;
      }
      const name = value.name.split('.');
      this.uploadImage(value, name[0]);
    },
    typeLanguage () {
      this.info.language = this.typeLanguage;
    }
  }
};
</script>
<style lang="scss" scoped>
.modal-card-body {
  min-width: 400px;
  width: 400px;
  max-width: 100%;
}
.MenuMultiple {
  &-cover {
    background-color: #f1f1f1;
    background-size: cover;
    height: 30px;
    width: 100%;
    cursor: pointer;
  }
  &-coverButton,
  &-logoButton {
    background-color: #fff;
    border: 1px solid #f1f1f1;
    border-radius: 2px;
    color: #00a7b5;
    display: initial;
    font-size: 0.75rem;
    margin: 1px;
    padding: 0.3rem;
    white-space: nowrap;
  }
  &-coverButtonText {
    display: none;
    opacity: 0;
    transition: all 1s;
  }
  &-logo {
    display: block;
    padding: 10px 0;
    margin-bottom: 30px;
    position: relative;
    &:before {
      background: #00adc6;
      content: "";
      width: 50px;
      height: 2px;
      position: absolute;
      bottom: -5px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  &-logoImage {
    max-height: 100px;
    max-width: 100%;
  }
  &-logoButton {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  &-icon {
    opacity: 0.5;
    vertical-align: middle;
  }
  &-block {
    background-color: #f5f5f5;
    color: #7a7a7a;
    margin: 1px;
    padding: 1.25rem 0;
    position: relative;
    text-align: center;
    &.is-small {
      padding: 0.7rem 0;
    }
  }
}
.MenuMultiple-cover:hover .MenuMultiple-coverButtonText {
  display: block;
  opacity: 1;
}
</style>
