const verifyUser = (user) => {
  if (user.isAdmin === 1) {
    return;
  }
  if (!user?.vendors?.length) {
    window.localStorage.removeItem('_token');
    window.localStorage.removeItem('_user');
    window.localStorage.removeItem('_userId');
    window.localStorage.removeItem('listLength');
    window.localStorage.removeItem('version');
    window.localStorage.removeItem('userModules');
    window.localStorage.removeItem('vendorUrl');
    window.localStorage.removeItem('_vendorId');
    window.localStorage.removeItem('_vendors');
    window.localStorage.removeItem('_id');
    window.location.href = '/';
    return;
  }
  let vendorId = window.localStorage.getItem('_id');
  if (!vendorId) {
    window.localStorage.removeItem('_token');
    window.localStorage.removeItem('_user');
    window.localStorage.removeItem('_userId');
    window.localStorage.removeItem('listLength');
    window.localStorage.removeItem('version');
    window.localStorage.removeItem('userModules');
    window.localStorage.removeItem('vendorUrl');
    window.localStorage.removeItem('_vendorId');
    window.localStorage.removeItem('_vendors');
    window.localStorage.removeItem('_id');
    window.location.href = '/';
    return;
  }
  vendorId = parseInt(vendorId, 10);
  let exists = false;
  user.vendors.forEach((vendor) => {
    if (vendor.vendorId === vendorId) exists = true;
  });

  if (!exists) {
    window.localStorage.removeItem('_token');
    window.localStorage.removeItem('_user');
    window.localStorage.removeItem('_userId');
    window.localStorage.removeItem('listLength');
    window.localStorage.removeItem('version');
    window.localStorage.removeItem('userModules');
    window.localStorage.removeItem('vendorUrl');
    window.localStorage.removeItem('_vendorId');
    window.localStorage.removeItem('_vendors');
    window.localStorage.removeItem('_id');
    window.location.href = '/';
  }
};

export default verifyUser;
