<template>
  <div id="modalReport">
    <div class="modal_close">
      <i class="icons icon_close_modal" @click="toggleMenuM"></i>
    </div>
    <div class="main_icon">
      <i class="icons icon_report"></i>
    </div>
    <div class="main_title">
      <span class="title_modal ffr">Reporte generado con éxito</span>
    </div>
    <div class="container_buttons">
      <button class="btn_report download ffr" v-if=!download @click="isDownload()">
        <i class="icons icon_download"></i>
        Descargar
      </button>
      <button class="btn_report download ffr" v-else>
        <i class="icons icon_download"></i>
        Descargando
      </button>
      <button class="btn_report close ffr" @click="toggleMenuM">
        <i class="icons icon_close_modal"></i>
        Cerrar
      </button>
    </div>
  </div>
</template>
<script>
import reservationTypes from '@/store/types/reservation';
import axios from 'axios';

export default {
  name: 'modalReport',
  data () {
    return {
      download: false
    };
  },
  methods: {
    isOpen () {
      this.$store.dispatch({ type: 'reservation:deleteReport', data: { url: this.apiReportDownload } }).then(() => {
        this.$store.commit(reservationTypes.mutations.setApiReportDownload, null);
      }).catch(() => {
        this.$store.commit(reservationTypes.mutations.setApiReportDownload, null);
      });
    },
    isDownload () {
      this.download = true;
      axios({
        url: process.env.VUE_APP_URL_REPORT + '/downloadReport/' + this.apiReportDownload,
        responseType: 'blob',
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('_token')
        }
      }).then((response) => {
        this.download = false;
        const year = new Date().getFullYear();
        const hour = new Date().getHours();
        const hour2 = (hour <= 9) ? '0' + hour : hour;
        const minute = new Date().getMinutes();
        const minute2 = (minute <= 9) ? '0' + minute : minute;
        const second = new Date().getSeconds();
        const second2 = (second <= 9) ? '0' + second : second;
        const month = new Date().getMonth() + 1;
        const month2 = (month <= 9) ? '0' + month : month;
        const date = new Date().getDate();
        const date2 = (date <= 9) ? '0' + date : date;
        const filename = year + '_' + month2 + '_' + date2 + '_' + hour2 + ':' + minute2 + ':' + second2;
        if (this.typeApiReportDownload === 'xlsx') {
          const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = 'Reporte_Reservas_' + filename + '.xlsx';
          link.click();
          this.isOpen();
        } else {
          const blob = new Blob([response.data], { type: 'application/pdf' });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = 'Reporte_Reservas_' + filename + '.pdf';
          link.click();
          this.isOpen();
        }
      }).catch(() => {
        this.download = false;
        this.$buefy.notification.open({
          message: 'Archivo no encontrado',
          type: 'is-danger'
        });
      });
    },
    toggleMenuM () {
      this.$store.commit(reservationTypes.mutations.setApiReportDownload, null);
      this.download = false;
      this.$parent.close();
    }
  }
};
</script>
<style lang="scss">
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700);
@import '../../../assets/styles/users/modalReport.scss';
</style>
