<template>
  <div class="RestaurantTable" ref="table">
    <iframe class="RestaurantTable-resize" ref="Eventor" allowtransparency=true></iframe>
    <div class="RestaurantTable-centre" :class="{'RestaurantTable-centre--rounded': rounded, 'RestaurantTable-centre-active': isActive, 'RestaurantTable-centre-inactive': !isActive}">
      <b-icon icon="eye-off-outline" class="RestaurantTable-private" v-if="isPrivate"  :style="{transform: 'rotate(-' + rotationTable + 'deg)'}"></b-icon>
      <span class="RestaurantTable-centreText" :style="{transform: 'rotate(-' + rotationTable + 'deg)', marginTop: '-12px'}"><b>{{ name }}</b></span>
      <span class="RestaurantTable-centreText" :style="{transform: 'rotate(-' + rotationTable + 'deg)', marginTop: '10px'}">{{size }} / {{ sizeMax }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RestaurantTable',
  props: {
    chairs: {
      type: Number,
      default: 1
    },
    rounded: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: ''
    },
    id: {
      type: Number,
      default: 0
    },
    size: {
      type: Number,
      default: 0
    },
    sizeMax: {
      type: Number,
      default: 0
    },
    selected: {
      type: Boolean,
      default: false
    },
    isPrivate: {
      type: Boolean,
      default: false
    },
    isActive: {
      type: Number,
      default: 0
    },
    rotationTable: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      theChairs: this.chairs
    };
  },
  watch: {
    chairs () {
      this.render();
    }
  },
  mounted () {
    this.table = this.$refs.table;
    this.render();
    this.$refs.Eventor.contentWindow.addEventListener('resize', this.render);
  },
  methods: {
    render () {
      var size = 20;
      var radius = this.table.offsetWidth - (size * 2.75);
      var width = this.table.offsetWidth;
      var height = this.table.offsetHeight;
      var angle = 0;
      var step = (2 * Math.PI) / this.chairs;

      var chairs = Array(this.chairs).fill().map(chair => {
        chair = {
          x: Math.round(width / 2 + radius * Math.cos(angle) - size / 2.5),
          y: Math.round(height / 2 + radius * Math.sin(angle) - size / 2.5),
          angle: angle * 180 / Math.PI
        };
        angle += step;
        return chair;
      });
      this.theChairs = JSON.parse(JSON.stringify(chairs));
    }
  }
};
</script>

<style scoped lang="scss">
.RestaurantTable {
  pointer-events: none;
  border-radius: 4px;
  position: relative;
  height: 100%;
  width: 100%;
  &:hover {
    background-color: #eee;
  }
  &-resize {
    width: 100%;
    height: 100%;
    position: absolute;
    border: none;
    background-color: transparent;
  }
  &-centre {
    &-active {
      background: url('../../../assets/wooden.jpg');
      background-size: cover;
    }
    &-inactive {
      background: rgba(130, 120, 120, 0.69);
      background-size: cover;
      color: #fff;
    }
    border-radius: 4px;
    overflow: hidden;
    position: absolute;
    top: 14px;
    right: 14px;
    bottom: 14px;
    left: 14px;
    z-index: 1;
  }
  &-centre--rounded {
    border-radius: 100rem;
  }
  &-centreText {
    color: #fff;
    position: absolute;
    width: 100%;
    height: 100%;
    text-shadow: 1px 1px 3px #333;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &-chair {
    // background: url('../assets/chair.png');
    background: #D2D2D0;
    border-radius: 100%;
    width: 16px;
    height: 16px;
    position: absolute;
  }
  &-private {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: .3;
  }
}
::v-deep .mdi-24px.mdi-eye-off-outline.mdi:before {
  font-size: 50px;
}
</style>
