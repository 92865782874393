<template>
  <div
    id="Time"
    v-show="!toggle || (toggle && !map)"
    :class="{
      'component-lateral': toggle,
      islist: !isMap,
      'is-sidebar-active': $store.getters['global:sideBarState'],
    }"
  >
    <div
      class="card-header"
      :class="{ active: mactive }"
      @click.prevent.stop="activeHours()"
    >
      <p class="Filters" v-if="isMap">Reservas</p>
      <p class="Filters" v-else>Filtros</p>
      <div class="alertContainer" v-show="(dateFilterSelect.length > 0 || selectedDates.length > 0) && !mactive">
        <i class="icon_alert_danger"></i>
      </div>
      <a class="icon_left_filter icon-mks white desplegar" @click="contraer()"
      style="width: 7px; right: 14px;"></a>
    </div>
    <a
      v-if="toggle == true"
      class="card-title"
      :class="{ 'tittle-lateral': toggle }"
      @click="expand()"
    >
      <i class="icon_dashboard_clock icon-mks gray-blue"></i>
      Filtros
      <i class="icon_left icon-mks white"></i>
      <div
        class="alertContainer lateralposition"
        v-show="(dateFilterSelect.length > 0 || selectedDates.length > 0)"
      >
        <i class="icon_alert_danger"></i>
      </div>
    </a>
    <div class="hour-content" :class="{ hidden: toggle, active: mactive }">
      <Hours
        :isMap="isMap"
        @activatefilter="SetFilter"
        :theActivate="filterActivateSelected"
      ></Hours>
    </div>
    <div
      class="btn-box"
      v-if="!isMap && !validatePermission('ReadOnly') && !validatePermission('MixSFCN') && !toggle"
      :class="{ noPrecompra: !params.prePurchaseDashboard }"
    >
      <a
        href="javascript:void(0)"
        @click.prevent.stop="setAddReservation()"
        class="buton-action reservation"
        :class="{
          'btn-reserva-lateral': toggle,
          'buttons-change-size':
            params.prePurchaseDashboard && selectedDate == today,
        }"
      >
        <div>Reserva</div>
        <i class="icon_buttonPlus"></i>
      </a>
      <a
        v-if="params.prePurchaseDashboard"
        href="javascript:void(0)"
        @click.prevent.stop="setAddPrecompra()"
        class="buton-action precompra"
        :class="{
          'btn-reserva-lateral': toggle,
          'buttons-change-size':
            params.prePurchaseDashboard && selectedDate == today,
        }"
      >
        <div>Precompra</div>
        <i class="icon_buttonPlus"></i>
      </a>
      <a
        href="javascript:void(0)"
        @click.prevent.stop="setAddWalkin()"
        class="buton-action walking"
        :class="{
          'btn-walkin-lateral': toggle,
          'buttons-change-size': params.prePurchaseDashboard,
        }"
        v-if="selectedDate == today"
      >
        <div>Walk-In</div>
        <i class="icon_buttonPlus"></i>
      </a>
      <button
        class="buttonList"
        @click="toggleMenuL"
        :peopleAwaiting="listLength"
        :class="{ peopleWaiting: listLength > 0 }"
      >
        <img src="@/assets/icons/addReservation/clock.svg" alt="icon" />
      </button>
    </div>
  </div>
</template>
<script>
import Hours from './Hours';
import reservationTypes from '@/store/types/reservation';
export default {
  components: { Hours },
  props: ['lateral', 'isMap', 'filterActivateSelected'],
  data () {
    return {
      toggle: this.lateral,
      mactive: false,
      listLength: 0
    };
  },
  created () {
    window.setInterval(this.getListLength, 600);
  },
  watch: {
    lateral () {
      this.toggle = this.lateral;
    }
  },
  methods: {
    getListLength () {
      this.listLength = window.localStorage.getItem('listLength');
    },
    activeHours () {
      this.mactive = !this.mactive;
    },
    contraer () {
      this.toggle = true;
      this.$emit('toggle', this.toggle);
    },
    expand () {
      this.toggle = false;
      this.$emit('toggle', this.toggle);
    },
    SetFilter (value) {
      this.$emit('activatefilter', value);
    },
    toggleMenuL () {
      this.$store.commit(
        reservationTypes.mutations.setSideLateral,
        !this.sidelateral
      );
    }
  }
};
</script>

<style lang="scss">
#Time {
  .alertContainer {
    background-color: red;
    border-radius: 50%;
    height: 24px;
    width: 24px;
    align-items: center;
    justify-content: center;
    display: flex;
    @media screen and (max-width: 1000px) {
      height: 20px;
      width: 20px;
      margin-left: 10px;
      &.lateralposition {
        display: none;
      }
    }
          @media screen and (min-width: 1000px) {
        &:not(.lateralposition) {
          display: none;
        }
      }
    .icon_alert_danger {
      @media screen and (min-width: 1000px) {
        transform: rotate(180deg);
      }

      width: 100%;
      height: 100%;
      align-self: center;
      mask-repeat: no-repeat;
      mask-size: 60%;
      mask-position: center;
      background-color: white;
      @media screen and (max-width: 1000px) {
      }
    }
  }

  .Filters {
    font-size: 14px;
    transform: translateY(1px);
  }
  &.component-lateral {
    padding-left: 0px;
    transition: all 0.4s ease-in-out;
    background: #444b57;
  }
  position: relative;
  // height: 100%;
  height: auto;
  overflow-y: visible;
  border-radius: 0 0 10px 10px;
  z-index: 2;
  background: #fff;
  display: flex;
  flex-direction: column;
  .hour-content {
    flex: 1;
  }
  &.islist {
    height: 100%;
    .card-header {
      @media screen and (max-width: 900px) {
        width: 100%;
        margin: unset;
      }
    }
  }
  .hidden {
    display: none;
  }
  .flex-container {
    display: flex;
  }
  .btn-reserva-lateral {
    position: absolute;
    left: -89px;
    bottom: 15px;
    transform: rotate(180deg);
  }
  .btn-walkin-lateral {
    position: absolute;
    left: -84px;
    bottom: 60px;
    transform: rotate(180deg);
  }
  .card-header {
    background-color: #444b57 !important;
    padding: 8px 10px;
    align-items: center !important;
    justify-content: center !important;
    color: #fff;
    font-weight: 600;
    font-size: 15px;
    display: flex;
    &:not(.is-arrowless)::after {
      display: none;
    }
    i {
      align-self: flex-end;
      justify-self: flex-end;
    }
    .icon-mks {
      &.desplegar {
        position: absolute;
        right: 0;
        top: 12px;
      }
    }
    .icon_left_filter {
      @media screen and (max-width: 1000px) {
        display: none;
      }
    }
  }
  .tittle-lateral {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    letter-spacing: 0.1em;
    transform: rotate(180deg);
    position: absolute;
    top: 10px;
    right: 0;
    left: 0;
    margin: auto;
    writing-mode: vertical-lr;
    white-space: nowrap;
    font-weight: bold;
    color: white;
    cursor: pointer !important;
    display: flex;
    align-items: center;
    gap: 14px;
    i.icon_left {
      margin: unset;
    }
  }
  .btn-box {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    background: #fff;
    padding: 10px 0px;
    bottom: 0;
    &.noPrecompra {
      justify-content: center;
      gap: 16px;
    }
    .buttonList {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 34px;
      height: 30px;
      border-radius: 9px;
      border: 1px solid black;
      outline: unset;
      position: relative;
      cursor: pointer;
      &.peopleWaiting {
        &::after {
          content: attr(peopleAwaiting);
          display: block;
          border-radius: 50%;
          width: 14px;
          height: 14px;
          position: absolute;
          top: -6px;
          right: -6px;
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 2px;
          background: #f83037;
          font-family: "Roboto";
          font-style: normal;
          font-weight: 500;
          font-size: 8px;
          line-height: 11px;
          display: flex;
          align-items: center;
          text-align: center;
        }
      }
      img {
        width: 55%;
        height: 55%;
      }
    }
    .buton-action {
      background: #000;
      color: #fff;
      font-weight: 600;
      display: flex;
      padding: 8px 17px 8px 17px !important;
      border-radius: 50px;
      line-height: 22px;
      font-family: "Roboto";
      align-items: center;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      min-width: 93px;
      justify-content: center;
      &.buttons-change-size {
        padding: 5px 5px 5px 10px;
      }
      &.reservation {
        background: #00adc6;
      }
      &.walking {
        background: #8a51b4;
      }
      &.precompra {
        background: #bdc30e;
      }
      div {
        transform: translateY(1px);
      }
      i {
        height: 13px;
        width: 15px;
        font-size: 18px;
        margin-left: 2px;
        mask-repeat: no-repeat;
        background-color: white;
        mask-size: 80%;
        mask-position: center;
      }
    }
  }
}

.list-mode#Time {
  height: 100% !important;
  @media screen and (max-width: 900px) {
    border-radius: 4px !important;
    padding: unset !important;
  }
}
// .is-sidebar-active{
//   display: none !important;
// }
</style>
