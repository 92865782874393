<template>
  <section class="card-content card-custom-content">
    <b-tabs class="tabMenuCustom" v-model="tabActive">
      <b-tab-item label="Menú Digital">
        <div class="columns">
          <div
            class="column"
            v-if="restaurant.isActiveMenuDigital && (restaurant.typeMenuDigital == 'basic' || restaurant.typeMenuDigital == 'medium' || this.restaurant.typeMenuDigital === 'intermediate' || this.restaurant.typeMenuDigital === 'advanced')"
          >
            <b-button
              @click="addItemSingle"
              icon-left="plus"
              style="margin-top: 20px;"
              type="is-success"
            >Agregar Menú</b-button>
            <div style="display: flex; flex-wrap: wrap; width: 100%;">
              <div
                :key="'menu#' + mIn"
                :style="[(menu.isActive === 1) ? '' : {opacity:0.5}]"
                style="margin-right: 5px; width: 200px; min-width: 200px; max-width: 100%; margin-top: 5px; border: 1px solid lightgray; padding: 10px;"
                v-for="(menu, mIn) in menuItem"
              >
                <img :src="menu.url" />
                <div style="display: flex; justify-content: center;align-content: center;">
                  <b-button @click="deleteItem(menu.id)" icon-left="delete" type="is-danger"></b-button>
                  <b-button
                    @click="eyeItem(menu)"
                    icon-left="pencil"
                    style="margin-left: 5px;"
                    type="is-info"
                  ></b-button>
                  <b-checkbox
                    :false-value="0"
                    :native-value="menu.isActive"
                    :true-value="1"
                    @input="deactivateItem(menu)"
                    icon-left="eye"
                    style="margin-left: 5px;"
                    type="is-info"
                    v-model="menu.isActive"
                  ></b-checkbox>
                </div>
              </div>
            </div>
          </div>
          <div
            class="column"
            v-if="restaurant.multiMenu && (restaurant.typeMenuDigital == 'intermediate' || restaurant.typeMenuDigital == 'advanced')"
          >
            <b-button
              @click="addItemMultiple"
              icon-left="plus"
              style="margin-top: 20px;"
              type="is-success"
            >Agregar Menú Multiple</b-button>
            <div style="display: flex; flex-wrap: wrap; width: 100%;">
              <div
                :key="'multiple#' + mInM"
                :style="[{'background-image': 'linear-gradient(rgba(18, 18, 18, 0.5), rgba(18, 18, 18, 0.5)),url(' + menuM.image + ')','background-size':'cover'}]"
                style="margin-right: 5px; width: 200px; min-width: 200px; max-width: 100%; margin-top: 5px; border: 1px solid lightgray; padding: 10px; height: 120px; max-height: 100%;"
                v-for="(menuM, mInM) in multiMenus"
              >
                <div style="display: flex; justify-content: center;align-content: center;">
                  <span style="color: white;">{{ menuM.title }}</span>
                </div>
                <div style="display: flex; justify-content: center;align-content: center;">
                  <b-button
                    @click="deleteItemMultiple(menuM.id)"
                    icon-left="delete"
                    type="is-danger is-small"
                  ></b-button>
                  <b-button
                    @click="editItemMultiple(menuM)"
                    icon-left="pencil"
                    style="margin-left: 5px;"
                    type="is-info is-small"
                  ></b-button>
                  <b-button
                    @click="eyeItemMultiple(menuM.id)"
                    icon-left="eye"
                    style="margin-left: 5px;"
                    type="is-primary is-small"
                  ></b-button>
                  <b-checkbox
                    :false-value="0"
                    :native-value="menuM.isActive"
                    :true-value="1"
                    @input="deactivateItemMultiple(menuM)"
                    icon-left="eye"
                    style="margin-left: 5px;"
                    type="is-info"
                    v-model="menuM.isActive"
                  ></b-checkbox>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-tab-item>
      <b-tab-item label="Información">
        <b-field label="PDF a JPG Click para ver" style="cursor:pointer">
          <span @click="copyToGo">{{ urlPdf }}</span>
        </b-field>
        <b-field label="Para Optimizar las imagenes" style="cursor:pointer">
          <span @click="copyToGoOptimizer">{{ urlOptimizar }}</span>
        </b-field>
        <b-field>
          <span>
            El peso maximo de las imagenes es de que no se quieran optimizar es de
            <b>2MB</b>
          </span>
        </b-field>
        <b-field>
          <span>
            Si se van a subír multiples imagenes ninguna puede pesar más de
            <b>2MB</b>
          </span>
        </b-field>
        <b-field>
          <span>
            Solo se acepta formato
            <b>JPG, JPEG, PNG</b>
          </span>
        </b-field>
      </b-tab-item>
    </b-tabs>
  </section>
</template>
<script>
import AddMenuSingle from '@/components/schedules/qr/AddMenuSIngle.vue';
import AddMenuMultiple from '@/components/schedules/qr/AddMenuMultiple.vue';
import AddMenuMultipleOption from '@/components/schedules/qr/AddMenuMultipleOption.vue';
import globalTypes from '@/store/types/global';
import { mapGetters } from 'vuex';
import serviceQr from '@/helpers/apis/serviceQr.js';
export default {
  props: ['restaurant'],
  data () {
    return {
      tabActive: 0,
      menuItem: [],
      multiMenus: [],
      urlPdf: 'https://www.ilovepdf.com/es/pdf_a_jpg',
      urlOptimizar: 'https://imagecompressor.com/es/'
    };
  },
  computed: {
    ...mapGetters({
      restaurantData: [globalTypes.getters.vendor]
    })
  },
  mounted () {
    if (this.restaurant.isActiveMenuDigital && (this.restaurant.typeMenuDigital === 'basic' || this.restaurant.typeMenuDigital === 'medium' || this.restaurant.typeMenuDigital === 'intermediate' || this.restaurant.typeMenuDigital === 'advanced')) {
      this.getMenu();
    }
    if (this.restaurant.multiMenu && (this.restaurant.typeMenuDigital === 'intermediate' || this.restaurant.typeMenuDigital === 'advanced')) {
      this.getMultiMenu();
    }
  },
  methods: {
    deactivateItem (menu) {
      const data = {
        ...menu,
        userId: this.user.id
      };
      this.loading = true;
      serviceQr.post('/menudigital/single/state', data).then(({ data }) => {
        this.$buefy.toast.open({
          message: data.message,
          type: 'is-success'
        });
        this.getMenu();
        this.loading = false;
      }).catch(() => {
        this.loading = false;
        this.$buefy.toast.open({
          message: this.$t('errors.request'),
          type: 'is-danger'
        });
      });
    },
    deactivateItemMultiple (menu) {
      const data = {
        ...menu,
        userId: this.user.id
      };
      this.loading = true;
      serviceQr.post('/menudigital/multiple/state', data).then(({ data }) => {
        this.$buefy.toast.open({
          message: data.message,
          type: 'is-success'
        });
        this.getMultiMenu();
        this.loading = false;
      }).catch(() => {
        this.loading = false;
        this.$buefy.toast.open({
          message: this.$t('errors.request'),
          type: 'is-danger'
        });
      });
    },
    copyToGo () {
      window.open(this.urlPdf, '_blank');
    },
    copyToGoOptimizer () {
      window.open(this.urlOptimizar, '_blank');
    },
    addItemSingle () {
      const data = {};
      data.vendorId = this.restaurant.vendorId;
      data.url = null;
      data.order = 1;
      data.language = 'spanish';
      var instance = this;
      this.$buefy.modal.open({
        parent: instance,
        props: {
          id: null,
          info: data,
          restaurant: instance.restaurant
        },
        events: {
          update () {
            instance.getMenu();
          }
        },
        component: AddMenuSingle,
        hasModalCard: true,
        canCancel: false
      });
    },
    eyeItem (menu) {
      const data = {};
      data.vendorId = this.restaurant.vendorId;
      data.url = menu.url;
      data.order = menu.order;
      data.language = menu.language;
      var instance = this;
      this.$buefy.modal.open({
        parent: instance,
        props: {
          id: menu.id,
          info: data,
          restaurant: instance.restaurant,
          update: true
        },
        events: {
          update () {
            instance.getMenu();
          }
        },
        component: AddMenuSingle,
        hasModalCard: true,
        canCancel: false
      });
    },
    getMenu () {
      this.loading = true;
      serviceQr.get('/menudigital/single/' + this.restaurant.vendorId).then(({ data }) => {
        this.menuItem = data.data;
        this.loading = false;
      }).catch(() => {
        this.loading = false;
        this.$buefy.toast.open({
          message: this.$t('errors.request'),
          type: 'is-danger'
        });
      });
    },
    getMultiMenu () {
      this.loading = true;
      serviceQr.get('/menudigital/multimenu/' + this.restaurant.vendorId).then(({ data }) => {
        this.multiMenus = data.data;
        this.multiMenus.forEach(element => {
          if (element.dayOfWeek != null) {
            const strDaysArray = element.dayOfWeek.split(',');
            element.dayOfWeek = strDaysArray.map(Number);
          }
        });
        this.loading = false;
      }).catch(() => {
        this.loading = false;
        this.$buefy.toast.open({
          message: this.$t('errors.request'),
          type: 'is-danger'
        });
      });
    },
    deleteItem (id) {
      var instance = this;
      this.$buefy.dialog.confirm({
        title: 'Eliminar menú',
        message: '¿Está seguro de que desea <b> eliminar </b> el menú? Esta acción no se puede deshacer.',
        confirmText: 'Si, Eliminar',
        cancelText: 'No, cancelar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          instance.loading = true;
          serviceQr.delete('/menudigital/single/' + id + '/' + this.user.id).then(({ data }) => {
            instance.loading = false;
            instance.$buefy.toast.open({
              message: data.message,
              type: 'is-success'
            });
            instance.getMenu();
          }).catch(() => {
            instance.loading = false;
            instance.$buefy.toast.open({
              message: instance.$t('errors.request'),
              type: 'is-danger'
            });
          });
        }
      });
    },
    addItemMultiple () {
      const intance = this;
      const data = {};
      data.vendorId = this.restaurant.vendorId;
      data.image = null;
      data.title = null;
      data.titleEnglish = null;
      data.imageEnglish = null;
      data.param = 0;
      data.order = 1;
      data.programing = 0;
      data.dayOfWeek = null;
      data.initPrograming = null;
      data.finishPrograming = null;
      this.$buefy.modal.open({
        parent: this,
        props: {
          id: null,
          info: data,
          restaurant: this.restaurant,
          restaurantData: this.restaurantData
        },
        events: {
          update () {
            intance.getMultiMenu();
          }
        },
        component: AddMenuMultiple,
        hasModalCard: true,
        canCancel: false
      });
    },
    eyeItemMultiple (id) {
      const data = {};
      data.vendorId = this.restaurant.vendorId;
      data.image = null;
      data.type = 'image';
      data.order = 1;
      var instance = this;
      this.$buefy.modal.open({
        parent: instance,
        props: {
          id: id,
          info: data,
          restaurant: instance.restaurant,
          restaurantData: this.restaurantData
        },
        events: {
          update () {
            instance.getMultiMenu();
          }
        },
        component: AddMenuMultipleOption,
        hasModalCard: true,
        canCancel: false
      });
    },
    editItemMultiple (data) {
      const info = {};
      info.vendorId = this.restaurant.vendorId;
      info.image = data.image;
      info.title = data.title;
      info.titleEnglish = data.titleEnglish;
      info.imageEnglish = data.imageEnglish;
      info.param = data.param;
      info.order = data.order;
      info.programing = data.programing;
      info.dayOfWeek = data.dayOfWeek ? data.dayOfWeek : null;
      info.initPrograming = data.initPrograming;
      info.finishPrograming = data.finishPrograming;
      var instance = this;
      this.$buefy.modal.open({
        parent: instance,
        props: {
          id: data.id,
          info: info,
          restaurant: instance.restaurant,
          restaurantData: this.restaurantData
        },
        events: {
          update () {
            instance.getMultiMenu();
          }
        },
        component: AddMenuMultiple,
        hasModalCard: true,
        canCancel: false
      });
    },
    deleteItemMultiple (id) {
      var instance = this;
      this.$buefy.dialog.confirm({
        title: 'Eliminar menú multiple',
        message: '¿Está seguro de que desea <b> eliminar </b> el menú multiple? Esta acción no se puede deshacer.',
        confirmText: 'Si, Eliminar',
        cancelText: 'No, cancelar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          instance.loading = true;
          serviceQr.delete('/menudigital/multimenu/' + id + '/' + this.user.id).then(({ data }) => {
            instance.loading = false;
            instance.$buefy.toast.open({
              message: data.message,
              type: 'is-success'
            });
            instance.getMultiMenu();
          }).catch(() => {
            instance.loading = false;
            instance.$buefy.toast.open({
              message: instance.$t('errors.request'),
              type: 'is-danger'
            });
          });
        }
      });
    }
  }
};
</script>
<style lang="scss">
.menu-container {
  width: 200px;
  min-width: 200px;
  max-width: 100%;
}
.icon-size-action {
  width: 20px;
  height: 20px;
  margin: auto;
}
.card-custom-content {
  overflow-y: scroll;
  height: calc(100vh - 90px);
}
.tabMenuCustom > .tabs {
  display: block !important;
}
.inputs-size-custom > .input {
  font-size: 1rem !important;
  height: 2.25em !important;
}
.width-auto > .vue-treeselect__control {
  width: auto !important;
}
@media screen and (min-width: 769px) {
  .modal-customMenu {
    width: 960px !important;
  }
}
@media screen and (min-width: 200px) and (max-width: 621px) {
  .modal-customMenu {
    max-height: calc(100vh - 40px) !important;
  }
}
</style>
