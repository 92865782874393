<template>
  <div class="blacklogin">
    <b-modal :active.sync="showModalLogin" :width="500" scroll="keep" class="ModaLogin" :can-cancel="false">
      <div class="box-login" v-if="getStatusOnline">
        <div class="columns is-marginless is-paddingless" id="columns-box">
          <!-- Lado izquierdo-->
          <div class="column is-half is-marginless is-paddingless hidden-mobile">
            <div class="logo-main">
              <div class="withebackground"></div>
              <div class="box-circulo-main">
                <img class="img-logo-mobile" src="../../../public/images/precompro_logo.png">
              </div>
            </div>
            <!-- Lado izuqiero recovery de la pass-->
            <div class="recovery-pass" :class="{'active' : recoverPassword == true}">
              <div class="box-recovery">
                <a v-if="!resetSend" class="img-cancel-black" @click="closeRecovery">
                  <img class="img-size" src="../../assets/images/Login/icons/icons8-cancel-24.png" alt="cancel">
                </a>
                <div v-show="!resetSend">
                  <p class="p-normal p-normal-left">Recuperación de contraseña</p>
                  <p class="p-normal">Ingrese la dirección de correo electrónico y haga clic en Enviar</p>
                  <p class="p-normal">Le enviaremos instrucciones por correo electrónico sobre cómo restablecer su contraseña.</p>
                  <form autocomplete="off" @submit.prevent="validateBeforeSubmitReset">
                    <b-field :type="{'is-danger': errors.has('email')}" style="color: black !important;width: 100%;margin-top: 10px;" :message="errors.first('email')">
                      <b-input icon-pack="fa" icon="envelope" type="email" class="form-control border-radius-group-right5" placeholder="Email" v-validate="'required|email'" autocomplete="off" name="email" v-model="emailReset" data-vv-scope="reset" data-vv-name="email" />
                    </b-field>
                    <button type="submit" class="btn-recovery" v-if="!loading">ENVIAR</button>
                    <span v-if="loading" class="btn-recovery"><i class="fa fa-spin fa-spinner"></i>&nbsp;Enviando...</span>
                  </form>
                </div>
                <div v-if="validationToken">
                  <p class="p-normal">Se ha enviado a tu correo un token para restablecer la contraseña, por favor copialo y pegalo a continuación</p>
                  <form autocomplete="off" @submit.prevent="validateBeforeSubmitValidationToken">
                    <b-field :type="{'is-danger': errors.has('validationToken')}" style="color: red !important;width: 100%;margin-top: 10px;" :message="errors.first('validateToken')">
                      <b-input icon-pack="fa" icon="key" type="text" placeholder="CODIGO" v-validate="'required'" autocomplete="off" name="validationToken" v-model="token" maxlength="8" />
                    </b-field>
                    <button type="submit" class="btn-recovery" v-if="!loading">VERIFICAR</button>
                    <span v-if="loading" class="btn-recovery"><i class="fa fa-spin fa-spinner"></i>&nbsp;Verificando...</span>
                  </form>
                </div>
                <ChangedPassword v-if="showChangedPassword" v-bind:tokUserId="tokenUser"></ChangedPassword>
              </div>
            </div>
          </div>
          <!-- Lado derecho-->
          <div class="column is-marginless is-paddingless column-flex ">
            <!--bar imagen para mobile-->
            <div class="header-mobile logo-main" style="height:60%;">
              <div class="container" id="container-header-mobile">
                <!--bar imagen para movil fin-->
                <!--logo central mobile-->
                <div class="container" id="container-box-logo-mobile">
                  <div class="columns is-vcentered is-marginless is-paddingless is-hidden-desktop">
                    <div class="column has-text-centered is-marginless is-paddingless">
                      <div class="box-circulo">
                        <img class="img-logo-mobile" src="../../../public/images/precompro_logo.png" alt="El Chato">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--logo central mobile-->
            <!--Recovery mobile Inicio-->
            <div class="columns is-marginless is-paddingless hidden-desktop" style="height: 100%;
                  position: absolute; z-index: 10;">
              <div class="recovery-pass-mobile" :class="{'active' : recoverPassword == true}">
                <div class="box-recovery">
                  <a v-if="!resetSend" class="img-cancel-black-mobile" @click="closeRecovery">
                    <img class="img-size" src="../../assets/images/Login/icons/icons8-cancel-24.png" alt="cancel">
                  </a>
                  <div v-show="!resetSend">
                    <p class="p-normal p-normal-left">Recuperación de contraseña</p>
                    <p class="p-normal">Ingrese la dirección de correo electrónico y haga clic en Enviar</p>
                    <p class="p-normal">Le enviaremos instrucciones por correo electrónico sobre cómo restablecer su contraseña.</p>
                    <form autocomplete="off" @submit.prevent="validateBeforeSubmitReset2">
                      <b-field :type="{'is-danger': errors.has('email')}" style="color: black !important;width: 100%;margin-top: 10px; " :message="errors.first('email')">
                        <b-input icon-pack="fa" icon="envelope" type="email" class="form-control border-radius-group-right5" id="emailReset" placeholder="Email" v-validate="'required|email'" autocomplete="off" name="email" v-model="emailReset" data-vv-scope="reset2" data-vv-name="email" />
                      </b-field>
                      <button type="submit" class="btn-recovery" v-if="!loading">ENVIAR</button>
                      <span v-if="loading" class="btn-recovery"><i class="fa fa-spin fa-spinner"></i>&nbsp;Enviando...</span>
                    </form>
                  </div>
                  <div v-if="validationToken">
                    <p class="p-normal">Se ha enviado a tu correo un token para restablecer la contraseña, por favor copialo y pegalo a continuacion</p>
                    <form autocomplete="off" @submit.prevent="validateBeforeSubmitValidationToken">
                      <b-field :type="{'is-danger': errors.has('validationToken')}" style="color: red !important;width: 100%;margin-top: 10px;" :message="errors.first('validateToken')">
                        <b-input icon-pack="fa" icon="key" type="text" placeholder="CODIGO" v-validate="'required'" autocomplete="off" name="validationToken" v-model="token" maxlength="8" />
                      </b-field>
                      <button type="submit" class="btn-recovery" v-if="!loading">VERIFICAR</button>
                      <span v-if="loading" class="btn-recovery"><i class="fa fa-spin fa-spinner"></i>&nbsp;Verificando...</span>
                    </form>
                  </div>
                  <ChangedPassword v-if="showChangedPassword" v-bind:tokUserId="tokenUser"></ChangedPassword>
                </div>
              </div>
            </div>
            <!--Recovery mobile fin-->
            <div v-show="rightLogo" class="logo-main">
              <div class="withebackground"></div>
              <div class="box-circulo-main">
                <img class="img-logo-mobile" src="../../../public/images/precompro_logo.png">
              </div>
            </div>
            <div v-show="!rightLogo" class="container-contenido-modal">
              <!-- Titulo de Iniciar Sesion y Registrate-->
              <div class="titulo">
                <div class="columns is-mobile columns-width">
                  <div class="column">
                    <button id="viewIniciar" class="login-tittle" :class="{ 'login-tittle-active' : LogSelection == 0 }" :disabled="LogSelection == 0">Iniciar sesión</button>
                  </div>
                </div>
              </div>
              <b-tabs position="is-centered" class="block" v-model="LogSelection">
                <b-tab-item label="" class="inicio">
                  <form @submit.prevent="validateBeforeSubmit">
                    <b-field :type="{'is-danger': errors.has('email')}" :message="errors.first('email')">
                      <b-input icon-pack="fa" icon="envelope" type="email" class="form-control border-radius-group-right5" id="email" placeholder="Email" v-validate="'required|email'" autocomplete="off" name="email" v-model="usuario.email" />
                    </b-field>
                    <b-field :type="{'is-danger': errors.has('password')}" :message="errors.first('password')">
                      <b-input type="password" class="form-control border-radius-group-right5" id="password" placeholder="Contraseña" autocomplete="off" v-validate="'required|min:6'" name="password" v-model="usuario.password" password-reveal icon-pack="fa" icon="lock" />
                    </b-field>
                    <div class="columns is-mobile is-gapless columns-width columns-font-size">
                      <div class="column is-pulled-right has-text-centered column-flex">
                        <a id="reset-pass" class="p-normal-ligth" @click="recoverPassword = true">
                          <img class="img-olvi" src="../../assets/images/Login/icons/Icon feather-arrow-rigth.png" alt="arrow">¿Olvido su Contraseña?</a>
                      </div>
                    </div>
                    <button type="submit" class="btn btn-register" v-if="!loadingLogin">Entrar</button>
                    <span v-if="loadingLogin" class="btn btn-register"><i class="fa fa-spin fa-spinner"></i>&nbsp;Verificando...</span>
                  </form>
                </b-tab-item>
              </b-tabs>
            </div>
          </div>
        </div>
        <!--botones Fin-->
      </div>
    </b-modal>
  </div>
</template>
<script>
import authTypes from '@/store/types/auth';
import ChangedPassword from '@/components/_shared/ChangedPasswordForm';
export default {
  metaInfo: {
    title: 'Iniciar Sesión'
  },
  components: {
    ChangedPassword
  },
  data () {
    return {
      show: false,
      usuario: {
        email: '',
        password: ''
      },
      emailReset: '',
      error: null,
      infoError: null,
      loading: false,
      loadingLogin: false,
      showModalLogin: true,
      recoverPassword: false,
      LogSelection: 0,
      resetSend: false,
      token: '',
      showChangedPassword: false,
      validationToken: false,
      rightLogo: false,
      tokenUser: null,
      processReset: false,
      loginSuccess: false
    };
  },
  mounted () {
    const vendorUrl = this.subDomain();
    this.$store.commit(authTypes.mutations.setUser);
    if (vendorUrl !== undefined && vendorUrl != null) {
      this.$store.dispatch({
        type: 'global:vendorId',
        data: {
          url: vendorUrl
        }
      });
    }
  },
  methods: {
    closeRecovery () {
      this.recoverPassword = false;
      this.resetSend = false;
      this.loading = false;
      this.emailReset = '';
    },
    danger (error) {
      this.$buefy.toast.open({
        duration: 5000,
        message: error,
        position: 'is-bottom',
        type: 'is-danger'
      });
    },
    closeError () {
      this.error = false;
      this.infoError = false;
    },
    resetPassword () {
      window.location.href = '/dashboard/reset';
    },
    validateBeforeSubmit () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.loadingLogin = true;
          this.processLogin(this.usuario);
        }
      });
    },
    processLogin (usuario) {
      this.login({
        email: usuario.email,
        password: usuario.password,
        deviceId: this.deviceId,
        system: 'dashboard'
      })
        .then(() => {
          this.loadingLogin = false;
        })
        .catch(({ response }) => {
          if (parseInt(response.status) !== 200) {
            this.error = true;
            this.loadingLogin = false;
            this.danger(this.textErrorTransform(response.data.message));
          } else {
            this.loadingLogin = false;
            this.error = true;
            this.danger('Error al procesar la petición');
          }
        });
    },
    textErrorTransform (msj) {
      if (msj === 'EMAIL_NOT_FOUND') {
        return 'Email inválido';
      } else if (msj === 'INVALID_PASSWORD') {
        return 'Contraseña inválida';
      } else {
        return msj;
      }
    },
    validateBeforeSubmitReset () {
      this.$validator.validateAll('reset').then(result => {
        if (result) {
          this.loading = true;
          this.processResetUser(this.emailReset);
        }
      });
    },
    validateBeforeSubmitReset2 () {
      this.$validator.validateAll('reset2').then(result => {
        if (result) {
          this.loading = true;
          this.processResetUser(this.emailReset);
        }
      });
    },
    validateBeforeSubmitValidationToken () {
      this.$validator.validateAll('token').then(result => {
        if (result) {
          this.loading = true;
          this.checkToken(this.token);
        }
      });
    },
    checkToken (token) {
      this.validateToken(token)
        .then(response => {
          this.tokenUser = response.data.user;
          this.validationToken = false;
          this.showChangedPassword = true;
        })
        .catch(() => {
          this.error = true;
          this.loading = false;
          this.danger('Token invalido');
          this.errors.add({
            field: 'validationToken',
            msg: 'Token invalido'
          });
        });
    },
    processResetUser (email) {
      this.reset({
        email: email
      })
        .then(() => {
          this.validationToken = true;
          this.resetSend = true;
          this.rightLogo = true;
          this.loading = false;
        })
        .catch(() => {
          this.validationToken = false;
          this.loading = false;
          this.error = true;
          this.danger('Error al procesar la petición');
        });
    }
  },
  watch: {
    user () {
      const isArray = Array.isArray(this.user);
      if (!isArray) {
        const vendorUrl = this.subDomain();
        if (vendorUrl !== undefined && vendorUrl != null) {
          window.location.href = '/dashboard';
        }
        window.location.href = '/dashboard/choose-vendor';
      } else {
        window.localStorage.removeItem('_token');
        window.localStorage.removeItem('_user');
        window.localStorage.removeItem('_userId');
        window.localStorage.removeItem('listLength');
        window.localStorage.removeItem('version');
        window.localStorage.removeItem('userModules');
        window.localStorage.removeItem('vendorUrl');
        window.localStorage.removeItem('_vendorId');
        window.localStorage.removeItem('_vendors');
        window.localStorage.removeItem('_id');
      }
    }
  }
};
</script>
<style lang="scss">
@import "../../assets/styles/Login/styles";
.blacklogin {
  background: url('../../assets/images/Login/fondo-dash.jpg') no-repeat center center / cover;
  height: 100%;
  width: 100%;
}

.ModaLogin {
  .modal-background {
    background-color: rgba(10, 10, 10, 0.6);
  }
}

@media (min-width: 801px) {
  .hidden-desktop {
    display: none !important;
  }
}

@media (max-width: 800px) {
  .hidden-mobile {
    display: none !important;
  }
}
</style>
