<template>
  <div id="Calendar">
    <BlockDay v-if="blockDay" v-model="blockDay" @eventBlockCalendar="getHistoryBlock()"></BlockDay>
    <UnblockDay v-if="unblockDay" v-model="unblockDay" @eventBlockCalendar="getHistoryBlock()"></UnblockDay>
    <BlockRange v-if="blockRange" v-model="blockRange" @eventBlockCalendar="getHistoryBlock()"></BlockRange>
    <BlockHours v-if="blockHours" v-model="blockHours" @eventBlockCalendar="getHistoryBlock()"></BlockHours>
    <div class="conventions-button">
      <b-dropdown :mobile-modal="false" :position="isMobile ? 'is-bottom-right' : 'is-top-left'"
      aria-role="list" class="px-2">
        <a slot="trigger" class="convButton calendarConv">...</a>
        <b-dropdown-item
            v-for="(item, index) in calendarLeyend"
            :key="index"
            :focusable="false"
            custom
            aria-role="listitem"
        >
            <div class="media">
                <div class="media-left-custom" v-html="item.html">
                </div>
                <div class="media-content">
                    <h3 class="has-text-white has-text-weight-medium">{{item.text}}</h3>
                </div>
            </div>
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <div v-show="isHidden && !keepOpen">
      <div>{{dayCurrent | moment('D')}}</div>
      <div hoy>{{dayCurrent | moment('MMMM - YYYY')}} &nbsp;<span v-if="selectedDate !== today" class="btn btn-default btn-xs" @click="setTodayCalendarLine()" style="font-size: 11px;">Hoy</span></div>
        <i class="fa fa-chevron-left" @click="prevDate()"></i>
      <div >{{dayCurrent | moment('dddd')}}</div>
        <i class="fa fa-chevron-right" @click="nextDate()"></i>
    </div>
    <div class="card-content" v-show="!isHidden || keepOpen">
      <Calendar @nobar="changenobar" @sibar="changesibar"></Calendar>
      <div class="bottom-butons" v-if="loadingReservationCalendar == false && nobars == false && !validatePermission('ReadOnly')">
        <a v-if = "validatePermission('Blockday')" v-show="selectedBlocked" @click.prevent.stop="setUnblockDay">Desbloquear día</a>
        <a v-if = "validatePermission('Blockday')" v-show="!selectedBlocked" @click.prevent.stop="setBlockDay">Bloquear día</a>
        <a v-if = "validatePermission('BlockHours')" v-show="!selectedBlocked && !selectedBlockedHours" @click.prevent.stop="setBlockHour">Bloqueo por horas</a>
        <a v-if = "validatePermission('BlockHours')" v-show="selectedBlocked || selectedBlockedHours" @click.prevent.stop="setBlockHour">Desbloqueo por horas</a>
        <a v-if = "validatePermission('BlockDayRange')" style="opacity:0.3"  v-tooltip="{content: 'En Desarrollo', placement: 'bottom'}" > Bloquear rango</a>
      </div>
    </div>
  </div>
</template>

<script>
import reservationTypes from '@/store/types/reservation';
import Calendar from './Calendar';
import BlockDay from './BlockDay';
import UnblockDay from './UnblockDay';
import BlockRange from './BlockRange';
import BlockHours from './BlockHours';
import serviceBlocking from '@/helpers/apis/serviceBlocking';

export default {
  props: ['keepOpen'],
  components: { Calendar, BlockDay, UnblockDay, BlockHours, BlockRange },
  data: () => ({
    isHidden: false,
    blockDay: false,
    unblockDay: false,
    blockRange: false,
    blockHours: false,
    nobars: false,
    currentMonth: null,
    currentYear: null,
    calendarLeyend: [
      { text: 'Reservas por día', html: '<div class="reservationDay">2</div>' },
      { text: 'Bloqueo por horas', html: `<img class="icon-mks-leyend" src="${require('@/assets/icons/blockHour.svg')}">` },
      { text: 'Día bloqueado', html: '<div class="reservationDay bloqDay">17</div>' },
      { text: 'Día seleccionado', html: '<div class="reservationDay selectDay">17</div>' },
      { text: 'Día festivo', html: '<div class="ml-1 reservationDay festivoDay"></div>' },
      { text: 'Rango bloqueado', html: '<div class="reservationDay bloqRange"><div class="range">1</div> <div class="range">2</div></div>' },
      { text: 'Día sin horario de atención', html: '<div style="font-weight: lighter; color: #A8ADB5;">29</div>' }
    ]
  }),
  created () {
    const month = parseInt(this.$moment(this.dateComplete).format('M'));
    const date = this.monthConvert(month);
    this.currentMonth = date;
    const year = parseInt(this.$moment(this.dateComplete).format('YYYY'));
    this.currentYear = year;
  },
  methods: {
    getHistoryBlock () {
      serviceBlocking.post('/blocked/day', { vendorId: this.idVendor }).then(({ data }) => {
        this.$store.commit(reservationTypes.mutations.setBlockingDay, data.data);
      });
    },
    setBlockDay () {
      this.blockDay = true;
      this.$store.commit(reservationTypes.mutations.setBlockingTime, true);
    },
    setBlockHour () {
      this.blockHours = true;
      this.$store.commit(reservationTypes.mutations.setBlockingTime, true);
    },
    setUnblockDay () {
      this.unblockDay = true;
      this.$store.commit(reservationTypes.mutations.setBlockingTime, true);
    },
    setTodayCalendarLine () {
      const today = this.$moment().format('YYYY-MM-DD');
      this.$store.commit(reservationTypes.mutations.setSelectedMonth, today);
      this.$store.commit(reservationTypes.mutations.setSelectedDate, today);
      this.$store.dispatch({ type: 'reservation:reservationsDay', data: { vendorId: this.$store.state.vendorId, month: this.selectedMonth } });
      this.$store.dispatch({ type: 'reservation:reservations', data: { vendorId: this.$store.state.vendorId, date: this.selectedDate } });
    },
    prevDate () {
      const prev = this.$moment(this.dateComplete).add(-1, 'days').format('YYYY-MM-DD');
      this.$store.commit(reservationTypes.mutations.setSelectedDate, prev);
      const date = parseInt(this.$moment(this.dateComplete).format('M'));
      const year = parseInt(this.$moment(this.dateComplete).format('YYYY'));
      this.currentMonth = this.monthConvert(date);
      this.currentYear = year;
      const today = this.$moment([this.currentYear, this.currentMonth, 1, 0, 0, 0]).format('YYYY-MM-DD');
      this.$store.commit(reservationTypes.mutations.setSelectedMonth, today);
      this.$store.commit(reservationTypes.mutations.setLoadingReservation, true);
      this.$store.dispatch({ type: 'reservation:reservationsDay', data: { vendorId: this.$store.state.vendorId, month: this.selectedMonth } });
      this.$store.dispatch({ type: 'reservation:reservations', data: { vendorId: this.$store.state.vendorId, date: this.selectedDate } })
        .finally(() => {
          this.$store.commit(reservationTypes.mutations.setLoadingReservation, false);
        });
    },
    nextDate () {
      const next = this.$moment(this.dateComplete).add(1, 'days').format('YYYY-MM-DD');
      this.$store.commit(reservationTypes.mutations.setSelectedDate, next);
      const date = parseInt(this.$moment(this.dateComplete).format('M'));
      const year = parseInt(this.$moment(this.dateComplete).format('YYYY'));
      this.currentMonth = this.monthConvert(date);
      this.currentYear = year;
      const today = this.$moment([this.currentYear, this.currentMonth, 1, 0, 0, 0]).format('YYYY-MM-DD');
      this.$store.commit(reservationTypes.mutations.setSelectedMonth, today);
      this.$store.commit(reservationTypes.mutations.setLoadingReservation, true);
      this.$store.dispatch({ type: 'reservation:reservationsDay', data: { vendorId: this.$store.state.vendorId, month: this.selectedMonth } });
      this.$store.dispatch({ type: 'reservation:reservations', data: { vendorId: this.$store.state.vendorId, date: this.selectedDate } })
        .finally(() => {
          this.$store.commit(reservationTypes.mutations.setLoadingReservation, false);
        });
    },
    changeVertical () {
      this.$store.commit(reservationTypes.mutations.setVerticalReservation, true);
    },
    changenobar () {
      this.nobars = true;
    },
    changesibar () {
      this.nobars = false;
    }
  },
  watch: {
    blockDay () {
      if (this.blockDay) {
        this.unblockDay = false;
        this.blockRange = false;
        this.blockHours = false;
      }
    },
    unblockDay () {
      if (this.unblockDay) {
        this.blockDay = false;
        this.blockRange = false;
        this.blockHours = false;
      }
    },
    blockRange () {
      if (this.blockRange) {
        this.unblockDay = false;
        this.blockDay = false;
        this.blockHours = false;
      }
    },
    blockHours () {
      if (this.blockHours) {
        this.unblockDay = false;
        this.blockRange = false;
        this.blockDay = false;
      }
    }
  }
};
</script>

<style lang="scss">
  #Calendar{
    .media-left-custom {
      min-width: 55px;
      display: flex;
      justify-content: flex-end;
      margin-right: 15px;
      margin-left: 5px;
    }
    .icon-mks-leyend {
      height: 25px !important;
      width: 25px !important;
      margin: 0;
    }
    .icon-mks {
      margin: 0 10px;
      height: 25px;
      width: 25px;
    }
    .card-content {
      background-color: transparent;
      padding: 1.5rem 1rem 1rem !important;
      border-top: 1px solid #83837E;
      display:none;
      @media screen and (max-width:600px) {
        border-top: none;
      }
    }
    .bottom-butons{
      display:flex;
      align-items:center;
      justify-content: space-around;
      margin-top: 10px;
      width:100%;
      a{
        color:#fff;
        font-size:10px;
        font-weight: normal;
        &:hover{
          text-decoration: underline;
        }
      }
    }

    //convenciones
    .conventions-button{
      .dropdown-content{
        background: #444B57 !important;
      }
      top: 16px;
      bottom: 0;
      right: inherit;
      height: 33px;
      .dropdown-menu{
        top: 70px;
        right: 45px;
        width: 14rem;
      }
      @media screen and (min-width: 768px) {
        .dropdown-menu{
          top: 32px;
        }
      }
      @media screen and (min-width:1100px) {
        .dropdown-menu{
          top: 0;
        }
      }
      .reservationDay {
        display: flex;
        background: rgba(91, 33, 80, 1);
        color: white;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        justify-content: center;
        align-items: center;
        &.bloqHora{
            background: rgba(244, 67, 54, 1);
            border: 2px dotted;
        }
        &.bloqDay{
          background: rgba(244, 67, 54, 1);
        }
        &.selectDay{
          border: 1px solid white;
        }
        &.festivoDay{
          background: rgba(202, 211, 0, 1);
          border-radius: 1px;
          height: 7px;
        }
        &.bloqRange{
          background: rgba(244, 67, 54, 0.7);
          width: 45px;
          height: 20px;
          border-radius: 10px;
          justify-content: space-between;
          .range{
            font-size: 10px;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background: rgba(244, 67, 54, 1);
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
    .convButton{
        transform: rotate(90deg);
        background: inherit;
        color: #fff;
        font-size: 30px;
        box-shadow:inherit;
    }

    // Modal
    .content-modal_calendar{
      width: 400px;
      background: #444B55;
      position: absolute;

      right: 98%;
      top: 1px;
      height: 100%;
      border-radius: 10px 0 0 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px;
      height: calc(100% - 1px) !important;
    }

      .box-loading{
        display:flex;
        flex-direction:column;
        align-items:center;
        &.calendar-bg{
          height: calc(100% - 2.5rem);
          position: absolute;
          width: calc(100% - 2rem);
          justify-content: center;
        }
        span{
          color:#fff;
          margin-top:10px;
        }
      }
      .loading{
        width: 60px;
        height: 60px;
        background: rgba(255, 255, 255, 0.52);
      }
  }
</style>
