<template>
  <form class="MenuMultiple">
    <div class="modal-card moda-multimenu" style="width: 100%;">
      <header class="modal-card-head">
        <p class="modal-card-title">Item Menú Multiple</p>
      </header>
      <section class="modal-card-body">
        <b-field>
          <b-checkbox v-model="multipleCheck">Multiple</b-checkbox>
        </b-field>
        <b-field v-if="multipleCheck">
          <b-select placeholder="Seleccione el tipo de menú" v-model="typeMenuMulti">
            <option
              :key="'typeM-'+i"
              :value="type.value"
              v-for="(type,i) in typeMenus"
            >{{ type.text }}</option>
          </b-select>
        </b-field>
        <b-field v-if="multipleCheck">
          <b-radio-button name="spanish" native-value="spanish" v-model="typeLanguage">Español</b-radio-button>
          <b-radio-button name="english" native-value="english" v-model="typeLanguage">Ingles</b-radio-button>
        </b-field>
        <b-upload
          multiple
          style="width: 100%;"
          v-if="(multipleCheck == true && typeMenuMulti === 'image')"
          v-model="selectedImages"
        >
          <div class="MenuMultiple-cover">
            <div class="MenuMultiple-coverButton">
              <b-icon icon="camera" size="is-small"></b-icon>
              <span>Click para seleccionar imagenes</span>
            </div>
          </div>
        </b-upload>
        <b-field v-else-if="multipleCheck === true && typeMenuMulti === 'custom'">
          <treeselect :multiple="true" :options="selectMenus" v-model="selectedMenus"></treeselect>
        </b-field>
        <div :key="'item-menu-' + iIn" v-for="(item, iIn) in items">
          <hr style="background-color: black;" v-if="iIn > 0" />
          <b-field>
            <b-select placeholder="Seleccione el tipo de menú" v-model="typeMenu[iIn]">
              <option
                :key="'type1-'+i"
                :value="type.value"
                v-for="(type,i) in typeMenus"
              >{{ type.text }}</option>
            </b-select>
          </b-field>
          <b-field label="Url" v-if="typeMenu[iIn] === 'url'">
            <b-input
              expanded
              id="imageExternalUrl"
              name="imageExternalUrl"
              placeholder="Url"
              type="text"
              v-model="item.imageExternalUrl"
            ></b-input>
          </b-field>
          <b-upload
            style="width: 100%;"
            v-if="typeMenu[iIn] === 'image'"
            v-model="fileRestaurantCover"
          >
            <div class="MenuMultiple-cover">
              <div class="MenuMultiple-coverButton">
                <b-icon icon="camera" size="is-small"></b-icon>
                <span>Click para cambiar imagen</span>
              </div>
            </div>
          </b-upload>
          <b-field v-else-if="typeMenu[iIn] === 'custom'">
            <b-select placeholder="Seleccione el tipo de menú" v-model="selectMenu[iIn]">
              <option
                :key="'menu1-'+i"
                :value="menu.id"
                v-for="(menu,i) in selectMenus"
              >{{ menu.label }}</option>
            </b-select>
          </b-field>
          <b-field :key="'itemradio-'+iIn">
            <b-radio-button
              :id="'spanish-'+iIn"
              name="type"
              native-value="spanish"
              v-model="listTypeLanguage[iIn]"
            >Español</b-radio-button>
            <b-radio-button
              :id="'english-'+iIn"
              name="type"
              native-value="english"
              v-model="listTypeLanguage[iIn]"
            >Ingles</b-radio-button>
          </b-field>
          <img :src="item.image" v-if="item.image != null" />
          <b-field label="Orden">
            <b-input
              min="0"
              name="order"
              placeholder="Orden"
              type="number"
              v-model="item.order"
              v-validate="'required|numeric'"
            ></b-input>
          </b-field>
          <b-button
            @click="uploadFile(item)"
            icon-left="upload"
            style="margin-top: 10px;"
            type="is-primary"
            v-if="fileRestaurantCover !== null && typeMenu[iIn] === 'image'"
          >Subir</b-button>
          <b-button
            @click="del(iIn)"
            icon-left="delete"
            style="margin-top: 10px; margin-left: 10px;"
            type="is-danger"
          >Quitar</b-button>
        </div>
        <center style="margin-top: 20px;">
          <b-button
            @click="add"
            icon-left="plus"
            style="margin-top: 10px;"
            type="is-info"
            v-if="!multipleCheck"
          >Agregar</b-button>
          <b-button
            @click="uploadImages()"
            icon-left="upload"
            style="margin-top: 10px;"
            type="is-primary"
            v-if="(!!selectedImages && multipleCheck && typeMenuMulti === 'image')"
          >Subir {{selectedImages.length}} imagenes</b-button>
          <b-button
            @click="deleteImages()"
            icon-left="delete"
            style="margin-top: 10px; margin-left: 10px;"
            type="is-danger"
            v-if="(!!selectedImages && multipleCheck && typeMenuMulti === 'image' )"
          >Quitar</b-button>
          <b-button
            @click="setMenu"
            icon-left="content-save"
            style="margin-top: 10px; margin-left: 10px;"
            type="is-success"
            v-if="!multipleCheck"
          >Guardar</b-button>
          <b-button
            @click="setMenuImgMultiple"
            icon-left="content-save"
            style="margin-top: 10px; margin-left: 10px;"
            type="is-success"
            v-if="multipleCheck && !!selectedImages && imagesSend.length == selectedImages.length"
          >Guardar</b-button>
          <b-button
            @click="setMenuViewsMultiple"
            icon-left="content-save"
            style="margin-top: 10px; margin-left: 10px;"
            type="is-success"
            v-if="multipleCheck  && selectedMenus.length > 0"
          >Guardar</b-button>
          <b-button
            @click="$parent.close()"
            icon-left="close"
            style="margin-top: 10px; margin-left: 10px;"
            type="is-danger"
          >Cerrar</b-button>
        </center>
      </section>
    </div>
  </form>
</template>
<script>
import globalTypes from '@/store/types/global';
import serviceQr from '@/helpers/apis/serviceQr.js';
export default {
  props: ['id', 'info', 'restaurant', 'restaurantData'],
  data () {
    return {
      fileRestaurantCover: null,
      items: [],
      selectedImages: null,
      multipleCheck: false,
      typeMenuMulti: 'spanish',
      typeMenu: [],
      typeLanguage: null,
      listTypeLanguage: [],
      typeMenus: [
        {
          text: 'Imagen',
          value: 'image'
        },
        {
          text: 'Menú',
          value: 'custom'
        },
        {
          text: 'Link',
          value: 'url'
        }
      ],
      selectMenu: [],
      imagesSend: [],
      selectMenus: [],
      selectedMenus: []
    };
  },
  mounted () {
    this.getItems();
    this.getMenus();
  },
  methods: {
    bytesToSize (bytes) {
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
      if (bytes === 0) return '0 Byte';
      const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return { size: Math.round(bytes / Math.pow(1024, i), 2), format: sizes[i] };
    },
    getMenus () {
      serviceQr.get(`/menuCustom/listMenu/${this.restaurant.vendorId}`).then(({ data }) => {
        const arrayList = [];
        data.data.forEach(element => {
          arrayList.push({ id: element._id, label: element.name });
        });
        this.selectMenus = arrayList;
      });
    },
    uploadImages () {
      this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: true, typeMessage: 3 });
      const sortedArray = this.selectedImages.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
      sortedArray.forEach((file) => {
        file.realSize = this.bytesToSize(file.size);
      });
      const pass = sortedArray.filter(sa => (sa.realSize.format === 'MB' && sa.realSize.size >= 2));
      if (pass.length > 0) {
        this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
        this.$buefy.dialog.alert({
          title: 'Error',
          message: `Las siguientes imagenes tienen un peso mayor a <b>2MB</b> (${pass.map(p => p.name).join(',')}). Por favor dirijase a la pestaña de información y use el optimizador.`,
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true,
          confirmText: 'Cerrar'
        });
        return;
      }
      this.uploadImageMultiple(sortedArray, this.restaurantData.vendor, 'menu', 'multiple/options/').then(({ data, code }) => {
        if (code === 200) {
          data.data.forEach((url, index) => {
            this.imagesSend.push({
              image: url,
              order: index + 1,
              menuId: this.id,
              type: 'image',
              vendorCustomId: null,
              typeLanguage: this.typeLanguage,
              activeUrlImage: 0,
              imageExternalUrl: null
            });
          });
        }
      });
      this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
    },
    deleteImages () {
      this.selectedImages = null;
      this.typeMenu = null;
    },
    add () {
      this.items.push({ menuId: this.id, image: null, type: null, order: 1, vendorCustomId: null, typeLanguage: 'spanish', activeUrlImage: 0, imageExternalUrl: null });
    },
    del (index) {
      this.typeMenu.splice(index, 1);
      this.selectMenu.splice(index, 1);
      this.listTypeLanguage.splice(index, 1);
      this.items.splice(index, 1);
    },
    getItems () {
      this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: true, typeMessage: 3 });
      serviceQr.get('/menudigital/multimenu/items/' + this.id).then(({ data }) => {
        data.data.forEach((e, i) => {
          this.selectMenu[i] = e.vendorCustomId;
          this.typeMenu[i] = e.type;
          this.listTypeLanguage[i] = e.typeLanguage;
        });
        this.items = data.data;
        this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
      }).catch(() => {
        this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
        this.$buefy.toast.open({
          message: 'Lo siento, ha ocurrido un error al intentar procesar tu petición',
          type: 'is-danger'
        });
      });
    },
    setMenu () {
      const infoItem = JSON.parse(JSON.stringify(this.items));
      infoItem.forEach((e, i) => {
        e.type = this.typeMenu[i];
        e.vendorCustomId = this.selectMenu[i] === undefined ? null : this.selectMenu[i];
        e.typeLanguage = this.listTypeLanguage[i] === undefined ? 'spanish' : this.listTypeLanguage[i];
      });
      Object.keys(infoItem).forEach(key => {
        if (infoItem[key].type === 'image' && (infoItem[key].image === '' || infoItem[key].image == null)) {
          delete infoItem[key];
        }
      });
      if (Object.keys(infoItem).length > 0) {
        this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: true, typeMessage: 3 });
        const data = {};
        data.menuId = this.id;
        data.items = infoItem;
        data.userId = this.user.id;
        data.vendorId = this.restaurant.vendorId;
        serviceQr.post('/menudigital/multimenu/items/' + this.id, data).then(({ data }) => {
          this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
          this.$buefy.toast.open({
            message: data.message,
            type: 'is-success'
          });
          this.$emit('update');
          this.$parent.close();
        }).catch(() => {
          this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
          this.$buefy.toast.open({
            message: 'Lo siento, ha ocurrido un error al intentar procesar tu petición',
            type: 'is-danger'
          });
        });
      } else {
        this.$buefy.toast.open({
          message: 'Debe al menos tener un item con información',
          type: 'is-danger'
        });
      }
    },
    setMenuImgMultiple () {
      if (this.imagesSend.length > 0) {
        this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: true, typeMessage: 3 });
        const data = {};
        data.menuId = this.id;
        if (this.items.length > 0) {
          data.items = this.imagesSend.concat(this.items);
        } else {
          data.items = this.imagesSend;
        }
        data.userId = this.user.id;
        data.vendorId = this.restaurant.vendorId;
        serviceQr.post('/menudigital/multimenu/items/' + this.id, data).then(({ data }) => {
          this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
          this.$buefy.toast.open({
            message: data.message,
            type: 'is-success'
          });
          this.$emit('update');
          this.$parent.close();
        }).catch(() => {
          this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
          this.$buefy.toast.open({
            message: 'Lo siento, ha ocurrido un error al intentar procesar tu petición',
            type: 'is-danger'
          });
        });
      } else {
        this.$buefy.toast.open({
          message: 'Debe al menos tener un item con información',
          type: 'is-danger'
        });
      }
    },
    setMenuViewsMultiple () {
      if (this.selectedMenus.length > 0) {
        this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: true, typeMessage: 3 });
        const jsonArray = [];
        this.selectedMenus.forEach((e, i) => {
          jsonArray[i] = { menuId: this.id, image: null, type: 'custom', order: i + 1, vendorCustomId: e };
        });
        Object.keys(this.items).forEach(key => {
          if (this.items[key].type === 'image' && (this.items[key].image === '' || this.items[key].image == null)) {
            delete this.items[key];
          } else {
            jsonArray.push(this.items[key]);
          }
        });
        const data = {};
        data.menuId = this.id;
        data.items = jsonArray;
        data.userId = this.user.id;
        data.vendorId = this.restaurant.vendorId;
        serviceQr.post('/menudigital/multimenu/items/' + this.id, data).then(({ data }) => {
          this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
          this.$buefy.toast.open({
            message: data.message,
            type: 'is-success'
          });
          this.$emit('update');
          this.$parent.close();
        }).catch(() => {
          this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
          this.$buefy.toast.open({
            message: 'Lo siento, ha ocurrido un error al intentar procesar tu petición',
            type: 'is-danger'
          });
        });
      } else {
        this.$buefy.toast.open({
          message: 'Debe al menos seleccionar un item',
          type: 'is-danger'
        });
      }
    },
    uploadFile (item) {
      const value = this.fileRestaurantCover;
      if (value != null) {
        const fileInfo = this.bytesToSize(value.size);
        if (fileInfo.format === 'MB' && fileInfo.size > 2) {
          this.$buefy.dialog.confirm({
            title: 'Imagen Muy Pesada.',
            message: 'Se recomienda subir imagenes con tamaño inferior a <b>2 MB</b>. ¿Desea que optimicemos la imagen que esta cargando?. El peso actual de tu imagen es ' + `<b>${fileInfo.size} ${fileInfo.format}</b>`,
            confirmText: 'Si, subir',
            cancelText: 'No, cancelar',
            type: 'is-danger',
            hasIcon: true,
            onConfirm: () => {
              const name = value.name.split('.');
              this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: true, typeMessage: 3 });
              this.uploadImageGlobal(
                value,
                `multiple/option/${name[0]}`,
                null,
                this.restaurantData.vendor,
                'menu',
                1,
                name,
                'multiple/option/'
              ).then(({ data, code }) => {
                if (code === 200) {
                  item.image = data.data;
                  this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
                  return;
                }
                this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
                this.$buefy.toast.open({
                  message: 'Lo siento, ha ocurrido un error al intentar procesar tu petición',
                  type: 'is-danger'
                });
              }).finally(() => {
                this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
                this.fileRestaurantCover = null;
              });
            },
            onCancel: () => {
              this.fileRestaurantCover = null;
            }
          });
          return;
        }
        const name = value.name.split('.');
        this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: true, typeMessage: 3 });
        this.uploadImageGlobal(
          value,
          `multiple/option/${name[0]}`,
          null,
          this.restaurantData.vendor,
          'menu'
        ).then(({ data, code }) => {
          if (code === 200) {
            item.image = data.data;
            this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
            return;
          }
          this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
          this.$buefy.toast.open({
            message: 'Lo siento, ha ocurrido un error al intentar procesar tu petición',
            type: 'is-danger'
          });
        }).finally(() => {
          this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
          this.fileRestaurantCover = null;
        });
      } else {
        this.$buefy.toast.open({
          message: 'Debe seleccionar un archivo',
          type: 'is-danger'
        });
      }
    }
  },
  watch: {
    multipleCheck () {
      if (!this.multipleCheck) {
        this.typeMenuMulti = null;
        this.selectMenu = [];
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.moda-multimenu {
  width: 500px;
}
.modal-card-body {
  min-width: 400px;
  //width: 400px;
  max-width: 100%;
}
.MenuMultiple {
  &-cover {
    background-color: #f1f1f1;
    background-size: cover;
    height: 30px;
    width: 100%;
    cursor: pointer;
  }
  &-coverButton,
  &-logoButton {
    background-color: #fff;
    border: 1px solid #f1f1f1;
    border-radius: 2px;
    color: #00a7b5;
    display: initial;
    font-size: 0.75rem;
    margin: 1px;
    padding: 0.3rem;
    white-space: nowrap;
  }
  &-coverButtonText {
    display: none;
    opacity: 0;
    transition: all 1s;
  }
  &-logo {
    display: block;
    padding: 10px 0;
    margin-bottom: 30px;
    position: relative;
    &:before {
      background: #00adc6;
      content: "";
      width: 50px;
      height: 2px;
      position: absolute;
      bottom: -5px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  &-logoImage {
    max-height: 100px;
    max-width: 100%;
  }
  &-logoButton {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  &-icon {
    opacity: 0.5;
    vertical-align: middle;
  }
  &-block {
    background-color: #f5f5f5;
    color: #7a7a7a;
    margin: 1px;
    padding: 1.25rem 0;
    position: relative;
    text-align: center;
    &.is-small {
      padding: 0.7rem 0;
    }
  }
}
.MenuMultiple-cover:hover .MenuMultiple-coverButtonText {
  display: block;
  opacity: 1;
}
</style>
