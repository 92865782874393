<template>
  <div id="toolbar_marketing">
    <div class="toolbar">
      <div class="toolbar-logo" @click="setToggle()" :style="{width: (moduleLoad) ? '25%' : '100%'}">
        <div class="toolbar-logo-img">
          <img :src="vendor.thumbnailURL" />
        </div>
      </div>
      <div class="center" v-if="moduleLoad">
        <div class="center-item">
          <div class="center-item-option" :class="{selectedOption: moduleLoad === 'sms', disabledOption: params.activateMarketingSms === 0}" @click="changeModule('sms')">
            <span class="center-item-option-text" :class="{selectedText: moduleLoad === 'sms'}">SMS</span>
          </div>
          <div class="center-item-option" :class="{selectedOption: moduleLoad === 'email', disabledOption: params.activateMarketingEmail === 0}" @click="changeModule('email')">
            <span class="center-item-option-text" :class="{selectedText: moduleLoad === 'email'}">Correo electrónico</span>
          </div>
        </div>
      </div>
      <div class="right" :class="{full: !moduleLoad, limit: moduleLoad}">
        <div class="right-notification" @click="show()">
          <img src="@/assets/icons/marketing/historial.svg" alt="precompro.com" class="right-icon">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    moduleLoad: {
      type: String
    }
  },
  methods: {
    setToggle () {
      this.$emit('setChangeToggle', true);
    },
    changeModule (option) {
      this.$emit('changeModule', {
        module: option
      });
    },
    show () {
      this.$emit('changeView', {
        module: 'history',
        item: this.moduleLoad || 'sms',
        show: true
      });
    }
  }
};
</script>
<style lang="scss">
#toolbar_marketing {
  width: 100%;
  height: 52px;
  flex-shrink: 0;
  background: #444B57;
  .toolbar {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    position: relative;
    &-logo {
      position: absolute;
      left: 12px;
      width: 100%;
      display: none;
      @media screen and (max-width: 600px) {
        display: flex;
      }
      &-img {
        display: flex;
        width: 33px;
        height: 33px;
        overflow: hidden;
        border-radius: 50%;
      }
    }
  }
  .full {
    width: 100%;
  }
  .limit {
    width: 120px;
  }
  .center {
    display: flex;
    align-items: center;
    justify-content: center;
    &-item {
      width: 240px;
      height: 40px;
      flex-shrink: 0;
      border-radius: 4px;
      background: #F3F3F3;
      display: flex;
      justify-content: center;
      align-items: center;
      .selectedOption {
        background: #444B57;
      }
      .disabledOption {
        pointer-events: none;
      }
      &-option {
        width: 117px;
        height: 36px;
        flex-shrink: 0;
        border-radius: 4px;
        background: #FFF;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        &-text {
          color: #444B57;
          text-align: center;
          font-size: 12px;
          font-family: Source Sans Pro;
          font-weight: 300;
          user-select: none;
        }
        @media screen and (max-width: 768px) {
          &-text {
            font-weight: 600;
          }
        }
        .selectedText {
          color: #FFF;
          font-weight: 600;
        }
      }
    }
  }
  .right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 12px;
    height: 100%;
    position: absolute;
    right: 0;
    @media screen and (max-width: 500px) {
      margin-right: 4.67px;
    }
    &-icon {
      width: 41px;
      height: 41px;
      flex-shrink: 0;
      user-select: none;
    }
    &-separator {
      width: 30px;
      height: 1px;
      transform: rotate(90deg);
      flex-shrink: 0;
      background: #FFF;
    }
    &-notification {
      margin-top: 10px;
      cursor: pointer;
      height: 100%;
    }
    &-search {
      padding: 10px;
      cursor: pointer;
    }
  }
}
</style>
