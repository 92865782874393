<template>
  <div class="container_config_gift_card">
    <div class="close_container" @click="$emit('close')"><img src="@/assets/icons/polls/close_black.svg" alt=""></div>
    <div class="logo">
      <img :src="giftcardInfo.logo" alt="">
    </div>
    <div class="gift_card_info">
      <h2 class="gift_card_info__title">Configuración del bono de regalo</h2>
      <label for="" class="gift_card_info__labels_inputs">Título del Bono de Regalo</label>
      <div class="gift_card_info__input" :style="giftcardInfo.title !== '' ? {'background' : '#e5e7e9'} : ''">
        <input type="text" placeholder="Escribe el título para el bono de regalo" v-model="giftcardInfo.title" :style="giftcardInfo.title !== '' ? {'background' : '#e5e7e9'} : ''">
      </div>
      <label for="" class="gift_card_info__labels_inputs">Descripción del Bono de Regalo</label>
      <div class="gift_card_info__input" :style="giftcardInfo.message !== '' ? {'background' : '#e5e7e9'} : ''">
        <input type="text" placeholder="Escribe la descripción para el bono de regalo" v-model="giftcardInfo.message" :style="giftcardInfo.message !== '' ? {'background' : '#e5e7e9'} : ''">
      </div>
      <div class="gift_card_info__checks">
        <div class="gift_card_info__checks__title" v-if="priceList.length > 0">
          <h2>Listado de precios:</h2>
        </div>
        <div class="gift_card_info__checks__container columns is-mobile">
          <div class="list_prices is-4 column" v-for="(price, index) in priceList" :key="index">
            <label for="" class="circle_check">
              <input type="checkbox" :value="'price_'+(index+1)" name="check_price" :checked="price !== '' ? true : false">
            </label>
            <div :style="giftcardInfo['price_'+(index+1)] !== '' ? {'background' : '#e5e7e9'} : ''" :id="'price_container_'+(index+1)">
              <input type="number" placeholder="Escribir valor" :id="'price_'+(index+1)" @keyup="changeBackground((index+1))" v-model="giftcardInfo['price_'+(index+1)]" :style="giftcardInfo['price_'+(index+1)] !== '' ? {'background' : '#e5e7e9'} : ''">
            </div>
          </div>
        </div>
        <div class="add_more_values" @click="addMorePrices()"><img src="@/assets/icons/giftCards/plus.svg" alt="" srcset=""><span>Agregar {{priceList.length > 0 ? 'más' : ''}} valores</span></div>
      </div>
      <label for="" class="gift_card_info__labels_inputs">Términos y condiciones del Bono de Regalo </label>
      <div class="gift_card_info__textarea">
        <textarea name="" id="" rows="4" placeholder="Escribe los términos y condiciones del Bono de Regalo" v-model="giftcardInfo.terms" :style="giftcardInfo.terms !== '' ? {'background' : '#e5e7e9'} : ''"></textarea>
      </div>
      <div class="gift_card_info__save_config">
        <button @click="prepareConfig">{{giftcardInfo != null ? 'Guardar cambios': 'Crear bono de regalo'}}</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['giftcardInfo'],
  mounted () {
    if (this.giftcardInfo.priceList && this.giftcardInfo.priceList !== '') {
      this.priceList = this.giftcardInfo.priceList.split(',');
    }
  },
  data () {
    return {
      priceList: []
    };
  },
  methods: {
    addMorePrices () {
      this.priceList.push('');
      const length = this.priceList.length;
      this.giftcardInfo['price_' + length] = '';
    },
    prepareConfig () {
      const priceList = document.querySelectorAll('input[name="check_price"]:checked');
      if (priceList.length > 0) {
        this.giftcardInfo.priceList = '';
        this.priceList = [];
        for (var checkbox of priceList) {
          this.giftcardInfo.priceList += this.giftcardInfo[checkbox.value] + ',';
          this.priceList.push(this.giftcardInfo[checkbox.value]);
        }
        this.giftcardInfo.priceList = this.giftcardInfo.priceList.slice(0, -1);
      }
      this.$emit('updateConfig');
      this.$emit('close');
    },
    changeBackground (id) {
      const input = document.getElementById(`price_${id}`);
      const inputContainer = document.getElementById(`price_container_${id}`);
      if (input.value !== '') {
        inputContainer.style.background = '#e5e7e9';
        input.style.background = '#e5e7e9';
      } else {
        if (inputContainer.hasAttribute('style') && input.hasAttribute('style')) {
          inputContainer.removeAttribute('style');
          input.removeAttribute('style');
        }
      }
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/styles/giftCards/_modal_config";
</style>
