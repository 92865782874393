import sectionTypes from '@/store/types/section';
import serviceVendor from '@/helpers/apis/serviceVendor';

const state = {
  sections: []
};

const actions = {
  [sectionTypes.actions.sections]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      serviceVendor.post('section/dashboard', data.data)
        .then(({ data }) => {
          commit(sectionTypes.mutations.setSections, data);
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};
const getters = {
  [sectionTypes.getters.sections]: state => state.sections
};

const mutations = {
  [sectionTypes.mutations.setSections]: (state, sections) => {
    state.sections = sections;
  }
};

export default {
  state,
  actions,
  getters,
  mutations
};
