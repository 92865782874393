<template>
  <stars :rating="rating" :starStyle="style" :isIndicatorActive="isIndicatorActive"></stars>
</template>
<script>
import stars from 'vue-dynamic-star-rating';
export default {
  name: 'App',
  components: {
    stars
  },
  props: ['rating', 'starWidth', 'starHeight'],
  data () {
    return {
      isIndicatorActive: false,
      style: {
        fullStarColor: '#DFAE04',
        emptyStarColor: '#EBECED',
        starWidth: this.starWidth,
        starHeight: this.starHeight
      }
    };
  }
};
</script>
