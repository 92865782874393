<template>
  <div id="payu-form">
    <div class="columns is-desktop style-columns">
      <div class="column is-4">
        <b-field
          :message="errors.first('apiLogin')"
          :type="{'is-danger': errors.has('apiLogin')}"
          label="API LOGIN"
        >
          <b-input name="apiLogin" v-model="apiLogin" v-validate="'required'"></b-input>
        </b-field>
      </div>
      <div class="column is-4">
        <b-field
          :message="errors.first('apiKey')"
          :type="{'is-danger': errors.has('apiKey')}"
          label="API KEY"
        >
          <b-input name="apiKey" v-model="apiKey" v-validate="'required'"></b-input>
        </b-field>
      </div>
      <div class="column is-4">
        <b-field
          :message="errors.first('countPayu')"
          :type="{'is-danger': errors.has('countPayu')}"
          label="COUNT PAYU"
        >
          <b-input name="countPayu" v-model="countPayu" v-validate="'required'"></b-input>
        </b-field>
      </div>
    </div>
    <div class="columns is-desktop style-columns">
      <div class="column is-4">
        <b-field
          :message="errors.first('merchant')"
          :type="{'is-danger': errors.has('merchant')}"
          label="MERCHANT ID"
        >
          <b-input name="merchant" v-model="merchantId" v-validate="'required'"></b-input>
        </b-field>
      </div>
    </div>
    <div class="columns is-desktop style-columns">
      <div class="column is-4">
        <b-field>
          <b-button
            :loading="sending"
            @click="saveFeatures"
            icon-left="content-save"
            type="is-primary"
          >
            <span>Guardar</span>
          </b-button>
          <b-button
            :loading="sending"
            @click="deleteIntegration"
            icon-left="delete"
            style="margin-left:0.5rem;"
            type="is-danger"
            v-if="id !== null"
          >
            <span>Borrar</span>
          </b-button>
          <b-field>
            <b-button
              :loading="sending"
              @click="cleanUp"
              style="margin-left:0.5rem;"
              type="is-primary"
            >
              <span>Volver</span>
            </b-button>
          </b-field>
        </b-field>
      </div>
    </div>
  </div>
</template>

<script>
import globalTypes from '@/store/types/global';
export default {
  props: ['restaurant', 'integration', 'dataIntegrations'],
  data () {
    return {
      id: null,
      type: 'payu',
      sending: false,
      apiLogin: '',
      apiKey: '',
      countPayu: '',
      merchantId: ''
    };
  },
  mounted () {
    const integration = this.dataIntegrations.find(inte => inte.type === this.type);
    if (integration) {
      this.id = integration.id;
      this.apiKey = this.decryptMx(integration.key);
      this.apiLogin = this.decryptMx(integration.login);
      this.merchantId = this.decryptMx(integration.merchant);
      this.countPayu = this.decryptMx(integration.account);
    }
  },
  methods: {
    cleanUp () {
      this.$emit('cleanUpIntegration');
    },
    saveFeatures () {
      this.sending = true;
      this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: true, typeMessage: 3 });
      try {
        this.$validator.validateAll().then(result => {
          if (result) {
            this.$emit('saveUpdateIntegration', {
              id: this.id,
              type: this.type,
              login: this.encrypt(this.apiLogin),
              key: this.encrypt(this.apiKey),
              merchant: this.encrypt(this.merchantId),
              account: this.encrypt(this.countPayu)
            });
            this.sending = false;
            this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
            return;
          }
          this.sending = false;
          this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
        });
      } catch (error) {
        this.sending = false;
        this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
      }
    },
    deleteIntegration () {
      try {
        this.$buefy.dialog.confirm({
          title: 'Borrar Integración.',
          message: '¿Esta Seguro de que desea borrar la integración?',
          confirmText: 'Si, borrar',
          cancelText: 'No, cancelar',
          type: 'is-danger',
          hasIcon: true,
          onConfirm: () => {
            this.sending = true;
            this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: true, typeMessage: 3 });
            this.$emit('deleteIntegration', this.id);
          },
          onCancel: () => {
            this.sending = false;
            this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
          }
        });
      } catch (error) {
        this.sending = false;
        this.$store.commit(globalTypes.mutations.setLoaderPrecompro, { status: false });
      }
    }
  }
};
</script>

<style lang="scss">
#payu-form {
  .style-columns {
    gap: 0.5rem;
    padding: 10px 10px !important;
  }
  .columns {
    margin-left: 0px;
  }
}
</style>
