<template>
  <div class="mapchart">
    <span class="mapchart__title">País de origen de la reserva</span>
    <div class="mapchart__tagcontainer">
      <div class="mapchart__tagcontainer__tag">
        <i class="icon-mks gray-blue small-grafica icon_reservation" style="margin-bottom: -6px;"></i>
        <span>{{totalReservations}}</span>
      </div>
      <div class="mapchart__tagcontainer__tag">
        <i
          class="icon-mks gray-blue small-grafica icon_reserva_precompro"
          style="margin-bottom: -4px;"
        ></i>
        <span>{{totalPrecompras}}</span>
      </div>
      <div class="mapchart__tagcontainer__tag">
        <i class="icon-mks gray-blue small-grafica icon_walkin" style="margin-bottom: -4px;"></i>
        <span>{{totalWalkIn}}</span>
      </div>
    </div>
    <div class="mapchart__totalcontainer">
      <div class="mapchart__totalcontainer__total">Total {{total}}</div>
    </div>
    <div class="mapchart" ref="chartgeneralsdiv"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import * as am5 from '@amcharts/amcharts5';
import * as am5map from '@amcharts/amcharts5/map';
import metricsTypes from '@/store/types/metrics';
import am5geodataWorldLow from '@amcharts/amcharts5-geodata/worldLow';
import am5themesAnimated from '@amcharts/amcharts5/themes/Animated';
import am5themesResponsive from '@amcharts/amcharts5/themes/Responsive';
import countryCodes from '@/helpers/countryCodes.js';
// import am5geodataLangES from '@amcharts/amcharts5-geodata/lang/ES';
export default {
  props: ['sectionId'],
  data () {
    return {
      typeByTable: 1,
      flag: 0,
      windowWidth: window.innerWidth,
      tablesChart: [],
      colors: ['#FFFFFF', '#D5D7DA', '#CCCED1', '#A5A9AF', '#7C8189', '#444B57'], // General Colors
      totalReservations: 0,
      totalPrecompras: 0,
      totalWalkIn: 0,
      total: 0
    };
  },
  computed: {
    ...mapGetters({
      byCountryPrecompras: [metricsTypes.getters.byCountryPrecompras],
      byCountryReservations: [metricsTypes.getters.byCountryReservations],
      byCountryWalkIn: [metricsTypes.getters.byCountryWalkIn]
    })
  },
  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
    this.buildDataForMap();
  },
  methods: {
    onResize () {
      this.windowWidth = window.innerWidth;
    },
    getPorcentaje (percentage, max) {
      const number = (Math.floor(max * percentage)) / 100;
      return Math.round(number);
    },
    buildDataForMap () {
      const that = this;
      const dataInitialData = [];
      const finalData = [];
      if (Array.isArray(this.byCountryReservations) && this.byCountryReservations.length > 0) {
        for (const iterator of this.byCountryReservations) {
          dataInitialData.push({ country: iterator.country, reservations: iterator.reservations });
        }
      }
      if (Array.isArray(this.byCountryPrecompras) && this.byCountryPrecompras.length > 0) {
        for (const iterator of this.byCountryPrecompras) {
          dataInitialData.push({ country: iterator.country, precompras: iterator.reservations });
        }
      }
      if (Array.isArray(this.byCountryWalkIn) && this.byCountryWalkIn.length > 0) {
        for (const iterator of this.byCountryWalkIn) {
          dataInitialData.push({ country: iterator.country, walkin: iterator.reservations });
        }
      }
      const dataOrganized = {};
      for (const countryData of dataInitialData) {
        if (!dataOrganized[countryData.country]) {
          dataOrganized[countryData.country] = { ...countryData };
        } else {
          dataOrganized[countryData.country] = { ...dataOrganized[countryData.country], ...countryData };
        }
      }
      for (const key in dataOrganized) {
        if (Object.hasOwnProperty.call(dataOrganized, key)) {
          const countryData = dataOrganized[key];
          countryData.reservations = (countryData.reservations) ? countryData.reservations : 0;
          countryData.walkin = (countryData.walkin) ? countryData.walkin : 0;
          countryData.precompras = (countryData.precompras) ? countryData.precompras : 0;
          countryData.total = (countryData.reservations + countryData.walkin + countryData.precompras);
          finalData.push({ ...countryData });
        }
      }
      const dataForMap = finalData.map(dataCountry => {
        return {
          id: countryCodes[dataCountry.country],
          value: dataCountry.total,
          precompras: dataCountry.precompras,
          walkin: dataCountry.walkin,
          reservations: dataCountry.reservations
        };
      });
      const maxValueReservations = dataForMap.reduce((sum, { value }) => sum + value, 0);
      const maxValuePrecompras = dataForMap.reduce((sum, { precompras }) => sum + precompras, 0);
      const maxValueWalkIn = dataForMap.reduce((sum, { walkin }) => sum + walkin, 0);
      this.totalReservations = maxValueReservations;
      this.totalPrecompras = maxValuePrecompras;
      this.totalWalkIn = maxValueWalkIn;
      const maxValue = (maxValueReservations + maxValuePrecompras + maxValueWalkIn);
      this.total = maxValue;
      const colors = this.colors;
      const root = am5.Root.new(this.$refs.chartgeneralsdiv);
      am5.ColorSet.new(root, {});
      root.setThemes([
        am5themesAnimated.new(root),
        am5themesResponsive.new(root)
      ]);
      const chart = root.container.children.push(
        am5map.MapChart.new(root, {
          pinchZoom: false,
          maxPanOut: 1,
          wheelY: 'none',
          layout: root.verticalLayout,
          panX: 'none',
          panY: 'none'
        })
      );
      chart.set('paddingTop', 30);
      chart.set('paddingBottom', 30);
      this.flag = 1;
      if (this.windowWidth <= 1280) {
        this.flag = 0;
        chart.set('x', am5.percent(-12));
      } else {
        chart.set('x', am5.percent(-9));
      }
      const worldSeries = chart.series.push(
        am5map.MapPolygonSeries.new(root, {
          legendLabelText: 'País de origen de la reserva',
          legendValueText: 'País de origen de la reserva',
          geoJSON: am5geodataWorldLow,
          // geodataNames: am5geodataLangES,
          valueField: 'value',
          calculateAggregates: true,
          exclude: ['AQ']
        })
      );
      worldSeries.mapPolygons.template.setAll({
        tooltipText: '[fontFamily: Roboto;fontSize: 15px]Pais: {name}\nReservas: {reservations}\nPrecompras: {precompras}\nWalkIn: {walkin}',
        interactive: true,
        fill: am5.color('#FFFFFF'),
        templateField: 'polygonSettings',
        stroke: am5.color('#444B57')
      });
      worldSeries.set('heatRules', [{
        target: worldSeries.mapPolygons.template,
        dataField: 'value',
        min: am5.color('#FFFFFF'),
        max: am5.color('#0693A8'),
        key: 'fill',
        customFunction: function (sprite, min, max, value) {
          if (!value || value === 0) {
            sprite.set('fill', am5.color(colors[0]));
          }
          if (value === 1 || value <= that.getPorcentaje(20, maxValue)) {
            sprite.set('fill', am5.color(colors[1]));
          }
          if (value >= that.getPorcentaje(21, maxValue) && value <= that.getPorcentaje(40, maxValue)) {
            sprite.set('fill', am5.color(colors[2]));
          }
          if (value >= that.getPorcentaje(41, maxValue) && value <= that.getPorcentaje(60, maxValue)) {
            sprite.set('fill', am5.color(colors[3]));
          }
          if (value >= that.getPorcentaje(61, maxValue) && value <= that.getPorcentaje(80, maxValue)) {
            sprite.set('fill', am5.color(colors[4]));
          }
          if (value >= that.getPorcentaje(81, maxValue) && value <= maxValue) {
            sprite.set('fill', am5.color(colors[5]));
          }
        }
      }]);

      worldSeries.data.setAll(dataForMap);
      const legend = chart.children.push(am5.Legend.new(root, {
        nameField: 'name',
        fillField: 'color',
        strokeField: 'stroke',
        y: am5.percent(55),
        x: am5.percent(84),
        layout: am5.GridLayout.new(root, {
          maxColumns: 1,
          fixedWidthGrid: true
        })
      }));
      if (this.windowWidth <= 1280) {
        legend.set('x', am5.percent(92));
      }
      legend.data.setAll([
        {
          name: '[fontFamily: Roboto;fontSize: 12px]0% de reservación',
          color: am5.color(colors[0]),
          stroke: am5.color('#444B57')
        },
        {
          name: '[fontFamily: Roboto;fontSize: 12px]1-20% de reservación',
          color: am5.color(colors[1])
        },
        {
          name: '[fontFamily: Roboto;fontSize: 12px]21-40% de reservación',
          color: am5.color(colors[2])
        },
        {
          name: '[fontFamily: Roboto;fontSize: 12px]41-60% de reservación',
          color: am5.color(colors[3])
        },
        {
          name: '[fontFamily: Roboto;fontSize: 12px]61-80% de reservación',
          color: am5.color(colors[4])
        },
        {
          name: '[fontFamily: Roboto;fontSize: 12px]81-100% de reservación',
          color: am5.color(colors[5])
        }
      ]);
      const tooltip = am5.Tooltip.new(root, {
        getFillFromSprite: false,
        autoTextColor: false,
        contentAlign: 'left'
      });
      tooltip.get('background').setAll({
        fill: am5.color('#ffffff'),
        fillOpacity: 1,
        strokeOpacity: 0,
        shadowColor: am5.color(0x000000),
        shadowBlur: 10,
        shadowOffsetX: 2,
        shadowOffsetY: 2
      });
      tooltip.label.setAll({
        fill: am5.color('#444B57')
      });
      worldSeries.set('tooltip', tooltip);
      this.root = root;
    }
  },
  beforeDestroy () {
    if (this.root) {
      this.root.dispose();
    }
    window.removeEventListener('resize', this.onResize);
  },
  watch: {
    byCountryReservations (data) {
      if (data === null || typeof data === 'undefined') {
        return;
      }
      this.root.dispose();
      this.buildDataForMap();
    },
    byCountryPrecompras (data) {
      if (data === null || typeof data === 'undefined') {
        return;
      }
      this.root.dispose();
      this.buildDataForMap();
    },
    byCountryWalkIn (data) {
      if (data === null || typeof data === 'undefined') {
        return;
      }
      this.root.dispose();
      this.buildDataForMap();
    },
    windowWidth (value) {
      if (value <= 1280 && this.flag === 1) {
        this.flag = 0;
        this.root.dispose();
        this.buildDataForMap();
      }
      if (value >= 1281 && this.flag === 0) {
        this.flag = 1;
        this.root.dispose();
        this.buildDataForMap();
      }
    }
  }
};
</script>

<style lang='scss'>
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700);
.mapchart {
  width: 100%;
  height: 400px;
  background: white;
  padding-bottom: 2rem;
  min-width: 1100px;
  margin-top: 10px;
  position: relative;
  &__title {
    display: flex;
    justify-content: center;
    font-family: "Roboto";
    padding-top: 1rem;
    font-weight: 500;
    font-size: 20px;
    color: #444b57;
  }
  &__tagcontainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 6px;
    position: absolute;
    right: 5%;
    top: 40%;
    z-index: 1;
    &__tag {
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: "Roboto";
      width: 4rem;
      color: #444b57;
      background: #f5f6f6;
      border-radius: 0.3rem;
      height: 2rem;
    }
    &__tag:last-child {
      margin-right: 2rem;
    }
  }
  &__totalcontainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    &__total {
      display: flex;
      font-family: "Roboto";
      justify-content: center;
      align-items: center;
      background: #444b57;
      color: #ffffff;
      height: 2rem;
      width: 12rem;
      margin-right: 2.3rem;
      border-radius: 0.3rem;
      position: absolute;
      z-index: 1;
      top: 50%;
      right: 5%;
    }
  }
}
@media screen and (max-width: 1280px) {
  .mapchart {
    &__tagcontainer {
      right: 1%;
    }
    &__totalcontainer {
      &__total {
        right: 1%;
      }
    }
  }
}
</style>
