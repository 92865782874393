<template>
  <div id="modal-companion">
    <b-loading :active.sync="isLoading" :can-cancel="false" :is-full-page="false"></b-loading>
    <div class="desktop" v-if="!isResponsive()">
      <div class="header">
        <span class="text bold">Agrega o edita los acompañantes para esta reserva</span>
      </div>
      <div class="table-companion">
        <table>
          <tr>
            <th class="header-table">
              <span class="text gris bold-600 size-10">Nombre</span>
            </th>
            <th class="header-table">
              <span class="text gris bold-600 size-10">E-mail</span>
            </th>
            <th class="header-table">
              <span class="text gris bold-600 size-10">Télefono</span>
            </th>
            <th class="header-table" v-if="params.enableAddress == 1">
              <span class="text gris bold-600 size-10">Dirección</span>
            </th>
            <th class="header-table">
              <span class="text gris bold-600 size-10">Documento</span>
            </th>
            <th class="header-table">
              <span class="text gris bold-600 size-10">Temperatura</span>
            </th>
            <th class="header-table" v-if="params.questionCovid == 1">
              <span class="text gris bold-600 size-10">Preguntas</span>
            </th>
          </tr>
          <tr :key="index" v-for=" (item,index) in data">
            <td class="items-table">
              <span class="text gris bold-400 size-10">{{item.name}}</span>
            </td>
            <td class="items-table">
              <span class="text gris bold-400 size-10">{{item.email || 'N/A'}}</span>
            </td>
            <td class="items-table">
              <span class="text gris bold-400 size-10 nowrap">{{item.indicative}} {{item.phone}}</span>
            </td>
            <td class="items-table" v-if="params.enableAddress == 1">
              <span class="text gris bold-400 size-10 nowrap">{{item.address || 'N/A'}}</span>
            </td>
            <td class="items-table">
              <span class="text gris bold-400 size-10">{{item.documentIdentify || 'N/A'}}</span>
            </td>
            <td class="items-table">
              <span class="text gris bold-400 size-10">{{item.temperature || 'N/A'}}</span>
              <div class="icons" v-if="params.questionCovid == 0">
                <i
                  @click="modeEdit('edit',item ,index)"
                  aria-hidden="true"
                  class="fa fa-pencil-square-o"
                  style="margin-right:5px;cursor:pointer"
                ></i>
                <i
                  @click="deleteCompanion(index)"
                  aria-hidden="true"
                  class="fa fa-trash"
                  style="cursor:pointer"
                ></i>
              </div>
            </td>
            <td class="items-table" v-if="params.questionCovid == 1">
              <img
                alt="precompro.com"
                height="25"
                src="@/assets/icons/check-round.svg"
                v-if="(item.question1 == 0 || item.question1 == 1) && (item.question2 == 1 || item.question2 == 0)"
                width="25"
              />
              <div class="icons">
                <i
                  @click="modeEdit('edit', item, index)"
                  aria-hidden="true"
                  class="fa fa-pencil-square-o"
                  style="margin-right:5px; cursor:pointer"
                ></i>
                <i
                  @click="deleteCompanion(index)"
                  aria-hidden="true"
                  class="fa fa-trash"
                  style="cursor: pointer"
                ></i>
              </div>
            </td>
          </tr>
        </table>
      </div>
      <div class="edit">
        <div class="forms" id="editCompanion">
          <div class="formulario-edit">
            <div class="cont-form wrap">
              <div class="input-edit">
                <span class="text gris size-10 bold-600 margin-r-10 upliner">Nombre*</span>
                <input
                  @input="searchUser(compa.name)"
                  class="inp"
                  name="name_companion"
                  type="text"
                  v-model="compa.name"
                  v-show="add"
                  v-validate="'min:2|required'"
                />
                <i aria-hidden="true" class="fa fa-user-o"></i>
                <span
                  class="text size-9 red error"
                  v-if="errors.has('name_companion')"
                >{{ errors.first('name_companion') }}</span>
                <ul class="Concidence" v-if="showUl">
                  <li class="loading" v-if="isLoading">Cargando resultados...</li>
                  <li
                    class="loading"
                    v-if="!isLoading && dataUser.length == 0"
                  >No hay coincidencias..</li>
                  <li :key="ind" @click="setData(data)" v-for="(data, ind) in dataUser">
                    {{data.name}}
                    <b>#{{data.phone}}</b>
                    @{{ data.email }}
                  </li>
                </ul>
              </div>
              <div class="input-edit no-margin-last">
                <span class="text gris size-10 bold-600 margin-r-10 upliner">Mail* (Opcional)</span>
                <input
                  class="inp"
                  input
                  name="email_companion"
                  type="text"
                  v-model="compa.email"
                  v-show="add"
                  v-validate="'email'"
                />
                <i aria-hidden="true" class="fa fa-envelope-o"></i>
                <span
                  class="text size-9 red error"
                  v-if="errors.has('email_companion')"
                >{{ errors.first('email_companion') }}</span>
              </div>
            </div>
            <div class="cont-form wrap">
              <div class="input-edit min-215">
                <div class="left-box">
                  <img :src="bandera" class="bandera_list" />
                  <Multiselect
                    :options="paises"
                    @input="changedPais(option)"
                    class="list_conutry"
                    label="label_show"
                    placeholder
                    track-by="label_show"
                    v-if="option"
                    v-model="option"
                  ></Multiselect>
                </div>
                <span class="text gris size-10 bold-600 margin-r-10 upliner">Celular*</span>
                <input
                  class="inp padding-left"
                  name="phone_companion"
                  type="text"
                  v-model="compa.phone"
                  v-show="add"
                  v-validate="validateTel"
                />
                <i aria-hidden="true" class="fa fa-phone"></i>
                <span
                  class="text size-9 red error"
                  v-if="errors.has('phone_companion')"
                >{{ errors.first('phone_companion') }}</span>
              </div>
              <div class="input-edit">
                <span class="text gris size-10 bold-600 margin-r-10 upliner">Identificación</span>
                <input
                  class="inp"
                  name="identification_companion"
                  type="text"
                  v-model="compa.documentIdentify"
                  v-show="add"
                  v-validate="'required|regex:^[a-zA-Z0-9]*$|min:5'"
                />
                <i aria-hidden="true" class="fa fa-id-card-o"></i>
                <span
                  class="text size-9 red error"
                  v-if="errors.has('identification_companion')"
                >{{ errors.first('identification_companion') }}</span>
              </div>
              <div class="input-edit temp-width">
                <span class="text gris size-10 bold-600 margin-r-10 upliner">Temperatura°</span>
                <input
                  class="inp"
                  name="temperature_companion"
                  type="text"
                  v-model="compa.temperature"
                  v-validate="'required'"
                />
                <i aria-hidden="true" class="fa fa-thermometer-empty"></i>
                <span
                  class="text size-9 red error"
                  v-if="errors.has('temperature_companion')"
                >{{ errors.first('temperature_companion') }}</span>
              </div>
            </div>
            <div class="cont-form" v-if="params.enableAddress == 1">
              <div class="input-edit">
                <span class="text gris size-10 bold-600 margin-r-10 upliner">Dirección*</span>
                <input
                  class="inp"
                  name="address_companion"
                  type="text"
                  v-model="compa.address"
                  v-show="add"
                  v-validate="'min:2|required'"
                />
                <i aria-hidden="true" class="fa fa-map-marker"></i>
                <span
                  class="text size-9 red error"
                  v-if="errors.has('address_companion')"
                >{{ errors.first('address_companion') }}</span>
              </div>
            </div>
            <div class="cont-form spaceB" v-if="params.questionCovid == 1">
              <span
                class="t-question"
              >¿Ha estado en contacto con personas con infección respiratoria aguda o personas diagnosticadas con COVID-19?</span>
              <div class="questions">
                <div
                  :class="{'active': compa.question1 == 1 }"
                  @click="(compa.question1 == 1) ? compa.question1 = null : compa.question1 = 1"
                  class="input-question left"
                >
                  <span class="t-input">Si</span>
                </div>
                <div
                  :class="{'active': compa.question1 == 0 }"
                  @click="(compa.question1 == 0) ? compa.question1 = null : compa.question1 = 0"
                  class="input-question right"
                >
                  <span class="t-input">No</span>
                </div>
              </div>
            </div>
            <div class="cont-form spaceB" v-if="params.questionCovid == 1">
              <span
                class="t-question"
              >¿Presenta alguno de los siguientes síntomas: dolor de garganta, fiebre mayor a 38 grados, tos seca y persistente, dificultad para respirar, dolor muscular de cabeza y/o de articulaciones?</span>
              <div class="questions">
                <div
                  :class="{'active': compa.question2 == 1 }"
                  @click="(compa.question2 == 1) ? compa.question2 = null : compa.question2 = 1"
                  class="input-question left"
                >
                  <span class="t-input">Si</span>
                </div>
                <div
                  :class="{'active': compa.question2 == 0 }"
                  @click="(compa.question2 == 0) ? compa.question2 = null : compa.question2 = 0"
                  class="input-question right"
                >
                  <span class="t-input">No</span>
                </div>
              </div>
            </div>
          </div>
          <div class="btn-form">
            <div @click="modeEdit('add')" class="btn-companion">
              <span class="text color-btn size-14">
                <i class="fa fa-times"></i> Cancelar
              </span>
            </div>
            <div @click="addCompanion(compa)" class="btn-companion">
              <span class="text color-btn size-14">
                <i class="fa fa-save"></i> Guardar
              </span>
            </div>
          </div>
        </div>
        <div @click="modeEdit('add',null)" class="btn-companion absolute fade" v-if="!add && term">
          <span class="text color-btn size-14">Agregar acompañante</span>
        </div>
      </div>
    </div>
    <div class="mobile" v-if="isResponsive()">
      <span class="text bold center">Agrega o edita los acompañantes para esta reserva</span>
      <div class="cont-table">
        <div :key="index" class="card-table" v-for=" (item,index) in data">
          <div class="cont">
            <span class="text blueDark size-11 bold-700">Nombre:</span>
            <span class="text blueDark size-11 bold-700">E-mail:</span>
            <span class="text blueDark size-11 bold-700">Telefono:</span>
            <span class="text blueDark size-11 bold-700" v-if="params.enableAddress == 1">Dirección:</span>
            <span class="text blueDark size-11 bold-700">Documento:</span>
            <span class="text blueDark size-11 bold-700">Temperatura:</span>
            <span class="text blueDark size-11 bold-700" v-if="params.questionCovid == 1">Preguntas:</span>
          </div>
          <div class="cont">
            <span class="text blueDark size-11">{{item.name}}</span>
            <span class="text blueDark size-11">{{item.email || 'N/A'}}</span>
            <span class="text blueDark size-11 nowrap">{{item.indicative}} {{item.phone}}</span>
            <span
              class="text blueDark size-11 nowrap"
              v-if="params.enableAddress == 1"
            >{{item.address || 'N/A'}}</span>
            <span class="text blueDark size-11">{{item.documentIdentify || 'N/A'}}</span>
            <span class="text blueDark size-11">{{item.temperature || 'N/A'}}</span>
            <span class="text blueDark size-11" v-if="params.questionCovid == 1">
              <img
                alt="precompro.com"
                height="15"
                src="@/assets/icons/check-round.svg"
                v-if="(item.question1 == 0 || item.question1 == 1) && (item.question2 == 1 || item.question2 == 0)"
                width="15"
              />
            </span>
          </div>
          <div class="btns">
            <i
              @click="deleteCompanion(index)"
              aria-hidden="true"
              class="fa fa-trash"
              id="btn-edit-trash"
            ></i>
            <i
              @click="modeEdit('edit',item ,index)"
              aria-hidden="true"
              class="fa fa-pencil-square-o"
              id="btn-edit-trash"
            ></i>
          </div>
        </div>
      </div>
      <div
        :class="{'show' : mobile ,'questions': params.questionCovid == 1 && mobile}"
        class="edit mobile"
      >
        <i
          @click="modeEdit('add')"
          class="icon_left icon-mks black"
          id="arrow-mobile-companion"
          v-show="mobile"
        ></i>
        <div class="forms" id="editCompanion">
          <div class="formulario-edit mobile">
            <div class="cont-form wrap">
              <div class="input-edit">
                <span class="text gris size-10 bold-600 margin-r-10 upliner">Nombre*</span>
                <input
                  @input="searchUser(compa.name)"
                  class="inp"
                  name="name_companion"
                  type="text"
                  v-model="compa.name"
                  v-show="add"
                  v-validate="'min:2|required'"
                />
                <i aria-hidden="true" class="fa fa-user-o"></i>
                <span
                  class="text size-9 red error"
                  v-if="errors.has('name_companion')"
                >{{ errors.first('name_companion') }}</span>
                <ul class="Concidence" v-if="showUl">
                  <li class="loading" v-if="isLoading">Cargando resultados...</li>
                  <li
                    class="loading"
                    v-if="!isLoading && dataUser.length == 0"
                  >No hay coincidencias..</li>
                  <li :key="ind" @click="setData(data)" v-for="(data, ind) in dataUser">
                    {{data.name}}
                    <b>#{{data.phone}}</b>
                    @{{ data.email }}
                  </li>
                </ul>
              </div>
              <div class="input-edit no-margin-last">
                <span class="text gris size-10 bold-600 margin-r-10 upliner">Mail* (Opcional)</span>
                <input
                  class="inp"
                  input
                  name="email_companion"
                  type="text"
                  v-model="compa.email"
                  v-show="add"
                  v-validate="'email'"
                />
                <i aria-hidden="true" class="fa fa-envelope-o"></i>
                <span
                  class="text size-9 red error"
                  v-if="errors.has('email_companion')"
                >{{ errors.first('email_companion') }}</span>
              </div>
              <div class="input-edit" v-if="params.enableAddress == 1">
                <span class="text gris size-10 bold-600 margin-r-10 upliner">Dirección*</span>
                <input
                  class="inp"
                  name="address_companion"
                  type="text"
                  v-model="compa.address"
                  v-show="add"
                  v-validate="'min:2|required'"
                />
                <i aria-hidden="true" class="fa fa-map-marker"></i>
                <span
                  class="text size-9 red error"
                  v-if="errors.has('address_companion')"
                >{{ errors.first('address_companion') }}</span>
              </div>
            </div>
            <div class="cont-form wrap">
              <div class="input-edit">
                <div class="left-box">
                  <img :src="bandera" class="bandera_list" />
                  <Multiselect
                    :options="paises"
                    @input="changedPais(option)"
                    class="list_conutry"
                    label="label_show"
                    placeholder
                    track-by="label_show"
                    v-if="option"
                    v-model="option"
                  ></Multiselect>
                </div>
                <span class="text gris size-10 bold-600 margin-r-10 upliner">Celular*</span>
                <input
                  class="inp padding-left"
                  name="phone_companion"
                  type="text"
                  v-model="compa.phone"
                  v-show="add"
                  v-validate="validateTel"
                />
                <i aria-hidden="true" class="fa fa-phone"></i>
                <span
                  class="text size-9 red error"
                  v-if="errors.has('phone_companion')"
                >{{ errors.first('phone_companion') }}</span>
              </div>
              <div class="input-edit">
                <span class="text gris size-10 bold-600 margin-r-10 upliner">Identificación</span>
                <input
                  class="inp"
                  name="identification_companion"
                  type="text"
                  v-model="compa.documentIdentify"
                  v-show="add"
                  v-validate="'required|regex:^[a-zA-Z0-9]*$|min:5'"
                />
                <i aria-hidden="true" class="fa fa-id-card-o"></i>
                <span
                  class="text size-9 red error"
                  v-if="errors.has('identification_companion')"
                >{{ errors.first('identification_companion') }}</span>
              </div>
              <div class="input-edit temp-width">
                <span class="text gris size-10 bold-600 margin-r-10 upliner">Temperatura°</span>
                <input
                  class="inp"
                  name="temperature_companion"
                  type="text"
                  v-model="compa.temperature"
                  v-validate="'required'"
                />
                <i aria-hidden="true" class="fa fa-thermometer-empty"></i>
                <span
                  class="text size-9 red error"
                  v-if="errors.has('temperature_companion')"
                >{{ errors.first('temperature_companion') }}</span>
              </div>
              <div
                @click="addCompanion(compa)"
                class="btn-companion min-160"
                style="margin-left: 15px"
              >
                <span class="text color-btn size-14">Guardar</span>
              </div>
            </div>
            <div class="cont-form spaceB" v-if="params.questionCovid == 1">
              <span
                class="t-question"
              >¿Ha estado en contacto con personas con infección respiratoria aguda o personas diagnosticadas con COVID-19?</span>
              <div class="questions">
                <div
                  :class="{'active': compa.question1 == 1 }"
                  @click="(compa.question1 == 1) ? compa.question1 = null : compa.question1 = 1"
                  class="input-question left"
                >
                  <span class="t-input">Si</span>
                </div>
                <div
                  :class="{'active': compa.question1 == 0 }"
                  @click="(compa.question1 == 0) ? compa.question1 = null : compa.question1 = 0"
                  class="input-question right"
                >
                  <span class="t-input">No</span>
                </div>
              </div>
            </div>
            <div class="cont-form spaceB" v-if="params.questionCovid == 1">
              <span
                class="t-question"
              >¿Presenta alguno de los siguientes síntomas: dolor de garganta, fiebre mayor a 38 grados, tos seca y persistente, dificultad para respirar, dolor muscular de cabeza y/o de articulaciones?</span>
              <div class="questions">
                <div
                  :class="{'active': compa.question2 == 1 }"
                  @click="(compa.question2 == 1) ? compa.question2 = null : compa.question2 = 1"
                  class="input-question left"
                >
                  <span class="t-input">Si</span>
                </div>
                <div
                  :class="{'active': compa.question2 == 0 }"
                  @click="(compa.question2 == 0) ? compa.question2 = null : compa.question2 = 0"
                  class="input-question right"
                >
                  <span class="t-input">No</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div @click="modeEdit('add',null)" class="btn-companion absolute fade" v-if="!add && term">
          <span class="text color-btn size-14">
            <i class="fa fa-plus"></i> Agregar acompañante
          </span>
        </div>
      </div>
    </div>
    <div class="terms-companion">
      <input type="checkbox" v-model="term" />
      <span
        class="terms-companion-left"
      >Al agregar los datos de sus acompañantes declara que tiene la autorización previa de ellos y están aceptando el tratamiento de datos</span>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import reservationTypes from '@/store/types/reservation';
import BanderaPais from 'country-json/src/country-by-abbreviation.json';
import callingCode from 'country-json/src/country-by-calling-code.json';
import Multiselect from 'vue-multiselect';
import _ from 'lodash';
export default {
  components: {
    Multiselect
  },
  data () {
    return {
      term: true,
      data: [],
      columns: [],
      compa: {
        name: null,
        email: null,
        phone: null,
        indicative: '+57',
        reservationId: null,
        documentIdentify: null,
        temperature: 0,
        address: null,
        question1: null,
        question2: null
      },
      add: false,
      edit: false,
      editIndex: null,
      option: {
        value: 57,
        label: 'Colombia ( +57 )',
        label_show: '+57',
        selected: true
      },
      paises: [],
      bandera: null,
      pais: null,
      mobile: false,
      isLoading: false,
      dataUser: [],
      showUl: false,
      validateTel: ''
    };
  },
  mounted () {
    const indicativo = this.$store.getters['global:vendor'].params.indicativeRegion;
    if (this.companionsData) {
      this.data = this.companionsData;
    }
    this.listPaises();
    this.indicativeParam(indicativo);
    this.changedPais(this.option);
  },
  computed: {
    ...mapGetters({
      companionsData: reservationTypes.getters.companionsData
    })
  },
  methods: {
    indicativeParam (indicativo) {
      const selectIndicative = this.paises.find(value => parseInt(value.value) === parseInt(indicativo));
      this.option = selectIndicative;
    },
    modeEdit (type, data, index) {
      this.editIndex = null;
      this.compa = {
        name: null,
        email: null,
        phone: null,
        indicative: '+57',
        reservationId: null,
        documentIdentify: null,
        temperature: 0,
        address: null,
        question1: null,
        question2: null
      };
      if (type === 'add') {
        if (!this.add) {
          document.getElementById('editCompanion').classList.remove('disabled');
          document.getElementById('editCompanion').classList.add('active');
          this.add = true;
          this.edit = false;
          this.mobile = true;
        } else {
          document.getElementById('editCompanion').classList.remove('active');
          document.getElementById('editCompanion').classList.add('disabled');
          this.add = false;
          this.edit = false;
          this.mobile = false;
        }
      } else if (type === 'edit') {
        if (this.edit) {
          this.compa = JSON.parse(JSON.stringify(data));
          this.editIndex = index;
        } else {
          this.compa = JSON.parse(JSON.stringify(data));
          this.editIndex = index;
          if (!this.add) {
            document.getElementById('editCompanion').classList.remove('disabled');
            document.getElementById('editCompanion').classList.add('active');
            this.add = true;
            this.edit = true;
            this.mobile = true;
          } else {
            document.getElementById('editCompanion').classList.remove('active');
            document.getElementById('editCompanion').classList.add('disabled');
            this.add = false;
            this.edit = false;
            this.mobile = false;
          }
        }
      }
    },
    addCompanion (data) {
      this.errors.clear();
      this.$validator.validateAll().then(result => {
        if (result) {
          this.isLoading = true;
          if (this.edit && this.editIndex != null) {
            const info = JSON.parse(JSON.stringify(data));
            this.data[this.editIndex] = info;
            this.modeEdit('add', null, null);
          } else {
            const info = JSON.parse(JSON.stringify(data));
            this.data.push(info);
            this.modeEdit('add', null, null);
          }
          this.$store.commit(reservationTypes.mutations.setCompanionsData, this.data);
          this.isLoading = false;
        }
      });
    },
    deleteCompanion (id) {
      this.isLoading = true;
      this.data.splice(id, 1);
      if (this.data.length > 0) {
        this.$store.commit(reservationTypes.mutations.setCompanionsData, this.data);
      } else {
        this.$store.commit(reservationTypes.mutations.setCompanionsData, null);
      }
      this.isLoading = false;
    },
    isResponsive () {
      if (window.innerWidth <= 1000) {
        return true;
      } else {
        return false;
      }
    },
    listPaises () {
      var data = [];
      var ind = this.compa.indicative != null ? this.compa.indicative : null;
      _.forEach(callingCode, function (item) {
        if (item.calling_code !== null) {
          if (ind != null && ind === item.calling_code && item.country !== 'Bouvet Island') {
            data.push({
              value: item.calling_code,
              label: item.country + ' ( +' + item.calling_code + ' )',
              label_show: '+' + item.calling_code,
              selected: true
            });
          } else if (item.country !== 'Bouvet Island') {
            data.push({
              value: item.calling_code,
              label: item.country + ' ( +' + item.calling_code + ' )',
              label_show: item.country + ' ( +' + item.calling_code + ' )'
            });
          }
        }
      });
      this.paises = data;
    },
    changedPais (indicativo) {
      this.option = indicativo;
      let aux2 = null;
      const aux3 = indicativo.label;
      this.pais = indicativo.label;
      this.compa.indicativo = indicativo.value;
      const ind = indicativo.value;
      _.forEach(BanderaPais, function (item) {
        if (ind !== null) {
          const data = item.country + ' ( +' + ind + ' )';
          if (data === aux3) {
            aux2 = item.abbreviation.toLowerCase();
          }
        }
      });
      this.bandera = aux2 != null ? 'https://api.precompro.com/banderas/' + aux2 + '.png' : null;
      this.option.label_show = '+' + ind;
      this.compa.indicative = '+' + ind;
      switch (indicativo.value) {
        case 57:
          this.validateTel = 'required|numeric|min:10|max:10';
          break;
        case 507:
          this.validateTel = 'required|numeric|min:7|max:8';
          break;
        default:
          this.validateTel = 'required|numeric|min:7|max:11';
          break;
      };
    },
    searchUser (string) {
      clearTimeout(this.timeout);
      const thes = this;
      this.isLoading = true;
      this.timeout = setTimeout(function () {
        if (string !== '' && string.length >= 3) {
          thes.$store
            .dispatch({
              type: 'people:search',
              data: { search: string, vendorId: thes.vendor.id }
            })
            .then(response => {
              thes.dataUser = response;
              thes.showUl = true;
              thes.isLoading = false;
            }).catch(() => {
              thes.isLoading = false;
            });
        } else {
          thes.isLoading = false;
        }
      }, 500);
    },
    setData (data) {
      this.showUl = false;
      this.compa.name = data.name;
      if (data.phone) {
        this.compa.phone = data.phone;
      } else {
        this.compa.phone = null;
      }
      if (data.email) {
        this.compa.email = data.email;
      } else {
        this.compa.email = null;
      }
      this.compa.documentIdentify = data.identityDocument;
      this.compa.indicativo = data.indicative;
      const ind = parseInt(data.indicative);
      let option = null;
      _.forEach(callingCode, function (item) {
        if (item.calling_code !== null) {
          if (ind != null && ind === parseInt(item.calling_code)) {
            option = {
              value: item.calling_code,
              label: item.country + ' ( +' + item.calling_code + ' )',
              label_show: '+' + item.calling_code,
              selected: true
            };
          }
        }
      });
      if (option != null) {
        this.changedPais(option);
      }
    }
  }
};
</script>
<style lang="scss">
#modal-companion {
  background: #ffffff;
  border-radius: 16px;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  .desktop {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .header {
      border-radius: 12px 12px 0 0;
      display: flex;
      justify-content: center;
      height: 40px;
      align-items: center;
      background: var(--color2);
      width: 100%;
    }
    .table-companion {
      overflow-x: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      height: auto;
      width: 100%;
      max-height: 300px;
      min-height: 75px;
      .header-table {
        border: 1px solid #707070;
        border-right: 0px;
        border-left: 0px;
        &:first-child {
          border-radius: 5px 0 0 5px;
          border-left: 1px solid #707070;
        }
        &:last-child {
          border-radius: 0 5px 5px 0;
          border-right: 1px solid #707070;
        }
      }
      .items-table {
        border-right: 0px;
        border-left: 0px;
        margin-bottom: 10px;
        background: #00000014;
        &:first-child {
          border-radius: 2px 0 0 2px;
        }
        &:last-child {
          position: relative;
          border-radius: 0 2px 2px 0;
          padding-right: 47px;
        }
        .icons {
          position: absolute;
          right: 12px;
          top: 8px;
        }
      }
      table {
        width: 90%;
        margin-top: 15px;
        border-collapse: separate;
        border-spacing: 0 5px;
      }
      th,
      td {
        text-align: left;
        padding: 8px;
      }
    }
  }
  .edit {
    width: 90%;
    display: flex;
    justify-content: flex-end;
    margin: 12px 0 0 0;
    -webkit-tap-highlight-color: transparent;
    background: transparent;
    &:focus,
    &:active,
    &:hover {
      background: transparent;
    }
    .forms {
      width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      opacity: 0;
      -webkit-tap-highlight-color: transparent;
      background: transparent;
      &:focus,
      &:active,
      &:hover {
        background: transparent;
      }
      &.active {
        opacity: 1;
        animation: fadeDown 1s ease-in-out;
      }
      &.disabled {
        transform: translateY(600px);
        animation: fadeDownInverse 1s ease-in-out;
        opacity: 0;
      }
      .formulario-edit {
        width: 100%;
        display: flex;
        flex-direction: column;
        border: 2px solid rgba(112, 112, 112, 0.5);
        border-radius: 10px;
        padding: 15px 10px 10px 10px;
        .cont-form {
          display: flex;
          &.wrap {
            flex-wrap: wrap;
          }
          .input-edit {
            flex: 1;
            border: 1.2px solid rgba(112, 112, 112, 0.5);
            border-radius: 4px;
            padding: 5px;
            width: calc(100% - 10px);
            margin: 0 7px 10px 7px;
            min-width: 190px;
            display: flex;
            align-items: center;
            position: relative;
            &:first-child {
              margin: 0 0px 10px 0 !important;
            }
            &:last-child {
              margin: 0 7px 10px 7px;
            }
            &.no-margin-last {
              &:last-child {
                margin: 0 7px 10px 7px !important;
              }
            }
            .inp {
              width: 100%;
              border: 0;
              outline: 0;
              &.padding-left {
                padding-left: 82px;
              }
            }
            &.temp-width {
              min-width: 100px;
              max-width: 180px;
            }
            &.min-215 {
              min-width: 215px;
            }
          }
        }
      }
      .btn-form {
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding: 0 30px;
        margin: 15px 0;
      }
    }
  }

  .mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: flex-start;
    .edit {
      &.mobile {
        bottom: -80px;
        position: absolute;
        border-radius: 10px 10px 0 0;
        box-shadow: 0px -3px 6px #00000029;
        padding-top: 15px;
        height: 300px;
        width: 100%;
        animation: mobile-slide-inverse 1s ease-in-out;
        z-index: 10;
        background: #ffffff;
      }
      &.show {
        bottom: 140px;
        animation: mobile-slide 1s ease-in-out;
        &.questions {
          height: 400px;
        }
      }
      .forms {
        .formulario-edit {
          &.mobile {
            border: inherit;
            padding: 15px 15px 15px 15px;
          }
        }
      }
    }
    .cont-table {
      width: 100%;
      height: calc(100% - 280px);
      overflow: auto;
      .card-table {
        display: flex;
        background: #efefef;
        margin: 6px 0;
        position: relative;
        .btns {
          position: absolute;
          top: 12px;
          right: 10px;
          display: flex;
          flex-direction: column;
          #btn-edit-trash {
            color: #000;
            font-size: 20px;
            margin-bottom: 10px;
          }
        }
        .cont {
          display: flex;
          flex-direction: column;
          position: relative;
          padding: 8px 21px;
        }
      }
    }
  }
  .bandera_list {
    display: flex;
    margin-top: 0px;
    width: 25px;
    height: 17px;
    margin-right: 0;
  }

  .indicativo {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    .field.is-horizontal {
      width: 100%;
    }

    .phone-indi {
      position: relative;
    }
    .field-body {
      .field {
        &:first-child {
          position: absolute;

          .help.is-danger {
            color: #ff3860;
            display: none;
          }
        }

        &:last-child {
          .input {
            padding-left: 100px;
          }
        }
      }
    }

    .input-phone {
      width: 100%;
      padding: 5px 8px;
      border: 1px solid #d2d6de;
      border-radius: 0 5px 5px 0;
      outline: none;
      height: 30px;
    }
  }

  .multiselect {
    box-sizing: content-box !important;
    display: block !important;
    position: relative !important;
    width: 100% !important;
    text-align: left !important;
    color: #35495e !important;
    min-height: auto !important;
    height: 100% !important;

    &__tags {
      margin-left: -32px !important;
      min-height: 30px !important;
      display: block !important;
      padding: 0px 30px 0 30px !important;
      border-radius: 5px !important;
      border: none !important;
      background: transparent !important;
      font-size: 14px !important;
    }

    &__select {
      width: 33px !important;
      height: 21px !important;

      &::before {
        height: 30px !important;
      }
    }

    &__option--highlight:after {
      content: "" !important;
      background: #41b883 !important;
      color: #fff !important;
    }

    &__option:after {
      display: none !important;
    }

    &__option {
      display: flex !important;
      align-items: center !important;
      padding: 5px !important;
      height: 20px !important;
      line-height: 12px !important;
      text-decoration: none !important;
      text-transform: none !important;
      position: relative !important;
      cursor: pointer !important;
      white-space: nowrap !important;
      font-size: 12px !important;
      min-height: 30px !important;
    }

    &__single {
      margin-top: -1px;
    }

    &__input,
    &__single {
      padding-left: 5px !important;
      margin-bottom: 0px !important;
      font-size: 11px !important;
      font-weight: 600 !important;
      line-height: 21px !important;
      background: transparent !important;
    }

    &__content-wrapper {
      position: absolute !important;

      display: block;
      background: #fff !important;
      width: 200px !important;
      max-height: 239px !important;
      overflow: auto !important;
      border: 1px solid #e8e8e8;
      border-top: none !important;
      border-bottom-left-radius: 5px !important;
      border-bottom-right-radius: 5px !important;
      z-index: 50 !important;
      -webkit-overflow-scrolling: touch !important;
      -webkit-transform: translateZ(0px) !important;
    }
  }

  .field .input,
  .field .taginput .taginput-container.is-focusable,
  .field .textarea,
  .field .select select {
    border-radius: 50px !important;
    box-shadow: none !important;
    padding-left: 15px;
  }

  .field-label.is-normal {
    padding-top: 0;
    display: flex;
    margin-right: 10px;

    .label {
      display: flex;
      align-items: center;
      max-height: 30px;
      font-size: 14px;
      padding-left: 0px;
      white-space: nowrap;
    }
  }

  .control .help.counter {
    float: right;
    margin-left: 0.5em;
    display: none;
  }

  .left-box {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    border-radius: 50px 0 0 50px;
    border-right: 0;
    height: 20px;
    height: 30px;
    max-width: 82px;
    padding-top: 5px;
    margin-right: 0 !important;
    position: absolute;
    border-right: 1.2px solid rgba(112, 112, 112, 0.5);
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .text {
    color: var(--colorCheckout);
    &.bold {
      font-weight: bold;
    }
    &.bold-400 {
      font-weight: 400;
    }
    &.bold-600 {
      font-weight: 600;
    }
    &.bold-700 {
      font-weight: 700;
    }
    &.gris {
      color: #707070;
    }
    &.blueDark {
      color: #444b57;
    }
    &.red {
      color: red;
    }
    &.color-btn {
      color: var(--btnColorReservationFooter);
    }
    &.size {
      &-9 {
        font-size: 9px;
      }
      &-11 {
        font-size: 11px;
      }
      &-12 {
        font-size: 12px;
      }
      &-14 {
        font-size: 14px;
      }
      &-10 {
        font-size: 10px;
      }
    }
    &.margin {
      &-r-10 {
        margin-right: 10px;
      }
    }
    &.center {
      text-align: center;
    }
    &.error {
      position: absolute;
      background: white;
      bottom: -6px;
      padding: 0 2px;
      white-space: nowrap;
    }
    &.padding-left-cel {
      padding-left: 95px;
    }
    &.upliner {
      position: absolute;
      top: -8px;
      background: #ffffff;
      left: 10px;
    }
    &.nowrap {
      white-space: nowrap;
    }
  }
  .btn-companion {
    background: #444b57;
    box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.5);
    padding: 0 15px;
    height: 34px;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 210px;
    min-width: 125px;
    cursor: pointer;
    &.absolute {
      position: absolute;
      right: 60px;
    }
    &.fade {
      animation: fade 1s ease-in-out;
    }
    &.min-160 {
      min-width: 160px;
    }
  }
  #arrow-mobile-companion {
    position: absolute;
    right: 20px;
    top: 8px;
    cursor: pointer;
    transform: rotate(-90deg);
  }
  .questions {
    display: flex;
    justify-content: center;
    align-items: center;
    .input-question {
      box-shadow: 0 1px 7px rgba(0, 0, 0, 0.3);
      width: 30px;
      height: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      &.left {
        border-radius: 10px 0 0 10px;
      }
      &.right {
        border-radius: 0 10px 10px 0;
      }
      &.active {
        background: #14274e;
        color: #ffffff;
      }
      .t-input {
        font-size: 13px;
        font-weight: 600;
      }
    }
  }
  .t-question {
    font-size: 12px;
    font-weight: 400;
    max-width: 600px;
    margin-right: 10px;
  }
  li {
    padding: 5px 10px;
    font-size: 9px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }
  @keyframes fadeDown {
    0% {
      opacity: 0;
      transform: translateY(300px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  @keyframes fadeDownInverse {
    0% {
      opacity: 1;
      transform: translateY(0);
    }
    100% {
      opacity: 0;
      transform: translateY(300px);
    }
  }
  @keyframes fade {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes mobile-slide {
    0% {
      bottom: -80px;
    }
    100% {
      bottom: 140px;
    }
  }
  @keyframes mobile-slide-inverse {
    0% {
      bottom: 140px;
    }
    100% {
      bottom: -80px;
    }
  }
  @mixin hover-supported {
    @media not all and (hover: none) {
      &:hover {
        @content;
      }
    }
  }
}
@media (max-width: 750px) {
  #modal-companion {
    .edit {
      .forms {
        .formulario-edit {
          .cont-form {
            .input-edit {
              min-width: 280px;
              margin: 0 0px 10px 0 !important;
              &.no-margin-last {
                &:last-child {
                  margin: 0 0px 10px 0 !important;
                }
              }
            }
          }
        }
      }
    }
  }
}
.terms-companion {
  width: 95%;
  padding: 10px;
  bottom: 60px;
  position: absolute;
  z-index: 99;
  &-left {
    margin-left: 5px;
  }
}

@media (max-width: 565px) {
  #modal-companion .bandera_list {
    margin-top: -5px !important;
  }
  #modal-companion .left-box {
    border-top: 1.2px solid rgba(112, 112, 112, 0.5) !important;
  }
}
</style>
