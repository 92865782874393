<template>
  <div class="container-pdf-viewer">
    <div class="pdf-controls">
      <div class="pdf-controls__display-pages">
        <div> Página </div>
        <div class="pages">{{currentPage}}</div>
        <div>de {{ totalPages }}</div>
      </div>
      <div class="pdf-controls__icons">
        <img class="pdf-controls__icons__zoom_plus" @click="zoomIn" src="@/assets/images/icons/zoom_plus.svg" alt="">
        <img class="pdf-controls__icons__zoom_minus" @click="zoomOut" src="@/assets/images/icons/zoom_minus.svg" alt="">
        <img class="pdf-controls__icons__download" @click="downloadFile" src="@/assets/images/icons/download.svg" alt="">
      </div>
    </div>
    <div class="pdf-viewer">
      <cafe-pdf v-if="pdfSrcURl !== '' && reVisualize" ref="pdf" @on-success="load" @on-scroll="scroll" :filePath="pdfSrcURl">
      </cafe-pdf>
      <!-- <i class="mdi mdi-download"  @click="downloadPDF"></i>
      <iframe allowfullscreen ref="myIframe" :src="pdfSrcURl" width="100%" height="100%" frameborder="0"></iframe> -->
    </div>
    <div class="pdf-footer">
      <div class="pdf-footer__update-date">
        <div class="pdf-footer__update-date__info" v-if="dateAcceptTerms">
          <img class="pdf-controls__icons__zoom_plus" src="@/assets/images/icons/union_check.svg" alt="">
          <div ><b>Fecha de aceptación {{ dateAcceptTerms | moment('MMMM DD [del] YYYY, hh:mm:ss a')  }}</b></div>
        </div>
        <div class="pdf-footer__update-date__info">
          <img class="pdf-controls__icons__zoom_plus" src="@/assets/images/icons/union_check.svg" alt="">
          <div>Última actualización {{ dateUpdateTerms | moment('MMMM DD [del] YYYY, hh:mm:ss a')  }}</div>
        </div>
      </div>
      <div :class="{'pdf-footer__accept-aux': onlyView}" class="pdf-footer__accept">
        <div v-if="!onlyView">Para hacer uso del sistema debes aceptar los términos y condiciones</div>
        <b-button v-if="!onlyView" @click="acceptTermsConditions">Acepto</b-button>
        <b-button v-if="onlyView" @click="$parent.close()">Cerrar</b-button>
      </div>
    </div>
  </div>
</template>
<script>
import cafePdf from 'cafe-pdf';
import 'cafe-pdf/lib/index.css';
import axios from 'axios';
export default {
  props: ['newTermsForOldUsers', 'onlyView'],
  components: {
    cafePdf
  },
  data () {
    return {
      showModal: false,
      pdfSrc: null,
      pdfSrcURl: '',
      currentPage: 1,
      pageCount: 0,
      zoomLevel: 1,
      pdfAux: false,
      totalPages: 0,
      lastScrollTop: null,
      scale: 1,
      isSmallScreen: false,
      dateUpdateTerms: this.$moment().format('MMMM DD [del] YYYY, hh:mm:ss a'),
      dateAcceptTerms: null,
      reVisualize: false
    };
  },
  mounted () {
    this.getCustomPDF();
    document.addEventListener('visibilitychange', this.handleVisibilityChange);
  },
  beforeDestroy () {
    document.removeEventListener('visibilitychange', this.handleVisibilityChange);
  },
  methods: {
    // Zoom in y zoom out
    zoomIn () {
      if (this.zoomLevel < 2) {
        this.zoomLevel += 0.1; // Incrementa el nivel de zoom
      }
    },
    zoomOut () {
      if (this.zoomLevel > 0.5) {
        this.zoomLevel -= 0.1; // Disminuye el nivel de zoom
      }
    },
    // Función para descargar el PDF
    async downloadFile () {
      this.$emit('registerDateDownloadFile');
      const fileUrl = this.pdfSrcURl;
      const fileName = 'terminos_y_condiciones.pdf';

      try {
        const response = await axios.get(fileUrl, {
          responseType: 'blob'
        });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error('Error al descargar el archivo:', error);
      }
    },
    getCustomPDF () {
      this.$emit('changeLoading', true);
      try {
        this.$store.dispatch({ type: 'global:getCustomPDF', data: { vendorId: this.vendor.id, restaurantName: this.vendor.displayName, userDateCreated: this.$moment(this.user.created_at).format('DD/MM/YYYY') } }).then(resp => {
          const { data } = resp;
          if (resp.status === 200) {
            this.pdfSrcURl = `${data.link}?nocache=${new Date().getTime()}`;
            this.dateUpdateTerms = data.dateUpdateTerms;
            this.reVisualize = true;
            this.getLogDataUserExistTermsConditions();
            this.$emit('changeLoading', false);
          } else {
            this.$buefy.toast.open({
              message: data.message,
              type: 'is-danger'
            });
          }
        }).catch((error) => {
          console.log(error);
          this.$buefy.toast.open({
            message: 'Lo siento, ha ocurrido un error al intentar procesar tu petición',
            type: 'is-danger'
          });
        });
      } catch (error) {
        console.log(error);
      }
    },
    checkScreenSize () {
      this.isSmallScreen = window.innerWidth <= 500;
      if (window.innerWidth <= 1920) {
        this.$refs.pdf.changeScale(1.1);
        this.zoomLevel = 1.1;
      }
      if (window.innerWidth <= 1280) {
        this.$refs.pdf.changeScale(1.2);
        this.zoomLevel = 1.2;
      }
      if (window.innerWidth <= 768) {
        this.$refs.pdf.changeScale(1);
        this.zoomLevel = 1;
      }
      if (window.innerWidth <= 500) {
        this.$refs.pdf.changeScale(0.7);
        this.zoomLevel = 0.7;
      }
      if (window.innerWidth <= 414) {
        this.$refs.pdf.changeScale(0.6);
        this.zoomLevel = 0.6;
      }
    },
    acceptTermsConditions () {
      if (this.newTermsForOldUsers) {
        this.$emit('trackUserAcceptTermsConditions');
        this.$parent.close();
      } else {
        this.$emit('changeTermsConditions', true);
        this.$parent.close();
      }
    },
    errorLoad (error) {
      console.log(error);
    },
    fileChanged () {
      this.file = this.src;
    },
    load (val) {
      this.$nextTick(() => {
        setTimeout(() => {
          this.totalPages = val;
          const pdfViewer = this.$refs.pdf;
          if (pdfViewer && pdfViewer.$el) {
            const container = pdfViewer.$el.querySelector('.pdf-container');
            if (container) {
              const scrollTo = (container.scrollWidth - container.clientWidth) / 2;
              container.scrollLeft = scrollTo;
            }
          }
        }, 500); // Ajusta este tiempo según sea necesario
      });
    },
    scroll (val) {
      this.currentPage = val;
    },
    pre () {
      this.$refs.pdf.prePage();
    },
    next () {
      this.$refs.pdf.nextPage();
    },
    pageChange (val) {
      this.$refs.pdf.goToPage(val);
    },
    print () {
      this.$refs.pdf.printFile();
    },
    cw () {
      this.$refs.pdf.rotateCW();
    },
    ccw () {
      this.$refs.pdf.rotateCCW();
    },
    handleVisibilityChange () {
      if (document.visibilityState === 'hidden') {
        this.reVisualize = false;
      }
      if (document.visibilityState === 'visible' && this.pdfSrcURl) {
        this.reVisualize = true;
      }
    },
    getLogDataUserExistTermsConditions () {
      const data = {
        email: this.user.email,
        vendorId: this.vendor.id
      };
      this.$store.dispatch({ type: 'global:getUserLogTermsConditionsAccepted', data }).then(resp => {
        const { data } = resp;
        if (data.code === 200) {
          if (data.exist) {
            this.dateAcceptTerms = data.data.dateAccept;
          }
        } else {
          this.$buefy.toast.open({
            message: data.message,
            type: 'is-danger'
          });
        }
      }).catch(() => {
        this.$buefy.toast.open({
          message: 'Lo siento, ha ocurrido un error al intentar procesar tu petición',
          type: 'is-danger'
        });
      });
    }
  },
  watch: {
    zoomLevel () {
      this.$refs.pdf.changeScale(this.zoomLevel);
    },
    pdfSrcURl () {
      if (this.pdfSrcURl !== '') {
        setTimeout(() => {
          this.checkScreenSize();
        }, 500);
      }
    },
    reVisualize () {
      if (this.reVisualize) {
        setTimeout(() => {
          this.checkScreenSize();
        }, 500);
      }
    }
  }
};
</script>
<style lang="scss">
  @import "../../assets/styles/users/modalPdfViewer.scss";
</style>
