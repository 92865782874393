<template>
  <div id="search">
    <div>
      <Sidenav @change-full="toggle"></Sidenav>
      <div class="content-wrap">
        <Toolbar></Toolbar>
        <ChangedPassword v-if="changedPassword" v-model="changedPassword"></ChangedPassword>
        <Searchs></Searchs>
      </div>
    </div>
    <TempPassword></TempPassword>
  </div>
</template>
<script>
import Sidenav from '@/components/_shared/Sidenav';
import Toolbar from '@/components/_shared/Toolbar';
import Searchs from './searchs';
import ChangedPassword from '@/components/_shared/ChangedPassword';
import TempPassword from '@/components/_shared/TempPassword';

export default {
  components: { Sidenav, Toolbar, Searchs, ChangedPassword, TempPassword },
  mounted () {
    this.$store.dispatch({
      type: 'auth:refreshUser',
      data: {
        userId: this.user.id
      }
    });
  },
  methods: {
    toggle () {
      const el = document.documentElement;
      if (!document.fullscreenElement) {
        if (el.requestFullscreen) {
          el.requestFullscreen();
        } else if (el.webkitRequestFullscreen) { /* Safari */
          el.webkitRequestFullscreen();
        } else if (el.msRequestFullscreen) { /* IE11 */
          el.msRequestFullscreen();
        }
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitExitFullscreen) { /* Safari */
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) { /* IE11 */
          document.msExitFullscreen();
        }
      }
    }
  }
};
</script>
<style lang="scss">
#search {
  .modal-download {
    background-color : #fff;
    height: 100px;
    width: 350px;
    border-radius: 10px;
  }
  @media screen and (max-width: 1200px) {
    .navbar {
        height: 50px !important;
    }
  }
  @media screen and (min-width: 770px) and (max-width: 1180px){
    overflow: hidden !important;
    #search-index {
      overflow: hidden !important;
    }
    #list-search {
      width: 100%;
      position: relative;
    }
    .b-table{
      overflow-x: scroll;
    }
    .pagination {
      float: left !important;
    }
    .scroll-hor {
      position: absolute;
      width: 100% !important;
      margin-top: 45px;
    }
    .list-search-fill input {
      width: 100%;
    }
    .hidden-tablet {
      display: none;
    }
    ::-webkit-scrollbar-thumb {
      width: auto !important;
    }
  }
  background: rgb(237, 237, 237);
  .icon-mks {
      &-search {
         display:none;
      }
  }
      .btn-filters {
      display: none;
    }
    @media screen and (min-width: 768px) and (max-width: 1023px) {
    #filters{
      #datePicker{
      .icon_calendar{
        display: none;
      }
      .label{
       font-size: 10px;
      }
      input{
        font-size: 9px;
      }
      .vdpFloating{
        left: 0!important;
        right: 0;
        top: -2250%;
      }
      .vdpInnerWrap{
       position: fixed;
       transform: translate3d(0,0,0);
        will-change: transform;
      }
      .vdpClearInput{
        display: none;
      }
      }
      .column-margin-rigth {
    margin-right: 0px;

    }
    .btn{
      margin-top: 5px;
    }
 }
    }
  @media screen and (min-width: 320px) and (max-width: 768px) {

      #list-search {
        .icon-report {
          color: #fff;
          float: right;
          margin-left: 5px;
          margin-right: 10px;
          margin-top: 10px;
        }
        .lup {
          display: none;
        }
      }
    .btn-filters {
      display: flex;
      position: absolute;

      justify-content: center;
      color: black;
      box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.16);
      background-color: #444b57;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      right: 10px;
      bottom: 120px;
      z-index: 1;
    }
    .icon-mks {
      &-search {
        display:flex;
        height: 50px;
        margin-top: 0px;
        width: 50px;
        margin-left: 5px;
      }
      &-filter{
      background: white;
      }
    }
      .icon_close{
    display: flex;
  }
  .icon_filter{
    display: flex;
    position: absolute;

    left: 8px;
    top: 8px;
    height: 25px;
    width: 25px
  }
  .icon_left{
    display: none;
  }
    #filters {
      display: none;
      box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.16);
      width: 100%;
      border-radius: 0px 0px 0 0;
      .header{
        border-radius: 0px 0px 0px 0px;
        &:first-child{
          position:fixed;
          transform: translate3d(0,0,0);
          width:100%;
            will-change: transform;
        }
      }
      #datePicker {
      .vdpFloating{
        top: 0;
      }
      }
      #datePicker .header{
        position:relative;
        width:100%;
      }
      .filters-body {
        padding: 76px 10px;
        height: 100%;
        overflow: auto;
        z-index: 1;
      }
      .lup2{
        position: absolute;
        right: 3px;
      }
    }
    #list-search {
      background: none;
      border-radius: 0px 0px 0px 0px;
      height: calc(100vh - 125px);
    }
    #list-search .header {
      background: #444b57;
      margin-bottom: 20px;
      border-radius: 0px 0px 0px 0px;
      padding: 5px 5px 15px 45px;
      position: fixed;
      transform: translate3d(0,0,0);
       will-change: transform;
      width: 100%;
      z-index: 15;
      top: 0px;
      .icon_filter{
        left: 46px !important;
        top: 12px !important;
      }
      #list-search .icon-mks {
        height: 50px;
        width: 50px;
        position: absolute;

        right: 4px;
        top: -4px;
      }
    }
    #list-search input {
      color: white;
      border-bottom: 2px solid white;
      &::placeholder {
        color: white;
      }
    }
    .table {
      background-color: #ededed !important;
    }
    tr {
      box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.16);
      background: white ;
    }
    td{
      font-size: 13px;
    }
    #filters .icon-mks-size-fix {
      margin-right: -40px;
      height: 50px;
      width: 50px;
      margin-left: 35px;
    }
    #search-index {
      height: 100vh;
      margin: 0px;
      padding-top: 55px;
      .filter-box{
        position:fixed;
        transform: translate3d(0,0,0);
         will-change: transform;
        top:0;
        left:0;
        width:100%;
        z-index:99;
        height:100%;
      }
    }
    .table-wrapper{
      margin: 5px 8px;
    }
    .pagination-previous, .pagination-next, .pagination-link {
      background: white;
    }
    .pagination-previous, .pagination-next, .pagination-link, .pagination-ellipsis {
      margin: 1px;
    }

  }
}
</style>
