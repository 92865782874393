<template>
  <div id="input-personalizado" v-if="field">
    <div class="input-personalizado">
      <div
        :style="`${ iconLeft == 'user' ? 'padding-left: 10px' : '' }`"
        :class="{'input-error': errors.has(field)}"
        class="input-personalizado-icon-left"
        v-if="iconLeft"
      >
        <img
          alt="icon"
          src="@/assets/icons/addReservation/usuario.svg"
          style="width: 16px;"
          v-if="iconLeft == 'user'"
        />
        <img
          alt="icon"
          src="@/assets/icons/addReservation/mail.svg"
          style="width: 16px;"
          v-if="iconLeft == 'email'"
        />
        <img
          alt="icon"
          src="@/assets/icons/addReservation/gift-card.svg"
          style="width: 16px;"
          v-if="iconLeft == 'gift-card'"
        />
        <img
          alt="icon"
          src="@/assets/icons/addReservation/address.svg"
          style="width: 16px;"
          v-if="iconLeft == 'address'"
        />
        <img
          alt="icon"
          src="@/assets/icons/addReservation/temperature.svg"
          style="width: 12px;"
          v-if="iconLeft == 'temperature'"
        />
        <img
          alt="icon"
          src="@/assets/icons/addReservation/calendar.svg"
          style="width: 12px;"
          v-if="iconLeft == 'birthday'"
        />
        <img
          alt="icon"
          src="@/assets/icons/addReservation/company.svg"
          style="width: 16px;"
          v-if="iconLeft == 'company'"
        />
        <img
          alt="icon"
          src="@/assets/icons/addReservation/password.svg"
          style="width: 16px;"
          v-if="iconLeft == 'password'"
        />
        <img
          alt="icon"
          src="@/assets/icons/addReservation/habitation.svg"
          style="width: 16px;"
          v-if="iconLeft == 'habitation'"
        />
      </div>
      <div class="input-personalizado-center" v-if="field !== 'birthday'">
        <input
          :autocomplete="'new-' + field"
          :class="{'input-personalizado-disabled': disabled, 'borderRight': !iconRight, 'password': field === 'pinHost', 'show-password': showPasswordText}"
          :data-vv-name="field"
          :disabled="disabled"
          :name="'new-' + field"
          :placeholder="placeholder"
          :type="type"
          @input="()=>inputhasPress=true"
          @keydown="keypress"
          class="input-personalizado-center-text"
          v-if="!required"
          v-model="value"
        />
        <input
          :style="`${ iconLeft == 'user' ? 'padding-left: 10px' : '' }`"
          :autocomplete="'new-' + field"
          :class="{'input-error': errors.has(field), 'input-personalizado-disabled': disabled, 'borderRight': !iconRight, 'borderRight-red': !iconRight && errors.has(field), 'password': field === 'pinHost', 'show-password': showPasswordText}"
          :data-vv-name="field"
          :disabled="disabled"
          :name="'new-' + field"
          :placeholder="placeholder"
          :type="type"
          @input="()=>inputhasPress=true"
          @keydown="keypress"
          class="input-personalizado-center-text"
          v-if="required"
          v-model="value"
          v-validate="rules"
        />
      </div>
      <div class="input-personalizado-center" v-if="field === 'birthday'">
        <date-pick
          :class="{'input-personalizado-disabled': disabled, 'borderRight': !iconRight, 'borderRight-red': !iconRight && errors.has(field)}"
          :format="'YYYY-MM-DD'"
          :months="months"
          :weekdays="weekdays"
          :selectableYearRange="{from: new Date().getFullYear() - 100, to: new Date().getFullYear() }"
          class="input-personalizado-center-text"
          v-model="birthday"
        >
          <template v-slot:default="{toggle, inputValue}">
            <button
              @click="toggle"
              aria-placeholder="sad"
              class="toggle_birthday"
            >{{ inputValue || "Seleccione la fecha de nacimiento" }}</button>
          </template>
        </date-pick>
      </div>
      <div
        :class="{'input-error': errors.has(field)}"
        :style="{ 'width': params.isActiveHotel ? '100px;' : '60px'}"
        class="input-personalizado-icon-right"
        v-if="iconRight"
      >
        <div
          :class="{'input-error': errors.has(field)}"
          @click="yateToogle"
          class="input-personalizado-icon-right-item"
          v-if="vendor.id === 231"
        >
          <img alt="icon" src="@/assets/icons/boteActive.svg" style="min-width: 27px;" v-if="isYate" />
          <img alt="icon" src="@/assets/icons/addReservation/bote.svg" style="min-width: 27px;" v-else />
        </div>
        <div
          :class="{'input-error': errors.has(field)}"
          @click="hotelToogle"
          class="input-personalizado-icon-right-item"
          v-if="params.isActiveHotel"
        >
          <img
            alt="icon"
            src="@/assets/icons/addReservation/huesped_icon_activo.svg"
            style="height: 80%; width: 100%;min-width: 21px;"
            v-if="isHotel"
          />
          <img
            alt="icon"
            src="@/assets/icons/addReservation/huesped_icon_inactivo.svg"
            style="height: 80%; width: 100%;min-width: 21px;"
            v-else
          />
        </div>
        <div
          :class="{'input-error': errors.has(field)}"
          class="input-personalizado-icon-right-item"
          style="cursor: default;"
          v-if="!iconRightExact"
        >
          <div class="container-general-tags-precompro">
            <div v-if="params.isActiveRecommended && params.isActiveRecommended === 1" class="container-general-tags-precompro__tag" :class="{ 'container-general-tags-precompro__tag--active' : isRecommended || isRecommended === 1}" @click="recommendedToogle">
              <i class="icon-mks icon-recommended-reservation"></i>
            </div>
            <div v-if="params.isActiveHabitual && params.isActiveHabitual === 1" class="container-general-tags-precompro__tag" :class="{ 'container-general-tags-precompro__tag--active-habitual' : isHabitual || isHabitual === 1}" @click="habitualToogle">
              <i class="icon-mks icon-habitual-reservation"></i>
            </div>
            <div class="container-general-tags-precompro__tag" :class="{ 'container-general-tags-precompro__tag--active-vip' : isVip || isVip === 1}" @click="vipToogle">
              <i class="icon-mks icon-vip-reservation"></i>
            </div>
          </div>
        </div>
        <div
          :class="{'input-error': errors.has(field), 'without-border': borderNone}"
          @click="showPassword"
          class="input-personalizado-icon-right-item"
          v-if="iconRightExact && iconRightExact === 'eye'"
        >
          <img alt="icon" src="@/assets/icons/addReservation/eye.svg" />
        </div>
      </div>
    </div>
    <span class="text-error" v-if="errors.has(field)">{{ errors.first(field) }}</span>
    <span class="text-error" v-if="bag != null && !errors.has('email')">{{ bag }}</span>
  </div>
</template>
<script>
import DatePick from 'vue-date-pick';
export default {
  components: {
    DatePick
  },
  props: {
    iconLeft: {
      type: String
    },
    iconRight: {
      type: Boolean
    },
    iconRightExact: {
      type: String
    },
    placeholder: {
      type: String
    },
    field: {
      type: String
    },
    type: {
      type: String
    },
    rules: {
      type: String
    },
    info: {
      type: Object
    },
    createdAtInfo: {
      type: String
    },
    hostValueInfo: {
      type: Number || null
    },
    required: {
      type: Boolean
    },
    disabled: {
      type: Boolean
    },
    borderNone: {
      type: Boolean
    },
    formInfo: {
      type: Object
    },
    initInfo: {
      type: Object
    }
  },
  data () {
    return {
      inputhasPress: false,
      showPasswordText: false,
      value: '',
      createdAt: null,
      hostValue: null,
      isHotel: false,
      isVip: false,
      isRecommended: false,
      isHabitual: false,
      isYate: false,
      weekdays: [
        'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab', 'Dom'
      ],
      months: [
        'Enero', 'Febrero', 'Marzo', 'Abril',
        'Mayo', 'Junio', 'Julio', 'Agosto',
        'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
      ],
      birthday: null,
      bag: null
    };
  },
  mounted () {
    if (this.initInfo) {
      if (this.field) {
        this.value = this.initInfo[this.field] !== '' ? this.initInfo[this.field] : null;
      }
      if (this.initInfo.isVip) {
        this.isVip = this.initInfo.isVip;
      }
      if (this.initInfo.isRecommended) {
        this.isRecommended = this.initInfo.isRecommended;
      }
      if (this.initInfo.isHabitual) {
        this.isHabitual = this.initInfo.isHabitual;
      }
      if (this.initInfo.isYate) {
        this.isYate = this.initInfo.isYate;
      }
      if (this.initInfo.isHotel) {
        this.isHotel = this.initInfo.isHotel;
      }
      if (this.createdAtInfo) {
        this.createdAt = this.createdAtInfo;
      }
      if (this.hostValueInfo) {
        this.hostValue = this.hostValueInfo;
      }
    }
  },
  methods: {
    vipToogle () {
      this.isVip = !this.isVip;
    },
    recommendedToogle () {
      this.isRecommended = !this.isRecommended;
    },
    habitualToogle () {
      this.isHabitual = !this.isHabitual;
    },
    yateToogle () {
      this.isYate = !this.isYate;
    },
    hotelToogle () {
      this.isHotel = !this.isHotel;
    },
    showPassword () {
      this.showPasswordText = !this.showPasswordText;
    },
    keypress (e) {
      this.$emit('keypress', e);
    }
  },
  watch: {
    value () {
      if (this.value) {
        if (this.vendor.id === 231) {
          if (!this.iconRight) {
            this.$emit('changeValue', { [this.field]: this.value, isYate: this.isYate, inputPressed: this.inputhasPress });
          } else {
            this.$emit('changeValue', { [this.field]: this.value, isYate: this.isYate, isVip: this.isVip, inputPressed: this.inputhasPress, isHotel: this.isHotel, isRecommended: this.isRecommended, isHabitual: this.isHabitual });
          }
        } else {
          if (!this.iconRight) {
            this.$emit('changeValue', { [this.field]: this.value, inputPressed: this.inputhasPress });
          } else {
            this.$emit('changeValue', { [this.field]: this.value, isYate: this.isYate, isVip: this.isVip, inputPressed: this.inputhasPress, isHotel: this.isHotel, isRecommended: this.isRecommended, isHabitual: this.isHabitual });
          }
        }
        if (this.field === 'pinHost') {
          if (this.info.pin === this.value) {
            if (this.createdAt === this.hostValue) {
              this.createdAt = null;
              this.$emit('error', false);
            } else {
              this.createdAt = this.hostValue;
              this.$emit('error', false);
            }
            this.value = null;
            this.$emit('exactValue', false);
            this.$emit('getHostID', this.info.value);
          } else {
            this.$emit('error', true);
          }
        }
      } else {
        this.$emit('changeValue', { [this.field]: '', inputPressed: this.inputhasPress });
      }
    },
    isVip () {
      if (this.vendor.id === 231) {
        this.$emit('changeValue', { [this.field]: this.value, isYate: this.isYate, isVip: this.isVip, inputPressed: this.inputhasPress });
      } else {
        this.$emit('changeValue', { [this.field]: this.value, isVip: this.isVip, inputPressed: this.inputhasPress });
      }
    },
    isRecommended () {
      if (!this.iconRight) {
        this.$emit('changeValue', { [this.field]: this.value, isYate: this.isYate, inputPressed: this.inputhasPress });
      } else {
        this.$emit('changeValue', { [this.field]: this.value, isVip: this.isVip, inputPressed: this.inputhasPress, isRecommended: this.isRecommended });
      }
    },
    isHabitual () {
      if (!this.iconRight) {
        this.$emit('changeValue', { [this.field]: this.value, isYate: this.isYate, inputPressed: this.inputhasPress });
      } else {
        this.$emit('changeValue', { [this.field]: this.value, isVip: this.isVip, inputPressed: this.inputhasPress, isHabitual: this.isHabitual });
      }
    },
    isYate () {
      if (!this.iconRight) {
        this.$emit('changeValue', { [this.field]: this.value, isYate: this.isYate, inputPressed: this.inputhasPress });
      } else {
        this.$emit('changeValue', { [this.field]: this.value, isYate: this.isYate, isVip: this.isVip, inputPressed: this.inputhasPress });
      }
    },
    isHotel () {
      if (!this.iconRight) {
        this.$emit('changeValue', { [this.field]: this.value, isYate: this.isYate, inputPressed: this.inputhasPress });
      } else {
        this.$emit('changeValue', { [this.field]: this.value, isYate: this.isYate, isVip: this.isVip, inputPressed: this.inputhasPress, isHotel: this.isHotel });
      }
    },
    formInfo: {
      handler (data) {
        if (this.field === 'email') {
          this.value = data.email;
          if (data.email) {
            const regex = /^[a-zA-Z0-9_%+-]+(\.[a-zA-Z0-9_%+-]+)*@[a-zA-Z0-9]+(\.[a-zA-Z0-9]+)?\.[a-zA-Z]{2,}$/i;
            if (!regex.test(data.email)) {
              this.bag = 'El correo electrónico no es válido';
            } else {
              this.bag = null;
            }
            this.value = data.email;
          } else {
            this.bag = null;
          }
        }
        if (this.field === 'displayName') {
          if (data.displayName) {
            this.value = data.displayName;
          }
        }
        if (this.field === 'displayName' && this.iconRight) {
          if (data.isVip) {
            this.isVip = true;
          }
          if (data.isRecommended) {
            this.isRecommended = true;
          }
          if (data.isHabitual) {
            this.isHabitual = true;
          }
          if (data.isYate) {
            this.isYate = true;
          }
          if (data.isHotel) {
            this.isHotel = true;
          }
        }
      },
      deep: true

    },
    initInfo: {
      handler (data) {
        if (this.field === 'email') {
          this.value = data.email;
          if (data.email) {
            this.value = data.email;
          }
        }
        if (this.field === 'displayName') {
          if (data.displayName) {
            this.value = data.displayName;
          }
        }
      },
      deep: true
    },
    birthday () {
      if (this.field === 'birthday') this.$emit('changeValue', { [this.field]: this[this.field] });
    }
  }
};
</script>
<style lang="scss">
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
#input-personalizado {
  /* Chrome, Safari, Edge, Opera */

  margin-bottom: 10px;
  @media screen and (min-width: 600px) and (max-width: 1000px) {
    .emailContainer > & {
      margin-bottom: 5px;
    }
  }
  .input-personalizado {
    display: flex;
    width: 100%;
    height: 40px;
    border: 1px solid rgba(68, 75, 87, 0.5);
    border-radius: 10px;
    &-icon-left {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      width: 50px;
    }
    &-center {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;

      &-text {
        width: 100%;
        height: 40px;
        border-left: none;
        border-right: none;
        border-radius: unset;
        border: unset;
        outline: unset;
        background-color: transparent;
      }
      .password {
        -webkit-text-security: disc;
      }
      .show-password {
        -webkit-text-security: none;
      }
      .vdpComponent:hover {
        background-color: unset !important;
      }
      .toggle_birthday {
        height: 100%;
        width: 100%;
        border: unset;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: #303030;
        &:hover {
          background-color: unset;
          cursor: pointer;
        }
      }
    }
    &-icon-right {
      display: flex;
      justify-content: center;
      flex-direction: row;
      align-items: center;
      height: 40px;
      width: fit-content;
      border-left: none;
      position: relative;
      &-item {
        display: flex;
        border-left: 1px solid rgba(68, 75, 87, 0.5);
        /* width: 60px; */
        padding: 0 10px;
        justify-content: center;
        align-items: center;
        height: 30px;
        cursor: pointer;
      }
      .container-general-tags-precompro{
        display: flex;
        gap: 4px;
        &__tag{
          cursor: pointer;
          border-radius: 4px;
          background: #D3D6DA;
          width: 24px;
          height: 19px;
          display: flex;
          align-items: center;
          justify-content: center;
          &--active{
            background-color: #FFE500;
          }
          &--active-habitual{
            background-color: #3A65BA;
            i{
              background-color: #fff!important;
            }
          }
          &--active-vip{
            background-color: #F7BE56!important;
            i{
              background-color: #DC8744!important;
            }
          }
        }
      }
    }
    &-disabled {
      cursor: no-drop;
    }
    .without-border {
      border-left: unset;
    }
    .modify-width {
      width: 60px;
    }
    .borderRight {
      .input-personalizado-center-text {
        margin-right: 3px;
      }
    }
  }
  .text-error {
    color: red;
    font-family: "Roboto";
    font-size: 12px;
  }
  .input-error {
    border-color: red;
  }
}
</style>
